import React, { Component } from 'react'
import './room.css'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import '../ProductPage/productpage.css'
import Cookies from 'js-cookie'

//components
import InnerNavigation from '../../globals/InnerNavigation/InnerNavigation'
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
import CalcInput from '../../globals/CalcInput/CalcInput'
import Cart from '../../layouts/Cart/Cart'
import Login from '../Login/Login'
//images
import BlueIcon from '../../../images/icons/bluelogo.png'
import ArrowLeft from '../../../images/icons/whiteredleft.svg'
import ArrowLeftReg from'../../../images/original/2.svg'
import ArrowLeftMako from '../../../images/mako/sliderl.svg'
import sliderArrowRight from '../../../images/icons/whiteredright.svg'
import CancelCircle from '../../../images/icons/deskpopupcancel.svg'
import MakoDesktopArrow from '../../../images/icons/mako/2.svg'
import BlackEdit from '../../../images/icons/blackediticon.svg'
//configs
import {IMAGE_URL_LEVELS,IMAGE_URL,API_URL,USER_TOKEN,HOST_NAME} from '../../../configs/configs'
//tools
import {calculatePrices,ApiGet, CheckMobileOrTablet} from '../../../tools/tools'
//lang
import * as Hebrew from '../../../texts/hebrew'
//
import Slider1 from '../../../images/navcart/5.png'
import Slider2 from '../../../images/recommendationflow/4.png'
import Slider3 from '../../../images/categories1/4.png'
import Slider4 from '../../../images/navcart/6.png'


class Room extends Component {
    constructor(props){
        super(props)

        this.state = {
            typeOfMechiron:'',
            editMeasurementsMenu: false,
            recommendationPopup:false,
            loginPopup:false,
            length: this.props.levels.selectedLevelOne.x || 0,
            width: this.props.levels.selectedLevelOne.y || 0,
            height: this.props.levels.selectedLevelOne.z || 0,
        }

        this.length =  this.props.levels.selectedLevelOne.x || 0
        this.width =  this.props.levels.selectedLevelOne.y || 0
        this.height =  this.props.levels.selectedLevelOne.z || 0

    }

    componentDidMount() {
        if(!CheckMobileOrTablet()){
            this.setState({editMeasurementsMenu: true})
        }
        this.props.getRecommendation(this.props.match.params.levelOneId,this.props.typeOfMechiron)
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);

        //handle redux change to inputs
        let indexOfSelectedRoom = this.props.userCart.userCart.findIndex((room)=>{
            return room.level_one_id === this.props.match.params.levelOneId
        })
   
        if(indexOfSelectedRoom > -1){
            let newRoom = JSON.parse(JSON.stringify(this.props.userCart.userCart[indexOfSelectedRoom]))
            newRoom.x = this.state.length
            newRoom.y = this.state.width
            newRoom.z = this.state.height
            this.props.updateMeasurmentsFromCart(newRoom)
        }
    }

    handleClickOutside = (event) => {
        if (event.target.className.includes('curtain')) {
            this.setState({ recommendationPopup: false,loginPopup:false })
        }
    }
    
    
    async componentWillMount() {
        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})
       await this.getLevelsOnDirectUrlMount()
    }


    componentWillReceiveProps(nextProps) {
        let levelOne
        if(nextProps.levels.levelOnes !== this.props.levels.levelOnes && nextProps.levels.levelOnes.length > 0)
        levelOne = nextProps.levels.levelOnes.find(level=>{
            return level.level_one_id === this.props.match.params.levelOneId
        })
        if(levelOne)
        this.props.initUserCartRoom(levelOne,this.props.userCart)

        //handle redux change to inputs
        let indexOfSelectedRoom = this.props.userCart.userCart.findIndex((room)=>{
            return room.level_one_id === this.props.match.params.levelOneId
        })
  
       
        if(nextProps.userCart.userCart[indexOfSelectedRoom] && JSON.stringify(this.props.userCart.userCart) !== JSON.stringify(nextProps.userCart.userCart) && indexOfSelectedRoom > -1){
            this.setState({
                length: nextProps.userCart.userCart[indexOfSelectedRoom].x,
                width: nextProps.userCart.userCart[indexOfSelectedRoom].y,
                height: nextProps.userCart.userCart[indexOfSelectedRoom].z,
            })
        }
    }

    getLevelsOnDirectUrlMount = async() => {
        let levelOnesInit = this.props.levels.levelOnes
        if(Object.keys(levelOnesInit).length === 0 && levelOnesInit.constructor === Object) {
            this.props.saveVendors()
            await this.props.saveLevelOnes()
        }

        let selectedLevelOneInit = this.props.levels.selectedLevelOne
        if(selectedLevelOneInit.constructor === Object && Object.keys(selectedLevelOneInit.length === 0)) {
            this.props.levels.levelOnes.filter(async(item)=>{
                if (item.level_one_id === this.props.match.params.levelOneId) {
                    //handle redux change to inputs
                    let indexOfSelectedRoom = this.props.userCart.userCart.findIndex((room)=>{
                        return room.level_one_id === this.props.match.params.levelOneId
                    })

                    if(indexOfSelectedRoom === -1) {
                        this.setState({length: item.x, width: item.y, height: item.z })
                    } else {
                        this.setState({
                            length: this.props.userCart.userCart[indexOfSelectedRoom].x,
                            width: this.props.userCart.userCart[indexOfSelectedRoom].y,
                            height: this.props.userCart.userCart[indexOfSelectedRoom].z 
                        })
                    }
                    
                    
                    await this.props.saveSelectedLevelOneItem(item)
                }
            })
        }

        let levelTwoInit = this.props.levels.levelTwos
        if(Object.keys(levelTwoInit).length === 0 && levelTwoInit.constructor === Object) {
            await this.props.saveLevelTwos(this.props.match.params.levelOneId)
        }
    }

    
    onSliderInit = () => {
        // fix bug that moves the slider to the end on init
        setTimeout(() => { 
            if((window.innerWidth > 979 && this.state.typeOfMechiron === 'mako')){
                this.slider.slickGoTo(this.props.levels.mostViewed.length - 4, false) 
            } else {
                this.slider.slickGoTo(this.props.levels.mostViewed.length - 3.5, false)
            }
        }, 0);
    }

    preventScroll = () => {
        document.ontouchmove = function (e) {
            e.preventDefault();
        }
    }

    enableScroll = () => {
        document.ontouchmove = function (e) {
            return
        }
    }

    setCurrentLevelTwo = async(item) => {
        const { levelOneId } = this.props.match.params
        this.props.saveLevelThrees(item.level_two_id).then(()=>{
            this.props.saveSelectedLevelTwoItem(item).then(()=>{
                this.props.history.push(`/calculator/${levelOneId}/room/${item.level_two_id}`)
            })
        })

    }

    handleRouteChange = () => {
        // this.props.history.push("/recommendation/" + this.props.match.params.levelOneId)
        this.addBidToCart()
    }

    goToProdPage = async(item) => {
        let res = await ApiGet(API_URL + '/filter/getById/' + item.filter_id)
        let lvl3 = res.level_three_id
        lvl3 = lvl3.split(';')
        lvl3 = lvl3[0]
        await this.props.saveSelectedProduct(item)
        this.props.history.push(`/products/all/${item.level_two_id}/${lvl3}/${item.pid_id}`)
    }
    
    roomDesktopCallback = () => {
        this.props.activateCurtain()
        this.setState({recommendationPopup:true,loginPopup:false})
    }


    addBidToCart = async() => {

        const {desktopMako, desktop} = this.props
        const {levelOneId} = this.props.match.params

        this.setState({addLoader:true},async()=>{
            this.props.removeRoomsWithoutProducts()
            await this.props.initUserCartRoom(this.props.levels.selectedLevelOne,this.props.userCart)
            
            // setTimeout(()=>{ 
                Promise.all (this.props.levels.recommendation.map(async(item,index)=>{
                    let makeApiCall = index === this.props.levels.recommendation.length-1
                    this.props.addProductToCart(item,this.props.match.params.levelOneId,false)
                }))
    
                let lv1 = this.props.match.params.levelOneId
                let indexInCart = this.props.userCart.userCart.findIndex(item => {return item.level_one_id === lv1})
                this.props.history.push(`/cart/${indexInCart}/leveloneid/${lv1}/token`)
            // }, 0);

        })

    }

    //input 


    setValues = (name, value) => {

        let newValue = value
        if (typeof value === 'number') {
            newValue = value.toFixed(1)
        }
        newValue = Number(newValue)

        if (newValue < 0) {
            this.setState({ [name]: 0 },()=>{
                this.props.setMeasurements(this.state.length,this.state.width,this.state.height)
            })
        } else if (newValue > 999) {
            this.setState({ [name]: 999 },()=>{
                this.props.setMeasurements(this.state.length,this.state.width,this.state.height)
            })
        } else if (!newValue) {
            this.setState({ [name]: '' },()=>{
                this.props.setMeasurements(this.state.length,this.state.width,this.state.height)
            })
        } else {
            this.setState({ [name]: newValue },()=>{
                this.props.setMeasurements(this.state.length,this.state.width,this.state.height)
            })
        }
    }

    focusHandler = (inputName) => {
        this[inputName] = this.state[inputName]
        this.setState({
            [inputName] : ''
        })
    }

    blurHandler = (e) => {
        if(!this.state[e.target.name]) {
            this.setState({
                [e.target.name]: this[e.target.name]
            })
        }
    }

    


    render() {

        const { lang,desktop,desktopMako } = this.props
        const { levelTwos } = this.props.levels
        const {typeOfMechiron,recommendationPopup,loginPopup, length, width, height, editMeasurementsMenu} = this.state

        let isMobileOrTablet = CheckMobileOrTablet()

        let settings2 = {
            infinite: false,
            rtl: true,
            arrows: false,
            speed: 500,
            slidesToShow: (window.innerWidth > 979 && this.state.typeOfMechiron === 'mako') ? 4:3.5,
            slidesToScroll: (window.innerWidth > 979 && this.state.typeOfMechiron === 'mako') ? 4:3.5,
            initialSlide: 1,
            draggable:false
        };

        const { selectedLevelOne } = this.props.levels
        let newCopyLevelOne = {...selectedLevelOne}
        if(Object.keys(newCopyLevelOne).length === 0 && newCopyLevelOne.constructor === Object)
        newCopyLevelOne = {
            name:'כללי'
        }

        return (
            <div className="room">

                {loginPopup && desktop ? 
                    <span className="login__popup__wrapper">
                        <img src={CancelCircle} alt="cancel-popup" className="choice__calculator__input__x" onClick={()=>{this.setState({desktopPopupCalculator:false,loginPopup:false});this.props.disActivateCurtain()}}></img>
                        <div className="room__rec__popup" style={{overflow:'hidden'}}>
                            <Login roomDesktopCallback={this.roomDesktopCallback} lang={Hebrew.Login}/>
                        </div>
                    </span>

                    :
                    null
                }

                {/* {recommendationPopup && desktop ? 
                    <div className="room__rec__popup">
                        <Cart lang={Hebrew.Cart}/>
                    </div>
                    :
                    null
                } */}

                <InnerNavigation pages={['ראשי', newCopyLevelOne.name]} links={[this.props.levels.path[0], '/calculator/' + this.props.levels.path[1] + '/room']} />
                <h1>{newCopyLevelOne.name}</h1>

                <h2>המידות שלכם שונות? התאימו אותן כאן. אל דאגה, ניתן לשנות גם בהמשך.</h2>

                { editMeasurementsMenu ? 
                    <div className="room__input-section">
                        <section>
                            <h4>מידות החדר</h4>
                            <button  onClick={()=>{this.setState({editMeasurementsMenu: false})}}>אישור</button>
                        </section>
                        <CalcInput 
                            header = {'אורך החדר'}
                            typeOfMechiron = {this.state.typeOfMechiron}
                            value = {length}
                            typeOfInput = {'length'}
                            setValues = {this.setValues}
                            focusHandler = {this.focusHandler}
                            blurHandler = {this.blurHandler}
                        />
                        <CalcInput 
                            header = {'רוחב החדר'}
                            typeOfMechiron = {this.state.typeOfMechiron}
                            value = {width}
                            typeOfInput = {'width'}
                            setValues = {this.setValues}
                            focusHandler = {this.focusHandler}
                            blurHandler = {this.blurHandler}
                        />
                        <CalcInput 
                            header = {'גובה החדר'}
                            typeOfMechiron = {this.state.typeOfMechiron}
                            value = {height}
                            typeOfInput = {'height'}
                            setValues = {this.setValues}
                            focusHandler = {this.focusHandler}
                            blurHandler = {this.blurHandler}
                        />
                    </div>
                    :
                    <div className="room__input-section">
                        <section>
                            <h4>מידות החדר</h4>
                            <figure onClick={()=>{this.setState({editMeasurementsMenu: true})}}> <img src={BlackEdit} alt="edit"/> </figure>
                        </section>
                        <span>
                            {'אורך ' + this.state.length + ' ס״מ' + ' / ' + 'רוחב ' + this.state.width + ' ס״מ' + ' / ' + 'גובה ' + this.state.height + ' ס״מ'}
                        </span>
                    </div>
                }
               
                {/* <h2 style={{fontFamily:"f400",opacity:'0.6'}}>תוכלו להרכיב את אפשרות השיפוץ בעצמכם או לקבל הצעה לשיפוץ  {'ה'+ newCopyLevelOne.name} מאנשי המקצוע שלנו</h2> */}
                

                <div className="room__categories__item room__categories__item--recommendation" style={{cursor:'unset'}}>
                    <section style={{cursor:'unset'}}>
                        <img src={BlueIcon} alt="logo-blue-top" />
                        <section >
                            <ImageComponent src={IMAGE_URL_LEVELS + 'ace/' + newCopyLevelOne.picture_l1_id + '.png'} alt={newCopyLevelOne.name} />
                        </section>
                        <div><p>{lang.aceRecommendation}</p></div>
                        {desktopMako ?
                            <aside >
                                <p>רוצים לדעת מה צריך לעשות בשיפוץ {newCopyLevelOne.name}? הכנו אומדן עלות עבורכם</p>
                                <button onClick={()=>{this.handleRouteChange()}}>אני רוצה להתחיל</button>
                            </aside>
                            :desktop ?
                            <aside>
                                <p >רוצים לדעת מה צריך לעשות בשיפוץ {newCopyLevelOne.name}? הכנו אומדן עלות עבורכם</p>
                                <button onClick={()=>{this.handleRouteChange()}}>אני רוצה להתחיל</button>
                            </aside>
                            :
                            <aside>
                               <p>רוצים לדעת מה צריך לעשות בשיפוץ {newCopyLevelOne.name}? הכנו אומדן עלות עבורכם</p>
                               <button onClick={()=>{this.handleRouteChange()}}>אני רוצה להתחיל</button>
                            </aside>

                        } 

                    </section>

                    {/* <div>
                        <p>לחצו על התמונה לקבלת המלצה לכל מרכיבי שיפוץ <span>{selectedLevelOne.name}</span> והתאימו לטעמכם, או בחרו את מרכיבי השיפוץ בעצמכם מהפרקים מטה</p>
                    </div> */}

                    <h1>להכנת אומדן לפי פרקים</h1>
                    <h3>בחרו את עבודות השיפוץ הרצויות</h3>

                    {/* {desktop ? null:<p>כנסו לפרקים המופעים למטה והוסיפו לרשימה שלכם את  הפריטים והעבודות שברצונכם לעשות. </p>} */}
                </div>

                <div className="room__categories">

                    {Object.keys(levelTwos).length > 0 && levelTwos.map(item => {
                        return <div id={"UC: Clicked On Level_Two- " + item.name} key={item.level_two_id} className="room__categories__item" key={item.level_two_id} onClick={()=>{this.setCurrentLevelTwo(item)}}>
                            <section id={"UC: Clicked On Level_Two- " + item.name}>
                                <section id={"UC: Clicked On Level_Two- " + item.name}>
                                    <ImageComponent src={IMAGE_URL_LEVELS + 'ace/' + item.level_two_id + '.png'} alt="room" />
                                    <img id={"UC: Clicked On Level_Two- " + item.name} src={IMAGE_URL_LEVELS + 'ace/' + item.vendor_id + 'S.svg'} alt="logo" />
                                </section>

                            </section>

                            <div id={"UC: Clicked On Level_Two- " + item.name}>
                                <h3 id={"UC: Clicked On Level_Two- " + item.name}>{item.name}</h3>
                                <p id={"UC: Clicked On Level_Two- " + item.name}>{item.description}</p>
                            </div>
                        </div>
                    })}
                </div>


                {desktop ? null:<h3>{lang.mostViewed}</h3>}
             
                <div className="most__wanted" style={desktop ? {display:'none'}:{}}>
                    <div className="product-slider">
                        <Slider {...settings2} ref={c => (this.slider = c)} onInit={() => { this.onSliderInit() }}>
                            {this.props.levels.mostViewed.map(item => {
                               
                                // let totalPrice = calculatePrices(item,'work') + calculatePrices(item,'product')
                              
                                return <div key={item.name} onClick={()=>{this.goToProdPage(item)}} className="product-slider__item" onTouchStart={()=>{this.preventScroll()}} onTouchEnd={()=>{this.enableScroll()}}>
                                    <ImageComponent src={IMAGE_URL + '/ace/' + item.picture_id + '.png'} alt="product" />
                                    <h1>{item.name}</h1>
                                    <h5>{}</h5>
                                    <h6>{}</h6>
                                </div>
                            })}
                        </Slider>
                        <img src={desktopMako ? MakoDesktopArrow:typeOfMechiron === 'ace' ? ArrowLeft: typeOfMechiron === 'mako' ? ArrowLeftMako:ArrowLeftReg} alt="arrow" onClick={() => { this.slider.slickPrev() }} />
                    </div>
                </div>
                



            </div>
        );
    }
}


function stateToProps({ levels,userCart }) {
    return { levels,userCart }
}
  
export default withRouter(connect(stateToProps, actions)(Room))