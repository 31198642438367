import { API_URL } from '../configs/configs'
import { ApiGet, ApiPostWithBody } from '../tools/tools'

import {
    INIT_USER_CART_ROOM,
    ADD_PRODUCT_TO_CART,
    UPDATE_QUANTITY_OF_CART_ITEM,
    REPLACE_CART_ITEM,
    EDIT_CART_ITEM,
    REMOVE_ITEM_FROM_CART,
    SAVE_USER_ID,
    SAVE_REGULATION,
    SAVE_USE_DETAILS,
    SAVE_MEASUREMENT_FROM_CART,
    DELETE_ROOM_FROM_CART,
    REMOVE_ROOMS_WITHOUT_PRODUCTS,
    SAVE_INITIAL_CART_DATA,
    SET_USER_CART_ID_TO_EDIT_AS_PRO,
    RESET_CART,
    SHOW_BACK_TO_INTERFACE_BTN,
    HIDE_BACK_TO_INTERFACE_BTN,
    SAVE_URL_TO_REDIRECT_INTERFACE,
    REMOVE_URL_TO_REDIRECT_INTERFACE,
    SAVE_OFFSET_FOR_ITEM_CHANGE,
    SAVE_PID_FOR_OFFSET_CHANGE,
    SET_USER_IS_ACE_PRO
} from './types'

export const saveUrlToRedirectToProsInterface = (payload) => dispatch =>{
    dispatch({ type: SAVE_URL_TO_REDIRECT_INTERFACE, payload })
}

export const removeUrlToRedirectToProsInterface = () => dispatch =>{
    dispatch({ type: REMOVE_URL_TO_REDIRECT_INTERFACE })
}

export const setUserIsAcePro = () =>  dispatch => {
    dispatch({ type: SET_USER_IS_ACE_PRO })
}

export const setUserCartToEditAsPro = (cartId, cart) => dispatch => {
    dispatch({ type: SET_USER_CART_ID_TO_EDIT_AS_PRO, payload:{cartId, cart} })
    
}

export const resetCartInStore = () => dispatch => {
    dispatch({ type: RESET_CART })
}

export const initUserCartRoom = (room,userCart) => async dispatch => {
 
    var body = {
        room,
        cart_id:userCart.cartId,
        user_id:userCart.userId
    }
    //
    body.room.initializedByUser = true

    let newRoom = {}
    const checkRoleExistence = param => userCart.userCart.some( ({level_one_id}) => level_one_id === param)
    if(checkRoleExistence(room.level_one_id)){
        newRoom = {...room}
        //
        newRoom.initializedByUser = true
        dispatch({ type: INIT_USER_CART_ROOM, payload: newRoom })
    } else {
        newRoom = {...room}
        //
        newRoom.initializedByUser = true
        let res = await ApiPostWithBody(API_URL + '/room/new',body)

        if(res && res.ok) {
            newRoom.room_id = res.result
            dispatch({ type: INIT_USER_CART_ROOM, payload: newRoom })
        } else {
            newRoom = {...room}
            dispatch({ type: INIT_USER_CART_ROOM, payload: newRoom })
        }
    }
   
}

export const addProductToCart = (product,levelOneId,makeApiCall = true) => async dispatch => {
    dispatch({ type: ADD_PRODUCT_TO_CART, payload: {product,levelOneId,makeApiCall} })
}

export const updateQuantityOfItem = (index,item) => dispatch => {
    dispatch({ type: UPDATE_QUANTITY_OF_CART_ITEM, payload: {index,item} })
}

export const removeCartItem = (roomIndex,itemToRemove) => dispatch => {
    dispatch({ type: REMOVE_ITEM_FROM_CART, payload: {roomIndex,itemToRemove} })
}

export const replaceCartItem = (levelOneId,pidToReplace, newProd) => dispatch => {
    dispatch({ type: REPLACE_CART_ITEM, payload: {levelOneId,pidToReplace, newProd} })
}

export const editCartItem = (levelOneId,pidToReplace, newProd) => dispatch => {
    dispatch({ type: EDIT_CART_ITEM, payload: {levelOneId,pidToReplace, newProd} })
}

export const saveRegulation = () => dispatch => {
    dispatch({ type: SAVE_REGULATION })
}

export const saveUserDetails = (payload) => dispatch => {
    dispatch({ type: SAVE_USE_DETAILS, payload })
}

export const updateMeasurmentsFromCart = (payload) => dispatch => {
    console.log(payload)
    //payload is whole room updated
    dispatch({ type: SAVE_MEASUREMENT_FROM_CART, payload })
}

export const deleteRoomFromCart = (payload) => async dispatch => {
    dispatch({ type: DELETE_ROOM_FROM_CART, payload })
}

export const removeRoomsWithoutProducts = () => dispatch => {
    dispatch({ type: REMOVE_ROOMS_WITHOUT_PRODUCTS })
}

export const showBackToInterfaceButton = (payload) => dispatch => {
    dispatch({ type: SHOW_BACK_TO_INTERFACE_BTN, payload })
}

export const hideBackToInterfaceBtn = () => dispatch =>{
    dispatch({ type: HIDE_BACK_TO_INTERFACE_BTN })
}

export const saveOffsetForItemChange = (payload) => dispatch =>{
    dispatch({ type: SAVE_OFFSET_FOR_ITEM_CHANGE, payload})
}

export const saveProductIdForOffsetChange = (payload) => dispatch =>{
    dispatch({ type: SAVE_PID_FOR_OFFSET_CHANGE, payload})
}



//save userid

export const saveUserId = (userId) => dispatch => {
    dispatch({ type: SAVE_USER_ID, payload: userId })
}

//save cart initial data from api
export const saveInitialCartData =(cartId,cart)=> dispatch => {
    dispatch({ type: SAVE_INITIAL_CART_DATA, payload: {cartId,cart} })
}