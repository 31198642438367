import React, { Component } from 'react'
import './newcart.css'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
import { withRouter } from 'react-router-dom'
import Cookies from 'js-cookie'
import { CSVLink, CSVDownload } from "react-csv";

//comps
import InnerNavigation from '../../globals/InnerNavigation/InnerNavigation'
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
import NewCartItem from './NewCartItem'
import {Banner} from '../../layouts/Aces/Banner'
//img
import Page from '../../../images/icons/redPage.svg'
import PageReg from '../../../images/original/18.svg'
import PageMako from '../../../images/mako/2.svg'
import Width from '../../../images/icons/width.svg'
import Length from '../../../images/icons/length.svg'
import Height from '../../../images/icons/height.svg'
import WidthReg from '../../../images/original/9.svg'
import LengthReg from '../../../images/original/8.svg'
import HeightReg from '../../../images/original/10.svg'
import PlusIcon from '../../../images/icons/plus.svg'
import MakoPlusIcon from '../../../images/icons/mako/3.svg'
import Printer from '../../../images/icons/print.svg'

import WidthMako from '../../../images/mako/7.svg'
import LengthMako from '../../../images/mako/8.svg'
import HeightMako from '../../../images/mako/9.svg'

import BlueArrow from '../../../images/original/16.svg'
import LightBlueArrow from '../../../images/mako/4.svg'
import GeneralRoom from '../../../images/rooms/general.png'
import CartBig from '../../../images/icons/cartBig.svg'

import PenW from '../../../images/icons/penred.svg'
import MakoPenW from '../../../images/icons/mako/5.svg'
import ThrashR from '../../../images/icons/redtrash.svg'
import MakoThrashR from '../../../images/icons/mako/4.svg'

import RegPenW from '../../../images/icons/penreg.svg'
import RegThrashR from '../../../images/icons/regthrash.svg'


import RedArrow from '../../../images/icons/redarrow.svg'
import MenuDots from '../../../images/icons/3dots.svg'
import MenuDotsB from '../../../images/icons/3dotsb.svg'

import CancelBlack from '../../../images/icons/cancelx.svg'

//banner img
import Guy from '../../../images/banner/guy.png'
import CheckAce from '../../../images/banner/checkace.svg'
import CheckMako from '../../../images/banner/checkmako.svg'
import LinesAce from '../../../images/banner/linesace.svg'
import LinesMako from '../../../images/banner/linesmako.svg'
//configs
import {IMAGE_URL_LEVELS,HOST_NAME,API_URL,USER_TOKEN} from '../../../configs/configs'
//tools
import {ApiGet,calculatePrices,CheckMobileOrTablet,CSVBuilder} from '../../../tools/tools'
//lang 
import * as Hebrew from '../../../texts/hebrew'
import ProductSlider from '../ProductPage/parts/ProductSlider';


class NewCart extends Component {

    constructor(){
        super()

        this.state = {
            popup:false,
            typeOfMechiron: '',
            measurementsPopup:false,
            currentRoomPicked: '',
            initCartWithFirstIndex:false,
            roomEditMenu:false,
            selectedItem:{},
            csvData:''
        }

        this.x = ''
        this.y = ''
        this.z = ''
    }

    
    componentWillMount() {
        this.props.removeRoomsWithoutProducts()
        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})
    }

    async componentDidMount() {
        window.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClick);
    }

    handleClick = (e) => {        
        if(e.target.className.includes('curtain') || e.target.className === 'minimun__price__popup'){
            let aceCurtain = document.getElementsByClassName("curtain__ace")[0];
            if(aceCurtain != null)
            aceCurtain.style.display="unset"
            let Curtain = document.getElementsByClassName("curtain")[0];
            Curtain.style.zIndex="-1"
            this.props.disActivateCurtain()
            
            this.setState({roomEditMenu:false})
        }

        if(!this.props.desktop && !this.props.desktopMako){
            if(e.target.className.includes('curtain')){
                this.props.disActivateCurtain()
            }
        }
    }

    async componentWillReceiveProps(nextProps) {
        if(this.props.userCart.userCart.length !== nextProps.userCart.userCart.length){
            if(this.props.desktop || this.props.desktopMako)
            this.props.removeRoomsWithoutProducts()
        }

        if(this.props.userCart.userId && !this.state.cartInit) {
            let cart = await ApiGet(API_URL + '/cart/get?user_id=' + this.props.userCart.userId)
            this.props.saveInitialCartData(cart.cart_id,cart.cart)
            this.setState({cartInit:true})
        }

        if(this.props.userCart.userCart.length !== nextProps.userCart.userCart.length){
            this.requestCsvData(nextProps.userCart.userCart)
        }

    }
    

    seeIfPopup = () => {
       
            let priceCounter = 0
            this.props.userCart.userCart.map(item=>{
                item.products.map(product=>{
                    let formula = product.qty_formula
                    formula = formula.toString()
                    formula = formula.replace(/x/gi, item.x);
                    formula = formula.replace(/y/gi, item.y);
                    formula = formula.replace(/z/gi, item.z);
                    formula = eval(formula)
                    let total
                    if(product.only_work){
                        total = formula * (calculatePrices(product,'work',{x:item.x,y:item.y,z:item.z}))
                    } else {
                        total = formula * (calculatePrices(product,'work',{x:item.x,y:item.y,z:item.z}) + calculatePrices(product,'product',{x:item.x,y:item.y,z:item.z}))
                    }
                    total = parseFloat(Math.round(total * 100) / 100)
                    priceCounter += total
                })
            })

            priceCounter = priceCounter * 1.17

            if(priceCounter < 10000){
                this.setState({popup:true})
            } else {
                this.props.history.push('/aces/cart')
            }
       
    }

    cancelPopup = (e) => {
        if(e.target.className.includes('minimun__price__popup') || e.target.nodeName === 'BUTTON')
        this.setState({popup:false})
        // this.props.history.push('/aces/cart')
    }

    cancelPopupMeasurments = (e) => {
        if(e.target.className.includes('minimun__price__popup'))
        this.setState({measurementsPopup:false})
    }


    openMeasurementPopoup = (e,item) => {
        e.stopPropagation()
        if(this.props.disActivateCurtain)
        this.props.disActivateCurtain()
        let Curtain = document.getElementsByClassName("curtain")[0];

        this.setState({
            measurementsPopup:true,
            currentRoomPicked:item,
            roomEditMenu:false
        })
    }

    updateMeasurments = (room) => {
        this.props.updateMeasurmentsFromCart(room)
        this.setState({measurementsPopup:false})
    }

    openRoomEditMenu = (e,item) => {
        e.stopPropagation()
        let aceCurtain = document.getElementsByClassName("curtain__ace")[0];
        if(aceCurtain != null)
        aceCurtain.style.display="none"
        let Curtain = document.getElementsByClassName("curtain")[0];
        Curtain.style.transition="all .2s"
        Curtain.style.zIndex="11111"
        this.props.activateCurtain()
        this.setState({roomEditMenu:true,selectedItem:item})
    }

    //

    setValues = (name, value) => {
        let newValue = value
        let currentRoomPickedCopy = {...this.state.currentRoomPicked}

        if (newValue < 0) {
            currentRoomPickedCopy[name] = 0
            this.setState({
                currentRoomPicked:currentRoomPickedCopy
            })
        } else if (newValue > 999) {
            currentRoomPickedCopy[name] = 999
            this.setState({
                currentRoomPicked:currentRoomPickedCopy
            })
        } else if (!newValue) {
            currentRoomPickedCopy[name] = ''
            this.setState({
                currentRoomPicked:currentRoomPickedCopy
            })
        } else {
            currentRoomPickedCopy[name] = Number(newValue)
            this.setState({
                currentRoomPicked:currentRoomPickedCopy
            })
        }

        
    }

    focusHandler = (e) => {
        this[e.target.name] = this.state.currentRoomPicked[e.target.name]
        let currentRoomPickedCopy = {...this.state.currentRoomPicked}
        currentRoomPickedCopy[e.target.name] = ''
        this.setState({
            currentRoomPicked:currentRoomPickedCopy
        })
    }

    blurHandler = (e) => {
        if(!this.state.currentRoomPicked[e.target.name]) {
            let currentRoomPickedCopy = {...this.state.currentRoomPicked}
            currentRoomPickedCopy[e.target.name] = this[e.target.name]
            this.setState({
                currentRoomPicked:currentRoomPickedCopy
            })
        }
    }
    
    //


    deleteRoom = async(item) => {
        let cart = this.props.userCart.userCart
        let paramIndex = this.props.match.params.indexInCart 
        let itemIndex = cart.findIndex((iteminCart,i)=>{
            return cart[i].level_one_id === item.level_one_id
        })

        
        if(this.props.disActivateCurtain)
        this.props.disActivateCurtain()
        this.setState({roomEditMenu:false})
        await this.props.deleteRoomFromCart(item.room_id)

        if(this.props.desktopMako || this.props.desktop) {
            if(cart[paramIndex] == null)
            return
            if(cart[paramIndex].level_one_id === item.level_one_id){
                this.setState({},()=>{
                    if(paramIndex > 0) {
                        paramIndex = paramIndex -1
                        this.props.history.push(`/cart/${paramIndex}/leveloneid/${cart[paramIndex].level_one_id}/token`)
                    } else {
                        if(cart[1] != null)
                        this.props.history.push(`/cart/0/leveloneid/${cart[1].level_one_id}/token`)
                    }
                })
            } else if(itemIndex < paramIndex){
                this.props.history.push(`/cart/${paramIndex -1}/leveloneid/${cart[paramIndex].level_one_id}/token`)
            } else {
                this.props.history.push(`/cart/${paramIndex}/leveloneid/${cart[paramIndex].level_one_id}/token`)
            }
          
        }
    }


    openMobileBanner = () => {
        this.setState({mobileBanner:true})
    }

    requestCsvData = (cart) => {
        return CSVBuilder(cart)
    }

    handleAuthentication = () => {
        if(!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null) {
            this.props.activateCurtain()
            if(this.props.userCart.userCart.length > 0){
                this.props.activateLoginPopup(`/cart/0/leveloneid/${this.props.userCart.userCart[0].level_one_id}/token`)
            } else {
                this.props.activateLoginPopup('/cart/token')
            }
            
        } else {
            Cookies.remove(USER_TOKEN)
            this.props.history.push(`/`)
            this.props.resetCart()
        }
    }

    handleLoginIsExport = () => {
        if(this.props.userCart.userCart && this.props.userCart.userCart.length > 0){
            this.props.activateLoginPopup(`/cart/0/leveloneid/${this.props.userCart.userCart[0].level_one_id}/token`)
        } else {
            this.props.activateLoginPopup('/cart/token')
        }
        
        this.props.activateCurtain()
    }
    


    render() {
      
        const {popup,typeOfMechiron,measurementsPopup,currentRoomPicked,roomEditMenu} = this.state
        const {lang,desktop,desktopMako} = this.props
        const {userCart} = this.props.userCart


        let totalProductsInCart = 0
        let totalCartPrice = 0
        userCart.map(item=>{
            totalProductsInCart+=item.products.length
            item.products.map(product=>{
                let formula = product.qty_formula
                formula = formula.toString()
                formula = formula.replace(/x/gi, item.x);
                formula = formula.replace(/y/gi, item.y);
                formula = formula.replace(/z/gi, item.z);
                formula = eval(formula)
                let total
                if(product.only_work){
                    total = formula * (calculatePrices(product,'work',{x:item.x,y:item.y,z:item.z}))
                } else {
                    total = formula * (calculatePrices(product,'work',{x:item.x,y:item.y,z:item.z}) + calculatePrices(product,'product',{x:item.x,y:item.y,z:item.z}))
                }
                
                total = total * 1.17
                total = parseFloat(Math.round(total * 100) / 100)
                totalCartPrice += total
            })
        })

        totalCartPrice = 10000 - Math.ceil(totalCartPrice)
        totalCartPrice = totalCartPrice.toLocaleString()

        let mobileOrTablet = CheckMobileOrTablet()

        let empty = true
        
        userCart.map(item=>{
            if(item.products.length > 0)
            empty = false
        })

        let csvData = ''
        if(userCart != null){
            csvData = this.requestCsvData(userCart)
        }

        return (
            <div className="newcart newcart__desktop"  style={empty && (desktop||desktopMako) ? {minHeight:'calc(100vh - 250px)'}:{}}>

                <aside className={roomEditMenu ? "aces__area-filter aces__area-filter--active":"aces__area-filter"}>
                    <p>אפשרויות חדר</p>
                    <div className="newcart__menu__options__container" onClick={(e)=>{this.openMeasurementPopoup(e,this.state.selectedItem)}}>
                        <span>
                            <img 
                                src={typeOfMechiron === 'ace' ? PenW:typeOfMechiron === 'mako' ? MakoPenW:RegPenW} 
                                alt="change-measurements"
                            />
                        </span>
                        <p>שנה מידות חדר</p>
                    </div>
                    <div className="newcart__menu__options__container" onClick={(e)=>{this.deleteRoom(this.state.selectedItem)}}>
                        <span>
                            <img 
                                src={typeOfMechiron === 'ace' ? ThrashR:typeOfMechiron === 'mako' ? MakoThrashR:RegThrashR} 
                                alt="delete-room"
                            />
                        </span>
                        <p>מחק חדר</p>
                    </div>
                    
                </aside>


                {desktop || desktopMako ? 
                <div className="newcart newcart__desktop__top">
                    {desktopMako ? <InnerNavigation pages={['הרשימה שלי']} links={['/']}/>:null}
                    <h1>הרשימה שלי</h1>
                </div>
                :
                null
                }


                { (!desktop || !desktopMako) && this.state.mobileBanner ? 
                    <div className="newcart__mobile-banner">
                        <div className="newcart__mobile-banner--content">
                            <aside onClick={()=>{this.setState({mobileBanner:false})}}><img src={CancelBlack} alt="close"/></aside>
                            <section>
                                <h1>למה לבחור בבעלי המקצוע שלנו?</h1>
                                <span></span>
                                <span></span>
                            </section>
                            <section>
                                <span>
                                    <img src={typeOfMechiron === 'ace' ? CheckAce:CheckMako} alt="check"/>
                                    <p>מחויבים למחירי העבודות לפי המחירון.</p>
                                </span>
                                <span>
                                    <img src={typeOfMechiron === 'ace' ? CheckAce:CheckMako} alt="check"/>
                                    <p>תנאי תשלום ייחודיים שמגנים עלייך בשלבי השיפוץ והבנייה.</p>
                                </span>
                                <span>
                                    <img src={typeOfMechiron === 'ace' ? CheckAce:CheckMako} alt="check"/>
                                    <p>אחריות לשנה על כל העבודות. שנתיים על אינסטלציה וחשמל!</p>
                                </span>
                                <span>
                                    <img src={typeOfMechiron === 'ace' ? CheckAce:CheckMako} alt="check"/>
                                    <p>הנכס שלך מבוטח לכל תקופת השיפוץ והבנייה.</p>
                                </span>
                            </section>
                            <section>
                                <img src={Guy} alt="pro"/>
                                <button onClick={()=>{this.props.history.push('/aces/cart')}}>אני רוצה לקבל הצעה</button>
                            </section>
                        </div>
                    </div>
                    :
                    null
                }
                


                {desktop || desktopMako ? 
                    <div className="newcart__desktop__buttons-section">

                        <span>
                            <button id="UC: Clicked Add Room In Cart" onClick={()=>{this.props.history.push('/choice')}}>
                                <img id="UC: Clicked Add Room In Cart" src={typeOfMechiron === 'mako' ? MakoPlusIcon:PlusIcon} alt="add-item"/>
                                <p id="UC: Clicked Add Room In Cart">הוספת חדר</p>
                            </button>
                            <button id="UC: enter Professional Page From Cart" onClick={()=>{this.seeIfPopup()}}>
                                <p id="UC: enter Professional Page From Cart">לקבלת הצעה</p>
                            </button>
                        </span>

                        {/* <button style={empty ? {display:'none'}:{}} onClick={()=>{this.props.history.push(`/calculator/${this.props.match.params.levelOneId}/room`)}}>
                            <img src={typeOfMechiron === 'mako' ? MakoPlusIcon:PlusIcon} alt="add-item"/>
                            <p>הוספת פריט</p>
                        </button> */}

                        {!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null ?
                            <button
                                style={{  backgroundColor:'rgba(0, 0, 0, 0.1)',fontSize:'14px',width:'160px'}}
                                onClick={()=>{this.handleAuthentication()}}
                            >שמור את הרשימה שלי</button>
                            :
                            null
                        }


                    </div>
                    :
                    null
                }


                {totalProductsInCart === 0  ? <div className="newcart__empty-cart"><img src={CartBig} alt="empty-cart"/><p>עגלת הפריטים שלך ריקה</p></div>:null}

                <div className="newcart__desktop-grid" style={empty ? {display:'none'}:{}}>
                
                    <div className={popup ? "minimun__price__popup minimun__price__popup--active":"minimun__price__popup"} onClick={(e)=>{this.cancelPopup(e)}}>
                        <div>
                            <img src={typeOfMechiron === 'ace' ? Page: typeOfMechiron === 'mako' ? PageMako:PageReg} alt="less-then-10k"/>
                            <p>אנשי המקצוע שלנו לא תומכים בעבודות הקטנות מ-10,000 ₪</p>
                            <p>חסר לכם עוד {totalCartPrice} ₪</p>
                            
                            <button onClick={()=>{this.props.history.push('/aces/cart')}}>המשך בכל זאת </button>
                        </div>
                    </div>

                   {desktop || desktopMako ? <div className="newcart__desktop-grid__header">רשימת חדרים</div>:null} 


                    

                    <div className={measurementsPopup ? "minimun__price__popup minimun__price__popup--active":"minimun__price__popup"}  onClick={(e)=>{this.cancelPopupMeasurments(e)}}>
                        <section>
                            <header>מידות {currentRoomPicked.name}</header>

                            <div className="calculator__input">
                            <label htmlFor="x"></label>
                            <aside>{lang.inputLength}</aside>
                            <div>
                                <img src={typeOfMechiron === 'ace' ? Length: typeOfMechiron === 'mako' ? LengthMako:LengthReg} alt="x" />
                                <input type="number" id="x" value={currentRoomPicked.x} name="x" onChange={(e) => { this.setValues(e.target.name, e.target.value) }} onFocus={(e)=>{this.focusHandler(e)}} onBlur={(e)=>{this.blurHandler(e)}}/>
                                <p>ס"מ</p>
                            </div>

                            {
                                !mobileOrTablet ?
                                    <div>
                                        <span>
                                            <img src={typeOfMechiron === 'ace' ? RedArrow: typeOfMechiron === 'mako' ? LightBlueArrow:BlueArrow} alt="arrow" onClick={() => { this.setValues('x', currentRoomPicked.x + 1) }} />
                                            <img src={typeOfMechiron === 'ace' ? RedArrow: typeOfMechiron === 'mako' ? LightBlueArrow:BlueArrow} alt="arrow" onClick={() => { this.setValues('x', currentRoomPicked.x - 1) }} />
                                        </span>
                                    </div>
                                    :
                                    <div style={{background:'transparent'}}></div>
                            }

                        </div>

                        <div className="calculator__input">
                            <label htmlFor="y"></label>
                            <aside>{lang.inputWidth}</aside>
                            <div>
                                <img src={typeOfMechiron === 'ace' ? Width: typeOfMechiron === 'mako' ? WidthMako:WidthReg} alt="y" />
                                <input type="number" id="y" value={currentRoomPicked.y} name="y" onChange={(e) => { this.setValues(e.target.name, e.target.value) }} onFocus={(e)=>{this.focusHandler(e)}} onBlur={(e)=>{this.blurHandler(e)}}/>
                                <p>ס"מ</p>
                            </div>
                            {
                                !mobileOrTablet ?
                                    <div>
                                        <span>
                                            <img src={typeOfMechiron === 'ace' ? RedArrow: typeOfMechiron === 'mako' ? LightBlueArrow:BlueArrow} alt="arrow" onClick={() => { this.setValues('y', currentRoomPicked.y + 1) }} />
                                            <img src={typeOfMechiron === 'ace' ? RedArrow: typeOfMechiron === 'mako' ? LightBlueArrow:BlueArrow} alt="arrow" onClick={() => { this.setValues('y', currentRoomPicked.y - 1) }} />
                                        </span>
                                    </div>
                                    :
                                    <div style={{background:'transparent'}}></div>
                            }
                        </div>

                        <div className="calculator__input">
                            <label htmlFor="z"></label>
                            <aside>{lang.inputHeight}</aside>
                            <div>
                                <img src={typeOfMechiron === 'ace' ? Height: typeOfMechiron === 'mako' ? HeightMako:HeightReg} alt="z" />
                                <input type="number" id="z" value={currentRoomPicked.z} name="z" onChange={(e) => { this.setValues(e.target.name, e.target.value) }} onFocus={(e)=>{this.focusHandler(e)}} onBlur={(e)=>{this.blurHandler(e)}}/>
                                <p>ס"מ</p>
                            </div>
                            {
                                !mobileOrTablet ?
                                    <div>
                                        <span>
                                            <img src={typeOfMechiron === 'ace' ? RedArrow: typeOfMechiron === 'mako' ? LightBlueArrow:BlueArrow} alt="arrow" onClick={() => { this.setValues('z', currentRoomPicked.z + 1) }} />
                                            <img src={typeOfMechiron === 'ace' ? RedArrow: typeOfMechiron === 'mako' ? LightBlueArrow:BlueArrow} alt="arrow" onClick={() => { this.setValues('z', currentRoomPicked.z - 1) }} />
                                        </span>
                                    </div>
                                    :
                                    <div style={{background:'transparent'}}></div>
                                }
                            </div>
                                <button onClick={()=>{this.updateMeasurments(currentRoomPicked)}}>עדכן מידות</button>
                            </section>
                        </div>




                    <header>                    
                        <div>
                            <h1>{typeOfMechiron === 'ace' ? 'הרשימה שלי':lang.cart}</h1>
                            <span>
                                {
                                    Cookies.get(USER_TOKEN) == null ?
                                    <a>
                                        <button onClick={()=>{this.props.history.push(`/login/tocart`)}}>הדפס</button>
                                    </a>
                                    :
                                    <CSVLink  filename={'MyList.csv'} data={csvData} target="_blank" >
                                        <button className="">הדפס </button>
                                    </CSVLink>
                                }

                                {
                                    Cookies.get(USER_TOKEN) == null ?
                                    <button onClick={()=>{this.props.history.push(`/login/tocart`)}}>שמור</button>
                                    :
                                    null
                                }

                                {/* <p onClick={()=>{this.openMobileBanner()}}>למה בעלי המקצוע שלנו?</p>
                                <p></p> */}
                            </span>
                        </div>
                    </header>

                    {totalProductsInCart === 0 ? <div className="newcart__empty-cart"><img src={CartBig} alt="empty-cart"/><p>עגלת הפריטים שלך ריקה</p></div>:null}

                    {userCart && userCart.length && userCart.map((item,index)=>{
                        let productNames = ''
                        let productCounter = 0
                        let productPrice = 0

                        item.products.map((product,index)=>{
                            if(index !== item.products.length - 1 && productNames.length < 56){
                                productNames+= product.name + ', '
                            } else if(productNames.length < 56) {
                                productNames+= product.name
                            }
                            
                            productCounter++

                            let formula = product.qty_formula
                            formula = formula.toString()
                            formula = formula.replace(/x/gi, item.x);
                            formula = formula.replace(/y/gi, item.y);
                            formula = formula.replace(/z/gi, item.z);
                            formula = eval(formula)
                            let total
                            if(product.only_work){
                                total = formula * (calculatePrices(product,'work',{x:item.x,y:item.y,z:item.z}))
                            } else {
                                total = formula * (calculatePrices(product,'work',{x:item.x,y:item.y,z:item.z}) + calculatePrices(product,'product',{x:item.x,y:item.y,z:item.z}))
                            }
                            total = parseFloat(Math.round(total * 100) / 100)
                            productPrice += total

                        })
                        productPrice = productPrice * 1.17
                        // productPrice = productPrice.toFixed(2)
                        if(productNames.length >= 56)
                        productNames = productNames.substr(0,56) + '...'
                        if(item.products.length > 0)
                        return  <div className={item.level_one_id === this.props.match.params.levelOneId ? "newcart__room newcart__room--active":"newcart__room"} onClick={()=>{this.props.history.push(`/cart/${index}/leveloneid/${item.level_one_id}/token`)}}>
                                   
                                    {desktop || desktopMako ? 
                                        <span className="newcart__room__change-btn--desktop">
                                            <div onClick={()=>{this.deleteRoom(item)}} ><img src={typeOfMechiron === 'mako' ? MakoThrashR:ThrashR} alt="delete-room"/></div>
                                            <div onClick={(e)=>{this.openMeasurementPopoup(e,item)}} ><img src={typeOfMechiron === 'mako' ? MakoPenW:PenW} alt="edit-measurments"/></div>
                                        </span>
                                        :
                                        // <p onClick={(e)=>{this.openMeasurementPopoup(e,item)}} className="newcart__room__change-btn">מידות חדר</p>
                                        <button onClick={(e)=>{this.openRoomEditMenu(e,item)}} className="newcart__room__change-btn"><img src={typeOfMechiron === 'ace' ? MenuDots:MenuDotsB} alt="menu"/></button>
                                    }
                                    <div>
                                        {item.level_one_id !== 'all' ? 
                                            <ImageComponent src={IMAGE_URL_LEVELS + 'ace/' + item.level_one_id + '.png'} alt={item.name}/>
                                            :
                                            <ImageComponent src={GeneralRoom} alt="חדר-כללי"/>
                                        }
                                        
                                    </div>
                                    <div>
                                        <h3>{item.name}</h3>
                                        <p>{productNames}</p>
                                        <span>
                                            <span>
                                                <p>{lang.products}</p>
                                                <p>{productCounter}</p>
                                            </span>
                                            <span>
                                                ₪{parseFloat(productPrice.toFixed(2)).toLocaleString()}
                                            </span>
                                        </span>
                                    </div>
                                    
                            </div>
                      
                            
                    })}
                    <Banner typeOfMechiron={typeOfMechiron}/>
                    

                


                    <footer>
                        <button id="UC: Clicked Add Room In Cart" onClick={()=>{this.props.history.push('/choice')}}>{lang.addRoom}</button>
                        <button id="UC: enter Professional Page From Cart" onClick={()=>{this.seeIfPopup()}}>{lang.getBid}</button>
                    </footer>
                </div>
                    {desktop || desktopMako ? 
                        <span>
                            <NewCartItem scrollToItemWhenEdit={this.props.scrollToItemWhenEdit} typeOfMechiron = {desktopMako ? 'mako':desktop ? 'ace':null} desktop={desktop} lang={Hebrew.UserCart}/>
                            <div className="newcart__item__footer__desktop">
                                <button onClick={()=>{this.props.history.push(`/calculator/${this.props.match.params.levelOneId}/room`)}}>הוסף מוצר</button>
                            </div>
                        </span>
                    :null}



            {desktop || desktopMako ? 
                <div className="newcart__banner">

                    {
                        this.props.userCart.userCart.length === 0 ? null:
                        Cookies.get(USER_TOKEN) == null ?
                        <a>
                            <button className="newcart__export-btn newcart__export-btn--desktop" onClick={()=>{this.handleLoginIsExport()}}>הדפס את הרשימה שלי</button>
                        </a>
                        :
                        this.props.userCart.userCart.length > 0 ?
                        <CSVLink  filename={'MyList.csv'} data={csvData} target="_blank" >
                            <button className="newcart__export-btn newcart__export-btn--desktop">הדפס את הרשימה שלי <img src={Printer} alt="print"/> </button>
                        </CSVLink> 
                        :
                        null
                    }
            

                    <img src={Guy} alt="our-pros"/>
                    <div className="newcart__banner__info">
                        <section className="newcart__banner__info--1">
                            <h1>למה לבחור בבעלי המקצוע שלנו?</h1>
                            <img src={desktopMako ? LinesMako:LinesAce} alt="why-our-pros"/>
                        </section>
                        <section className="newcart__banner__info--2">
                            <span>
                                <img src={desktopMako? CheckMako:CheckAce} alt="check"/>
                                <p>מחויבים למחירי העבודות לפי המחירון.</p>
                            </span>
                            <span>
                                <img src={desktopMako? CheckMako:CheckAce} alt="check"/>
                                <p>אחריות לשנה על כל העבודות. שנתיים על אינסטלציה וחשמל!</p>
                            </span>

                        </section>
                        <section className="newcart__banner__info--2">
                            <span>
                                <img src={desktopMako? CheckMako:CheckAce} alt="check"/>
                                <p>תנאי תשלום ייחודיים שמגנים עלייך בשלבי השיפוץ והבנייה.</p>
                            </span>
                            <span>
                                <img src={desktopMako? CheckMako:CheckAce} alt="check"/>
                                <p>הנכס שלך מבוטח לכל תקופת השיפוץ והבנייה.</p>
                            </span>
                        </section>
                    </div>
                    <button onClick={()=>{ this.props.history.push('/aces/cart')}}>אני רוצה לקבל הצעה</button>

                </div>
                :
                null
            }
                
            </div>
        );
    }
}


function stateToProps({ userCart }) {
    return { userCart }
}
  
export default withRouter(connect(stateToProps, actions)(NewCart))