import React, { Component } from 'react'
import './aces.css'
import { Link,withRouter } from 'react-router-dom'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
import {IMAGE_URL,HOST_NAME} from '../../../configs/configs'
import Cookies from 'js-cookie'
//components
import InnerNavigation from '../../globals/InnerNavigation/InnerNavigation'
import Filter from '../../globals/Filter/Filter'
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
import Loader from '../../globals/Loader'
import {AceProDetails} from './AceProDetails'
import {Banner} from './Banner'
//tools
import {calculatePrices,stringAreaSwap,ApiPostWithBody} from '../../../tools/tools'
//configs
import {API_URL} from '../../../configs/configs'
//images
import Arrow from '../../../images/icons/redarrow.svg'
import ArrowReg from '../../../images/original/16.svg'
import ArrowMako from '../../../images/mako/20.svg'
import GPS from '../../../images/icons/gps.svg'
import Phone from '../../../images/icons/phone.svg'
import MailW from '../../../images/icons/mailwhite.svg'
import Hands from '../../../images/icons/hands.svg'
import Plus from '../../../images/icons/plus.svg'
import PlusReg from '../../../images/original/52.svg'
import Check from '../../../images/icons/checkicon.svg'

import CheckAce from '../../../images/banner/checkace.svg'
import CheckMako from '../../../images/banner/checkmako.svg'

//test
import AcePro from '../../../images/pro.png'


class Aces extends Component {

    constructor() {
        super()

        this.state = {
            menuOpen: false,
            currentFilterId: false,
            filterMenuArea:false,
            typeOfMechiron: '',
            forbiddenSmsArr: [],
            areaFilter:null,
            loader: true
        }

        // CLICK OUTSIDE BUTTON TO CLOSE CURTAIN AND MENU
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }


    // CLICK OUTSIDE BUTTON TO CLOSE CURTAIN AND MENU

    componentDidMount() {
        this.sendLeades()
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('scroll', this.trackScrolling);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
        document.removeEventListener('scroll', this.trackScrolling);
    }

    trackScrolling = () => {
        let docHeight = document.documentElement.getBoundingClientRect().height
        let windowHeight = window.innerHeight
        let scrollTop = window.scrollY
        if(docHeight - windowHeight - 10 <= scrollTop) {
            this.props.getPros(10,this.props.pros.prosPage + 10,this.state.currentFilterId)
        }
    }

    componentWillMount() {
        //cancel curtain
        if(document.querySelector(".curtain__ace")){
            document.querySelector(".curtain__ace").style.display = 'none'
            document.querySelector(".curtain__ace").style.zIndex = '-100'
        }


        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})

        this.props.saveReviews()

        if(this.props.pros.pros.length === 0) {
            this.props.getPros(10,0,this.state.currentFilterId)
        }
        let prosType = this.props.pros.prosType
        if(Object.keys(prosType).length === 0 && prosType.constructor === Object) {
            this.props.saveProsType()
        }
        let prosArea = this.props.pros.prosArea
        if(Object.keys(prosArea).length === 0 && prosArea.constructor === Object) {
            this.props.saveProsArea()
        }

        if(this.props.match.params.levelOneId) {
            let obj = this.props.levels.levelOnes
            if(Object.keys(obj).length === 0 && obj.constructor === Object) {
                this.props.saveVendors()
                this.props.saveLevelOnes().then(()=>{
                    
                    if(Array.isArray(this.props.levels.levelOnes)) {
                        let choosenId = this.props.levels.levelOnes.filter(item=>{
                            return item.level_one_id === this.props.match.params.levelOneId
                        })
                        this.props.saveSelectedLevelOneItem(choosenId[0]).then(()=>{
                            this.props.getRecommendation(choosenId[0].level_one_id,this.state.typeOfMechiron)
                        })
                    }
    
                })
            }
        }


    }

    componentWillReceiveProps(nextProps) {
        
        if(this.props.pros.pros.length !== nextProps.pros.pros.length){
            this.setState({loader:true})
        } else {
            this.setState({loader:false})
        }
    }

    sendLeades = () => {
      
        if(!this.state.userMadeContact && window.location.pathname.includes('aces') && window.location.pathname.includes('cart') ){
          let counter = 0
          this.props.userCart.userCart.map(room=>{
            room.products.map(item => {
                let formula = item.qty_formula
                formula = formula.toString()
                formula = formula.replace(/x/gi, room.x);
                formula = formula.replace(/y/gi, room.y);
                formula = formula.replace(/z/gi, room.z);
                formula = eval(formula)
                let total = formula * (calculatePrices(item,'work',{x:room.x,y:room.y,z:room.z}) + calculatePrices(item,'product',{x:room.x,y:room.y,z:room.z}))
                total = total * 1.17
                counter += total
            })
        })
          let body = {
            user_id: this.props.userCart.userId, 
            cart_total: counter,
            mechiron: this.state.typeOfMechiron
          }
          ApiPostWithBody(API_URL + '/left',body)
        }
    }
    


    handleClickOutside(event) {
        if(event.target.className !== 'navbar__hamburger')
        // if (this.wrapperRef &&
        //     !this.wrapperRef.contains(event.target)) {
        //     this.setState({ menuOpen: false })
        //     if(!this.props.desktop)
        //     this.props.disActivateCurtain()
        // }
        if(!event.target.className.includes('aces__menu__opener')){
            document.body.style.overflowY = 'scroll';
            this.setState({filterMenuArea:false})
        }
        
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    //

    toggleMenu = () => {
        this.setState(prevState => ({
            menuOpen: !prevState.menuOpen
        }));
    }

    moveToProPage = (pro_id) => {
        this.props.history.push("/aces/type/" + pro_id)
    }

    setFilterPros = (filter_id,index) => {
        this.setState({currentFilterId:filter_id},()=>{
            // this.props.resetProsList()
            // this.props.getPros(10,0,this.state.currentFilterId)
        })
    }

    openFilterMenuArea = () =>{
        if(this.props.desktop) {
            this.setState({filterMenuArea:true})
        } else {
            this.setState({filterMenuArea:true})
            document.body.style.overflow = 'hidden';
            this.props.activateCurtain()
        }

    }

    sendMailToPro = async (item) => {
        this.props.setBooleanIfUserMadeContact()
        let body = {
            pro_id: item.pro_id,
            cart_id: this.props.userCart.cartId
        }
       let res = await  ApiPostWithBody(API_URL + '/cart/sendByEmail', body)
    }

    sendSmsToPro = async (item) => {
        let arrayCopy = this.state.forbiddenSmsArr.map(item=>item)
        arrayCopy.push(item.pro_id)
        this.setState({forbiddenSmsArr:arrayCopy})

        this.props.setBooleanIfUserMadeContact()
        let body = {
            pro_id: item.pro_id,
            cart_id: this.props.userCart.cartId
        }
        let res = await ApiPostWithBody(API_URL + '/cart/sendBySMS', body)  
    }

    openCartMenu = () => {
        if(this.props.desktop) {
            this.toggleMenu()
        } else {
            this.toggleMenu()
            // this.props.toggleCurtain()
        }

    }

    render() {
        

        const { lang,desktop,desktopMako } = this.props
        const { pros,prosType,prosArea,reviews } = this.props.pros
        const { userCart } = this.props.userCart
        const { recommendation,selectedLevelOne } = this.props.levels
        const { currentFilterId,filterMenuArea,areaFilter,typeOfMechiron,forbiddenSmsArr } = this.state

        let totalProductPrice = 0

        if(window.location.pathname.includes('recommend')) {
            recommendation.map(item => {
                let formula = item.qty_formula
                formula = formula.toString()
                formula = formula.replace(/x/gi, selectedLevelOne.x);
                formula = formula.replace(/y/gi, selectedLevelOne.y);
                formula = formula.replace(/z/gi, selectedLevelOne.z);
                formula = eval(formula)
                let total = formula * (calculatePrices(item,'work',{x:selectedLevelOne.x,y:selectedLevelOne.y,z:selectedLevelOne.z}) + calculatePrices(item,'product',{x:selectedLevelOne.x,y:selectedLevelOne.y,z:selectedLevelOne.z}))
                total = total * 1.17
                total = parseFloat(Math.round(total * 100) / 100)
                totalProductPrice += total
            })
        } else {
            userCart.map(room=>{
                room.products.map(item => {
                    let formula = item.qty_formula
                    formula = formula.toString()
                    formula = formula.replace(/x/gi, room.x);
                    formula = formula.replace(/y/gi, room.y);
                    formula = formula.replace(/z/gi, room.z);
                    formula = eval(formula)
                    let total = formula * (calculatePrices(item,'work',{x:room.x,y:room.y,z:room.z}) + calculatePrices(item,'product',{x:room.x,y:room.y,z:room.z}))
                    total = total * 1.17
                    total = parseFloat(Math.round(total * 100) / 100)
                    totalProductPrice += total
                })
            })
        }

        // totalProductPrice = totalProductPrice.toFixed(2)

        let filteredPros = pros.filter(item =>{
            if(currentFilterId)
            return item.type_id === currentFilterId
            return item
        })

        if(this.state.areaFilter != null)
        filteredPros = filteredPros.filter(item =>{
            return item.area_id.includes(this.state.areaFilter.area_id) 
        })



       

        let filterParams = []
        
        if(prosType.length > 0) {
            filterParams = prosType.map((item)=>{
                return {
                    name:item.name,
                    filter_id: item.area_id
                }
            })
        }

        // filter empty pros categories
        filterParams = filterParams.filter(item=>{
            return (item.filter_id === 'T190001' || item.filter_id === 'T190002')
        })
       


        return (
            <div className="aces">
            {!desktop ? 
                <aside className={filterMenuArea ? "aces__area-filter aces__area-filter--active":"aces__area-filter"}>
                    <p>בחר אזור</p>
                    {prosArea.length > 0 && prosArea.map(item=>{
                        return <div onClick={()=>{this.setState({areaFilter:item,filterMenuArea:false})}}><p>{item.name}</p></div>
                    })}
                </aside>
                :
                null
            }

 
                <section style={filterMenuArea && !desktop ? {zIndex:'0'}:{}} className="aces__first-section" ref={this.setWrapperRef}>
                    {window.location.pathname.includes('recommend') ?
                        <InnerNavigation pages={['ראשי', selectedLevelOne.name, 'בנינו עבורכם', 'אנשי המקצוע שלנו']} links={['/', `/calculator/${selectedLevelOne.level_one_id}/room`, `/recommendation/${selectedLevelOne.level_one_id}`, '/aces/recommend']} />
                        :
                        typeOfMechiron === 'ace' ?
                        <InnerNavigation pages={['הרשימה שלי','אנשי המקצוע שלנו']} links={['/cart/token', '']} />
                        :
                        <InnerNavigation pages={['עגלת פריטים','אנשי המקצוע שלנו']} links={['/cart/token', '']} />
                    }




                {desktop ? 
                    <Banner typeOfMechiron={typeOfMechiron} />
                    :
                    null
                }



                    {desktop && !desktopMako ? <header>אנשי המקצוע שלנו</header>:null}

                    <h1>
                        <span>{lang.displayHeader + ' ב'}</span>
                        <span className="aces__menu__opener" onClick={()=>{this.openFilterMenuArea()}}>{areaFilter != null ? areaFilter.name:'כל האזורים'}</span>
                        <img src={typeOfMechiron === 'ace' ? Arrow: typeOfMechiron === 'mako' ? ArrowMako:ArrowReg} alt="arrow" />
                        {desktop ? 
                            <aside className={filterMenuArea ? "aces__area-filter aces__area-filter--active":"aces__area-filter"}>
                                <p>בחר אזור</p>
                                {prosArea.length > 0 && prosArea.map(item=>{
                                    return <div onClick={()=>{this.setState({areaFilter:item,filterMenuArea:false})}}><p>{item.name}</p></div>
                                })}
                            </aside>
                            :
                            null
                        }
                    </h1>

                    <h2>
                        {desktopMako ? <span className="header__two__span">מציג 16 אנשי מקצוע</span>:null}

                        <span>{lang.totalWorkPrice}</span>
                        <span>₪{parseFloat(totalProductPrice.toFixed(2)).toLocaleString()}</span>
                        <figure onClick={() => { this.openCartMenu() }}>
                            <img src={typeOfMechiron !== 'ace' ? PlusReg:Plus} alt="plus" className={this.state.menuOpen ? "menu__icon--active" : "menu__icon"} />
                        </figure>
                    </h2>

                    {window.location.pathname.includes('recommend') ?
                        <div className={this.state.menuOpen ? "aces__first-section__menu aces__first-section__menu--active" : "aces__first-section__menu"}>
                            {recommendation.map(item => {
                                let formula = item.qty_formula
                                formula = formula.toString()
                                formula = formula.replace(/x/gi, selectedLevelOne.x);
                                formula = formula.replace(/y/gi, selectedLevelOne.y);
                                formula = formula.replace(/z/gi, selectedLevelOne.z);
                                formula = eval(formula)
                                let total = formula * ( calculatePrices(item,'work',{x:selectedLevelOne.x,y:selectedLevelOne.y,z:selectedLevelOne.z}) + calculatePrices(item,'product',{x:selectedLevelOne.x,y:selectedLevelOne.y,z:selectedLevelOne.z}) )
                                total = total * 1.17
                                total = parseFloat(Math.round(total * 100) / 100)
                                return <div className="menu__item">
                                    <p onClick={()=>{this.props.deleteRecommendationItem(item.pid_id)}}>הסרה</p>
                                    <ImageComponent src={IMAGE_URL + '/ace/' + item.picture_id + '.png'} alt="menu-item" />
                                    <p>{item.name}</p>
                                    <span>
                                        <p>{formula}</p>
                                        <p>{item.units}</p>
                                    </span>
                                    <p>₪ {parseFloat(total.toFixed(2)).toLocaleString()}</p>
                                </div>
                            })}
                        </div>
                        :
                        <div className={this.state.menuOpen ? "aces__first-section__menu aces__first-section__menu--active" : "aces__first-section__menu"}>
                            {userCart.map((item,indexInCart) => {
                                
                                return item.products.map(product=>{
                                    let formula = product.qty_formula
                                    formula = formula.toString()
                                    formula = formula.replace(/x/gi, item.x);
                                    formula = formula.replace(/y/gi, item.y);
                                    formula = formula.replace(/z/gi, item.z);
                                    formula = eval(formula)
                                    let total = formula * (calculatePrices(product,'work',{x:item.x,y:item.y,z:item.z}) + calculatePrices(product,'product',{x:item.x,y:item.y,z:item.z}))
                                    total = total * 1.17
                                    total = parseFloat(Math.round(total * 100) / 100)
                                    return <div className="menu__item">
                                                <p onClick={()=>{this.props.removeCartItem(indexInCart,product)}}>הסרה</p>
                                                <ImageComponent src={IMAGE_URL + '/ace/' + product.picture_id + '.png'} alt="menu-item" />
                                                <span>
                                                    <p>{formula}</p>
                                                    <p>{product.units}</p>
                                                </span>
                                                <p>₪ {parseFloat(total.toFixed(2)).toLocaleString()}</p>
                                          </div>
                                })  
                            })}
                        </div>

                    }

                </section>

                <div className="aces__filter">
                    <Filter aces={true} numberOfItems={filteredPros.length} desktop={desktop} lang={lang} pros={true} filterParams={filterParams} setFilterPros={this.setFilterPros}/>
                </div>

                <section  className="aces__pros__container">

                {filteredPros.map((item, index)=>{
                let scoreCounteer = 0
                let toDeviedWith = 0
                let newDesc = item.description ? item.description:false
                if(newDesc && newDesc.length > 116)
                newDesc = newDesc.substr(0,113) + '...'

                reviews.map(review=>{
                    if(item.pro_id === review.pro_id)
                    scoreCounteer += (review.reliable + review.profession + review.service) / 3
                })

                toDeviedWith = reviews.filter(review=>review.pro_id === item.pro_id).length
                
                scoreCounteer = scoreCounteer / toDeviedWith
                scoreCounteer = Number(scoreCounteer.toFixed(1))
                if(index === 2 && !desktop && !desktopMako) {
                    return <span>
                                <Banner typeOfMechiron={typeOfMechiron} />
                                <AceProDetails item={item} scoreCounteer={scoreCounteer} newDesc={newDesc} moveToProPage={this.moveToProPage}/>
                           </span>
                } else {
                    return <AceProDetails item={item} scoreCounteer={scoreCounteer} newDesc={newDesc} moveToProPage={this.moveToProPage}/>
                }
                
                })}

            </section>

            {this.state.loader ? <Loader />:null}


            </div>
        );
    }
}

function stateToProps({ pros,levels,userCart }) {
    return { pros,levels,userCart }
}
  
export default withRouter(connect(stateToProps, actions)(Aces))