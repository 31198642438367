import React, { Component } from 'react'
import '../login.css'
import Loader from '../../../../Components/globals/Loader'

class AceRegister extends Component {
    render() {

        const {lang} = this.props
        const {phone,ace_email,ace_first_name,ace_last_name,phoneErr,ace_email_err,ace_first_name_err,ace_last_name_err,loader} =  this.props
        return (
            <div className="aceregister login__stageone">
                <h6>רגע לפני שממשיכים למחירים…</h6>
                <h6>השימוש במחירון מחייב את אישורכם לכך שאתם מבינים שהמחירים המוצגים במחירון הינם מחירים ממוצעים ולשימוש לאומדן בלבד. המחירים כוללים מע”מ. טל”ח. </h6>
                <p>אנא הזינו את הפרטים הבאים</p>

                <div className="login__stageone__form-group">
                    <label htmlFor="" className={this.props.phoneErr ? "login__stageone__form-group--error":""}>{lang.stageOneFormPhone}<b>*</b> {this.props.phoneErr ?  "- " +  this.props.phoneErr:null}</label>
                    <input
                    type="number"
                    name="phone"
                    value={this.props.phone}
                    placeholder="05x-xxxx-xxx"
                    onChange={(e)=>{this.props.handleChange(e.target.name,e.target.value)}}
                    onBlur={(e)=>{this.props.blurHandle(e.target.name,this.props.phone)}}
                    className={this.props.phoneErr ? "login__stageone__form-group--error--input":""}
                    />

                    <p>{lang.stageOneFormPhoneDisclaimer}</p>
                </div>

                <div className="login__stageone__form-group">
                    <label htmlFor="" className={this.props.ace_email_err ? "login__stageone__form-group--error":""}>כתובת מייל<b>*</b> {this.props.ace_email_err ?  "- " +  this.props.ace_email_err:null}</label>
                    <input
                    type="text"
                    name="ace_email"
                    value={this.props.ace_email}
                    placeholder="hamechiron@mail.com"
                    onChange={(e)=>{this.props.handleChange(e.target.name,e.target.value)}}
                    onBlur={(e)=>{this.props.blurHandle(e.target.name,this.props.ace_email)}}
                    className={this.props.ace_email_err ? "login__stageone__form-group--error--input":""}
                    />

                </div>

                <div className="login__stageone__form-group">
                    <label htmlFor="" className={this.props.ace_first_name_err ? "login__stageone__form-group--error":""}>שם פרטי<b>*</b> {this.props.ace_first_name_err ?  "- " +  this.props.ace_first_name_err:null}</label>
                    <input
                    type="text"
                    name="ace_first_name"
                    value={this.props.ace_first_name}
                    placeholder="ישראל"
                    onChange={(e)=>{this.props.handleChange(e.target.name,e.target.value)}}
                    onBlur={(e)=>{this.props.blurHandle(e.target.name,this.props.ace_first_name)}}
                    className={this.props.ace_first_name_err ? "login__stageone__form-group--error--input":""}
                    />

                </div>

                <div className="login__stageone__form-group">
                    <label htmlFor="" className={this.props.ace_last_name_err ? "login__stageone__form-group--error":""}> שם משפחה<b>*</b> {this.props.ace_last_name_err ?  "- " +  this.props.ace_last_name_err:null}</label>
                    <input
                    type="text"
                    name="ace_last_name"
                    value={this.props.ace_last_name}
                    placeholder="כהן"
                    onChange={(e)=>{this.props.handleChange(e.target.name,e.target.value)}}
                    onBlur={(e)=>{this.props.blurHandle(e.target.name,this.props.ace_last_name)}}
                    className={this.props.ace_last_name_err ? "login__stageone__form-group--error--input":""}
                    />

                </div>

                <p>משתמשים קיימים? <b onClick={()=>{this.props.memberExistHandle()}}>לחצו כאן</b> </p>
                <button className={
                    phone && ace_email && ace_first_name && ace_last_name && !phoneErr && !ace_email_err && !ace_first_name_err && !ace_last_name_err ? 
                "button__red":"button__red button__disabled"
                } type="submit" onClick={()=>{this.props.next()}}>
                    {loader ? 
                        <Loader />
                        :
                        <span>אישור</span>
                    }
                   
                </button>

            </div>
        );
    }
}

export default AceRegister;