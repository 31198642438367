import {ApiPostWithBody} from '../tools/tools'
import {API_URL} from '../configs/configs'

import { 
    INIT_USER_CART_ROOM,
    ADD_PRODUCT_TO_CART,
    REMOVE_ITEM_FROM_CART,
    REPLACE_CART_ITEM,
    EDIT_CART_ITEM,
    UPDATE_QUANTITY_OF_CART_ITEM,
    SAVE_USER_ID,
    SAVE_REGULATION,
    SAVE_USE_DETAILS,
    SAVE_MEASUREMENT_FROM_CART,
    DELETE_ROOM_FROM_CART,
    REMOVE_ROOMS_WITHOUT_PRODUCTS,
    SHOW_BACK_TO_INTERFACE_BTN,
    HIDE_BACK_TO_INTERFACE_BTN,
    SET_USER_IS_ACE_PRO,
    SET_USER_CART_ID_TO_EDIT_AS_PRO,
    SAVE_URL_TO_REDIRECT_INTERFACE,
    REMOVE_URL_TO_REDIRECT_INTERFACE,
    SAVE_OFFSET_FOR_ITEM_CHANGE,
    SAVE_PID_FOR_OFFSET_CHANGE,
    RESET_CART,
    SAVE_INITIAL_CART_DATA
} from '../actions/types'

const initialState = {
    userCart: [],
    userId: '',
    cartId:'',
    regulation:false,
    userIsAcePro:false,
    backToInterface: false,
    interfaceReturnUrl: '',
    urlToRedirect: '',
    pidIdForOffsetChange: null,
    userDetails: {}
}

export default function(state = initialState, action) {

    switch(action.type) {
        
        case (SAVE_PID_FOR_OFFSET_CHANGE) : {
            return { ...state, pidIdForOffsetChange: action.payload }
        }

        case (SAVE_OFFSET_FOR_ITEM_CHANGE) : {
            return { ...state, offsetY: action.payload }
        }

        case (REMOVE_URL_TO_REDIRECT_INTERFACE) : {
            return { ...state, urlToRedirect: '' }
        }

        case (SAVE_URL_TO_REDIRECT_INTERFACE) : {
            return { ...state, urlToRedirect: action.payload }
        }

        case (SET_USER_CART_ID_TO_EDIT_AS_PRO) : {
            return { ...state, cartId: action.payload.cartId, userCart: action.payload.cart }
        }

        case (HIDE_BACK_TO_INTERFACE_BTN) : {
            return { ...state, backToInterface: false, interfaceReturnUrl: '' }
        }

        case (SHOW_BACK_TO_INTERFACE_BTN) : {
            return { ...state, backToInterface: true, interfaceReturnUrl: action.payload}
        }

        case(SET_USER_IS_ACE_PRO) : {
            return { ...state, userIsAcePro:true }
        }
        
        case(RESET_CART) : {
            return { ...state,userCart:[] }
        }

        case(REMOVE_ROOMS_WITHOUT_PRODUCTS) : {
            let newState = state.userCart.filter(item=>{
                if(item.products.length === 0)
                ApiPostWithBody(API_URL + `/cart/removeRoom`,{cart_id:state.cartId,room_id: item.room_id})
                return item.products.length > 0
            })
            return { ...state,userCart:newState }
        }

        case(DELETE_ROOM_FROM_CART) : {
            let newRoomsState = state.userCart.filter(item=>{
                return item.room_id !== action.payload
            })

            ApiPostWithBody(API_URL + `/cart/removeRoom`,{cart_id:state.cartId,room_id:action.payload})

            return { ...state,userCart:newRoomsState }
        }
        case(REPLACE_CART_ITEM) : {

            let newState = state.userCart.map(item=>item)

            let roomIndexInState = newState.findIndex((item)=>{
                return item.level_one_id === action.payload.levelOneId
            })

            let exists = newState[roomIndexInState].products.filter(item=>{
                return action.payload.newProd.pid_id !== item.pid_id
            })
            
            let newProducts = newState[roomIndexInState].products.map(item=>{
                if(item.pid_id === action.payload.pidToReplace) {
                    return action.payload.newProd
                } else {
                    return item
                }
            })

            newProducts= newProducts.filter((v,i,a)=>a.findIndex(t=>(t.pid_id === v.pid_id))===i)


            newState[roomIndexInState].products = newProducts.slice(0)

            //send changes to server
            if(state.userId && state.cartId) {
                let body = {
                    user_id: state.userId,
                    room:newState[roomIndexInState],
                    cart_id: state.cartId,
                }
                ApiPostWithBody(API_URL + '/room/new',body)
            }

            return { ...state,userCart:newState }
        }
        
        case(REMOVE_ITEM_FROM_CART) : { 
            
            
            let newState = state.userCart.map(item=>item)
            newState[action.payload.roomIndex].products = newState[action.payload.roomIndex].products.filter(item=>{
                return item.pid_id !== action.payload.itemToRemove.pid_id
            })

            //send changes to server
            if(state.userId && state.cartId) {
                let body = {
                    user_id: state.userId,
                    room:newState[action.payload.roomIndex],
                    cart_id: state.cartId,
                }
                ApiPostWithBody(API_URL + '/room/new',body)
            }
            return { ...state, userCart:newState}
        } 

        case(INIT_USER_CART_ROOM) : {
            
            let exist = state.userCart.find(function(stateRoom) {
                return stateRoom.level_one_id === action.payload.level_one_id
            });
            
            if(exist)
            return { ...state }
           
            action.payload.products = []
            let newState = state.userCart.map(item=>{return item})
            newState.push(action.payload)
            return { ...state, userCart:newState}
        }

        case(UPDATE_QUANTITY_OF_CART_ITEM) : {
            let newState = state.userCart.map(item=>{return item})
            newState = newState.map(function(stateRoom) {
                if(stateRoom.pid_id === action.payload.item.pid_id){
                    //send changes to server
                    if(state.userId && state.cartId) {
                        let body = {
                            user_id: state.userId,
                            room:action.payload.item,
                            cart_id: state.cartId,
                        }
                        ApiPostWithBody(API_URL + '/room/new',body)
                    }
                    return action.payload.item
                } else {
                    //send changes to server
                    if(state.userId && state.cartId) {
                        let body = {
                            user_id: state.userId,
                            room:stateRoom,
                            cart_id: state.cartId, 
                        }
                        ApiPostWithBody(API_URL + '/room/new',body)
                    }
                    return stateRoom
                }
            })
            return { ...state, userCart:newState}
        }

        case(ADD_PRODUCT_TO_CART) : {
            let newStateAddProductToCart = state.userCart.map(item=>{return item})
            
            newStateAddProductToCart = newStateAddProductToCart.map(function(stateRoom) {
                if(stateRoom.level_one_id === action.payload.levelOneId) {
                    
                    if(stateRoom.products.filter(e => e.pid_id === action.payload.product.pid_id).length === 0) {
                        stateRoom.products.push(action.payload.product)
                    } else {
                        // stateRoom.products = stateRoom.products.filter(item=>{
                        //     return item.pid_id !== action.payload.product.pid_id
                        // })
                    }
                    
                    //send changes to server
                    if(state.userId && state.cartId) {
                        let add_to_cart_body = {
                            user_id: state.userId,
                            room:stateRoom,
                            cart_id: state.cartId,
                        }
                        if(action.payload.makeApiCall)
                        ApiPostWithBody(API_URL + '/room/new',add_to_cart_body)
                    }
                }

                stateRoom.products = stateRoom.products.sort((a, b)=> {
                    if(a.product_price === '0')return 1
                    return -1
                })
                
                return stateRoom
            })
            return { ...state, userCart:newStateAddProductToCart}
        }

        case(SAVE_USER_ID) : {
            return { ...state, userId:action.payload}
        }

        case(SAVE_MEASUREMENT_FROM_CART) : {
            let newRoom = action.payload
            let newState = state.userCart.map(room=>{
                return room.level_one_id === newRoom.level_one_id ? newRoom:room
            })

            //send changes to server
            if(state.userId && state.cartId) {
                let new_cart_body = {
                    user_id: state.userId,
                    room:newRoom,
                    cart_id: state.cartId,
                }
                ApiPostWithBody(API_URL + '/room/new',new_cart_body)
            }

            return { ...state,userCart:newState }
        }
        case(SAVE_REGULATION) : {
            return { ...state, regulation:true}
        }
        case(SAVE_USE_DETAILS) : {
            return { ...state, userDetails:action.payload}
        }
        
        case(SAVE_INITIAL_CART_DATA) : {
            if(action.payload.cart && action.payload.cart.length > 0){
                return { ...state, cartId:action.payload.cartId, userCart:action.payload.cart}
            } else {
                return { ...state, cartId:action.payload.cartId}
            }
        }
        default: 
            return state
    }
}