import React from "react"
import {Link} from 'react-router-dom'
import Ace from '../../images/newnavbar/logo-ace.png'
import Mark from '../../images/icons/footericon.svg'

import LogoAce from '../../images/newnavbar/logo-ace.png'
import LogoMako from '../../images/newnavbar/logo-mako.svg'
import LogoMechiron from '../../images/newnavbar/logo-mechiron.svg'

const DesktopFooter = props => {

  const {typeOfMechiron } = props
  
  return (
    <footer className="desktop__footer">
        <div>
            <span>
              {
                  typeOfMechiron === 'ace' ? 
                  <img src={LogoAce} alt="search"/>
                  : typeOfMechiron === 'mako' ? 
                  <img src={LogoMako} alt="search"/>
                  :
                  <img src={LogoMechiron} alt="search"/>
              }
              <p>המחירים לאומדן בלבד וכוללים מע״מ. טל״ח.</p>
              <p>מופעל על ידי המחירון של רמדור</p>
            </span>

            <span>
                <Link to="/regulations">
                  <p>תנאי השימוש במחירון</p>
                </Link>

                <a onClick={()=>{props.footerContactHandler()}}>
                  <p> צור קשר</p>
                </a>
            </span>

        </div>
    </footer>
  );
};

export default DesktopFooter;
