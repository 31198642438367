import React from 'react'
import './innernavigation.css'
import {Link} from 'react-router-dom'
import { CheckMobileOrTablet } from '../../../tools/tools'

const InnerNavigation = (props) => {
    let mobileOrTablet = CheckMobileOrTablet()
    return (
        <div className={mobileOrTablet ? "innernav innernav--mobile":"innernav innernav--desktop"}>
            {props.pages.map((item,index)=>{
                return <Link to={props.links[index]}>
                           <p className="innernav__link">{item}</p>
                       </Link>
            })}

        </div>
    );
};

export default InnerNavigation;