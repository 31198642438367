import React, { Component } from 'react'
import LogoAce from '../../images/newnavbar/logo-ace.png'
import LogoMako from '../../images/newnavbar/logo-mako.svg'
import LogoMechiron from '../../images/newnavbar/logo-mechiron.svg'

class MobileFooter extends Component {
    render() {
        const {typeOfMechiron } = this.props
        return (
            <div className="mobile-footer" style={this.props.hide ? {display:'none'}:{}}>

                <section>
                    {
                        typeOfMechiron === 'ace' ? 
                        <img src={LogoAce} alt="search"/>
                        : typeOfMechiron === 'mako' ? 
                        <img src={LogoMako} alt="search"/>
                        :
                        <img src={LogoMechiron} alt="search"/>
                    }

                    <div>
                        <button onClick={()=>{this.props.footerContactHandler()}}>צור קשר</button>
                        <button onClick={()=>{this.props.goToRegulations()}}>תנאי השימוש במחירון</button>
                    </div>
                </section>

                <p>המחירים לאומדן בלבד וכוללים מע״מ. טל״ח.</p>
                <p>מופעל על ידי המחירון של רמדור</p>
            </div>
        )
    }
}

export default MobileFooter
