import React, { Component } from 'react'
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
import './newhomepage.css'

import { Link,withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import * as actions from '../../../actions/index'
//comps
import HomeSlider from './HomeSlider'
//config
import {HOST_NAME,USER_TOKEN} from '../../../configs/configs'
//img
import MobileHeader from '../../../images/newhomepage/mobilebg.png'
import DesktopHeader from '../../../images/newhomepage/desktopbg.png'
import StartIcon from '../../../images/newhomepage/starticon.svg'
import Banner1 from '../../../images/newhomepage/banner1.svg'
import Banner2 from '../../../images/newhomepage/banner2.svg'
import Banner3 from '../../../images/newhomepage/banner3.svg'
import BannerMako1 from '../../../images/newhomepage/bannermako1.svg'
import BannerMako2 from '../../../images/newhomepage/bannermako2.svg'
import BannerMako3 from '../../../images/newhomepage/bannermako3.svg'
import BannerMechiron1 from '../../../images/newhomepage/bannermechiron1.svg'
import BannerMechiron2 from '../../../images/newhomepage/bannermechiron2.svg'
import BannerMechiron3 from '../../../images/newhomepage/bannermechiron3.svg'
//
import ThirdSection1 from '../../../images/newhomepage/third1.svg'
import ThirdSection2 from '../../../images/newhomepage/third2.svg'
import ThirdSection3 from '../../../images/newhomepage/third3.svg'
import ThirdSection4 from '../../../images/newhomepage/third4.svg'
//
import ArrowWhiteAce from '../../../images/newhomepage/whitebtnarrowace.svg'
import ArrowWhiteMako from '../../../images/newhomepage/whitebtnarrowmako.svg'
import ArrowWhiteMechiron from '../../../images/newhomepage/whitebtnarrowmechiron.svg'

class NewHomePage extends Component {

    constructor() {
        super()

        this.state = {
            typeOfMechiron: ''
        }
    }

    async componentWillMount() {

        // if(Cookies.get(USER_TOKEN)) {
            this.props.history.push('/choice')
        // }

        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})

        this.props.saveLevelOnes()
        this.props.saveVendors()
        this.props.saveProsArea()
        this.props.saveProsType()
        this.props.getPros(100,0)

    }

    navigate = (link) => {
        this.props.history.push(link)
    }

    inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    render() {
        const {desktopVersion} = this.props
        const {pros} = this.props.pros
        const {typeOfMechiron} = this.state
        let whiteButtonSrc = typeOfMechiron === 'ace' ? ArrowWhiteAce:typeOfMechiron === 'mako' ? ArrowWhiteMako:ArrowWhiteMechiron
        let runInIframe = this.inIframe()

        return (
            <div className="newhomepage">
                <header className="newhomepage__header">
                    <ImageComponent src={desktopVersion ? DesktopHeader:MobileHeader} className={'newhomepage__header__bg'}/>

                    <div className="newhomepage__grid">
                        <div className="newhomepage__header__banner">
                            <h1>משפצים או בונים?</h1>
                            <h2>גלו כמה זה יעלה לכם </h2>
                            <p>במחירון תמצאו מחירי עבודות ומוצרים, המלצות למפרטים לפי חדר, לשמור ולערוך את הרשימה שלכם ולהנות מתנאים ייחודיים אצל בעלי מקצוע מומלצים.</p>
                            {typeOfMechiron !== "ace" && typeOfMechiron !== "mako" && runInIframe && !window.location.href.includes('mako') ? 
                                <a href="/choice" target="_blank" rel="noopener noreferrer" className="home__button__screen__animation">
                                    <figure></figure>
                                    <p>אני רוצה להתחיל</p>
                                    <img src={StartIcon} alt="start"/>
                                </a>
                                :
                                <button onClick={()=>{this.navigate('/choice')}} className="home__button__screen__animation">
                                    <figure></figure>
                                    <p>אני רוצה להתחיל</p>
                                    <img src={StartIcon} alt="start"/>
                                </button>
                            }

                        </div>
                    </div>

                </header>

                <section className="newhomepage__second-section">
                    <div className="newhomepage__grid--2">
                        <div className="newhomepage__second-section__info">
                            {
                                typeOfMechiron === 'ace' ? 
                                <img src={Banner1} alt="search"/>
                                : typeOfMechiron === 'mako' ? 
                                <img src={BannerMako1} alt="search"/>
                                :
                                <img src={BannerMechiron1} alt="search"/>
                            }
                            <h3>בחרו מה לשפץ או לבנות</h3>
                        </div>
                        <div className="newhomepage__second-section__info">
                            {
                                typeOfMechiron === 'ace' ? 
                                <img src={Banner2} alt="search"/>
                                : typeOfMechiron === 'mako' ? 
                                <img src={BannerMako2} alt="search"/>
                                :
                                <img src={BannerMechiron2} alt="search"/>
                            }
                            <h3>קבלו הערכת מחיר לעבודה</h3>
                        </div>
                        <div className="newhomepage__second-section__info">
                            {
                                typeOfMechiron === 'ace' ? 
                                <img src={Banner3} alt="search"/>
                                : typeOfMechiron === 'mako' ? 
                                <img src={BannerMako3} alt="search"/>
                                :
                                <img src={BannerMechiron3} alt="search"/>
                            }
                            <h3>פנו לבעלי המקצוע המומלצים שלנו</h3>
                        </div>
                    </div>

                </section>


                <section className="newhomepage__third-section">
                    <div className="newhomepage__grid--3">
                        <h1>
                            שיפוץ ובניה רק עם בעלי המקצוע המובילים שלנו
                            {desktopVersion ? 
                                <button onClick={()=>{this.navigate('/choice')}}>
                                    <p>בואו נתחיל</p>
                                    <img src={StartIcon} alt="start"/>
                                </button>
                                :
                                null
                            }

                        </h1>

                        <div className="newhomepage__third-section__banner-container">
                            <div>
                                <img src={ThirdSection1} alt=""/>
                                <h1>בלי הפתעות</h1>
                                <p>הקבלנים מחויבים למחירי העבודות לפי המחירון.</p>
                            </div>
                            <div>
                                <img src={ThirdSection2} alt=""/>
                                <h1>אין "קומבינות"</h1>
                                <p>תנאי תשלום ייחודיים שמגנים עליך בשלבי השיפוץ והבנייה.</p>
                            </div>
                            <div>
                                <img src={ThirdSection3} alt=""/>
                                <h1>אפשר להירגע</h1>
                                <p>הנכס שלך מבוטח לכל תקופת השיפוץ והבנייה.</p>
                            </div>
                            <div>
                                <img src={ThirdSection4} alt=""/>
                                <h1>כאן בשבילך</h1>
                                <p>אחריות לשנה על כל העבודות. שנתיים על אינסטלציה וחשמל!</p>
                            </div>
                        </div>

                        <button onClick={()=>{this.navigate('/choice')}} className="home__button__screen__animation">
                            <figure></figure>
                            <p>בואו נתחיל</p>
                            <img src={StartIcon} alt="start"/>
                        </button>
                    </div>
                </section>

                <section className="newhomepage__forth-section">
                    <h1> 
                        בעלי המקצוע שלנו
                        <button onClick={()=>{this.navigate('/aces/cart')}}>
                            <p>לכל בעלי המקצוע</p>
                            <img src={whiteButtonSrc} alt=""/>
                        </button>
                    </h1>
                    <HomeSlider sliderItems={pros} typeOfMechiron={typeOfMechiron} navigate={this.navigate} desktopVersion={desktopVersion}/>
                    <button onClick={()=>{this.navigate('/aces/cart')}}>
                        <p>לכל בעלי המקצוע</p>
                        <img src={whiteButtonSrc} alt=""/>
                    </button>
                </section>

                <section className="newhomepage__fifth-section">
                    <div>
                        <h1>אין לכם מושג איפה להתחיל?</h1>
                        <p>במחירון ניתן למצוא המלצות לשיפוץ חדרים, לקבל הערכת מחיר, וליהנות מתנאים ייחודיים אצל בעלי מקצוע מומלצים.</p>
                    </div>
                    <button onClick={()=>{this.navigate('/choice')}}>
                        תנו לנו לעשות את העבודה בשבילכם     
                    </button>
                </section>
                
            </div>
        )
    }
}

function stateToProps({ levels, pros }) {
    return { levels, pros }
}
  
export default withRouter(connect(stateToProps, actions)(NewHomePage))
  