import React, { Component } from 'react'
import './contact.css'
import CustomdropDown from '../../globals/CustomDropDown/CustomDropDown'
import validator from 'validator'
import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
import Loader from '../../globals/Loader'
import Cookies from 'js-cookie'
import ReactGA from 'react-ga';
//tools
import {ApiPostWithBody} from '../../../tools/tools'
//configs
import {API_URL,HOST_NAME,GOOGLE_ANALYTICS_MECHIRON_ID,GOOGLE_ANALYTICS_ACE_ID,GOOGLE_ANALYTICS_MAKO_ID} from '../../../configs/configs'
//img
import Page from '../../../images/icons/bell.svg'
import PageReg from '../../../images/original/17.svg'
import PageMako from '../../../images/mako/1.svg'

class Contact extends Component {
    constructor(){
        super()

        this.state = {
            name: '',
            phone: '',
            email: '',
            subject: '',
            loader: false,
            popup:false,
            typeOfMechiron: '',

            errName:'',
            errPhone: '',
            errEmail: '',
            errSubject: '',

            wasClicked:false,
            
        }
    }

    
    componentWillMount(){
        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})

        if(this.props.match.params.typeOfContact === '0'){
            this.setState({subject:'כללי'})
        } else if (this.props.match.params.typeOfContact === '1') {
            this.setState({subject:'פרסם במחירון'})
        } else if (this.props.match.params.typeOfContact === '2') {
            this.setState({subject:'הצטרף כמקצוען'})
        } else if (this.props.match.params.typeOfContact === '3') {
            this.setState({subject:'עוד משהו'})
        }
    }

    componentDidMount() {
        if(this.state.typeOfMechiron === 'ace') {
            ReactGA.initialize([
                {trackingId: GOOGLE_ANALYTICS_MECHIRON_ID, gaOptions: { name: 'trackerMechiron' }},
                {trackingId: GOOGLE_ANALYTICS_ACE_ID, gaOptions: { name: 'trackerAce' }} 
            ])
        } else if(this.state.typeOfMechiron === 'mako'){
            ReactGA.initialize([
                {trackingId: GOOGLE_ANALYTICS_MECHIRON_ID, gaOptions: { name: 'trackerMechiron' }},
                {trackingId: GOOGLE_ANALYTICS_MAKO_ID, gaOptions: { name: 'trackerMako' }} 
            ])
        } else {
            ReactGA.initialize(GOOGLE_ANALYTICS_MECHIRON_ID)
        }
    }
    

    wasClicked = () => {
        if(!this.state.subject)
        this.setState({wasClicked:true,errSubject: this.props.lang.errSubject})
    }

    valueWasPicked = () => {
        setTimeout(()=>{ 
            this.setState({errSubject:''})
         }, 100);

    }

    handleInput = (name,value) => {
        this.setState({
            [name] : value
        })
    }

    dropDownHandler = (item) => {
        this.handleInput('subject',item)
    }

    validate = (stateSection,errSection) => {
        if(stateSection === 'name'){
            if(!this.state[stateSection]) {
                this.setState({[errSection]:this.props.lang[errSection]})
            } else {
                this.setState({[errSection]:''})
            }
        } else if(stateSection === 'phone'){
            if(!this.state[stateSection]) {
                this.setState({[errSection]:this.props.lang[errSection + '1']})
            } else if(!validator.isMobilePhone(this.state[stateSection])) {
                this.setState({[errSection]:this.props.lang[errSection + '2']})
            } else {
                this.setState({[errSection]:''})
            }
        } else if(stateSection === 'email') {
            if(this.state[stateSection].length === 0){
                this.setState({[errSection]:''})
                return
            }
            

            if(!validator.isEmail(this.state[stateSection]) ){
                this.setState({[errSection]:this.props.lang[errSection]})
            } else {
                this.setState({[errSection]:''})
            }
        }
    }

    submit = async() => {

        if(this.state.typeOfMechiron === 'ace')
        ReactGA.ga('trackerAce.send', 'event', {hitType: "event", eventCategory: "User", eventAction: "User made contact"});

        if(this.state.typeOfMechiron === 'mako')
        ReactGA.ga('trackerMako.send', 'event', {hitType: "event", eventCategory: "User", eventAction: "User made contact"});

        ReactGA.ga('trackerMechiron.send', 'event', {hitType: "event", eventCategory: "User", eventAction: "User made contact"});

        this.setState({loader:true})
        const {email,subject, name, phone} = this.state
        let body = {
            email,
            subject,
            name,
            phone,
            user_id: this.props.userCart.userId
        }

        // console.log(body)
        let res = await ApiPostWithBody(API_URL + '/contactus',body)
        if(res.ok) {
            this.setState({popup:true})
        }
        this.setState({loader:false})
    }

    handlePopupButton =()=> {
        if(!this.props.desktop){
            this.props.history.goBack()
        } else {
            this.setState({popup:false})
            if(this.props.submitCallback)
            this.props.submitCallback()
            this.props.disActivateCurtain()
        }
    }

    

    render() {
        const {lang,disActivateCurtain,activateCurtain} = this.props
        const {errName,errPhone,errEmail,errSubject, subject, name, phone,loader,popup,typeOfMechiron} = this.state

        let options = [{name:lang.option1},{name:lang.option2},{name:lang.option3},{name:lang.option4}]
        if(typeOfMechiron === 'ace')
        options.splice(options.length -1 ,0,{name:'הצעת מחיר'})

        return (
            <div className="contact">

                <div className={popup ? "minimun__price__popup minimun__price__popup--active":"minimun__price__popup"} >
                    <div>
                        <img src={typeOfMechiron === 'ace' ? Page: typeOfMechiron === 'mako' ? PageMako:PageReg} alt="succesful-request"/>
                        <p>הפרטים התקבלו בהצלחה</p>
                        <button onClick={()=>{this.handlePopupButton()}}>חזור למחירון</button>
                    </div>
                </div>


                <h1>בואו נדבר</h1>
                {typeOfMechiron === 'ace' ? <h2>מלאו פרטים אלו ונחזור אליכם בהקדם</h2>:null}
                <span>
                    <div className="contact__input-group">
                        <label htmlFor="" className={errName ? "invalid-text":""}>{lang.label1} {errName ? '- ' + errName: null}</label>
                        <input className={errName ? "invalid-input":""} type="text" placeholder="ישראל ישראלי" onChange={(e)=>{this.handleInput('name',e.target.value)}} onBlur={()=>{this.validate('name','errName')}}/>
                    </div>
                    <div className="contact__input-group">
                        <label htmlFor="" className={errPhone ? "invalid-text":""}>{lang.label2} {errPhone ? '- ' + errPhone: null}</label>
                        <input className={errPhone ? "invalid-input":""} type="number" placeholder="05x-xxxx-xxx" onChange={(e)=>{this.handleInput('phone',e.target.value)}} onBlur={()=>{this.validate('phone','errPhone')}}/>
                    </div>
                    <div className="contact__input-group">
                        <label htmlFor="" className={errEmail ? "invalid-text":""}>{lang.label3} {errEmail ? '- ' + errEmail: null}</label>
                        <input className={errEmail ? "invalid-input":""} type="text" placeholder="username@email.com" onChange={(e)=>{this.handleInput('email',e.target.value)}} onBlur={()=>{this.validate('email','errEmail')}}/>
                    </div>

                    <div className="contact__input-group">
                        <label  className={errSubject ? "invalid-text":""} htmlFor="">{lang.subject} {errSubject ? '- ' + errSubject: null}</label>
                        <CustomdropDown
                           placeholder={lang.dropPlaceholder}
                           activateCurtain={activateCurtain}
                           disActivateCurtain={disActivateCurtain}
                           options={options}
                           keyInObjectToDisplay = {'name'}
                           changeHandler={this.dropDownHandler}
                           wasClicked={this.wasClicked}
                           err={this.state.errSubject}
                           valueWasPicked= {this.valueWasPicked}
                           valueIndex= {this.props.match.params.typeOfContact}
                           desktop={(this.props.desktop || this.props.desktopMako) ? true:false}
                           answer={this.props.answer ? this.props.answer:-1}
                        />
                    </div>
                </span>



                <button id={!errName && !errPhone && !errEmail && !errSubject  && name && phone && subject ? "UC: Contacted Us":""} onClick={()=>{this.submit()}} className={!errName && !errPhone && !errEmail && !errSubject  && name && phone && subject ? "":"invalid-button"}>{loader ? <Loader/>:lang.btn}</button>

                <p>{lang.activatedby}</p>
            </div>
        );
    }
}

// export default withRouter(Contact);

function stateToProps({ userCart }) {
    return { userCart }
}
  
export default withRouter(connect(stateToProps, actions)(Contact))