import React, { Component } from 'react'
import './productpage.css'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
import Cookies from 'js-cookie'
import ReactGA from 'react-ga';
//components
import InnerNavigation from '../../globals/InnerNavigation/InnerNavigation'
import QuantityInput from '../../globals/QuantityInput/QuantityInput'
import ProductSlider from './parts/ProductSlider'
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
//img
import addIcon from '../../../images/icons/addicon.svg'
import addedIcon from '../../../images/icons/checkicon.svg'
import Phone from '../../../images/icons/phone.svg'
import Mail from '../../../images/icons/mailwhite.svg'
import PhoneR from '../../../images/icons/redphone.svg'
import MailR from '../../../images/icons/redenv.svg'
import GeneralRoom from '../../../images/rooms/general.png'
import PhoneB from '../../../images/icons/bluephone.svg'
import MailB from '../../../images/icons/email.svg'

import CostIcon from '../../../images/icons/consticon.svg'
import InfoIcon from '../../../images/icons/infoicon.svg'
import ModelsIcon from '../../../images/icons/models.svg'
import MidotIcon from '../../../images/icons/midot.svg'


import RedArrow from '../../../images/icons/redarrow.svg'
import BlueArrow from '../../../images/original/16.svg'
import LightBlueArrow from '../../../images/mako/4.svg'
//configs
import {IMAGE_URL,API_URL,HOST_NAME, IMAGE_URL_LEVELS,USER_TOKEN,GOOGLE_ANALYTICS_MECHIRON_ID,GOOGLE_ANALYTICS_ACE_ID,GOOGLE_ANALYTICS_MAKO_ID} from '../../../configs/configs'
//tools
import {ApiGet,calculatePrices,initializeGA,sendGAEvent,CheckMobileOrTablet} from '../../../tools/tools'

//work pic
import WorkPic from '../../../images/worke.png'
import Eqpic from '../../../images/equipment.png'

class ProductPage extends Component {

    constructor() {
        super()

        this.state = {
            quantity: 0,
            addMenu: false,
            added: false,
            imgSrcChanged: false,
            addPopup:false,
            onlyWork:false,
            stopLvlThreeApi:false,
            exist:false,
            selectedProductCopy:false,
            contactClicked:false
        }

        this.quantityHolder = 0
        this.savedValue = 0

        // CLICK OUTSIDE BUTTON TO CLOSE CURTAIN AND MENU
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.setWrapperRef2 = this.setWrapperRef2.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    // CLICK OUTSIDE BUTTON TO CLOSE CURTAIN AND MENU

    async componentDidMount() {
        
        setTimeout(async()=>{ 
            this.buildBtnId()
        }, 1000)

       
        document.addEventListener('click', this.handleClickOutside);
        this.props.levels.products.forEach((item) => {
            if(item.pid_id === this.props.match.params.productId) {
                this.props.saveSelectedProduct(item)
            }
        })
        let formula = this.props.levels.selectedProduct.qty_formula
        let product = this.props.levels.selectedLevelOne
        if(product == null) {
            product = {
                x:1,
                y:1,
                z:1
            }
        }
      
        if(formula) {
            formula = String(formula)
            formula = formula.replace(/x/gi, product.x);
            formula = formula.replace(/y/gi, product.y);
            formula = formula.replace(/z/gi, product.z);
            formula = eval(formula)
        }
        
        this.setState({quantity:formula})

        if(Object.keys(this.props.levels.levelOnes).length === 0 && this.props.levels.levelOnes.constructor === Object) {
            await this.props.saveLevelOnes()
            let levelOne
           
            levelOne = this.props.levels.levelOnes.find(level=>{
                return level.level_one_id === this.props.match.params.levelOneId
            })
            if(levelOne)
            this.props.initUserCartRoom(levelOne,this.props.userCart)
        }
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
        this.props.setReverseVendorsBoolean()
        this.props.setReverseFiltersBoolean()
        this.props.setReverseLvl2Boolean()
        this.props.setReverseLvl1Boolean()
    }
    


    async componentWillMount() {
        
        if(Object.keys(this.props.levels.selectedProduct).length === 0 && this.props.levels.selectedProduct.constructor === Object) {
            let res2 = await ApiGet(API_URL + '/product/getById/' + this.props.match.params.productId)
            this.props.saveSelectedProduct(res2)
        }
        
        //save current level three
        let levelThreeInit = this.props.levels.selectedLevelThree
        if(Object.keys(levelThreeInit).length === 0 && levelThreeInit.constructor === Object) {
            let lvl3 = await ApiGet(API_URL + '/levelThree/getById/' + this.props.match.params.levelThreeId)
            await this.props.saveSelectedLevelThreeItem(lvl3)
        }

        if(this.props.levels.filters.length === 0)
        this.props.saveFilters(this.props.levels.selectedLevelThree,true)

    }

    async componentWillReceiveProps(nextProps){
        
        // if (Object.keys(this.props.levels.selectedProduct).length === 0 && this.props.levels.selectedProduct.constructor === Object && !window.location.href.includes('editproductinusercart')) {
        //     if(nextProps.levels.products.length !== this.props.levels.products) {
        //         if(nextProps.levels.products && nextProps.levels.products.length > 0) {
        //             nextProps.levels.products.forEach(item => {
        //                 if(item.pid_id === nextProps.match.params.productId) {
        //                     this.props.saveSelectedProduct(item)
        //                 }
        //             })
        //         }
        //     }
        // } 

        if (Object.keys(this.props.levels.selectedProduct).length !== 0 && this.props.levels.selectedProduct.constructor === Object) {
            this.existInCart()
        }
       
        if(this.props.levels.selectedLevelOne !== nextProps.levels.selectedLevelOne ){
            
            let formula = nextProps.levels.selectedProduct.qty_formula
         
            let product = nextProps.levels.selectedLevelOne
            if(product == null || (Object.keys(product).length === 0 && product.constructor === Object) ) {
                product = {
                    x:100,
                    y:100,
                    z:100
                }
            }
         
            if(formula) {
                formula = String(formula)
                formula = formula.replace(/x/gi, product.x);
                formula = formula.replace(/y/gi, product.y);
                formula = formula.replace(/z/gi, product.z);
                formula = eval(formula)
            }
           
            this.setState({quantity:formula})
        }

        if(Object.keys(this.props.levels.levelOnes).length === 0 && this.props.levels.levelOnes.constructor === Object) {
            await this.props.saveLevelOnes()
            let levelOne
           
            levelOne = this.props.levels.levelOnes.find(level=>{
                return level.level_one_id === this.props.match.params.levelOneId
            })
            if(levelOne)
            this.props.initUserCartRoom(levelOne,this.props.userCart)
        }
      
        //save current lvl one
        let selectedLevelOne = this.props.levels.selectedLevelOne
        if(selectedLevelOne != null)
        if(Object.keys(selectedLevelOne).length === 0 && selectedLevelOne.constructor === Object && !this.props.levels.lvl1Boolean) {
            this.props.setLvlOnesBoolean()
            let lvl1 = await ApiGet(API_URL + '/levelOne/getById/' + this.props.match.params.levelOneId)
            this.props.saveSelectedLevelOneItem(lvl1)
        }
        // save current level two
        let selectedLevelTwosInit = this.props.levels.selectedLevelTwo
        if(Object.keys(selectedLevelTwosInit).length === 0 && selectedLevelTwosInit.constructor === Object && !this.props.levels.lvl2Boolean) {
            this.props.setLvltwosBoolean()
            let lvl2 = await ApiGet(API_URL + '/levelTwo/getById/' + this.props.match.params.levelTwoId)
            this.props.saveSelectedLevelTwoItem(lvl2)
        }
        //save current level three
        let levelThreeInit = this.props.levels.selectedLevelThree
        if(
            (Object.keys(levelThreeInit).length === 0 && levelThreeInit.constructor === Object)
        ) {
            let lvl3 = await ApiGet(API_URL + '/levelThree/getById/' + this.props.match.params.levelThreeId)
            this.setState({stopLvlThreeApi:true})
            await this.props.saveSelectedLevelThreeItem(lvl3)
        }

        let filters = this.props.levels.filters
        let productsArr = this.props.levels.products

        //save filters
        if(Object.keys(levelThreeInit).length !== 0 && levelThreeInit.constructor === Object && filters.length === 0) {
            if(!this.props.levels.filtersBoolean){
                await this.props.saveFilters(this.props.levels.selectedLevelThree,true)
            }
        }
        //save products 
        
        if(filters.length > 0){
            if(!this.props.levels.stopFunctionSaveProducts){
                await this.props.getProductsLimit(this.props.levels.filters,10,0, this.props.typeOfMechiron)
            }
        }
        // get recommendation

        if(this.props.levels.recommendation !== this.props.levels.recommendation) {
            if(this.props.levels.recommendation.length === 0) {
                this.props.getRecommendation(this.props.match.params.levelOneId, this.props.typeOfMechiron)
            }
        }

        //save vendors 

        if(Object.keys(this.props.levels.vendors).length === 0 && this.props.levels.vendors.constructor === Object) {
            if(!this.props.levels.vendorsBoolean)
            this.props.saveVendors(true)
        }
            

    }

    componentDidUpdate(prevProps,prevState) {
        this.wrapperRef2.scrollTop = 0
        
        if (this.props.location.pathname !== prevProps.location.pathname) {
            
            let selected = this.props.levels.products.filter(item=>{
                return item.pid_id === this.props.match.params.productId
            })
            this.props.saveSelectedProduct(selected[0])
            this.setState({ imgSrcChanged: false, added: false })
        }
        
    }

    handleClickOutside(event) {

        if(event.target.className.includes('curtain')) {
            this.setState({addPopup:false})
            this.props.disActivateCurtain()
        }
   
        if (this.wrapperRef &&
            !this.wrapperRef.contains(event.target)) {
            this.setState({ addMenu: false })
        }
        if(this.state.addPopup && !this.wrapperRef2.contains(event.target) && !this.wrapperRef.contains(event.target) ){
            this.setState({addPopup:false})
            this.props.disActivateCurtain()
        }

    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    setWrapperRef2(node) {
        this.wrapperRef2 = node;
    }

    activatePopup = () => {
        this.setState({addPopup:true})
    }

    // INPUT COMPONENT

    changeQuantity = (val) => {
        if (val < 1) {
            this.setState({ quantity: 1 })
        } else if (val > 999) {
            this.setState({ quantity: 999 })
        } else if (!val) {
            this.setState({ quantity: 1 })
        } else {
            this.setState({ quantity: val })
        }
    }

    focusHandle = () => {
        this.quantityHolder = this.state.quantity
        this.setState({ quantity: '' })
    }

    blurHandle = () => {
        if (!this.state.quantity) {
            this.setState({ quantity: this.quantityHolder })
        }
    }

    // INPUT COMPONENT END

    toggleAddMenu = () => {
        this.setState(prevState => ({
            addMenu: !prevState.addMenu
        }));
    }

    //ADD ICON SWITCH

    handleAddIcon = (e,selectedProduct,productPrice = null,onlyWork = null) => {
        e.stopPropagation()
        const {desktopMako, desktop} = this.props
        const {levelTwoId, levelOneId, levelThreeId, productId} = this.props.match.params

        if(!desktopMako && !desktop) {
            let target = e.target.nodeName === 'BUTTON' ? e.target:e.target.parentElement
            let distance = target.offsetTop;

            var userHasScrolled = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
            if(userHasScrolled < distance - window.innerHeight + 200)
            window.scrollTo({ top: distance - window.innerHeight + 200, behavior: 'smooth' })
        }


        // let token = Cookies.get(USER_TOKEN)
        // if(!token) {
        //     if(desktop || desktopMako) {
        //         this.props.activateCurtain()
        //         this.props.activateLoginPopup(window.location.pathname)
        //         return
        //     } else {
        //         this.props.history.push(`/login/find/${levelOneId}/${levelTwoId}/${levelThreeId}/${productId}`)
        //         return
        //     }
        // }
        
        this.props.removeRoomsWithoutProducts()
        // adds general room to cart
        this.props.initUserCartRoom({level_one_id: "all", name: "כללי", x: 100, y: 100, z: 100,products:[]},this.props.userCart)
        //init current room
        let levelOne = this.props.levels.levelOnes.find(level=>{
            return level.level_one_id === this.props.match.params.levelOneId
        })
        if(levelOne)
        this.props.initUserCartRoom(levelOne,this.props.userCart)

        if(onlyWork){
            this.setState({onlyWork:true})
        } else {
            this.setState({onlyWork:false})
        }
        

        if( productPrice && !this.state.addMenu && selectedProduct.work_price !== '0'){
            this.setState({addMenu:true},()=>{
                if(document.querySelector(".navbar__ace"))
                document.querySelector(".navbar__ace").style.zIndex = '110'
                if(document.querySelector(".curtain__ace"))
                document.querySelector(".curtain__ace").style.display = 'none'
            })
            this.props.activateCurtain()
            return
        }


        if(window.location.pathname.includes('editproductinusercart')){
            this.props.saveProductIdForOffsetChange(this.props.match.params.productId)
            
            let selectedProductCopy = {...selectedProduct}
            selectedProductCopy.qty_formula = this.state.quantity
            selectedProductCopy.level_three_id = this.props.match.params.levelThreeId
            if(onlyWork)
            selectedProductCopy.only_work = true

            this.props.replaceCartItem(this.props.match.params.levelOneId,this.props.match.params.productId,selectedProductCopy)

            this.activatePopup()
            this.props.activateCurtain()
            this.setState({addMenu:false},()=>{
                if(document.querySelector(".navbar__ace"))
                document.querySelector(".navbar__ace").style.zIndex = '110'
                if(document.querySelector(".curtain__ace"))
                document.querySelector(".curtain__ace").style.display = 'none'
            })
            setTimeout(() => {
                this.setState({ addPopup: false })
                this.props.disActivateCurtain()
                this.props.history.goBack()
            }, 2000);
            return
        }

        if(window.location.pathname.includes('edit') && window.location.pathname.includes('cart')){
            this.props.saveProductIdForOffsetChange(this.props.match.params.productId)

            let selectedProductCopy = {...selectedProduct}
            selectedProductCopy.qty_formula = this.state.quantity
            selectedProductCopy.level_three_id = this.props.match.params.levelThreeId
            if(onlyWork)
            selectedProductCopy.only_work = true
            this.props.replaceCartItem(this.props.match.params.levelOneId,this.props.match.params.pidToEdit,selectedProductCopy)
            // this.props.history.push(`/cart/${this.props.match.params.indexInCart}/leveloneid/${this.props.match.params.levelOneId}/token`)
            // this.props.disActivateCurtain()
            this.activatePopup()
            this.props.activateCurtain()
            this.setState({addMenu:false},()=>{
                if(document.querySelector(".navbar__ace"))
                document.querySelector(".navbar__ace").style.zIndex = '110'
                if(document.querySelector(".curtain__ace"))
                document.querySelector(".curtain__ace").style.display = 'none'
            })
            setTimeout(() => {
                this.setState({ addPopup: false })
                this.props.disActivateCurtain()
                this.props.removeRoomsWithoutProducts()
                //if comes from interface - redirect to interface, else to cart
                if(this.props.userCart.urlToRedirect){
                    this.props.history.push(this.props.userCart.urlToRedirect)
                    this.props.removeUrlToRedirectToProsInterface()
                } else {
                    this.props.history.push(`/cart/${this.props.match.params.indexInCart}/leveloneid/${this.props.match.params.levelOneId}/token`)
                }
            }, 2000);
            return
        }
        if(window.location.pathname.includes('edit') && window.location.pathname.includes('recommendation')){
            let selectedProductCopy = {...selectedProduct}
            selectedProductCopy.level_three_id = this.props.match.params.levelThreeId
            selectedProductCopy.qty_formula = this.state.quantity
            if(onlyWork)
            selectedProductCopy.only_work = true
            this.props.replaceRecommedationItem(this.props.match.params.pidToEdit,selectedProductCopy)
            // this.props.history.push(`/recommendation/${this.props.match.params.levelOneId}`)
            // this.props.disActivateCurtain()
            this.activatePopup()
            this.props.activateCurtain()
            this.setState({addMenu:false},()=>{
                // document.querySelector(".navbar__ace").style.zIndex = '110'
                // document.querySelector(".curtain__ace").style.display = 'none'
            })
            setTimeout(() => {
                this.props.history.push(`/recommendation/${this.props.match.params.levelOneId}`)
            }, 2000);
            

            return
        }
        // if (!this.state.imgSrcChanged) {
            this.setState({ added: true }, () => {
                setTimeout(() => {
                    this.setState({ imgSrcChanged: true, added: false })
                }, 200);
            })
        // }
        let selectedProductCopy = {...selectedProduct}
        selectedProductCopy.qty_formula = this.state.quantity
        selectedProductCopy.level_three_id = this.props.match.params.levelThreeId
        if(onlyWork)
        selectedProductCopy.only_work = true

        if(this.props.match.params.levelOneId === 'all') {
            this.setState({selectedProductCopy})
        } else {
            this.props.addProductToCart(selectedProductCopy,this.props.match.params.levelOneId)
            setTimeout(() => {
                this.setState({ addPopup: false })
                this.props.disActivateCurtain()
                // this.determineWhereInCartToRedirect()
            }, 2000);
        }

        // this.props.disActivateCurtain()
        this.activatePopup()
        this.props.activateCurtain()
        this.setState({addMenu:false})
        
        if(document.querySelector(".navbar__ace") != null)
        document.querySelector(".navbar__ace").style.zIndex = '110'
        if(document.querySelector(".curtain__ace") != null)
        document.querySelector(".curtain__ace").style.display = 'none'
    }

    //change Measurments of selected product

    changeMeasurments = (e) => {
        if(e.target.value < 0 || e.target.value > 100000) return
        this.props.changeSelectedProductMeasurments(e.target.name,e.target.value)
    }

    focusHandler = (e) => {
        this.savedValue = e.target.value
        this.props.changeSelectedProductMeasurments(e.target.name,'')
    }

    blurHandler = (e) => {
        if(!this.props.levels.selectedProduct[e.target.name])
        this.props.changeSelectedProductMeasurments(e.target.name,this.savedValue)
    }

    //check if product exist in cart

    existInCart = () => {
        let selectedRoom ,exist
        if(this.props.match.params.levelOneId != null && this.props.userCart.userCart.length > 0)
        selectedRoom = this.props.userCart.userCart.find(item => {return item.level_one_id === this.props.match.params.levelOneId})
        if(selectedRoom != null)
        exist = selectedRoom.products.findIndex(item => {return item.pid_id === this.props.match.params.productId})
        if (exist > -1) {
            this.setState({exist:true})
        } else {
            this.setState({exist:'stop'})
        }
    }

    determineWhereInCartToRedirect = (param = false) => {
        let toCheck = param ? param:this.props.match.params.levelOneId
        this.setState({addPopup:false});
        this.props.disActivateCurtain();
        let indexToGoTo = -1
        this.props.userCart.userCart.map( (room,index) => {
            if(room.level_one_id === toCheck)
            indexToGoTo = index
        })
        this.props.history.push(`/cart/${indexToGoTo}/leveloneid/${toCheck ? toCheck:'all'}/token`)
    }

    addGeneralProduct = (item) => {
        this.props.addProductToCart(this.state.selectedProductCopy,item.level_one_id)
        this.setState({addPopup:false})
        this.props.disActivateCurtain()
        this.determineWhereInCartToRedirect(item.level_one_id)
    }

    buildBtnId = async() => {
        const {productId} = this.props.match.params
        let typeOfMechiron = Cookies.get(HOST_NAME)
        let searchQuery = window.location.search
        searchQuery = searchQuery.replace("?q=", "")
        searchQuery = decodeURIComponent(searchQuery)
        
        let pidName = await ApiGet(API_URL + `/product/getById/${productId}`)
        pidName = pidName.name
        let dataString = 
        "UC:"
        + "\"" + ', ' + "product_id:"
        + "\"" + productId + "\"" + ', ' + "product_name:"
        + "\"" + pidName + "\""
        if(this.props.userDetails)
        dataString = dataString + ', ' + "User_full_name:" 
        + "\"" + this.props.userDetails.full_name + "\"" +  ', ' + "User_phone:"
        + "\"" + this.props.userDetails.phone + "\"" 

        // sendGAEvent(typeOfMechiron,"User",dataString)
        this.setState({btnContactDataString:dataString})
     
    }

    handleTalkWithUs = async() => {
        setTimeout( ()=>{this.setState({contactClicked:true})},10) 
        const {userDetails} = this.props
        const {selectedProduct} = this.props.levels
        let userId = userDetails._id ? userDetails._id:null
        let name = userDetails.full_name ? userDetails.full_name:null
        let details = 'product id:' + selectedProduct.pid_id + ' product name: ' + selectedProduct.name
        let sendDataForTracking = await ApiGet(API_URL + `/alert?user_id=${userId}&name=${name}&alert=user_contact_from_product_page&details=${details}`)
        
    }


    render() {
       

        const { lang,desktop,desktopMako,typeOfMechiron } = this.props
        
        const { vendors,selectedLevelTwo,selectedLevelThree,filters,products,complementary } = this.props.levels
        const {quantity} = this.state

        let selectedLevelOne = this.props.levels.selectedLevelOne
        let x
        let y
        let z
        let selectedProduct = {...this.props.levels.selectedProduct}
        let showMeasurementsInName = true 


        if(selectedProduct.height === 'Z'){
            showMeasurementsInName = false
        }
        if(selectedProduct.width === 'Y'){
            showMeasurementsInName = false
        }
        if(selectedProduct.length === 'X'){
            showMeasurementsInName = false
        }


        if(selectedLevelOne == null || !selectedLevelOne.x ){
            x = 100
        } else {
            x = selectedLevelOne.x
        }
        if(selectedLevelOne == null || !selectedLevelOne.y){
            y = 100
        } else {
            y = selectedLevelOne.y
        }
        if(selectedLevelOne == null || !selectedLevelOne.z){
            z = 100
        } else {
            z = selectedLevelOne.z
        }



        
       
        if(selectedProduct.height === 'Z' && z!=null){
            showMeasurementsInName = false
            selectedProduct.height = z.toString()
        }
        if(selectedProduct.width === 'Y' && y!=null){
            showMeasurementsInName = false
            selectedProduct.width = y.toString()
        }
        if(selectedProduct.length === 'X' && x!=null){
            showMeasurementsInName = false
            selectedProduct.length = x.toString()
        }

        

        let formula = selectedProduct.qty_formula
        let productVendor
        let total
        let productArray = []
        let productArray2 = []

        let workPrice = calculatePrices(selectedProduct,'work',{x,y,z})
        workPrice = workPrice * 1.17
        let productPrice = calculatePrices(selectedProduct,'product',{x,y,z})
        productPrice = productPrice * 1.17

        if(vendors.length > 0) {
            vendors.map(vendor=>{
                if(vendor.vendor_id === selectedProduct.supplier_id){
                    productVendor = vendor
                }
            })
        }

        if(vendors.length > 0) {
            if(selectedProduct.ace === '0' && typeOfMechiron === 'ace')
            productVendor = vendors.filter(item=>item.vendor_id === 'V190001')[0]
        }


        if(formula && formula != null) {
            formula = String(formula)
            formula = formula.replace(/x/gi, x);
            formula = formula.replace(/y/gi, y);
            formula = formula.replace(/z/gi, z);
            formula = eval(formula)
            total = formula * workPrice
            
            total = parseFloat(Math.round(total * 100) / 100).toFixed(2);
        }


        if(filters && filters.length > 0){
            
            // let selectedFilter = filters.filter((item) => {
            //     return selectedProduct.filter_id === item.filter_id
            // })
    
            // selectedFilter = selectedFilter[0]
            // if (selectedFilter && selectedFilter.filter_id) {
            //     selectedFilter = selectedFilter.filter_id
            // }

            if(products && products.length > 0) {  
                productArray2 = products.filter(item=>{
                    return ((item.filter_id !== this.props.levels.currentFilter) && (item.pid_id !== this.props.match.params.productId))
                })
            }
            
            if(complementary && complementary.length > 0) {
                productArray = complementary.map(item=>item)
            }

            
        }



        let stringName = ''

        if(selectedProduct != null){
            stringName = selectedProduct.name + ' '
        }

        if(showMeasurementsInName) {
            if(selectedProduct != null && selectedProduct.width !== 'N' && selectedProduct.width != null ){
                stringName += selectedProduct.width + 'X'
            }
    
    
            if(selectedProduct != null && selectedProduct.height !== 'N' && selectedProduct.height != null){
                stringName += selectedProduct.height + 'X'
            }
    
    
            if(selectedProduct != null && selectedProduct.length !== 'N' && selectedProduct.length != null){
                stringName += selectedProduct.length
            }
    
            if(stringName.charAt(stringName.length -1) === 'X')
            stringName = stringName.substr(0,stringName.length -1)
        }





        let picSrc = ''
        if(selectedProduct.picture_id === 'work'){
            picSrc = WorkPic
        } else if(selectedProduct.picture_id === 'equipment') {
            picSrc = Eqpic
        } else {
            picSrc = `${IMAGE_URL}/ace/${selectedProduct.picture_id}.png`
        }
        
        let cartEdit = window.location.href.includes('cart') && window.location.href.includes('edit')
        let bool = this.props.match.params.filterId == null
        let isEdit = window.location.pathname.includes('edit')
        let isChangeInCart = window.location.pathname.includes('editproductinusercart')


        return (
            <div className="product-page">
           
                <div onClick={()=>{}} ref={this.setWrapperRef2} className={this.state.addPopup ? "product-page__popup product-page__popup--active":"product-page__popup"}>
                    <header>
                       {
                            isChangeInCart ?
                            'הפריט עודכן בהצלחה'
                            :isEdit ?
                            'המוצר הוחלף בהצלחה'
                            :this.props.match.params.levelOneId === 'all' ?
                            'בחרו לאיזה חדר תרצו להוסיף את הפריט'
                            :
                            'המוצר התווסף לסל'
                       } 
                    </header>
                    <main>
                        <ImageComponent
                           src={picSrc}
                           alt="product" 
                        />
                        <span>
                            <p>{selectedProduct.name}</p>
                            <p>₪{!this.state.onlyWork ? ((workPrice + productPrice).toFixed(2)):workPrice.toFixed(2)}</p>
                        </span>
                    </main>
                    {/* <footer>
                        {window.location.href.includes('edit') && window.location.href.includes('cart') ?
                             <p onClick={()=>{this.props.disActivateCurtain();this.props.history.push(`/cart/${this.props.match.params.indexInCart}/leveloneid/${this.props.match.params.levelOneId}/token`)}}>חזור לעגלה</p>
                             :
                             window.location.href.includes('edit') && window.location.href.includes('recommendation') ?
                             <p onClick={()=>{this.props.disActivateCurtain();this.props.history.push(`/recommendation/${this.props.match.params.levelOneId}`)}}>חזור להמלצה</p>
                             :
                             <p onClick={()=>{this.determineWhereInCartToRedirect()}}>עבור לרשימה שלי</p>
                        }
                        <p onClick={()=>{this.setState({addPopup:false});this.props.disActivateCurtain()}}>השאר בעמוד מוצר</p>
                    </footer> */}
                   {this.props.match.params.levelOneId === 'all' ?
                    <footer>
                       
                        <main>
                            {this.props.userCart.userCart.map((item,index) => {
                                let productNames = ''

                                item.products.map(product=>{
                                    if(item.products != null && index !== item.products.length - 1 && productNames.length < 56){
                                        productNames+= product.name + ', '
                                    } else if(productNames.length < 56) {
                                        productNames+= product.name
                                    }
                                })

                                if(productNames.charAt(productNames.length -2) === ',')
                                productNames = productNames.substr(0,productNames.length -2)

                                if(productNames.length > 56)
                                productNames+= '...'


                                if(item.products.length > 0 || item.level_one_id === 'all')
                                return <div className="product__page__popup__rooms__item" onClick={()=>{this.addGeneralProduct(item)}}>
                                          {item.level_one_id === 'all' ? 
                                            <img src={GeneralRoom} alt="room"/>
                                            :
                                            <img src={IMAGE_URL_LEVELS + 'ace/' + item.level_one_id + '.png'} alt="room"/>
                                          }
                                          <div>
                                            <h6>{item.name}</h6>
                                            <p>
                                                {productNames}
                                            </p>
                                            <h6>פריטים: <span>{item.products.length}</span></h6>
                                          </div>
                                          
                                       </div>
                            })}
                        </main>
                    </footer>
                    :
                    null
                   } 
                </div>
               
                {this.props.match.params.levelOneId !== 'all' && bool && !cartEdit ?
                    <InnerNavigation
                      pages={['ראשי', selectedLevelOne.name, selectedLevelTwo.name,selectedLevelThree.name,selectedProduct.name]}
                      links={[
                        '/',
                        '/calculator/' + selectedLevelOne.level_one_id + '/room',
                        '/calculator/' + selectedLevelOne.level_one_id + '/room/' + selectedLevelTwo.level_two_id,
                        '/products/' + selectedLevelOne.level_one_id + '/' + selectedLevelTwo.level_two_id + '/' + selectedLevelThree.level_three_id,
                        '/products/' + selectedLevelOne.level_one_id + '/' + selectedLevelTwo.level_two_id + '/' + selectedLevelThree.level_three_id + '/' + selectedProduct.pid_id
                      ]} 
                    />
                    :
                    isChangeInCart ? 
                    <InnerNavigation 
                    pages={['עגלת פריטים', selectedLevelOne != null ? selectedLevelOne.name:'כללי',selectedProduct.name]}
                    links={['/cart/token',`/cart/${this.props.match.params.indexInCart}/leveloneid/${this.props.match.params.levelOneId}/token`,'/']}
                    />
                    :
                    cartEdit ?
                    <InnerNavigation 
                        pages={['עגלת פריטים', selectedLevelOne != null ? selectedLevelOne.name:'כללי', selectedLevelThree.name,selectedProduct.name]}
                        links={['/cart/token',`/cart/${this.props.match.params.indexInCart}/leveloneid/${this.props.match.params.levelOneId}/token`,`/cart/edit/roomindex/${this.props.match.params.indexInCart}/edit/${this.props.match.params.pidToEdit}/${this.props.match.params.levelOneId}/${this.props.match.params.levelTwoId}/${this.props.match.params.levelThreeId}/filter/${this.props.match.params.filterId}`,'/']}
                    />
                    :
                    !bool? 
                    <InnerNavigation 
                       pages={['ראשי', selectedLevelOne.name || 'כללי', 'בנינו עבורכם',selectedLevelThree.name,selectedProduct.name]}
                       links={[
                           '/',
                           `/calculator/${selectedLevelOne.level_one_id}/room`,
                           `/recommendation/${selectedLevelOne.level_one_id}`,
                           `/recommendation/edit/${this.props.match.params.levelOneId}/${this.props.match.params.levelTwoId}/${this.props.match.params.levelThreeId}/filter/${this.props.match.params.filterId}`,
                           `/recommendation/edit/${this.props.match.params.levelOneId}/${this.props.match.params.levelTwoId}/${this.props.match.params.levelThreeId}/filter/${this.props.match.params.filterId}/${this.props.match.params.productId}`
                        ]}
                    />
                    :
                    <InnerNavigation 
                       pages={['כל פרקי המחירון', selectedLevelTwo.name, selectedLevelThree.name,selectedProduct.name]}
                       links={['/allchapters',`/calculator/all/room/${this.props.match.params.levelTwoId}`,`/products/${this.props.match.params.levelOneId}/${this.props.match.params.levelTwoId}/${this.props.match.params.levelThreeId}`,`/`]}
                    />
                    }

                    {desktop || desktopMako ?
                    <main className="product-page__desktop">
                        <section className="product-page__desktop__first">
                            <figure>
                                <ImageComponent
                                    src={picSrc}
                                    alt="product-img" 
                                />
                                {productVendor && productVendor.logo_id ?
                                    <img src={`${IMAGE_URL_LEVELS}ace/${productVendor.logo_id}.svg`} alt="vendor-img"/>
                                    :
                                    null
                                }
                            </figure>

                            <div className="product-page__desktop__first__vendor">
                                <h1>{lang.secondSectionHeader}</h1>
                                <h3>{lang.secondSectionSubHeader}</h3>
                                {this.state.contactClicked ? 
                                    <div>
                                        {productVendor && productVendor.phone ? 
                                            <a href={"tel:" + productVendor.phone}>
                                                <img src={desktop ? PhoneR:desktopMako ? PhoneB:Phone} alt="phone" />
                                                <p>{productVendor && productVendor.phone}</p>
                                            </a>
                                            :
                                            null
                                        
                                        }
                                        {productVendor && productVendor.mail ? 
                                            <a href={"mailto:" + productVendor.mail}>
                                                <img src={desktop ? MailR:desktopMako ? MailB:Mail} alt="email" />
                                                <p>{productVendor && productVendor.mail}</p>
                                            </a>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    <div onClick={()=>{ this.handleTalkWithUs() }}>
                                        <a style={{cursor:'pointer'}} id={this.state.btnContactDataString}>
                                            <p className="product-page__talk-with-us" id={this.state.btnContactDataString}>דברו איתנו</p>
                                        </a>
                                    </div>
                                    // style={{borderBottom:'1px solid var(--pinkish-red)',paddingRight:'0px'}}
                                    
                                }

                            </div>

                        </section>

                        <section className="product-page__desktop__sec">

                            <div className="product-page__desktop__sec__description">
                                <header className="">
                                    <img src={InfoIcon} alt="more-info"/>
                                    <span>פירוט</span>
                                </header>

                                <h2 id="add-ancor">
                                    {stringName}
                                </h2>

                                <p>{productVendor ? lang.manifacturer + productVendor.name:null}</p>
                                <p></p>
                                
                                <span>{selectedProduct.description}</span>
                            </div>


                            {
                            (selectedProduct.width !== 'N' || selectedProduct.length !== 'N' || selectedProduct.height !== 'N') && showMeasurementsInName ?
                            <section className="product-page__measurments">

                            
                                <header className="product-page__header">
                                    <img src={MidotIcon} alt="measurments"/>
                                    <span>עריכת מידות</span>
                                </header>

                                {selectedProduct.width !== 'N' && selectedProduct.width != null ? 
                        
                                    <div>
                                        <h2>רוחב</h2>
                                        <input
                                        type="number"
                                        name="width"
                                        placeholder="מידת הרוחב"
                                        value={selectedProduct.width}
                                        onChange={(e)=>{this.changeMeasurments(e)}}
                                        onFocus={(e)=>{this.focusHandler(e)}}
                                        onBlur={(e)=>{this.blurHandler(e)}}
                                        />
                                    </div>
                                    :
                                    null
                                }
                                {selectedProduct.length !== 'N' && selectedProduct.length != null ? 
                                    <div>
                                        <h2>אורך/עומק</h2>
                                        <input
                                        type="number"
                                        name="length"
                                        placeholder="מידת האורך/עומק"
                                        value={selectedProduct.length}
                                        onChange={(e)=>{this.changeMeasurments(e)}}
                                        onFocus={(e)=>{this.focusHandler(e)}}
                                        onBlur={(e)=>{this.blurHandler(e)}}
                                        />
                                    </div>
                                    :
                                    null
                                }
                                {selectedProduct.height !== 'N' && selectedProduct.height != null ? 
                                    <div>
                                        <h2>גובה/עובי</h2>
                                        <input
                                        type="number"
                                        name="height"
                                        placeholder="מידת הגובה/עובי"
                                        value={selectedProduct.height}
                                        onChange={(e)=>{this.changeMeasurments(e)}}
                                        onFocus={(e)=>{this.focusHandler(e)}}
                                        onBlur={(e)=>{this.blurHandler(e)}}
                                        />

                                    </div>
                                    :
                                    null
                                }

                            </section>
                            :
                            null

                        } 

                        <div className="product-page__desktop__sec__pricing">
                            {workPrice > 0 ?
                                <span>
                                    <h3>מחיר עבודה ל{selectedProduct.units}</h3>
                                    <p>₪{parseFloat(workPrice.toFixed(2)).toLocaleString()}</p>
                                    <p>₪{parseFloat((workPrice * 1.07).toFixed(2)).toLocaleString()}</p>
                                </span>
                                :
                                null
                            }

                            {productPrice ? 
                                <span>
                                    <h3>מחיר מוצר ל{selectedProduct.units}</h3>
                                    <p>₪{parseFloat(productPrice.toFixed(2)).toLocaleString()}</p>
                                    <p>₪{parseFloat((productPrice * 1.07).toFixed(2)).toLocaleString()}</p>
                                </span>
                                :
                                null
                            }

                        </div>

                        <span className="product-page__desktop__sec__adding">
                            <QuantityInput header={lang.quantity} quantity={quantity} changeQuantity={this.changeQuantity} blurHandle={this.blurHandle} focusHandle={this.focusHandle} unit={selectedProduct.units} />
                            <button id={"UC: Add Product - " + selectedProduct.name} style={this.state.addMenu ? {zIndex:'99999'}:{}} className="button__red" ref={this.setWrapperRef} onClick={(e) => { this.handleAddIcon(e,selectedProduct,productPrice) }} >

                                <img id={"UC: Add Product - " + selectedProduct.name} src={ this.state.exist!=='stop' ? addedIcon : addIcon} alt="add" className={this.state.added ? "faded-icon" : "faded-icon--active"} />

                                {

                                    isChangeInCart?
                                    <span>
                                        {'עדכן פריט'}
                                    </span>
                                    :isEdit ?
                                    <span>
                                        {lang.editButton}
                                    </span>
                                    :
                                    <span id={"UC: Add Product - " + selectedProduct.name}>
                                        { this.state.exist!=='stop' ? lang.addedBtn : lang.addBtn}
                                    </span>
                                }

                                <aside className={this.state.addMenu ? "add__menu add__menu--active" : "add__menu"}>
                                    <div onClick={(e) => { this.handleAddIcon(e,selectedProduct,null,true) }}>
                                        <p>עבודה בלבד</p>
                                        <span>
                                            <h6>₪{workPrice.toFixed(2)}</h6>
                                            <h6>₪{(workPrice * 1.07).toFixed(2)}</h6>
                                        </span>
                                    </div>
                                    <div onClick={(e) => { this.handleAddIcon(e,selectedProduct,null) }}>
                                        <p>עבודה + פריט</p>
                                        <span>
                                            <h6>₪{(workPrice + productPrice).toFixed(2)}</h6>
                                            <h6>₪{((workPrice + productPrice)*1.07).toFixed(2)}</h6>
                                        </span>
                                    </div>
                                </aside>
                            </button>
                        </span>


                        <section className="product-page__more-products">
                            {productArray && productArray.length > 0 ? <h3>{lang.completingProducts}</h3>:null}
                            {productArray && productArray.length > 0 ? 
                                <div className="product-page__more-products--count">
                                    <p>מציג {productArray.length} פריטים </p>
                                </div>
                                :
                                null
                            }
                            <ProductSlider display={productArray && productArray.length > 0} selectedLevelOne={selectedLevelOne} products={productArray} />
                            
                            {productArray2 && productArray2.length > 0 ? <h3>{lang.similarProducts}</h3>:null}
                            {productArray2 && productArray2.length > 0 ?
                                <div className="product-page__more-products--count">
                                    <p>מציג {productArray2.length} פריטים </p>
                                </div>
                                :
                                null
                            }
                            <ProductSlider display={productArray2 && productArray2.length > 0} selectedLevelOne={selectedLevelOne} products={productArray2} />
                            
                        </section>

                        </section>

                    </main>
                    :
                    <main>

                    <section className="product-page__image-section">
                        <ImageComponent
                           src={picSrc}
                           alt="product" 
                        />
                    </section>

                    <div className="product-page__seperator"></div>

                    {/* <div className="product-page__heading" style={{background:this.state.activeColor}}></div> */}

                    {/* <div className="product-page__more-items">
                        <span className={this.state.activeColorIndex === 1 ? "product-page__more-items--active":""}  style={{backgroundColor:'#8799bf'}} onClick={()=>{this.setState({activeColorIndex:1,activeColor:'#8799bf'})}}></span>
                        <span className={this.state.activeColorIndex === 2 ? "product-page__more-items--active":""}  style={{backgroundColor:'#d6e2fa'}} onClick={()=>{this.setState({activeColorIndex:2,activeColor:'#d6e2fa'})}}></span>
                        <span className={this.state.activeColorIndex === 3 ? "product-page__more-items--active":""}  style={{backgroundColor:'#b1c6f3'}} onClick={()=>{this.setState({activeColorIndex:3,activeColor:'#b1c6f3'})}}></span>
                        <span className={this.state.activeColorIndex === 4 ? "product-page__more-items--active":""}  style={{backgroundColor:'#e8efff'}} onClick={()=>{this.setState({activeColorIndex:4,activeColor:'#e8efff'})}}></span>
                        <span className={this.state.activeColorIndex === 5 ? "product-page__more-items--active":""}  style={{backgroundColor:'#d2daec'}} onClick={()=>{this.setState({activeColorIndex:5,activeColor:'#d2daec'})}}></span>
                        <span className={this.state.activeColorIndex === 6 ? "product-page__more-items--active":""} style={{backgroundColor:'#eaf1ff'}} onClick={()=>{this.setState({activeColorIndex:6,activeColor:'#eaf1ff'})}}></span>
                    </div> */}

                    <section className="product-page__description">
                        <header className="product-page__header">
                            <img src={InfoIcon} alt="more-info"/>
                            <span>פירוט</span>
                        </header>
                        <h2 id="add-ancor">
                            {stringName}
                        </h2>
                        <section>
                            <p>{productVendor ? lang.manifacturer + productVendor.name:null}</p>
                            {productVendor && productVendor.logo_id ?
                                <img src={`${IMAGE_URL_LEVELS}ace/${productVendor.logo_id}.svg`} alt={productVendor.name}/>
                                :
                                null
                            }
                        </section>
                        <p></p>
                        <p>{selectedProduct.description}</p>

                    </section>


                    <div className="product-page__seperator"></div>


                   {(selectedProduct.width !== 'N' || selectedProduct.length !== 'N' || selectedProduct.height !== 'N') && showMeasurementsInName ?
                    <section className="product-page__measurments">
                    <header className="product-page__header">
                        <img src={MidotIcon} alt="measurments"/>
                        <span>עריכת מידות</span>
                    </header>

                {selectedProduct.width !== 'N' && selectedProduct.width != null ? 
                
                            <div>
                                <h2>רוחב</h2>
                                <input
                                type="number"
                                name="width"
                                placeholder="מידת הרוחב"
                                value={selectedProduct.width}
                                onChange={(e)=>{this.changeMeasurments(e)}}
                                onFocus={(e)=>{this.focusHandler(e)}}
                                onBlur={(e)=>{this.blurHandler(e)}}
                                />
                            </div>
                            :
                            null
                        }
                        {selectedProduct.length !== 'N' && selectedProduct.length != null ? 
                            <div>
                                <h2>אורך/עומק</h2>
                                <input
                                type="number"
                                name="length"
                                placeholder="מידת האורך/עומק"
                                value={selectedProduct.length}
                                onChange={(e)=>{this.changeMeasurments(e)}}
                                onFocus={(e)=>{this.focusHandler(e)}}
                                onBlur={(e)=>{this.blurHandler(e)}}
                                />
                            </div>
                            :
                            null
                        }
                        {selectedProduct.height !== 'N' && selectedProduct.height != null ? 
                            <div>
                                <h2>גובה/עובי</h2>
                                <input
                                type="number"
                                name="height"
                                placeholder="מידת הגובה/עובי"
                                value={selectedProduct.height}
                                onChange={(e)=>{this.changeMeasurments(e)}}
                                onFocus={(e)=>{this.focusHandler(e)}}
                                onBlur={(e)=>{this.blurHandler(e)}}
                                />
                            </div>
                            :
                            null
                        }

                    </section>
                    :
                    null

                   } 


                    {(selectedProduct.width !== 'N' || selectedProduct.length !== 'N' || selectedProduct.height !== 'N') && showMeasurementsInName ?
                        <div className="product-page__seperator"></div>
                        :
                        null
                    }

                    <section className="product-page__description">
                        <header className="product-page__header">
                            <img src={CostIcon} alt="cost"/>
                            <span>עלות</span>
                        </header>
                        <div>
                            {workPrice > 0 ?
                                <span>
                                    <h3>מחיר עבודה ל{selectedProduct.units}</h3>
                                    <p>₪{parseFloat(workPrice.toFixed(2)).toLocaleString()}</p>
                                    <p>₪{parseFloat((workPrice * 1.07).toFixed(2)).toLocaleString()}</p>
                                </span>
                                :
                                null
                            }

                            {productPrice ? 
                                <span>
                                    <h3>מחיר מוצר ל{selectedProduct.units}</h3>
                                    <p>₪{parseFloat(productPrice.toFixed(2)).toLocaleString()}</p>
                                    <p>₪{parseFloat((productPrice * 1.07).toFixed(2)).toLocaleString()}</p>
                                </span>
                                :
                                null
                            }

                        </div>

                        <span>
                            <QuantityInput header={lang.quantity} quantity={quantity} changeQuantity={this.changeQuantity} blurHandle={this.blurHandle} focusHandle={this.focusHandle} unit={selectedProduct.units} />
                            <button style={this.state.addMenu ? {zIndex:'10000'}:{}} className="button__red" ref={this.setWrapperRef} onClick={(e) => { this.handleAddIcon(e,selectedProduct,productPrice) }} >

                                <img src={this.state.imgSrcChanged ? addedIcon : addIcon} alt="add" className={this.state.added ? "faded-icon" : "faded-icon--active"} />

                                {
                                    isChangeInCart?
                                    <span>
                                        {'עדכן פריט'}
                                    </span>
                                    :isEdit ?
                                    <span>
                                        {lang.editButton}
                                    </span>
                                    :
                                    <span>
                                        { this.state.exist!=='stop' ? lang.addedBtn : lang.addBtn}
                                    </span>
                                }

                                <aside className={this.state.addMenu ? "add__menu add__menu--active" : "add__menu"}>
                                    <div onClick={(e) => { this.handleAddIcon(e,selectedProduct,null,true) }}>
                                        <p>עבודה בלבד</p>
                                        <span>
                                            <h6>₪{workPrice.toFixed(2)}</h6>
                                            <h6>₪{(workPrice * 1.07).toFixed(2)}</h6>
                                        </span>
                                    </div>
                                    <div onClick={(e) => { this.handleAddIcon(e,selectedProduct,null) }}>
                                        <p>עבודה + פריט</p>
                                        <span>
                                            <h6>₪{(workPrice + productPrice).toFixed(2)}</h6>
                                            <h6>₪{((workPrice + productPrice)*1.07).toFixed(2)}</h6>
                                        </span>
                                    </div>
                                </aside>
                            </button>
                        </span>
                    </section>

                    <div className="product-page__seperator"></div>

                    <section className="product-page__contact">
                        <h1>{lang.secondSectionHeader}</h1>
                        <h3>{lang.secondSectionSubHeader}</h3>

                        {this.state.contactClicked ? 
                        <div>
                            {productVendor && productVendor.phone ? 
                                <a href={"tel:" + productVendor.phone} className="button__red">
                                    <img src={Phone} alt="phone" />
                                    <p>{productVendor && productVendor.phone}</p>
                                </a>
                                :
                                null
                            
                            }
                            {productVendor && productVendor.mail ? 
                                <a style={!productVendor.phone ? {width:'100%'}:{}} href={"mailto:" + productVendor.mail} className="button__red">
                                    <img src={Mail} alt="email" />
                                    <p>{productVendor && productVendor.mail}</p>
                                </a>
                                :
                                null
                            }
                        </div>
                        :
                        <div>
                            <a style={{width:'100%'}} className="button__red" id={this.state.btnContactDataString} onClick={()=>{ setTimeout( ()=>{this.setState({contactClicked:true})},10) }}>
                                <img src={Mail} alt="email" id={this.state.btnContactDataString}/>
                                <p style={{textOverflow:'unset'}} id={this.state.btnContactDataString}>דברו איתנו</p>
                            </a>
                        </div>
                        }


                    </section>

                    <div className="product-page__seperator"></div>
               


                <section className="product-page__more-products">
                    {productArray && productArray.length > 0 ? <h3>{lang.completingProducts}</h3>:null}
                    <ProductSlider idType={'UC: Clicked On Completing Product'} desktop={this.props.desktop || this.props.desktopMako} display={productArray && productArray.length > 0} selectedLevelOne={selectedLevelOne} products={productArray} />
                    
                    {productArray2 && productArray2.length > 0 ? <h3>{lang.similarProducts}</h3>:null}
                    <ProductSlider idType={'UC: Clicked On Similar Product'} desktop={this.props.desktop || this.props.desktopMako} display={productArray2 && productArray2.length > 0} selectedLevelOne={selectedLevelOne} products={productArray2} />
                    
                </section>
                

                </main>

                }
            </div>
        );
    }
}


function stateToProps({ levels,userCart }) {
    return { levels,userCart }
}
  
export default withRouter(connect(stateToProps, actions)(ProductPage))