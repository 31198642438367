import React, { Component } from 'react'
import './navbar.css'
import { Link } from 'react-router-dom'
import {withRouter} from 'react-router-dom'
import Cookies from 'js-cookie'
//images
import MechironWhiteLogo from '../../../images/mako/machw.svg'
import AceRed from '../../../images/acered.png'
import OriginalMechiron from '../../../images/original/11.svg'
import MakoMechiron from '../../../images/mako/16.svg'
import NavPro from '../../../images/icons/navpro.svg'
import NavProActive from '../../../images/icons/navproactive.svg'
import NavSearch from '../../../images/icons/navsearch.svg'
import NavSearchMako from '../../../images/mako/10.svg'
import Arrow from '../../../images/icons/nav1.svg'
import ArrowReg from '../../../images/original/16.svg'
import ArrowMako from '../../../images/mako/17.svg'
import BackArrow from '../../../images/icons/backArrow.svg'
import BackArrowMako from '../../../images/mako/11.svg'
import Nav2 from '../../../images/icons/nav2.svg'
import Nav3 from '../../../images/icons/nav3.svg'
import Nav4 from '../../../images/icons/nav4.svg'
import Nav5 from '../../../images/icons/nav5.svg'
import Nav6 from '../../../images/icons/nav6.svg'
import Nav7 from '../../../images/icons/loginreg.svg'
import Nav8 from '../../../images/icons/logoutreg.svg'
import NavMako1 from '../../../images/mako/nav1.svg'
import NavMako2 from '../../../images/mako/nav2.svg'
import NavMako3 from '../../../images/mako/nav3.svg'
import NavMako4 from '../../../images/mako/nav4.svg'
import NavMako5 from '../../../images/mako/nav5.svg'
import NavMako7 from '../../../images/mako/loginmako.svg'
import NavMako8 from '../../../images/mako/logoutmako.svg'
//comps
import {ApiGet} from '../../../tools/tools'
//config
import {API_URL, USER_TOKEN,HOST_NAME} from '../../../configs/configs'

class Navbar extends Component {

    constructor(){
        super()

        this.state = {
            navScrolled:false,
            navMenuActive: false,
            levelTwos: [],
            prevPath: '',
            typeOfMechiron: ''
        }
    }



    
    async componentWillMount() {
        let res = await ApiGet(API_URL + `/levelTwo/getAll`)
        if(res)
        this.setState({levelTwos:res})

        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})
    }
    

    componentDidMount() {
        window.addEventListener("scroll",this.handleScroll,{passive: true});
        window.addEventListener("click",this.handleClick,{passive: true});
    }

    componentWillUnmount() {
        window.addEventListener("scroll",this.handleScroll,{passive: true});
        window.addEventListener("click",this.handleClick,{passive: true});
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
          this.setState({ prevPath: this.props.location.pathname })
        }
    }

    handleScroll = () => {
        if(window.pageYOffset > 0) {
            this.setState({navScrolled:true})
        } else {
            this.setState({navScrolled:false})
        }
    }

    handleClick = (event) => {
        if(event.target.className !== 'navbar__hamburger')
        this.setState({navMenuActive:false})
    }

    toggleNav = (e) => {
        if(e != null)
        e.stopPropagation()

        this.setState(prevState => ({
            navMenuActive: !prevState.navMenuActive
        }),()=>{
            // this.props.toggleCurtain()
        });
    }

    goto = (link) => {
        if(link === '/login') {
            if(Cookies.get(USER_TOKEN)){
                Cookies.remove(USER_TOKEN)
                this.setState({navMenuActive:false})
                this.props.history.push(`/`)
                this.props.resetCart()
            } else {
                this.props.history.push('/login')
            }

            return
        }
        // if(Cookies.get(USER_TOKEN)) {
            this.setState({navMenuActive:false})
            this.props.disActivateCurtain()
            this.props.history.push(link)
        // } else {
        //     this.setState({navMenuActive:false})
        //     this.props.disActivateCurtain()
        //     if(link === '/regulations') {
        //         this.props.history.push('/regulations') 
        //     } else if(link.includes('contact')) {
        //         this.props.history.push('/contact/-1') 
        //     } else {
        //         this.props.history.push('/login')
        //     }
        // }

    }

    goBackFunc = () => {
        if(this.state.prevPath.includes('login') && window.location.pathname !=='/regulations'){
            this.props.history.push(`/`)
        } else {
            this.props.history.goBack()
        }
    }


    render() {

        const {navScrolled, navMenuActive, levelTwos,typeOfMechiron} = this.state
        const {lang,showNav} =  this.props

        let isContact = window.location.href.includes('contact')
        let loggedOutUser = (!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null)

        return (
            <nav  style={!showNav ? {display:'none'}:{}} className={navScrolled? "navbar navbar--scrolled":"navbar"} onClick={()=>{this.props.disActivateCurtain()}}>
                <section>

                    <div style={window.location.pathname === '/' ? {display:'none'}:{}} className="navbar__back" onClick={()=>{this.goBackFunc()}}>
                       <img src={typeOfMechiron === 'mako' ? BackArrowMako:BackArrow} alt="back"/>
                    </div>
                 
                    <Link to="/" onClick={()=>{this.props.disActivateCurtain()}}>
                        {!isContact && typeOfMechiron === 'ace' ?
                            <img src={AceRed} alt="logo-ace"  className="navbar__logo"/>
                            :
                            !isContact && typeOfMechiron === 'mako' ?
                            <img src={MakoMechiron} alt="logo-mako" className="navbar__logo"/>
                            :
                            isContact && typeOfMechiron === 'mako' ?
                            <img src={MechironWhiteLogo} alt="logo-mako" className="navbar__logo"/>
                            :
                            <img src={OriginalMechiron} alt="logo-mechiron" className="navbar__logo"/>
                        }
                    </Link>
                </section>


                {typeOfMechiron === 'mako' ?  
                    <a href="https://www.mako.co.il" className="navbar__back__mako"> לאתר mako</a>
                    :
                    null
                } 

                <section>
                    <Link to="/search">
                        <span className="navbar__icons">
                            <img src={typeOfMechiron === 'mako' ? NavSearchMako:NavSearch} alt="search" />
                        </span>
                    </Link>


                    <div id="nav-icon3" className={navMenuActive ? "open":""} onClick={(e)=>{this.toggleNav(e)}}>
                        <span onClick={(e)=>{this.toggleNav(e)}}></span>
                        <span onClick={(e)=>{this.toggleNav(e)}}></span>
                        <span onClick={(e)=>{this.toggleNav(e)}}></span>
                    </div>
                </section>



                <section className={navMenuActive ? "navbar__menu navbar__menu--active":"navbar__menu"}>
                
                    <div onClick={()=>{this.goto('/choice')}}>
                        <span>
                            <img src={typeOfMechiron === 'mako' ? NavMako1:Nav2} alt="rooms"/>
                            <p>{lang.nav2}</p>
                        </span>
                        <img src={typeOfMechiron === 'ace' ?  Arrow: typeOfMechiron === 'mako' ? ArrowMako:ArrowReg} alt="navigation-arrow"/>
                    </div>
                    <div onClick={()=>{this.goto('/aces/cart')}} id="UC: Clicked on pros in menu">
                        <span id="UC: Clicked on pros in menu">
                            <img id="UC: Clicked on pros in menu"src={typeOfMechiron === 'mako' ? NavMako2:Nav3} alt="cart"/>
                            <p id="UC: Clicked on pros in menu">{lang.nav3}</p>
                        </span>
                        <img id="UC: Clicked on pros in menu" src={typeOfMechiron === 'ace' ?  Arrow: typeOfMechiron === 'mako' ? ArrowMako:ArrowReg} alt="navigation-arrow"/>
                    </div>
                    <div id="UC: Enter Contact Us Page" onClick={()=>{this.goto('/contact/-1')}}>
                        <span id="UC: Enter Contact Us Page">
                            <img id="UC: Enter Contact Us Page" src={typeOfMechiron === 'mako' ? NavMako3:Nav4} alt="contact"/>
                            <p id="UC: Enter Contact Us Page">{lang.nav4}</p>
                        </span>
                        <img id="UC: Enter Contact Us Page" src={typeOfMechiron === 'ace' ?  Arrow: typeOfMechiron === 'mako' ? ArrowMako:ArrowReg} alt="navigation-arrow"/>
                    </div>
                    {/* <div>
                        <span>
                            <img src={typeOfMechiron === 'mako' ? NavMako4:Nav5} alt="liked"/>
                            <p>{lang.nav5}</p>
                        </span>
                        <img src={typeOfMechiron === 'ace' ?  Arrow: typeOfMechiron === 'mako' ? ArrowMako:ArrowReg} alt="navigation-arrow"/>
                    </div> */}
                    <div onClick={()=>{this.goto('/regulations')}}>
                        <span>
                            <img src={typeOfMechiron === 'mako' ? NavMako5:Nav6} alt="regulations"/>
                            <p>{lang.nav6}</p>
                        </span>
                        <img src={typeOfMechiron === 'ace' ?  Arrow: typeOfMechiron === 'mako' ? ArrowMako:ArrowReg} alt="navigation-arrow"/>
                    </div>  
                    <div onClick={()=>{this.goto('/login')}}>
                        <span>
                            <img src={
                                typeOfMechiron === 'mako' && loggedOutUser ? NavMako7
                                :
                                typeOfMechiron === 'mako' && !loggedOutUser ? NavMako8
                                :
                                typeOfMechiron !== 'mako' && loggedOutUser ? Nav7
                                :
                                Nav8
                            } alt="regulations"/>
                            <p>{loggedOutUser ? 'התחבר':'התנתק'}</p>
                        </span>
                        <img src={typeOfMechiron === 'ace' ?  Arrow: typeOfMechiron === 'mako' ? ArrowMako:ArrowReg} alt="navigation-arrow"/>
                    </div> 
                    <div className="navbar__menu--devider">{lang.devider1}</div>
                    <div onClick={()=>{this.goto('/allchapters')}}>
                        <span>{lang.chapters}</span>
                        <img src={typeOfMechiron === 'ace' ?  Arrow: typeOfMechiron === 'mako' ? ArrowMako:ArrowReg} alt="navigation-arrow"/>
                    </div>

                    {levelTwos.map(item=>{
                        return <div key={item.level_two_id} onClick={()=>{this.goto(`/calculator/all/room/${item.level_two_id}`)}}>
                                  <span>{item.name}</span>
                                  <img src={typeOfMechiron === 'ace' ?  Arrow: typeOfMechiron === 'mako' ? ArrowMako:ArrowReg} alt="navigation-arrow"/>
                               </div>
                    })}
                </section>

            </nav>
        );
    }
}

export default withRouter(Navbar);