import React, { Component } from 'react'
import './login.css'
import { withRouter } from 'react-router-dom'
import Cookies from 'js-cookie'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
import Loader from '../../../Components/globals/Loader'
import Validator from 'validator'
import ReactGA from 'react-ga';
//tools
import {ApiPostWithBody,ApiGet,ApiGetWithHeader,ApiPostWithHeaderToken} from '../../../tools/tools.js'
// configs
import {API_URL,USER_TOKEN,HOST_NAME,GOOGLE_ANALYTICS_MECHIRON_ID,GOOGLE_ANALYTICS_ACE_ID,GOOGLE_ANALYTICS_MAKO_ID} from '../../../configs/configs'
//img
import Bell from '../../../images/icons/bell.svg'

import Cubes from '../../../images/icons/cubes.svg'
import CubesReg from '../../../images/original/1.svg'

import StageOne from './parts/StageOne'
import StageTwo from './parts/StageTwo'

import AceRegister from './parts/AceRegister'

class Login extends Component {
    constructor() {
        super()

        this.state = {
            phone: '',
            name: '',
            ace_email:'',
            ace_first_name:'',
            ace_last_name:'',

            stageTwo:false,
            checkBox: false,
            vertified:false,
            codeInput:'',
            user_id: '',
            popup: false,
            token: '',
            typeOfMechiron: '',
            loader:false,
            resend:false,
            timing:10,
            register:false,

            nameErr: '',
            phoneErr:'',
            ace_email_err:'',
            ace_first_name_err:'',
            ace_last_name_err:''
        }

        this.interval = setInterval(()=>{ 
            if(this.state.timing > 0) {
                this.setState(prevState => ({
                    timing: prevState.timing--
                }));
            }
        }, 1000);

    }

    componentWillMount(){
        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})
        if(this.props.userCart.regulation)
        this.setState({stageTwo:true},()=>{
            setTimeout(()=>{ window.scrollTo(0, 0) }, 0);
        })
    }

    componentDidMount() {
        
        if(this.state.typeOfMechiron === 'ace') {
            ReactGA.initialize([
                {trackingId: GOOGLE_ANALYTICS_MECHIRON_ID, gaOptions: { name: 'trackerMechiron' }},
                {trackingId: GOOGLE_ANALYTICS_ACE_ID, gaOptions: { name: 'trackerAce' }} 
            ])
        } else if(this.state.typeOfMechiron === 'mako'){
            ReactGA.initialize([
                {trackingId: GOOGLE_ANALYTICS_MECHIRON_ID, gaOptions: { name: 'trackerMechiron' }},
                {trackingId: GOOGLE_ANALYTICS_MAKO_ID, gaOptions: { name: 'trackerMako' }} 
            ])
        } else {
            ReactGA.initialize(GOOGLE_ANALYTICS_MECHIRON_ID)
        }


        if(this.state.typeOfMechiron === 'ace')
        ReactGA.ga('trackerAce.send', {hitType: "event", eventCategory: "User", eventAction: "User Entered to Register Page"});

        if(this.state.typeOfMechiron === 'mako')
        ReactGA.ga('trackerMako.send', {hitType: "event", eventCategory: "User", eventAction: "User Entered to Register Page"});

        ReactGA.ga('trackerMechiron.send', {hitType: "event", eventCategory: "User", eventAction: "User Entered to Register Page"});

    }




    next = async() => {
        this.setState({loader:true})
        if(!this.state.stageTwo) {
            let body = {
                full_name: this.state.name,
                phone: this.state.phone,
                first_name: this.state.ace_first_name,
                last_name: this.state.ace_last_name,
                email: this.state.ace_email,
            }
            // this.props.saveUserDetails(body)
             
            let res = await ApiPostWithBody(API_URL + '/register', body)
            
            if(res && res.ok){
                this.setState({stageTwo:true,user_id:res.result,loader:false},()=>{
                    setTimeout(()=>{ window.scrollTo(0, 0) }, 0);
                })
                this.props.saveUserId(res.result)

            }
            this.blurHandle('name',this.state.name)
            this.blurHandle('phone',this.state.phone)
            this.setState({loader:false})
            return
        } else {
            await this.vertify(this.state.codeInput)
            //cacel popoup desktop app
            if(this.props.generalDesktopCallback)
            this.props.generalDesktopCallback(this.state.vertified)

            if(window.location.pathname.includes('loginacepage')){
                if(this.state.vertified){
                    this.props.history.push(`/aces/type/${this.props.match.params.proId}`)
                    let cart = await ApiGet(API_URL + '/cart/get?user_id=' + this.props.userCart.userId)
                    if(typeof cart === 'object'){
                        this.props.saveInitialCartData(cart.cart_id,cart.cart)
                    } else {
                        this.props.saveInitialCartData(cart,[])
                    }
                }
                this.setState({loader:false})
                return
            }

            if(window.location.pathname.includes('tocart')){
                if(this.state.vertified){
                    this.props.history.push(`/cart/token`)
                    let cart = await ApiGet(API_URL + '/cart/get?user_id=' + this.props.userCart.userId)
                    if(typeof cart === 'object'){
                        this.props.saveInitialCartData(cart.cart_id,cart.cart)
                    } else {
                        this.props.saveInitialCartData(cart,[])
                    }
                }
                this.setState({loader:false})
                return
            }

            if(window.location.pathname.includes('find')){
                if(this.state.vertified){
                    if(this.props.route && !this.props.roomDesktopCallback) {
                        this.props.history.push(this.props.route)
                    } else {
                        this.props.history.push(`/products/${this.props.match.params.levelOneId}/${this.props.match.params.levelTwoId}/${this.props.match.params.levelThreeId}/${this.props.match.params.productId}`)
                    }
                    let cart = await ApiGet(API_URL + '/cart/get?user_id=' + this.props.userCart.userId)
                    if(typeof cart === 'object'){
                        this.props.saveInitialCartData(cart.cart_id,cart.cart)
                    } else {
                        this.props.saveInitialCartData(cart,[])
                    }
                }
                this.setState({loader:false})
                return
            }
            if(this.props.match.params.levelTwoId != null) {
                if(this.state.vertified){
                    if(this.props.lvl3Id){
                        this.props.history.push(`/products/${this.props.match.params.levelOneId}/${this.props.match.params.levelTwoId}/${this.props.lvl3Id}`)
                    } else {
                        this.props.history.push(`/products/${this.props.match.params.levelOneId}/${this.props.match.params.levelTwoId}/${this.props.match.params.levelThreeId}`)
                    }
                    let cart = await ApiGet(API_URL + '/cart/get?user_id=' + this.props.userCart.userId)
                    if(typeof cart === 'object'){
                        this.props.saveInitialCartData(cart.cart_id,cart.cart)
                    } else {
                        this.props.saveInitialCartData(cart,[])
                    }
                }
                this.setState({loader:false})

            } else {
                if(this.state.vertified){

                    if(!this.props.roomDesktopCallback && !this.props.route){
                        if(!this.props.match.params.levelOneId || this.props.match.params.levelOneId == null){
                            this.props.history.push(`/`)
                            
                        } else if(this.props.match.params.levelOneId === 'topro') {
                            this.props.history.push(`/aces/type/${this.props.match.params.levelOneId}`)
                        } else {
                            this.props.history.push(`/recommendation/${this.props.match.params.levelOneId}`)
                        }

                    } else if(this.props.route && !this.props.roomDesktopCallback) {
                        this.props.history.push(this.props.route)

                    }
                    let cart = await ApiGet(API_URL + '/cart/get?user_id=' + this.props.userCart.userId)
                    if(typeof cart === 'object'){
                        this.props.saveInitialCartData(cart.cart_id,cart.cart)
                    } else {
                        this.props.saveInitialCartData(cart,[])
                    }
                     
                    this.props.saveInitialCartData(cart.cart_id,cart.cart)
                }
                this.setState({loader:false})
                if(this.props.roomDesktopCallback)
                this.props.roomDesktopCallback()

            }
        }
    }

    toggleCheckBox = () => {
        this.setState(prevState => ({
            checkBox: !prevState.checkBox
        }),()=>{
            // this.vertify(this.state.codeInput)
        });
    }

    handleChange = (name,value) => {
        this.setState({
            [name] : value
        })
    }

    cancelPopup = (e) => {
        if(e.target.className.includes('minimun__price__popup') || e.target.nodeName === 'BUTTON')
        this.setState({popup:false})
    }

    vertify = async(code) => {
        if(!this.state.checkBox || code.length < 4){
            this.setState({errValidation:'הקוד שהוזן לא נכון'})
            return
        }
        if(code.length === 4) {
            let body = {
                user_id: this.props.userCart.userId,
                code
            }
            let res = await ApiPostWithBody(API_URL + '/verify', body)
            if(res.ok) {
                this.setState({
                    vertified:true,
                    errValidation:'',
                    popup:true,
                    token:res.result.token
                },async()=>{
                  ReactGA.event({
                      category: 'User',
                      action: 'User registered successfully'
                  });
                  Cookies.set(USER_TOKEN, this.state.token, { expires: 90 });
                  let res = await ApiGetWithHeader(API_URL + '/signin',this.state.token)
                  this.props.saveUserId(res._id)
                  //checkif use is pro
                  let isPro = await ApiPostWithHeaderToken(API_URL + '/prointerface/login',this.state.token)
                  if(isPro.ok)
                  this.props.setUserIsAcePro()
                  //
                  let cart = await ApiGet(API_URL + '/cart/get?user_id=' + res._id)
                  this.props.saveInitialCartData(cart,cart.cart)
                  this.props.initUserCartRoom({level_one_id: "all", name: "כללי", x: 100, y: 100, z: 100,products:[]},this.props.userCart)

                })
            } else {
                this.setState({
                    vertified:false,
                    errValidation:'קוד לא נכון'
                })
            }
        } 
    }

    blurHandle = (name,value) => {
        if(name === 'name' && !value) {
            this.setState({nameErr:'אנא הכנס את שמך'})
        } else if (name === 'phone' && !value) {
            this.setState({phoneErr:'אנא הכנס מספר טלפון'})
        } else if (name === 'phone') {
            if(value.substr(0,2) !== '05' || value.length !== 10) {
                this.setState({phoneErr:'מספר טלפון לא תקין'})
            } else {
                this.setState({phoneErr:''})
            }
        } else if(name === 'name') {
            this.setState({nameErr:''})
        }
         else if(name === 'ace_email' && !value ) {
            this.setState({ace_email_err:'אנא הכנס כתובת דוא"ל'})
        } else if(name === 'ace_email' && !Validator.isEmail(value) ) {
            this.setState({ace_email_err:'כתובת דוא"ל אינה תקינה'})
        } else if (name === 'ace_email') {
            this.setState({ace_email_err:''})
        } else if(name === 'ace_first_name' && !value){
            this.setState({ace_first_name_err:'אנא הכנס שם פרטי'})
        } else if(name === 'ace_first_name'){
            this.setState({ace_first_name_err:''})
        } else if(name === 'ace_last_name' && !value){
            this.setState({ace_last_name_err:'אנא הכנס שם משפחה'})
        } else if(name === 'ace_last_name'){
            this.setState({ace_last_name_err:''})
        }

    }

    resetErr = () => {
        this.setState({
            errValidation:''
        })
    }


    sendAgain = async() => {
        let body = {
            user_id: this.props.userCart.userId,
        }
        let res = await ApiPostWithBody(API_URL + '/resendCode', body)
        console.log(res)
        if(res.ok){
            this.setState({
                resend:true
            },()=>{
                this.interval = setInterval(()=>{ 
                    if(this.state.timing > 0) {
                        this.setState(prevState => ({
                            timing: prevState.timing - 1
                        }));
                    }
                }, 1000);
            })

            setTimeout(()=>{ 
                this.setState({
                    resend:false,
                    timing:10
                },()=>{
                    clearInterval(this.interval);
                }) 
            }, 10000);
        } else {
            console.log('error in resend')
        }
    }

    saveRegulation = () => {
        this.props.saveRegulation()
    }

    submitForm = (e) => {
        e.preventDefault()
        this.next()
    }

    memberExistHandle = () => {
        this.setState({register:true})
    }

    render() {
        const {lang} = this.props
        const {timing,
            resend,
            stageTwo,
            checkBox,
            phone,
            name,
            phoneErr,
            nameErr,
            checkbox,
            vertified,
            codeInput,
            errValidation,
            popup,
            typeOfMechiron,
            loader,
            ace_email,
            ace_first_name,
            ace_last_name,
            ace_email_err,
            ace_first_name_err,
            ace_last_name_err,
            register
        } = this.state


        return (
            typeOfMechiron !== 'ace' ?

            <form id={!stageTwo ? "register-stage-one":"register-stage-two"} className="login" onSubmit={(e)=>{this.submitForm(e)}}>
              {!stageTwo ? 
              <StageOne typeOfMechiron={typeOfMechiron} nameErr={nameErr} phoneErr={phoneErr} lang={lang} handleChange={this.handleChange} name={name} phone={phone} blurHandle={this.blurHandle}/>  
              :
              <StageTwo timing={timing} resend={resend} isReg={typeOfMechiron} resetErr={this.resetErr} saveRegulation={this.saveRegulation} sendAgain={this.sendAgain} validate={this.vertify} errValidation={errValidation} handleChange={this.handleChange} codeInput={codeInput} toggleCheckBox={this.toggleCheckBox} checkBox={checkBox} lang={lang}/>
              }
            
              {!stageTwo ? 
                <button type="submit" className={phone && name && !nameErr && !phoneErr ? "button__red":"button__red button__disabled"}>
                    {loader ? 
                        <Loader />
                        :
                        <span>{lang.stageOneFormButton}</span>
                    }
                </button>
                :
                <button className={checkBox && codeInput ? "button__red":"button__red button__disabled"} type="submit">
                   {loader ? 
                       <Loader />
                       :
                       <span>{lang.stageOneFormButton}</span>
                    }
                </button>
              }
              <p>{lang.FormFooter}</p>
              <img src={typeOfMechiron !== 'ace' ?  CubesReg:Cubes} alt="cubes"/>
            </form>

            :

            !register && Cookies.get(USER_TOKEN) == 'false string to be edited later' & !stageTwo ? 
            <AceRegister
               lang={lang}
               blurHandle={this.blurHandle}
               handleChange={this.handleChange}
               ace_email={ace_email}
               phoneErr={phoneErr}
               phone={phone}
               ace_first_name={ace_first_name}
               ace_last_name={ace_last_name}
               ace_email_err={ace_email_err}
               ace_first_name_err={ace_first_name_err}
               ace_last_name_err={ace_last_name_err}
               memberExistHandle={this.memberExistHandle}
               next={this.next}
               loader={loader}
            />
            :
            <form id={!stageTwo ? "register-stage-one":"register-stage-two"} className="login" onSubmit={(e)=>{this.submitForm(e)}}>
              {!stageTwo ? 
              <StageOne typeOfMechiron={typeOfMechiron} nameErr={nameErr} phoneErr={phoneErr} lang={lang} handleChange={this.handleChange} name={name} phone={phone} blurHandle={this.blurHandle}/>  
              :
              <StageTwo timing={timing} resend={resend} isReg={typeOfMechiron} resetErr={this.resetErr} saveRegulation={this.saveRegulation} sendAgain={this.sendAgain} validate={this.vertify} errValidation={errValidation} handleChange={this.handleChange} codeInput={codeInput} toggleCheckBox={this.toggleCheckBox} checkBox={checkBox} lang={lang}/>
              }
            
              {!stageTwo ? 
                <button type="submit" className={phone && (typeOfMechiron ==='ace' || name) && !nameErr && !phoneErr ? "button__red":"button__red button__disabled"}>
                    {loader ? 
                        <Loader />
                        :
                        <span>{lang.stageOneFormButton}</span>
                    }
                </button>
                :
                <button className={checkBox && codeInput ? "button__red":"button__red button__disabled"} type="submit">
                   {loader ? 
                       <Loader />
                       :
                       <span>למחירון</span>
                    }
                </button>
              }
              <p>{lang.FormFooter}</p>
              <img src={typeOfMechiron !== 'ace' ?  CubesReg:Cubes} alt="cubes"/>
            </form>

        );
    }
}



function stateToProps({ userCart }) {
    return { userCart }
}
  
export default withRouter(connect(stateToProps, actions)(Login))