import { 
    SAVE_LEVEL_ONES,
    SAVE_SELECTED_LEVEL_ONE,
    SAVE_SELECTED_LEVEL_TWO,
    SAVE_SELECTED_LEVEL_THREE,
    SAVE_LEVEL_TWOS,
    SAVE_LEVEL_THREES,
    SAVE_SELECTED_PRODUCT,
    SAVE_RECOMMENDATION,
    UPDATE_RECOMMENDATION_QUANTITY,
    DELETE_RECOMMENDATION_ITEM,
    SET_FILTER,
    SAVE_FILTERS,
    SAVE_PRODUCTS,
    SET_MEASUREMENTS,
    SAVE_VENDORS,
    ADD_TO_PATH,
    SET_FALSE_SAVE_PRODUCTS,
    SET_TRUE_SAVE_PRODUCTS,
    REPLACE_RECOMMENDATION_PRODUCT,
    RESET_CATEGORY_STATE,
    CHANGE_SELECTED_MEASURMENTS,
    SAVE_MOST_VIEWED,
    SET_VENDORS_BOOLEAN,
    SET_REVERSE_VENDORS_BOOLEAN,
    SET_FILTERS_BOOLEAN,
    SET_REVERSE_FILTERS_BOOLEAN,
    SET_LVLONE_BOOLEAN,
    SET_REVERSE_LVLONE_BOOLEAN,
    SET_LVLTWO_BOOLEAN,
    SET_REVERSE_LVLTWO_BOOLEAN,
    SET_LVLTHREE_BOOLEAN,
    SET_REVERSE_LVLTHREE_BOOLEAN,
    STOP_PRODUCT_PAGINATION,
    SAVE_SEARCH_RESULTS,
    REINIT_PAGINATION,
    GET_MORE_SEARCH_RESULTS,
    STOP_MORE_SEARCH_RESULTS,
    SAVE_COMPLEMENTARY_PRODUCTS,
    RESET_PRODUCTS
} from '../actions/types'

const initialState = {
    levelOnes: {},
    selectedLevelOne: {},
    levelTwos: {},
    selectedLevelTwo: {},
    levelThrees: {},
    selectedLevelThree: {},
    vendors: {},
    filters: [],
    products: [],
    recommendation: [],
    selectedProduct: {},
    stopFunctionSaveProducts: false,
    vendorsBoolean: false,
    filtersBoolean:false,
    lvl1Boolean:false,
    lvl2Boolean:false,
    lvl3Boolean:false,
    currentFilter: '',
    stopPagination:false,
    categoryLoader: true,
    stopMoreSearchResults: false,
    mostViewed: [],
    searchResults: [],
    complementary:[],
    path: ['/']
}

export default function(state = initialState, action) {

    switch(action.type) {
        
        case(SAVE_COMPLEMENTARY_PRODUCTS) : {
            return { ...state, complementary:action.payload }
        }
        case(STOP_MORE_SEARCH_RESULTS) : {
            return { ...state, stopMoreSearchResults:true }
        }
        case(SAVE_SEARCH_RESULTS) : {
            return { ...state, stopMoreSearchResults:false, searchResults: action.payload }
        }
        case(GET_MORE_SEARCH_RESULTS) : {
            let newState = state.searchResults.map(item=>item)
            newState = newState.concat(action.payload)
            return { ...state, searchResults: newState }
        }
        case(REINIT_PAGINATION) : {
            return { ...state, stopPagination: false, categoryLoader:true }
        }
        case(STOP_PRODUCT_PAGINATION) : {
            return { ...state, stopPagination: true, categoryLoader:false }
        }
        case(SAVE_MOST_VIEWED) : {
            return { ...state, mostViewed: action.payload }
        }
        case(CHANGE_SELECTED_MEASURMENTS) : {
            let newState = state.selectedProduct
            newState[action.payload.name] = action.payload.value
            return { ...state, selectedProduct: newState }
        }
        case(SET_LVLONE_BOOLEAN) : {
            return { ...state, lvl1Boolean: true }
        }
        case(SET_REVERSE_LVLONE_BOOLEAN) : {
            return { ...state, lvl1Boolean: false }
        }
        case(SET_LVLTWO_BOOLEAN) : {
            return { ...state, lvl2Boolean: true }
        }
        case(SET_REVERSE_LVLTWO_BOOLEAN) : {
            return { ...state, lvl2Boolean: false }
        }

        case(SET_LVLTHREE_BOOLEAN) : {
            return { ...state, lvl3Boolean: true }
        }
        case(SET_REVERSE_LVLTHREE_BOOLEAN) : {
            return { ...state, lvl3Boolean: false }
        }
        
        case(SET_VENDORS_BOOLEAN) : {
            return { ...state, vendorsBoolean: true }
        }
        case(SET_REVERSE_VENDORS_BOOLEAN) : {
            return { ...state, vendorsBoolean: false }
        }
        case(SET_FILTERS_BOOLEAN) : {
            return { ...state, filtersBoolean: true }
        }
        case(SET_REVERSE_FILTERS_BOOLEAN) : {
            return { ...state, filtersBoolean: false, filters:[] }
        }
        case(SET_FALSE_SAVE_PRODUCTS) : {
            return { ...state, stopFunctionSaveProducts: true }
        }
        case(SET_TRUE_SAVE_PRODUCTS) : {
            return { ...state, stopFunctionSaveProducts: false }
        }
        case(SAVE_VENDORS) : {
            return { ...state, vendors:action.payload }
        }
        case(SAVE_LEVEL_ONES) : {
            return { ...state, levelOnes: action.payload }
        }
        case(RESET_CATEGORY_STATE) : {
            return { ...state,selectedLevelTwo: {},selectedLevelThree: {},products: [], filters: [],currentFilter:'',categoryLoader:false}
        }
        case(SAVE_LEVEL_TWOS) : {
            return { ...state, levelTwos: action.payload }
        }
        case(SAVE_SELECTED_LEVEL_ONE) : {
            return { ...state, selectedLevelOne:action.payload }
        }
        case(SAVE_SELECTED_LEVEL_TWO) : {
            return { ...state, selectedLevelTwo:action.payload }
        }
        case(SAVE_SELECTED_LEVEL_THREE) : {
            return { ...state, selectedLevelThree:action.payload }
        }
        case(SAVE_LEVEL_THREES) : {
            return { ...state, levelThrees:action.payload }
        }
        case(SAVE_FILTERS) : {
            return { ...state, filters:action.payload }
        }
        case(SAVE_SELECTED_PRODUCT) : {
            return { ...state, selectedProduct:action.payload }
        }
        case(SAVE_RECOMMENDATION) : {
            return { ...state, recommendation:action.payload }
        }
        case(REPLACE_RECOMMENDATION_PRODUCT) : {
            let newState = state.recommendation.map(item=>{
                if(item.pid_id !== action.payload.pidToReplace){
                    return item
                } else {
                    return action.payload.newProd
                }
            })
            return { ...state, recommendation: newState }
        }
        case(DELETE_RECOMMENDATION_ITEM) : {
            let newState = state.recommendation
            newState = newState.filter(item => {
                return item.pid_id !== action.payload
            })
            return { ...state, recommendation:newState }
        }
        case(UPDATE_RECOMMENDATION_QUANTITY) : {
            let newStateRec = state.recommendation
            newStateRec = newStateRec.map((item,index)=>{
                if(item.pid_id === action.payload.id){
                    item.qty_formula = action.payload.val
                    return item
                } else {
                    return item
                }
                   
            })
            return { ...state, recommendation:newStateRec }
        }
        case(SET_FILTER) : {
            return { ...state, currentFilter:action.payload }
        }
        case(RESET_PRODUCTS) : {
            return { ...state, products:[] }
        }
        case(SET_MEASUREMENTS) : {
            let newState = state.selectedLevelOne
            newState.x = action.payload.x
            newState.y = action.payload.y
            newState.z = action.payload.z
            return { ...state, selectedLevelOne:newState }
        }
        case(SAVE_PRODUCTS) : {
            let newProductArray = state.products.map(item=>item)
            action.payload.map(item => {
                const found = newProductArray.some(el => el.pid_id === item.pid_id);
                // console.log(found, item)
                if(!found){
                    newProductArray.push(item)
                } else {
                    // replace

                    // let existInIndex = newProductArray.findIndex(itemInArray=>{return itemInArray.pid_id === item.pid_id})

                    // if(item.pid_id === 'P1000919')
                    // console.log(newProductArray[existInIndex],item,'b4')

                    // newProductArray[existInIndex] = item

                    // if(item.pid_id === 'P1000919')
                    // console.log(newProductArray[existInIndex],item,'after')
                }
            })
            return { ...state,products:newProductArray}
        }

        case(ADD_TO_PATH) : {
            let newPathState = state.path
            newPathState.push(action.payload)
            return { ...state, path:newPathState }
        }
        default: 
            return state
    }
}