import React, { Component } from 'react'
import { Switch, Route, withRouter, Link, Router } from 'react-router-dom'
//components
import  Calender from './Calender'
import Appointment from './Appointment'

class Schedule extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/pros/interface/:proId/schedule/calender" render={() => <Calender />} />
                    <Route exact path="/pros/interface/:proId/schedule/appointment/meetingId/:meetingId" render={() => <Appointment />} />
                    <Route exact path="/pros/interface/:proId/schedule/appointment/customerUserId/:customerUserId/customerCartId/:customerCartId/meetingId/:meetingId" render={() => <Appointment />} />
                </Switch>
            </div>
        );
    }
}

export default Schedule;