import React, { Component } from 'react'
import './search.css'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import '../ProductPage/productpage.css'
import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
import Cookies from 'js-cookie'
//img
import RedSearch from '../../../images/icons/redSearch.svg'
import BlueSearch from '../../../images/original/50.svg'
import LightBlueSearch from '../../../images/mako/18.svg'
import History from '../../../images/icons/history.svg'
import ArrowRes from '../../../images/icons/backArrow.svg'
import ArrowLeft from'../../../images/icons/whiteredleft.svg'
import ArrowLeftReg from'../../../images/original/2.svg'
import ArrowLeftMako from'../../../images/mako/sliderl.svg'
import MakoDesktopArrow from '../../../images/icons/mako/2.svg'
import ArrowAllAce from '../../../images/icons/arrowallace.svg'
import ArrowAllMako from '../../../images/icons/arrowallmako.svg'
import ArrowAllMechiron from '../../../images/icons/arrowallmechiron.svg'

import Glass from '../../../images/navbarace/3.svg'
import WhiteX from '../../../images/navbarace/6.svg'

//comps
import ProductSlider from '../../layouts/ProductPage/parts/ProductSlider'
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
import Loader from '../../globals/Loader'
//configs
import {API_URL,IMAGE_URL,HOST_NAME} from '../../../configs/configs'
//tools
import {ApiGet} from '../../../tools/tools'

class Search extends Component {

    constructor(){
        super()

        this.state = {
            timeout: '',
            searchResults: [],
            loader:false,
            typeOfMechiron: '',
            noResults:false,
            value:'',
            page: 0,
            limit:20,
            canSearch: true
        }

        this.searchContainerRef = React.createRef();
    }

    // componentDidMount() {
    //     window.addEventListener('scroll',this.trackScrolling)
    // }

    // componentWillUnmount() {
    //     window.removeEventListener('scroll',this.trackScrolling)
    // }

    componentWillMount() {
        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})
    }

    onSliderInit = () => {
        // fix bug that moves the slider to the end on init
        setTimeout(() => { 
            if((window.innerWidth > 979 && this.state.typeOfMechiron === 'mako')){
                this.slider.slickGoTo(this.props.levels.mostViewed.length - 4, false) 
            } else {
                this.slider.slickGoTo(this.props.levels.mostViewed.length - 3.5, false)
            }
        }, 0);
    }

    componentWillReceiveProps(nextProps){
        setTimeout(() => { 
            if(this.slider && this.slider !=null)
            if((window.innerWidth > 979 && this.state.typeOfMechiron === 'mako')){
                this.slider.slickGoTo(this.props.levels.mostViewed.length - 4, false) 
            } else {
                this.slider.slickGoTo(this.props.levels.mostViewed.length - 3.5, false)
            }
        }, 0);

    }

    goToSearchAll = async() => {
        let query = this.state.value
        let typeOfMechiron = this.state.typeOfMechiron
        let result = await ApiGet(API_URL + '/product/search?keyword=' + this.state.value + `&page=${this.state.page}&limit=${50}&type=${typeOfMechiron}`)
        this.props.saveSearchResults(result)
        this.props.history.push(`/searchResults?q=${query}`)
    }

    preventScroll = () => {
        document.body.ontouchmove = function (e) {
            e.preventDefault();
        }
    }

    enableScroll = () => {
        document.body.ontouchmove = function (e) {
            return
        }
    }

    sleep = m => new Promise(r => this.setState({timeout:setTimeout(r, m)}))

    searchForValue = async (value) => {
        this.setState({loader:true,noResults:false,value})
        
        clearTimeout(this.state.timeout)
        await this.sleep(500)

        
        
        if(value.length <= 2) {
            this.setState({searchResults:[],loader:false})
            return
        }
        const {typeOfMechiron} = this.state
        let result = await ApiGet(API_URL + '/product/search?keyword=' + value + `&page=${0}&limit=${5}&type=${typeOfMechiron}`)

        if(result.length === 0){
            this.setState({searchResults:result,loader:false,noResults:true})
            return
        }

        if(result && result.length > 0){
            this.setState({searchResults:result})
        }
       

    }

     
    // trackScrolling = () => {
       
    //     if(!this.props.desktopMako){
           
    //         if(this.searchContainerRef.current && this.searchContainerRef.current != null) {
               
    //             if(this.searchContainerRef.current.scrollTop >= (this.searchContainerRef.current.scrollHeight - this.searchContainerRef.current.offsetHeight) && this.state.searchResults.length > 0) {
    //                 this.setState(prevState => ({
    //                     page: prevState.page + prevState.limit
    //                 }),()=>{
    //                     this.searchMore()
    //                 })
    //             }
     
    //          }
    //     } else {
    //         if( window.innerHeight + window.scrollY >= document.body.offsetHeight  ) {
               
    //             this.setState(prevState => ({
    //                 page: prevState.page + prevState.limit
    //             }),()=>{
    //                 this.searchMore()
    //             })
    //         }
    //     }

    // }

    searchMore = async() => {
        const {typeOfMechiron} = this.state
        let result = await ApiGet(API_URL + '/product/search?keyword=' + this.state.value + `&page=${this.state.page}&limit=${this.state.limit}&type=${typeOfMechiron}`)
        if(result && result.length > 0) {
            let newState = this.state.searchResults.map(item=>item)
            newState = newState.concat(result)
            this.setState({searchResults:newState})
        }
    }

    goToProdPage = async(item) => {
        let res = await ApiGet(API_URL + '/filter/getById/' + item.filter_id)
        let lvl3 = res.level_three_id
        lvl3 = lvl3.split(';')
        lvl3 = lvl3[0]

        let storedPids = JSON.parse(localStorage.getItem("ace-mechiron-searches"))
        
        if(storedPids == null){
            storedPids = [item]
            localStorage.setItem("ace-mechiron-searches", JSON.stringify(storedPids));
        } else {
            if(storedPids.length < 5){
                storedPids.push(item)
                localStorage.setItem("ace-mechiron-searches", JSON.stringify(storedPids));
            } else {
                storedPids.shift(item)
                storedPids.push(item)
                localStorage.setItem("ace-mechiron-searches", JSON.stringify(storedPids));
            }

        }
        
        let res2 = await ApiGet(API_URL + '/product/getById/' + item.pid_id)
        await this.props.saveSelectedProduct(res2)


        this.props.resetProductsSaveBoolean() 
        this.props.resetCategoryState()

        this.props.history.push(`/products/all/${item.level_two_id}/${lvl3}/${item.pid_id}/?q=${this.state.value}`)

    }

    buildId = (product) => {
        let dataString = 
        "UC:"
        + "\"" + ', ' + "product_id:"
        + "\"" + product.pid_id + "\"" + ', ' + "product_name:"
        + "\"" + product.name + "\""
        if(this.props.userDetails)
        dataString = dataString + ', ' + "User_full_name:" 
        + "\"" + this.props.userDetails.full_name + "\"" +  ', ' + "User_phone:"
        + "\"" + this.props.userDetails.phone + "\"" 

        if(this.state.value)
        dataString = dataString + ', ' + "User_has_typed:" 
        + "\"" + this.state.value + "\"" 
        return dataString
    }

    render() {

        const {searchResults,loader,typeOfMechiron,noResults,value} = this.state

        const {desktopMako} = this.props

       
        let lastSearchs = JSON.parse(localStorage.getItem("ace-mechiron-searches"))

        if(lastSearchs == null)
        lastSearchs = []

        let settings2 = {
            infinite: false,
            rtl: true,
            arrows: false,
            speed: 500,
            slidesToShow: (window.innerWidth > 979 && this.state.typeOfMechiron === 'mako') ? 4:3.5,
            slidesToScroll: (window.innerWidth > 979 && this.state.typeOfMechiron === 'mako') ? 4:3.5,
            initialSlide: 1,
            draggable:false
        };


        return (
            <div className="search" onScroll={()=>{}} ref={this.searchContainerRef}>
                {desktopMako ? <div className="search__header__mako">חיפוש במחירון</div>:null}

                {typeOfMechiron === 'ace' ?
                    <section className="search__search-section--ace">
                        <input type="text" placeholder="אני רוצה לשפץ…" onChange={(e)=>{this.searchForValue(e.target.value)}} />
                        <img src={Glass} alt="search"/>
                        <div onClick={()=>{this.props.history.goBack()}}><img src={WhiteX} alt="go-back"/></div>
                    </section>
                    :
                    <section className="search__search-section">
                        <p onClick={()=>{this.props.history.goBack()}}>ביטול</p>
                        <input type="text" placeholder="חיפוש במחירון" onChange={(e)=>{this.searchForValue(e.target.value)}}/>
                        <img src={typeOfMechiron === 'ace' ? RedSearch: typeOfMechiron === 'mako' ? LightBlueSearch:BlueSearch} alt="icon-search"/>
                    </section>
                }

                
                
                {searchResults && searchResults.length === 0 ?
                    <span>
                        {!loader && !noResults ?
                            <div className="search__last-searches">
                            {lastSearchs.length > 0 ? <header>חיפושים אחרונים</header>:null} 
                                {lastSearchs.map(item=>{
                                    return  <div onClick={()=>{this.goToProdPage(item)}} id={this.buildId(item)}>
                                                <p id={this.buildId(item)}>{item.name}</p>
                                                <img id={this.buildId(item)} src={History} alt="search-history"/>
                                            </div>
                                })}
                            </div>
                            :
                            !loader && noResults && value ?
                            <div className="search__last-searches--no-results">לא נמצאו תוצאות</div>
                            :
                            <Loader />
                        }
                    </span>
                    :
                    <span>
                        <div className="search__results">
                            <header>מהמחירון</header>
                            {searchResults.map(item=>{
                                return  <div id={this.buildId(item)} onClick={()=>{this.goToProdPage(item)}}>
                                            <p id={this.buildId(item)}>{item.name}</p>
                                            <img id={this.buildId(item)} src={ArrowRes} alt="search-product"/>
                                        </div>
                            })}

                            <div className="navbar__search-results--all" onClick={()=>{this.goToSearchAll()}}>
                                <p>לכל תוצאות החיפוש</p>
                                {
                                    typeOfMechiron === 'ace' ? 
                                    <img src={ArrowAllAce} alt="all-results"/>
                                    : typeOfMechiron === 'mako' ? 
                                    <img src={ArrowAllMako} alt="all-results"/>
                                    :
                                    <img src={ArrowAllMechiron} alt="all-results"/>
                                }
                            </div>
                        </div>
                    </span>

                    }


                    <div className="search__slider product-slider">
                            <header>הכי נצפים</header>
                    
                                <Slider {...settings2} ref={c => (this.slider = c)} onInit={() => { this.onSliderInit() }}>
                                    {this.props.levels.mostViewed.map(item => {
                                    
                                        // let totalPrice = calculatePrices(item,'work') + calculatePrices(item,'product')
                                    
                                        return <div key={item.name} onClick={()=>{this.goToProdPage(item)}} className="product-slider__item" onTouchStart={()=>{this.preventScroll()}} onTouchEnd={()=>{this.enableScroll()}}>
                                            <ImageComponent src={IMAGE_URL + '/ace/' + item.picture_id + '.png'} alt="product" />
                                            <h1>{item.name}</h1>
                                            <h5>{}</h5>
                                            <h6>{}</h6>
                                        </div>
                                    })}
                                </Slider>
                                <img src={desktopMako ? MakoDesktopArrow:typeOfMechiron === 'ace' ? ArrowLeft: typeOfMechiron === 'mako' ? ArrowLeftMako:ArrowLeftReg} alt="arrow" onClick={() => { this.slider.slickPrev() }} />
                    </div>


            </div>
        );
    }
}

// export default withRouter(Search);

function stateToProps({ userCart,levels }) {
    return { userCart,levels }
}
  
export default withRouter(connect(stateToProps, actions)(Search))