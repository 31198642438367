import { API_URL } from '../configs/configs'
import { ApiGet } from '../tools/tools'

import {
    SAVE_PROS_TYPE,
    SAVE_PROS_AREA,
    SET_SELECTED_PRO,
    RESET_PROS_LIST,
    SAVE_REVIEWS,
    ADD_PROS
} from './types'




export const saveProsArea = () => async dispatch => {
    let prosArea = await ApiGet(API_URL + '/prosArea/getAll')
    dispatch({ type: SAVE_PROS_AREA, payload: prosArea })
}

export const saveProsType = () => async dispatch => {
    let prosType = await ApiGet(API_URL + '/prosType/getAll')
    dispatch({ type: SAVE_PROS_TYPE, payload: prosType })
}

export const getPros = (limit, page, filter) => async dispatch => {
    if(filter){
        let pros = await ApiGet(API_URL + `/pros/getProsByTypeId/${filter}/limit/${limit}/page/${page}`)
        dispatch({ type: ADD_PROS, payload: {pros,limit,page} })
    } else {
        let pros = await ApiGet(API_URL + `/pros/getPros/limit/${limit}/page/${page}`)
        dispatch({ type: ADD_PROS, payload: {pros,limit,page} })
    }
    
}

export const resetProsList = ()=> async dispatch => {
    dispatch({ type: RESET_PROS_LIST })
}


export const setSelectedPro = (pro) => async dispatch => {
    dispatch({ type: SET_SELECTED_PRO, payload: pro })
}

export const saveReviews = () => async dispatch => {
    let payload = await ApiGet(API_URL + `/prosReviews/getAll`)
    dispatch({ type: SAVE_REVIEWS, payload })
}
