
//props to this component:
// 1. placeholder          - string
// 2. disActivateCurtain   - function
// 3. activateCurtain      - function
// 4. options              - array[object]
// 5. keyInObjectToDisplay - string -chooses what key to display in component
// 6. handleChange         - function - value pick handler
// 7. valueIndex           - number - index in options props to value
// 8. valueWasPicked       - function - check if value was picked
// 9. wasClicked           - function - check if dropdown was clicked
import React, { Component } from 'react';
import './customdropdown.css'
import Cookies from 'js-cookie'
//img
import redArrow from '../../../images/icons/redarrow.svg'
import BlueArrow from '../../../images/original/16.svg'
import WhiteArrow from '../../../images/mako/20.svg'
//config
import {HOST_NAME} from '../../../configs/configs'


class CustomDropDown extends Component {
    constructor(props){
        super(props)

        this.state = {
            activeMenu:false,
            activeValue: props.valueIndex >= 0 ? props.options[props.valueIndex][props.keyInObjectToDisplay] : '',
            wasClicked: false,
            typeOfMechiron: '',
            dropErrActive:false
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentWillMount() {
        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})

        if(this.props.answer && this.props.answer > -1 && this.props.desktop ) {
            console.log(this.props.answer)
            this.setState( { activeValue:this.props.options[this.props.answer].name } )
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    activateMenu = (e) => {
        this.setState({dropErrActive:true})
        if(document.querySelector(".navbar__ace"))
        document.querySelector(".navbar__ace").style.zIndex = '110'
        if(document.querySelector(".curtain__ace"))
        document.querySelector(".curtain__ace").style.display = 'none'
        if(!e.target.getAttribute('data-menu')) {
            this.setState({activeMenu:true})
            if(!this.props.desktop)
            this.props.activateCurtain()
        }

    }

    handleClickOutside(event) {
        // if(!this.props.desktop) {
            if(event.target.id !== 'nav-icon3') {
                if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
                    this.setState({ activeMenu: false })
                    if(!this.props.desktop) 
                    this.props.disActivateCurtain()
                    if(this.props.wasClicked && this.state.dropErrActive) {
                        this.props.wasClicked()
                    }
                }
            } else {
                this.setState({ activeMenu: false },()=>{
                    this.props.activateCurtain()
                })
            }
        // }

    }

    handlePick = (item) => {

        if(this.props.valueWasPicked) {
            this.props.valueWasPicked()
        }
        if(!this.props.desktop)
        this.props.disActivateCurtain()
        this.props.changeHandler(item)
        
        this.setState({
            activeValue:item,
            activeMenu:false
        })
        
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    render() {

        const {placeholder,options,keyInObjectToDisplay,valueIndex,err,desktop} = this.props
        const {activeMenu,activeValue,wasClicked,typeOfMechiron,dropErrActive} = this.state

        return (
            <div ref={this.setWrapperRef} className={!activeValue && err && dropErrActive ? "customdropdown customdropdown--invalid":"customdropdown"} onClick={(e)=>{this.activateMenu(e)}}>
                {activeValue ? 
                    <h1 style={{opacity:'1'}}>{activeValue}</h1>
                    :
                    <h1>{placeholder}</h1>
                }
                

                <img className="customdropdown--img" src={typeOfMechiron === 'ace' ? redArrow: typeOfMechiron === 'mako' ? WhiteArrow:BlueArrow} alt="arrow"/>

                <div data-menu="1" ref={this.setWrapperRef} className={activeMenu ? "customdropdown__menu--active":"customdropdown__menu"}>
                    <h3 data-menu="1">{placeholder}</h3>
                    {options.map(item=>{
                        return <div data-menu="1" className="customdropdown__menu--item" onClick={()=>{this.handlePick(item[keyInObjectToDisplay])}}><p data-menu="1">{item[keyInObjectToDisplay]}</p></div>
                    })}
                </div>
            </div>
        );
    }
}

export default CustomDropDown;