import React from "react";

const StageOne = props => {
  const { lang,typeOfMechiron } = props;
 
  return (
    <div className="login__stageone">
        <h6>{lang.stageOneHeader1}</h6>
        
        <h6 className="login__stageone--h6">{lang.stageOneHeader2}</h6>
        <h6 className="login__stageone--h6">{lang.stageOneHeader11}</h6>
        <p>{lang.stageOneFormHeader}</p>

        <span>

            {true ?
                    <div className="login__stageone__form-group">
                        <label htmlFor="" style={props.nameErr ? {}:{display:'none'}} className={props.nameErr ? "login__stageone__form-group--error":""}> {props.nameErr ? props.nameErr:null}</label>
                        <input
                          type="text"
                          name="name" 
                          value={props.name}
                          placeholder="שם מלא"
                          onChange={(e)=>{props.handleChange(e.target.name,e.target.value)}}
                          onBlur={(e)=>{props.blurHandle(e.target.name,props.name)}}
                          className={props.nameErr ? "login__stageone__form-group--error--input":""}
                        />
                    </div>
                    :
                    null
            }

          <div className="login__stageone__form-group">
                      <label htmlFor="" style={props.nameErr ? {}:{display:'none'}} className={props.phoneErr ? "login__stageone__form-group--error":""}> {props.phoneErr ?   props.phoneErr:null}</label>
                      <input
                        type="number"
                        name="phone"
                        value={props.phone}
                        placeholder="מספר נייד"
                        onChange={(e)=>{props.handleChange(e.target.name,e.target.value)}}
                        onBlur={(e)=>{props.blurHandle(e.target.name,props.phone)}}
                        className={props.phoneErr ? "login__stageone__form-group--error--input":""}
                      />

                      <p>{lang.stageOneFormPhoneDisclaimer}</p>
                  </div>

              </span>
          </div>
  );
};

export default StageOne;
