import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'; 
import thunk from 'redux-thunk'; // to use promises for asynchronous actions
import { createStore, applyMiddleware, compose } from 'redux'; // to create the store and middleware
import reducers from './reducers/index';

import createBrowserHistory from 'history/createBrowserHistory';

var history = createBrowserHistory();

const middleware = [thunk];
const store = createStore(reducers, {}, compose(applyMiddleware(...middleware)));
history.listen(function (location) {
    window.ga('set', 'page', location.pathname + location.search);
    window.ga('send', 'pageview');
});

ReactDOM.render(<BrowserRouter history={history}><Provider store={store}><App /></Provider></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
