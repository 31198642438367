import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import './kindofcategory.css'
import '../ProductPage/productpage.css'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
import Cookies from 'js-cookie'
//component
import InnerNavigation from '../../globals/InnerNavigation/InnerNavigation'
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
import Login from '../Login/Login'

//images
import Icon from '../../../images/icons/bluelogo.png'
import ArrowLeft from '../../../images/icons/whiteredleft.svg'
import CancelCircle from '../../../images/icons/deskpopupcancel.svg'

//tools
import {ApiGet} from '../../../tools/tools'
//configs
import { USER_TOKEN,IMAGE_URL_LEVELS,API_URL } from '../../../configs/configs';
//lang
import * as Hebrew from '../../../texts/hebrew'

class KindOfCategory extends Component {

    constructor() {
        super()

        this.state = {
            loginPopup:false,
            lvl3Id:false
        }
    }

 
    async componentWillMount() {
        
        this.props.resetProducts()
        await this.getLevelsOnDirectUrlMount()
        let selectedLevelTwosInit = this.props.levels.selectedLevelTwo
       
        this.props.levels.levelTwos.filter(async(item)=>{
            if (item.level_two_id === this.props.match.params.levelTwoId) {
                await this.props.saveSelectedLevelTwoItem(item)
            }
        })
        
    }


    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (event.target.className.includes('curtain')) {
            this.setState({ loginPopup:false })
        }
    }
    

   async componentWillReceiveProps(nextProps) {
  
        let levelOne
        if(nextProps.levels.levelOnes !== this.props.levels.levelOnes && nextProps.levels.levelOnes.length > 0)
        levelOne = nextProps.levels.levelOnes.find(level=>{
            return level.level_one_id === this.props.match.params.levelOneId
        })
        if(levelOne)
        this.props.initUserCartRoom(levelOne,this.props.userCart)

        if(Object.keys(this.props.levels.levelTwos).length === 0 && this.props.levels.levelTwos.constructor === Object) {
            await this.props.saveLevelTwos(nextProps.match.params.levelOneId)
            this.props.levels.levelTwos.filter(async(item)=>{
                if (item.level_two_id === this.props.match.params.levelTwoId) {
                    await this.props.saveSelectedLevelTwoItem(item)
                    await this.props.saveLevelThrees(this.props.match.params.levelTwoId)
                }
            })
        }

        let selectedLevelTwosInit = this.props.levels.selectedLevelTwo
        if(Object.keys(selectedLevelTwosInit).length === 0 && selectedLevelTwosInit.constructor === Object) {
            this.props.levels.levelTwos.filter(async(item)=>{
                if (item.level_two_id === this.props.match.params.levelTwoId) {
                    await this.props.saveSelectedLevelTwoItem(item)
                }
            })
        }

        if(this.props.match.params.levelTwoId !== nextProps.match.params.levelTwoId ){
             this.props.saveLevelThrees(this.props.match.params.levelTwoId)
        }
      
    }

    async componentDidUpdate(prevProps) {
        // if (this.props.location.pathname !== prevProps.location.pathname && Object.keys(this.props.levels.levelTwos).length !== 0 && this.props.levels.levelTwos.constructor !== Object) {
        //     this.props.levels.levelTwos.filter(async(item)=>{
        //         if (item.level_two_id === this.props.match.params.levelTwoId) {
        //             await this.props.saveSelectedLevelTwoItem(item)
        //             this.props.saveLevelThrees(this.props.match.params.levelTwoId)
        //         }
        //     })
        // }

        if(this.props.location.pathname !== prevProps.location.pathname){
           await this.props.saveLevelTwos(this.props.match.params.levelOneId)
           this.props.levels.levelTwos.map(async(item)=>{
              if (item.level_two_id === this.props.match.params.levelTwoId) {
                  await this.props.saveSelectedLevelTwoItem(item)
                  this.props.saveLevelThrees(this.props.match.params.levelTwoId)
              }
           })
           
           
        }

    }
 
     getLevelsOnDirectUrlMount = async() => {
         let levelOnesInit = this.props.levels.levelOnes
         if(Object.keys(levelOnesInit).length === 0 && levelOnesInit.constructor === Object) {
             this.props.saveVendors()
             await this.props.saveLevelOnes()
         }
 
         let selectedLevelOneInit = this.props.levels.selectedLevelOne
         if(selectedLevelOneInit != null)
         if(Object.keys(selectedLevelOneInit).length === 0 && selectedLevelOneInit.constructor === Object) {
             this.props.levels.levelOnes.filter(async(item)=>{
                 if (item.level_one_id === this.props.match.params.levelOneId) {
                     await this.props.saveSelectedLevelOneItem(item)
                 }
             })
         }
 
         let levelTwoInit = this.props.levels.levelTwos
         if(Object.keys(levelTwoInit).length === 0 && levelTwoInit.constructor === Object) {
             await this.props.saveLevelTwos(this.props.match.params.levelOneId)
         }

         let selectedLevelTwosInit = this.props.levels.selectedLevelTwo
         if(Object.keys(selectedLevelTwosInit).length === 0 && selectedLevelTwosInit.constructor === Object) {
             this.props.levels.levelTwos.filter(async(item)=>{
                 if (item.level_two_id === this.props.match.params.levelTwoId) {
                     await this.props.saveSelectedLevelTwoItem(item)
                 }
             })
         }

         let levelThreeInit = this.props.levels.levelThrees
         if(Object.keys(levelThreeInit).length === 0 && levelThreeInit.constructor === Object) {
            await this.props.saveLevelThrees(this.props.match.params.levelTwoId)
         }
     }
 

    onSliderInit = () => {
        // fix bug that moves the slider to the end on init
        setTimeout(() => { this.slider.slickGoTo(this.state.products.length - 3.5, false) }, 0);
    }

    preventScroll = () => {
        document.ontouchmove = function (e) {
            e.preventDefault();
        }
    }

    enableScroll = () => {
        document.ontouchmove = function (e) {
            return
        }
    }

    loadedPic = (name) => {
        this.setState({[name]:true})
    }

    handleNextPage = (item) => {
        this.setState({lvl3Id:item.level_three_id})
        const { levelOneId,levelTwoId } = this.props.match.params
        this.props.saveSelectedLevelThreeItem(item).then(()=>{
            this.props.saveFilters(this.props.levels.selectedLevelThree).then(()=>{

                // if(Cookies.get(USER_TOKEN)){
                    this.props.history.push(`/products/${levelOneId}/${levelTwoId}/${item.level_three_id}`)
                // } else {
                //     if(this.props.desktop) {
                //         this.props.activateCurtain()
                //         this.setState({loginPopup:true})
                //     } else {
                //         this.props.history.push(`/login/${levelOneId}/${levelTwoId}/${item.level_three_id}`)
                //     }
                // }
            })
        })

    }




    render() {
        let settings = {
            infinite: false,
            rtl: true,
            draggable:false,
            arrows: false,
            speed: 500,
            slidesToShow: 3.5,
            slidesToScroll: 3.5,
            initialSlide: 1
        };

        const { products,loginPopup,lvl3Id } = this.state
        const { selectedLevelTwo,levelThrees,selectedLevelOne } = this.props.levels
        const {desktop,desktopMako} = this.props

        return (
            
            <div className="kindofcategory">

                {loginPopup && desktop ? 
                    <span className="login__popup__wrapper">
                        <img src={CancelCircle} alt="cancel-popup" className="choice__calculator__input__x" onClick={()=>{this.setState({desktopPopupCalculator:false,loginPopup:false});this.props.disActivateCurtain()}}></img>
                        <div className="room__rec__popup" style={{overflow:'hidden'}}>
                            <Login lvl3Id={lvl3Id} lang={Hebrew.Login}/>
                        </div>
                    </span>
                    :
                    null
                }

                <div className="kindofcategory--grid">
                {this.props.match.params.levelOneId !== 'all' ? 
                    <InnerNavigation
                      pages={['ראשי', selectedLevelOne.name, selectedLevelTwo.name]}
                      links={['/', '/calculator/' + selectedLevelOne.level_one_id + '/room', '/calculator/' + selectedLevelOne.level_one_id + '/room/' + selectedLevelTwo.level_two_id]} 
                    />
                    :
                    <InnerNavigation
                     pages={['כל פרקי המחירון',selectedLevelTwo.name]}
                     links={['/allchapters',`/calculator/all/room/${this.props.match.params.levelTwoId}`]}
                     />
                }

                    <h1>{selectedLevelTwo.name}</h1>
                    
                    {/* {desktop ? <div className="kindofcategory__subjects-num">מציג {levelThrees.length} נושאים </div>:null} */}

                    <div className="kindofcategory__categories">
                        {Object.keys(levelThrees).length > 0 && levelThrees.map((item,index) => {
                        
                        if(this.props.match.params.levelOneId === 'all') {
                            return <div id={"UC: Clicked On Chapter- " + item.name} key={item.level_three_id} className="kindofcategory__categories__item" onClick={()=>{this.handleNextPage(item)}}>
                            <div id={"UC: Clicked On Chapter- " + item.name}>
                                <ImageComponent statePic={`img${index}`} loadedPic={this.loadedPic} src={IMAGE_URL_LEVELS + 'ace/' + item.picture_l3_id + '.png'} alt="category" />
                                {this.state['img' + index] ? <img id={"UC: Clicked On Chapter- " + item.name} src={IMAGE_URL_LEVELS + 'ace/' + item.vendor_id + 'S.svg'} alt="icon" />:null} 
                            </div>

                            <p id={"UC: Clicked On Chapter- " + item.name}>{item.name}</p>
                        </div>
                        } else {
                            if(item.level_one_id.includes(this.props.match.params.levelOneId))
                                return <div id={"UC: Clicked On Chapter- " + item.name} key={item.level_three_id} className="kindofcategory__categories__item" onClick={()=>{this.handleNextPage(item)}}>
                                <div id={"UC: Clicked On Chapter- " + item.name}>
                                    <ImageComponent statePic={`img${index}`} loadedPic={this.loadedPic} src={IMAGE_URL_LEVELS + 'ace/' + item.picture_l3_id + '.png'} alt="category" />
                                    {this.state['img' + index] ? <img id={"UC: Clicked On Chapter- " + item.name} src={IMAGE_URL_LEVELS + 'ace/' + item.vendor_id + 'S.svg'} alt="icon" />:null} 
                                </div>

                                <p id={"UC: Clicked On Chapter- " + item.name}>{item.name}</p>
                            </div>
                        }

                        })}
                    </div>
                </div>


                {/* <div className="kindofcategory__categories__slider product-slider ">
                    <Slider {...settings} ref={c => (this.slider = c)} onInit={() => { this.onSliderInit() }}>
                        {products.map(item => {
                            return <div className="product-slider__item" onTouchStart={()=>{this.preventScroll()}} onTouchEnd={()=>{this.enableScroll()}}>
                                <div style={{ backgroundColor: item.bg }}></div>
                                <h1>{item.name}</h1>
                                <h5>{item.price}</h5>
                                <h6>{item.oldPrice}</h6>
                            </div>
                        })}
                    </Slider>
                    <img style={{left:'-10px'}} src={ArrowLeft} alt="arrow" onClick={() => { this.slider.slickPrev() }} />
                </div> */}

            </div>
        );
    }
}


function stateToProps({ levels,userCart }) {
    return { levels,userCart }
}
  
export default withRouter(connect(stateToProps, actions)(KindOfCategory))