//configs and libreries
import React, { Component } from 'react'
import './App.css'
import { Switch, Route, withRouter, Link, Router } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from './actions/index'
import Cookies from 'js-cookie'
import {Helmet} from "react-helmet";

//components
import ScrollTop from './Components/ScrollTop/ScrollTop'
import GoogleTagManager from './Components/globals/ReactGTM'
import Home from './Components/layouts/Home/Home'
import Room from './Components/layouts/Room/Room'
import Navbar from './Components/globals/Navbar/Navbar'
import NavbarAce from './Components/globals/Navbar/NavbarAce'
import NavbarAceDesktop from './Components/globals/Navbar/NavbarAceDesktop'
import NavbarPlaceholder from './Components/globals/Navbar/NavbarPlaceholder'

import NewNavbar from './Components/layouts/NewNavbar/NewNavbar'
import NewNavbarMobileMenu from './Components/layouts/NewNavbar/NewNavbarMobileMenu'
import NewNavbarDesktop from './Components/layouts/NewNavbar/NewNavbarDesktop'

import NewHomePage from './Components/layouts/NewHomePage/NewHomePage'

import Choice from './Components/layouts/Choice/Choice'
import Category from './Components/layouts/Category/Category'
import SearchResults from './Components/layouts/Category/SearchResults'
import ProductPage from './Components/layouts/ProductPage/ProductPage'
import AllChapters from './Components/layouts/AllChapters/AllChapters'
import Cart from './Components/layouts/Cart/Cart'
import Calculator from './Components/layouts/Calculator/Calculator'
import Aces from './Components/layouts/Aces/Aces'
import AceProPage from './Components/layouts/AceProPage/AceProPage'
import KindOfCategory from './Components/layouts/KindOfCategory/KindOfCategory'
import Login from './Components/layouts/Login/Login'
import QuantityInput from './Components/globals/QuantityInput/QuantityInput'
import Regulations from './Components/layouts/Regulations/Regulations'
import NewCart from './Components/layouts/NewCart/NewCart'
import NewCartItem from './Components/layouts/NewCart/NewCartItem'
import Contact from './Components/layouts/Contact/Contact'
import Search from './Components/layouts/Search/Search'
import MakoMenuDesktop from './Components/layouts/MakoMenuDesktop/MakoMenuDesktop'
import MakoDesktopNavbar from './Components/layouts/MakoDesktopNavbar/MakoDesktopNavbar'
import DesktopFooter from './Components/globals/Footer'
import MobileFooter from './Components/globals/MobileFooter'
import DesktopMakoFooter from './Components/layouts/DesktopMakoFooter/DesktopMakoFooter'
import ProsInterface from './Components/layouts/ProsInterface/ProsInterface'

//images
import CartPopupImg from './images/icons/navpro.svg'
import Cancel from './images/icons/cancelicon.svg'
import CancelCircle from './images/icons/deskpopupcancel.svg'
import CancelCircleMako from './images/icons/desktoppopupcancelmako.svg'
import BackBtnUser from './images/prosinterface/client.svg'
import BackBtnArrow from './images/prosinterface/arrow.svg'

//texts
import * as Hebrew from './texts/hebrew'
//test
import Img1 from './images/recommendationflow/1.png'
import Img2 from './images/navcart/6.png'
import Img3 from './images/recommendationflow/5.png'
//tools
import {ApiGetWithHeader,ApiGet,ApiPostWithBody,calculatePrices,ApiPostWithHeaderToken} from './tools/tools'
//configs
import {API_URL,USER_TOKEN,HOST_NAME,IMAGE_URL_LEVELS,GOOGLE_GTM_ACE_ID,GOOGLE_GTM_MAKO_ID} from './configs/configs'
import {ImagesIdsArray} from './configs/imagesIds'





class App extends Component {

  constructor() {
    super()

    this.state = {
      curtain: false,
      cartPopup: false,
      cartOpen: false,
      showNav:true,
      totalProductsInCart:0,
      typeOfMechiron: '',
      desktop:false,
      desktopMako:false,
      loginPopup:false,
      contactPopup:false,
      route:'',
      contactOptionActive:-1,
      userDetails:{},
      scrollToItemWhenEdit:false,
      //usermadecontact
      userMadeContact: false,
      mobileMenuNav: false
    }
  }

  setBooleanIfUserMadeContact = () => {
    this.setState({userMadeContact: true})
  }


   getParams = (url) => {
    const splitParams =  url.split('?')
    if(splitParams[1]) {
        const params = splitParams[1].split('&').map(n => n.split('='))
        const object = {}
        params.forEach(n => {
            object[n[0]] = n[1]
        })
        return object
    } else {
        return undefined
    }
}
  
  componentWillMount() {

    
    if(Cookies.get(HOST_NAME) == null) {
      if(window.location.href.includes('ace')){
        Cookies.set(HOST_NAME, 'ace')
        this.setState({typeOfMechiron:'ace'})
      } else if (window.location.href.includes('mako')){
        Cookies.set(HOST_NAME, 'mako')
        this.setState({typeOfMechiron:'mako'})
      } else {
        Cookies.set(HOST_NAME, 'hamechiron')
        this.setState({typeOfMechiron:'hamechiron'})
      }
      
    } else {
      this.setState({typeOfMechiron:Cookies.get(HOST_NAME)})
    }

    // let type = this.getParams(window.location.href)

    // if(type != null && Object.keys(type).length > 0){
    //   Object.keys(type).map((key, index)=> {
    //     if(key === 'utm_campaign' && type[key] === 'ace'){
    //       Cookies.set(HOST_NAME, 'ace')
    //       this.setState({typeOfMechiron:'ace'})
    //     } else if(key === 'utm_campaign' && type[key] === 'mako'){
    //       Cookies.set(HOST_NAME, 'mako')
    //       this.setState({typeOfMechiron:'mako'})
    //     } else if(key === 'utm_campaign' && type[key] === 'hamechiron'){
    //       Cookies.set(HOST_NAME, 'hamechiron')
    //       this.setState({typeOfMechiron:'hamechiron'})
    //     } 
    //   })
    // }

    let path = window.location.origin
    // let path ='calc.ace.co.il'
    console.log(path)
    if(path.includes('hamechiron.mako.co.il')){
        Cookies.set(HOST_NAME, 'mako')
        this.setState({typeOfMechiron:'mako'})
    } else if(path.includes('calc.ace.co.il')) {
        Cookies.set(HOST_NAME, 'ace')
        this.setState({typeOfMechiron:'ace'})
    } else {
        Cookies.set(HOST_NAME, 'hamechiron')
        this.setState({typeOfMechiron:'hamechiron'}) 
    }

  }


  async componentDidMount() {
    
    //esc event listener
    document.addEventListener("keydown", this.escFunction, false);

    //determine kind of navbar
    if(window.innerWidth > 1249 && this.state.typeOfMechiron === 'ace') {
      this.setState({desktop:true},()=>{
        if(this.state.typeOfMechiron === 'ace')
        document.body.className = 'desktop-app';
      })
    } else if (window.innerWidth > 939 && this.state.typeOfMechiron === 'mako') {
      this.setState({desktopMako:true},()=>{
        if(this.state.typeOfMechiron === 'mako')
        document.body.className = 'desktop-mako-app';
      })
    } else {
      this.setState({desktop:false,desktopMako:false},()=>{
        if(this.state.typeOfMechiron === 'ace')
        document.body.className = '';
      })
    }
    
    //resize event check if desktop
    if(this.state.typeOfMechiron === 'ace' || this.state.typeOfMechiron === 'mako')
    window.addEventListener('resize', this.handleResize)

    //get mostviewd for slider
    let mostViewed = await ApiGet(API_URL + '/product/popular?type=' + this.state.typeOfMechiron)
    this.props.saveMostViewed(mostViewed)

    //hide nav on searchpage and pros interface
    if(this.props.location.pathname.includes('search') && !this.props.location.pathname.includes('Results'))
    this.setState({showNav:false})
    //clickoutside event
    document.addEventListener('click', this.handleClickOutside);

    //get userCart
    let token = Cookies.get(USER_TOKEN)
    if(token){
      let res = await ApiGetWithHeader(API_URL + '/signin',token)
      this.props.saveUserId(res._id)
      this.setState({userDetails:res})
      //checkif use is pro
      let isPro = await ApiPostWithHeaderToken(API_URL + '/prointerface/login',token)
      if(isPro.ok)
      this.props.setUserIsAcePro()
      //
      // console.log(res._id)
      let cart = await ApiGet(API_URL + '/cart/get?user_id=' + res._id)
      this.props.saveInitialCartData(cart.cart_id,cart.cart)
      this.props.initUserCartRoom({level_one_id: "all", name: "כללי", x: 100, y: 100, z: 100,products:[]},this.props.userCart)
    } else {
      this.setState({userDetails:false})
    }

    //preload images
    if(window.location.pathname === '/'){
      let ImagesIdsArrayCopy = ImagesIdsArray.map(item=>{
        let img = new Image()
        img.src = IMAGE_URL_LEVELS + 'ace/' + item + '.png'
        return img
      })
    }

  }

  componentDidUpdate(prevState) {
    if(this.props.location.pathname !== prevState.location.pathname){
      if(!this.props.location.pathname.includes('edit') && !this.props.location.pathname.includes('cart'))
      this.props.removeUrlToRedirectToProsInterface()
    }
  }


  componentWillUnmount() {
      document.removeEventListener("keydown", this.escFunction, false);
      document.removeEventListener('click', this.handleClickOutside);
      if(this.state.typeOfMechiron === 'ace')
      window.removeEventListener('resize', this.handleResize)
  }

  async componentWillReceiveProps(nextProps){

    if(nextProps.userCart.userCart !== this.props.userCart.userCart) {
      if(nextProps.userCart.userCart.length > 0) {
        let totalProductsInCart = 0
        nextProps.userCart.userCart.map(item=>{
            totalProductsInCart+=item.products.length
        })
        if(totalProductsInCart > 0){
          this.setState({ cartOpen: true,totalProductsInCart })
        } else {
          this.setState({ cartOpen: false,totalProductsInCart:0 })
        }
      } else {
        this.setState({ cartOpen: false,totalProductsInCart:0 })
      }
     
    }

    if(nextProps.userCart.userId !== this.props.userCart.userId){
      let token = Cookies.get(USER_TOKEN)
      if(token){
        
        let res = await ApiGetWithHeader(API_URL + '/signin',token)
        this.props.saveUserId(res._id)
        this.setState({userDetails:res})
        //checkif use is pro
        let isPro = await ApiPostWithHeaderToken(API_URL + '/prointerface/login',token)
        if(isPro.ok)
        this.props.setUserIsAcePro()
        //
        // console.log(res._id)
        let cart = await ApiGet(API_URL + '/cart/get?user_id=' + res._id)
        this.props.saveInitialCartData(cart.cart_id,cart.cart)
      } else {
        this.setState({userDetails:false})
      }
    }


    if(nextProps.location.pathname !== this.props.location.pathname){
      
      if(nextProps.location.pathname.includes('search') && !nextProps.location.pathname.includes('Results')){
          this.setState({showNav:false})
      } else {
          this.setState({showNav:true})
      }
      
  }

  }

  escFunction = (event) =>{
    if( (event.keyCode === 27 || event.key === 'Escape') && this.state.contactPopup ) {
      this.setState({contactPopup:false})
      this.disActivateCurtain()
    }
  }

  handleClickOutside = (event) => {
    if(event.target.className.includes('curtain')) {
      this.setState({ curtain: false,mobileMenuNav:false})
    }
  }


  activateCurtain = () => {
    let Curtain = document.getElementsByClassName("curtain")[0];
    Curtain.style.zIndex="10000"
    this.setState({ curtain: true })
  }

  disActivateCurtain = () => {
    let Curtain = document.getElementsByClassName("curtain")[0];
    Curtain.style.zIndex="-1"
    this.setState({ curtain: false,loginPopup:false })
  }

  toggleCurtain = () => {
    // this.setState(prevState => ({
    //   curtain: !prevState.curtain
    // }));
    if(this.state.curtain) {
      this.setState({curtain:false})
    } else {
      this.setState({curtain:true})
    }


  }

  activateCart = () => {
    this.props.history.push('/cart/token')
  }


  //test
  displayButtonCart = () => {
    this.setState({cartOpen:true})
  }

  handleResize = () => {
      if(window.innerWidth > 1249 && this.state.typeOfMechiron === 'ace') {
        this.setState({desktop:true},()=>{
          if(this.state.typeOfMechiron === 'ace')
          document.body.className = 'desktop-app';
        })
      } else if (window.innerWidth > 939 && this.state.typeOfMechiron === 'mako') {
        this.setState({desktopMako:true},()=>{
          if(this.state.typeOfMechiron === 'mako')
          document.body.className = 'desktop-mako-app';
        })
      } else {
        this.setState({desktop:false,desktopMako:false},()=>{
          document.body.className = ' ';
        })
      }
  }

  activateLoginPopup = (route) => {
    this.setState({loginPopup:true,route})
  }

  generalDesktopCallback= (vertified = true) => {
    if(vertified)
    this.setState({loginPopup:false,route:''})
  }

  activateContactPopup = (answer = -1) => {
    this.activateCurtain()
    this.setState({contactPopup:true,contactOptionActive:answer})
  }

  submitCallback = () => {
    this.setState({contactPopup:false})
  }

  resetCart = () => {
    this.props.resetCartInStore()
  }

  backToInterface = () => {
    this.props.hideBackToInterfaceBtn()
    this.props.history.push(this.props.userCart.interfaceReturnUrl)
  }

  activateMobileMenuNav = () => {
    this.setState({mobileMenuNav: true, curtain:true})
  }

  disActivateMobileMenuNav = () => {
    this.setState({mobileMenuNav: false, curtain:false})
  }

  footerContactHandler = () => {
    if(this.state.desktop || this.state.desktopMako){
      this.activateContactPopup(0)
    } else {
      this.props.history.push(`contact/0`)
    }
    
  }

  goToRegulations = () => {
    this.props.history.push('/regulations')
  }

  setScrollToItemBoolean = (param) => {
    if(param) {
      this.setState({scrollToItemWhenEdit:true})
    } else {
      this.setState({scrollToItemWhenEdit:false})
    }
  }



  render() {
    
   
    const { cartPopup,cartOpen,totalProductsInCart,
      showNav,
      typeOfMechiron,
      curtain,
      desktop,
      loginPopup,
      route,
      contactPopup,
      contactOptionActive,
      desktopMako,
      userDetails
    } = this.state

    const {backToInterface} = this.props.userCart

    let root = Cookies.get(HOST_NAME)
    let userLoggedIn = Cookies.get(USER_TOKEN) != null ? true:false

    let isLogin = window.location.href.includes('login')
    let isProInterface = window.location.href.includes('pros/interface')

    let hideMobileFooter = 
    window.location.href.includes('login') ||
    (window.location.href.includes('calculator') && !window.location.href.includes('room')) ||
    (window.location.href.includes('cart') && window.location.href.includes('token')) ||
    window.location.href.includes('interface') ||
    window.location.href.includes('recommendation') 

    return (
      <ScrollTop setScrollToItemBoolean={this.setScrollToItemBoolean} typeOfMechiron={typeOfMechiron} desktop={(desktop && typeOfMechiron === 'ace') || desktopMako} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain}>
      {typeOfMechiron === 'ace' ?
            <Helmet>
              <title>המחירון-אייס</title>         
              <link rel="icon" type="image/png" href="faviconace.ico" sizes="16x16" />
            </Helmet>
            :
            typeOfMechiron === 'mako' ?
            <Helmet>
              <title>המחירון-mako</title>
              <link rel="icon" type="image/png" href="faviconmako.ico" sizes="16x16" />
            </Helmet>
            :
            <Helmet>
              <title>המחירון של רמדור</title>
              <meta name="description" content="מחירון עלות בניה ושיפוץ. מחשבון בניית כתבי כמויות"/>
              <meta name="description" content="המחירון של רמדור: מחירון עלויות בניה, שיפוץ ותחזוקה למשפץ ולבונה. מחשבון להכנת אומדן ראשוני כולל כתב כמויות, בצורה קלה ומהירה."/>
              <link rel="icon" type="image/png" href="faviconreg.Ico" sizes="16x16" />
            </Helmet>

      }

      {desktop && typeOfMechiron === 'ace' ? 
        <GoogleTagManager gtmId={GOOGLE_GTM_ACE_ID}/>
        : desktopMako ?
        <GoogleTagManager gtmId={GOOGLE_GTM_MAKO_ID}/>
        :
        null
      }
        {loginPopup && (desktop || desktopMako) ? 
            <span className="login__popup__wrapper">
                <img src={CancelCircle} alt="cancel-popup" className="choice__calculator__input__x" onClick={()=>{this.setState({loginPopup:false});this.disActivateCurtain()}}></img>
                <div className="room__rec__popup" style={{overflow:'hidden'}}>
                <Login generalDesktopCallback={this.generalDesktopCallback} route={route} lang={Hebrew.Login}/>
                </div>
            </span>
            :
            null
        }

        {contactPopup && (desktop || desktopMako) ? 
            <div className="app__contact__popup">
              <aside><img src={typeOfMechiron === 'mako' ? CancelCircleMako:CancelCircleMako}  alt="cancel-popup" className="choice__calculator__input__x" onClick={()=>{this.setState({contactPopup:false});this.disActivateCurtain()}}></img></aside>
              <Contact answer={contactOptionActive} submitCallback={this.submitCallback} desktop={true} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} lang={Hebrew.Contact}/>
            </div>
            :
            null
        }

        <div className={root ? `${root} App`:"App"} ref={this.app}>
          <div className={this.state.curtain ? "curtain curtain--active" : "curtain"} ></div>
          {/* {typeOfMechiron === 'ace' && !window.location.pathname.includes('aces') && !window.location.pathname.includes('typeofcart') ? <div style={curtainHeight ? {height:'201px'} : curtainHeight2 ? {height:'248px'}:{}} className={curtain && typeOfMechiron === 'ace' ? "curtain__ace--active curtain__ace":"curtain__ace"}></div>:null}  */}
          {typeOfMechiron === 'ace' && !desktop ? 
            // <NavbarAce resetCart={this.resetCart} totalProductsInCart={totalProductsInCart} showNav={showNav} proIcon={this.proIcon} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} toggleCurtain={this.toggleCurtain} lang={Hebrew.navbar}/>
            <NewNavbar totalProductsInCart={totalProductsInCart}  showNav={showNav} disActivateMobileMenuNav={this.disActivateMobileMenuNav} activateMobileMenuNav={this.activateMobileMenuNav} typeOfMechiron={typeOfMechiron} desktop={desktopMako || desktop} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain}/>
            :
            typeOfMechiron === 'ace' && desktop ?
            // <NavbarAceDesktop userDetails={userDetails} resetCart={this.resetCart} activateContactPopup={this.activateContactPopup} activateLoginPopup={this.activateLoginPopup} totalProductsInCart={totalProductsInCart} showNav={showNav}proIcon={this.proIcon} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} toggleCurtain={this.toggleCurtain} lang={Hebrew.navbar}/>
            <NewNavbarDesktop  typeOfMechiron={typeOfMechiron} desktop={desktopMako || desktop} userDetails={userDetails} resetCart={this.resetCart} activateContactPopup={this.activateContactPopup} activateLoginPopup={this.activateLoginPopup} totalProductsInCart={totalProductsInCart} showNav={showNav} proIcon={this.proIcon} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} toggleCurtain={this.toggleCurtain} lang={Hebrew.navbar}/>

            :
            desktopMako ?
            <NewNavbarDesktop  typeOfMechiron={typeOfMechiron} desktop={desktopMako || desktop} userDetails={userDetails} resetCart={this.resetCart} activateContactPopup={this.activateContactPopup} activateLoginPopup={this.activateLoginPopup} totalProductsInCart={totalProductsInCart} showNav={showNav} proIcon={this.proIcon} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} toggleCurtain={this.toggleCurtain} lang={Hebrew.navbar}/>
            :
            <NewNavbar totalProductsInCart={totalProductsInCart} showNav={showNav} disActivateMobileMenuNav={this.disActivateMobileMenuNav} activateMobileMenuNav={this.activateMobileMenuNav} typeOfMechiron={typeOfMechiron} desktop={desktopMako || desktop} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain}/>
            // <Navbar resetCart={this.resetCart} showNav={showNav} proIcon={this.proIcon} disActivateCurtain={this.disActivateCurtain} toggleCurtain={this.toggleCurtain} lang={Hebrew.navbar}/>
          }
          <NavbarPlaceholder show={isLogin || isProInterface} big={(desktop && typeOfMechiron === 'ace')} showNav={showNav}/>
          <NewNavbarMobileMenu totalProductsInCart={totalProductsInCart} resetCart={this.resetCart} disActivateMobileMenuNav={this.disActivateMobileMenuNav} typeOfMechiron={typeOfMechiron} active={this.state.mobileMenuNav}/>
          {/* {desktopMako ? 
            <MakoMenuDesktop resetCart={this.resetCart} userCart={this.props.userCart.userCart} totalProductsInCart={totalProductsInCart} activateLoginPopup={this.activateLoginPopup} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain}/>
            : 
            null
          } */}

          <Switch>
            <Route exact path="/editproductinusercart/:levelOneId/:levelTwoId/:levelThreeId/:productId" render={() => <ProductPage typeOfMechiron={typeOfMechiron} userDetails={userDetails} activateLoginPopup={this.activateLoginPopup}  desktopMako={desktopMako} desktop={typeOfMechiron === 'ace' && desktop} displayButtonCart={this.displayButtonCart} lang={Hebrew.ProductPage} cartOpen={cartPopup} toggleCurtain={this.toggleCurtain} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} />} />
            <Route path="/cart/edit/roomindex/:indexInCart/:pidToEdit/:levelOneId/:levelTwoId/:levelThreeId/filter/:filterId/:productId" render={() => <ProductPage typeOfMechiron={typeOfMechiron} userDetails={userDetails} activateLoginPopup={this.activateLoginPopup} desktopMako={desktopMako} desktop={typeOfMechiron === 'ace' && desktop} displayButtonCart={this.displayButtonCart} lang={Hebrew.ProductPage} cartOpen={cartPopup} toggleCurtain={this.toggleCurtain} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} />} />
            <Route path="/recommendation/edit/:pidToEdit/:levelOneId/:levelTwoId/:levelThreeId/filter/:filterId/:productId" render={() => <ProductPage typeOfMechiron={typeOfMechiron} userDetails={userDetails} activateLoginPopup={this.activateLoginPopup} desktopMako={desktopMako} desktop={typeOfMechiron === 'ace' && desktop} displayButtonCart={this.displayButtonCart} lang={Hebrew.ProductPage} cartOpen={cartPopup} toggleCurtain={this.toggleCurtain} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} />} />
            <Route path="/products/:levelOneId/:levelTwoId/:levelThreeId/:productId" render={() => <ProductPage typeOfMechiron={typeOfMechiron} userDetails={userDetails} activateLoginPopup={this.activateLoginPopup} desktopMako={desktopMako} desktop={typeOfMechiron === 'ace' && desktop} displayButtonCart={this.displayButtonCart} lang={Hebrew.ProductPage} cartOpen={cartPopup} toggleCurtain={this.toggleCurtain} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} />} />
            <Route path="/calculator/:levelOneId/room/:levelTwoId" render={() => <KindOfCategory desktopMako={desktopMako} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} desktop={typeOfMechiron === 'ace' && desktop} lang={Hebrew.KindOfCategory} />} />
            <Route path="/products/:levelOneId/:levelTwoId/:levelThreeId" render={() => <Category typeOfMechiron={typeOfMechiron} desktopMako={desktopMako} desktop={typeOfMechiron === 'ace' && desktop} lang={Hebrew.Category} />} />
            <Route exact path="/productsCategory/:levelOneId/:levelTwoId/:levelThreeId/filterIdInCategory/:filterId" render={() => <Category typeOfMechiron={typeOfMechiron} desktopMako={desktopMako} desktop={typeOfMechiron === 'ace' && desktop} lang={Hebrew.Category} />} />
            <Route path="/cart/edit/roomindex/:indexInCart/edit/:pidToEdit/:levelOneId/:levelTwoId/:levelThreeId/filter/:filterId" render={() => <Category typeOfMechiron={typeOfMechiron} desktopMako={desktopMako} desktop={typeOfMechiron === 'ace' && desktop} lang={Hebrew.Category} />} />
            <Route path="/recommendationedit/edit/:pidToEdit/:levelOneId/:levelTwoId/:levelThreeId/filterId/:filterId" render={() => <Category  typeOfMechiron={typeOfMechiron} desktopMako={desktopMako} desktop={typeOfMechiron === 'ace' && desktop} lang={Hebrew.Category} />} />
            <Route path="/calculator/:levelOneId/room" render={() => <Room desktopMako={desktopMako} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} desktop={typeOfMechiron === 'ace' && desktop} lang={Hebrew.Room} />} />
            <Route path="/choice" render={() => <Choice desktopMako={desktopMako} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} desktop={desktop && typeOfMechiron === 'ace'} lang={Hebrew.Choice} />} />
            <Route exact path="/recommendation/:levelOneId" render={() => <Cart typeOfMechiron={typeOfMechiron} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain}  activateLoginPopup={this.activateLoginPopup} desktop={typeOfMechiron === 'ace' && desktop} desktopMako={desktopMako} lang={Hebrew.Cart} />} />
            <Route path="/calculator/:levelOneId" render={() => <Calculator desktopMako={desktopMako} lang={Hebrew.Calculator} />} />
            <Route path="/aces/typeofcart/:type/cart/:levelOneId" render={() => <Aces desktopMako={desktopMako} setBooleanIfUserMadeContact={this.setBooleanIfUserMadeContact} desktop={(typeOfMechiron === 'ace' && desktop) || desktopMako} lang={Hebrew.Aces} toggleCurtain={this.toggleCurtain} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} />} />
            <Route path="/aces/cart" render={() => <Aces desktopMako={desktopMako} setBooleanIfUserMadeContact={this.setBooleanIfUserMadeContact} desktop={(typeOfMechiron === 'ace' && desktop) || desktopMako} lang={Hebrew.Aces} toggleCurtain={this.toggleCurtain} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} />} />
            <Route path="/aces/:type/:proId" render={() => <AceProPage activateLoginPopup={this.activateLoginPopup} desktopMako={desktopMako} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} desktop={(typeOfMechiron === 'ace' && desktop) || desktopMako} lang={Hebrew.AceProPage} />} />
            {(!desktop || typeOfMechiron !== 'ace') && !desktopMako ? 
            <Route exact path="/cart/:indexInCart/leveloneid/:levelOneId/:token" render={() => <NewCartItem scrollToItemWhenEdit={this.state.scrollToItemWhenEdit} lang={Hebrew.UserCart} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain}/>} desktop={(typeOfMechiron === 'ace' && desktop) || desktopMako}/>
              :
              null
            }
            {(desktop && typeOfMechiron === 'ace') || desktopMako  ? 
            <Route exact path="/cart/:indexInCart/leveloneid/:levelOneId/:token" render={() => <NewCart activateLoginPopup={this.activateLoginPopup} scrollToItemWhenEdit={this.state.scrollToItemWhenEdit} desktopMako={desktopMako} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} desktop={(typeOfMechiron === 'ace' && desktop) || desktopMako} lang={Hebrew.UserCart} />} />
              :
              null
            }
            <Route exact path="/cart/:token" render={() => <NewCart activateLoginPopup={this.activateLoginPopup} scrollToItemWhenEdit={this.state.scrollToItemWhenEdit}  activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} desktopMako={desktopMako} desktop={typeOfMechiron === 'ace' && desktop} lang={Hebrew.UserCart} />} />
            <Route path="/contact/:typeOfContact" render={() => <Contact desktopMako={desktopMako} lang={Hebrew.Contact} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} />} />
            
            <Route path="/pros/interface/:proId/:pageState" render={() => <ProsInterface activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain}/>} />
            <Route path="/pros/interface/:proId/:pageState/:activeFilter" render={() => <ProsInterface activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain}/>} />

            <Route path="/search" render={() => <Search userDetails={userDetails} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain} activateLoginPopup={this.activateLoginPopup} desktopMako={desktopMako} lang={Search.Login} />} />
            <Route path="/login/find/:levelOneId/:levelTwoId/:levelThreeId/:productId" render={() => <Login desktopMako={desktopMako} desktop={desktop} lang={Hebrew.Login} />} />
            <Route path="/login/:levelOneId/:levelTwoId/:levelThreeId" render={() => <Login desktopMako={desktopMako} desktop={desktop} lang={Hebrew.Login} />} />
            <Route path="/login/:levelOneId" render={() => <Login desktopMako={desktopMako} desktop={desktop} lang={Hebrew.Login} />} />
            <Route path="/login/topro/:proId" render={() => <Login desktopMako={desktopMako} desktop={desktop} lang={Hebrew.Login} />} />
            <Route path="/login/tocart" render={() => <Login desktopMako={desktopMako} desktop={desktop} lang={Hebrew.Login} />} />
            <Route path="/login" render={() => <Login desktopMako={desktopMako} desktop={desktop} lang={Hebrew.Login} />} />
            <Route path="/loginacepage/:proId" render={() => <Login desktopMako={desktopMako} desktop={desktop} lang={Hebrew.Login}/>} />

            <Route exact path="/searchResults" render={() => <SearchResults typeOfMechiron={typeOfMechiron} desktopMako={desktopMako || desktop} lang={Hebrew.Category} />} />

            <Route path="/regulations" render={() => <Regulations aceApp={typeOfMechiron === 'ace'} desktopMako={desktopMako} lang={Hebrew.Regulations} />} />
            <Route path="/allchapters" render={() => <AllChapters desktopMako={desktopMako} desktop={typeOfMechiron === 'ace' && desktop} lang={Hebrew.AllChapters} />} />
            {/* <Route path="/" render={() => <Home activateLoginPopup={this.activateLoginPopup} desktopMako={desktopMako} activateContactPopup={this.activateContactPopup} desktop={desktop} totalProductsInCart={totalProductsInCart} lang={Hebrew.Home} activateCurtain={this.activateCurtain} disActivateCurtain={this.disActivateCurtain}/>} /> */}
            <Route path="/" render={() => <NewHomePage desktopVersion={desktopMako || desktop ? true:false}/>} />

            
          </Switch>

          {typeOfMechiron === 'ace' && desktop ?
              <DesktopFooter typeOfMechiron={typeOfMechiron} footerContactHandler={this.footerContactHandler}/>
              :
              null
          }

          {!desktop && !desktopMako ?
              <MobileFooter typeOfMechiron={typeOfMechiron}  hide={hideMobileFooter} footerContactHandler={this.footerContactHandler} goToRegulations={this.goToRegulations}/>
              :
              null
          }

          {desktopMako ?
              <DesktopFooter typeOfMechiron={typeOfMechiron}/>
              :
              null
          }

          {/* { cartOpen && !window.location.href.includes('cart') &&  !window.location.href.includes('aces')?
          <div id="UC: Went to cart" style={window.location.href.includes('recommendation') ? {bottom:'75px'}:{}} className="cart-popup-btn" onClick={() => { this.activateCart() }}>
            <img id="UC: Went to cart" src={CartPopupImg} alt="cart"></img>
            <figure id="UC: Went to cart"><p id="UC: Went to cart">{totalProductsInCart}</p></figure>
          </div>
          :
          null
        } */}

        {backToInterface && !isProInterface && userLoggedIn? 
            <div className="interface-back-button" onClick={()=>{this.backToInterface()}}>
                <img src={BackBtnUser}></img>
                <span><img src={BackBtnArrow}></img></span>
            </div>
            :
            null
        }


        </div>
      </ScrollTop>
    );
  }

}


function stateToProps({ userCart }) {
  return { userCart }
}

export default withRouter(connect(stateToProps, actions)(App))

