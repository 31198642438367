import React, { Component } from 'react'
import './navbar.css'
import { Link } from 'react-router-dom'
import {withRouter} from 'react-router-dom'
import Cookies from 'js-cookie'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
//images

import Arrow from '../../../images/icons/nav1.svg'
import ArrowReg from '../../../images/original/16.svg'
import ArrowMako from '../../../images/mako/17.svg'

import Nav2 from '../../../images/navbarace/11.svg'
import Nav3 from '../../../images/navbarace/22.svg'
import Nav4 from '../../../images/navbarace/33.svg'
import Nav5 from '../../../images/navbarace/55.svg'
import Nav6 from '../../../images/navbarace/66.svg'
import Nav7 from '../../../images/navbarace/77.svg'
import History from '../../../images/icons/history.svg'
import ArrowRes from '../../../images/icons/backArrow.svg'
// import AceWhite from '../../../images/AceWhiteNoShadow.svg'
import AceWhite from '../../../images/newlogoace.png'
import BackToAceLogo from '../../../images/icons/aoace.svg'

import NavArrowDesktop from '../../../images/navbarace/acedeskarrownav.svg'


import WhiteX from '../../../images/navbarace/6.svg'

import Gps from '../../../images/navbarace/4.svg'
import Cart from '../../../images/navbarace/5.svg'
//comps
import {ApiGet,ApiGetWithHeader} from '../../../tools/tools'
import Login from '../../layouts/Login/Login'
//config
import {API_URL, USER_TOKEN,HOST_NAME} from '../../../configs/configs'
//img
import Glass from '../../../images/navbarace/3.svg'
//lang
import * as Hebrew from '../../../texts/hebrew'

class NavbarAce extends Component {

    constructor(){
        super()

        this.state = {
            navScrolled:false,
            navMenuActive: false,
            levelTwos: [],
            prevPath: '',
            //search
            displayLastSearches:false,
            noResults:false,
            searchResults:[],
            hamburger:false,
            value:'',
            page: 0,
            limit:20
        }

        this.searchContainerRef = React.createRef();
    }

    async componentWillMount() {
        let res = await ApiGet(API_URL + `/levelTwo/getAll`)
        if(res)
        this.setState({levelTwos:res})

        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})
    }
    

    componentDidMount() {
        window.addEventListener("scroll",this.handleScroll,{passive: true});
        window.addEventListener("click",this.handleClick,{passive: true});
    }

    componentWillUnmount() {
        window.addEventListener("scroll",this.handleScroll,{passive: true});
        window.addEventListener("click",this.handleClick,{passive: true});
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
          this.setState({ prevPath: this.props.location.pathname })
        }
      }

    handleScroll = () => {
        if(window.pageYOffset > 0) {
            this.setState({navScrolled:true})
        } else {
            this.setState({navScrolled:false})
        }
    }

    handleClick = (event) => {
        
        if(event.target.className !== 'navbar__hamburger')
        this.setState({navMenuActive:false})

        if(!event.target.className.includes('section__search') && event.target.name !== 'nav-search' && this.state.displayLastSearches){
            this.setState({
                displayLastSearches:false,
                value:'',
                searchResults:[],
                page:0
            })
        }
    }

    toggleNav = (e) => {
        if(e != null)
        e.stopPropagation()

        this.setState(prevState => ({
            navMenuActive: !prevState.navMenuActive
        }),()=>{
            this.props.toggleCurtain()
        });
    }

    activateNav = (e) => {
        if(e != null)
        e.stopPropagation()
        setTimeout(()=>{ this.setState({navMenuActive: true},()=>{this.props.activateCurtain()}) }, 0);
        
    }

    disActivateNav = (e) => {
        if(e != null)
        e.stopPropagation()
        this.setState({navMenuActive: false},()=>{this.props.disActivateCurtain()})
    }

    
    buildId = (product) => {
        let dataString = 
        "UC:"
        + "\"" + ', ' + "product_id:"
        + "\"" + product.pid_id + "\"" + ', ' + "product_name:"
        + "\"" + product.name + "\""
        if(this.props.userDetails)
        dataString = dataString + ', ' + "User_full_name:" 
        + "\"" + this.props.userDetails.full_name + "\"" +  ', ' + "User_phone:"
        + "\"" + this.props.userDetails.phone + "\"" 

        if(this.state.value)
        dataString = dataString + ', ' + "User_has_typed:" 
        + "\"" + this.state.value + "\"" 
        return dataString
    }

    goto = (link) => {
        // if(Cookies.get(USER_TOKEN)) {
            this.setState({navMenuActive:false})
            this.props.disActivateCurtain()
            this.props.history.push(link)
        // } else {
        //     this.setState({navMenuActive:false})
        //     this.props.disActivateCurtain()
        //     if(link === '/regulations') {
        //         this.props.history.push('/regulations') 
        //     } else if(link.includes('contact')) {
        //         this.props.history.push('/contact/-1') 
        //     } else {
        //         this.props.activateCurtain()
        //         this.props.activateLoginPopup(link) 
        //     }
        // }
    }

    goBackFunc = () => {
        if(this.state.prevPath.includes('login') && window.location.pathname !=='/regulations'){
            this.props.history.push(`/`)
        } else {
            this.props.history.goBack()
        }
    }

    handleAceCartClick = () => {
        if(window.location.href.includes('cart') && window.location.href.includes('token'))return
        if(!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null ){
            this.props.history.push('/cart/token')
        } else {
            this.props.removeRoomsWithoutProducts()
            if(this.props.userCart.userCart.length > 0){
                this.props.history.push(`/cart/0/leveloneid/${this.props.userCart.userCart[0].level_one_id}/token`)
            } else {
                this.props.history.push('/cart/token')
            }
        }
       
    }

    // SEARCH
    sleep = m => new Promise(r => this.setState({timeout:setTimeout(r, m)}))

    searchForValue = async (value) => {
        this.setState({loader:true,noResults:false,value})
        
        clearTimeout(this.state.timeout)
        await this.sleep(500)
        
        if(value.length <= 2) {
            this.setState({searchResults:[],loader:false})
            return
        }

        let token = Cookies.get(USER_TOKEN)
        const {typeOfMechiron} = this.state

        let result = await ApiGetWithHeader(API_URL + '/product/search?keyword=' + value + `&page=${0}&limit=${20}&type=${typeOfMechiron}`,token)

        if(result.length === 0){
            
           this.setState({searchResults:result,loader:false,noResults:true})
           return
        }

        if(result && result.length > 0){
            this.setState({searchResults:result})
        }
    }

    goToProdPage = async(item,queryParam) => {
        
        let res = await ApiGet(API_URL + '/filter/getById/' + item.filter_id)
        let lvl3 = res.level_three_id
        lvl3 = lvl3.split(';')
        lvl3 = lvl3[0]
        
        let storedPids = JSON.parse(localStorage.getItem("ace-mechiron-searches"))
        
        if(storedPids == null){
            storedPids = [item]
            localStorage.setItem("ace-mechiron-searches", JSON.stringify(storedPids));
        } else {
            if(storedPids.length < 5){
                storedPids.push(item)
                localStorage.setItem("ace-mechiron-searches", JSON.stringify(storedPids));
            } else {
                storedPids.shift(item)
                storedPids.push(item)
                localStorage.setItem("ace-mechiron-searches", JSON.stringify(storedPids));
            }

        }

        
        
        let res2 = await ApiGet(API_URL + '/product/getById/' + item.pid_id)
        await this.props.saveSelectedProduct(res2)   
        // if(!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null) {
        //     this.props.activateCurtain()
        //     this.props.activateLoginPopup(`/products/all/${item.level_two_id}/${lvl3}/${item.pid_id}/?q=${queryParam}`)
        // } else {
            this.props.history.push(`/`)
            this.props.history.push(`/products/all/${item.level_two_id}/${lvl3}/${item.pid_id}/?q=${queryParam}`)
        // }

    }


    trackScrolling = () => {
        if(this.searchContainerRef.current && this.searchContainerRef.current != null) {
               
           if(this.searchContainerRef.current.scrollTop >= (this.searchContainerRef.current.scrollHeight - this.searchContainerRef.current.offsetHeight) && this.state.searchResults.length > 0) {
               this.setState(prevState => ({
                   page: prevState.page + prevState.limit
               }),()=>{
                   this.searchMore()
               })
           }

        }
    }

    searchMore = async() => {
        const {typeOfMechiron} = this.state
        let result = await ApiGet(API_URL + '/product/search?keyword=' + this.state.value + `&page=${this.state.page}&limit=${this.state.limit}&type=${typeOfMechiron}`)
        if(result && result.length > 0) {
            let newState = this.state.searchResults.map(item=>item)
            newState = newState.concat(result)
            this.setState({searchResults:newState})
        }
    }

    toggleMenuAce = () => {
        this.setState(prevState => ({
            hamburger: !prevState.hamburger
        })); 
    }

    handleAuthentication = () => {
        if(!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null) {
            this.props.activateCurtain()
            this.props.activateLoginPopup('/')
        } else {
            Cookies.remove(USER_TOKEN)
            this.props.history.push(`/`)
            this.props.resetCart()
        }
    }



    render() {

        const {navScrolled, navMenuActive, levelTwos,typeOfMechiron,searchResults,displayLastSearches,value,hamburger} = this.state
        const {lang,showNav} =  this.props

        let isContact = window.location.href.includes('contact')

        let lastSearchs = JSON.parse(localStorage.getItem("ace-mechiron-searches"))

        if(lastSearchs == null)
        lastSearchs = []



        return (
           <nav className="navbar__ace__desktop">


                <section className="navbar__ace__desktop__section">

                    <div className="navbar__ace__desktop--grid">

                        <div  className="navbar__ace__desktop__section--menu">
                            <div className="navbar__ace__desktop__section__img" onClick={()=>{this.props.history.push('/')}}>
                                <img src={AceWhite} alt="ace-logo"/>
                            </div>

                            <span>
                                <a className="navbar__ace__desktop__section--menu__ace-link" href="https://www.ace.co.il"><img src={BackToAceLogo} alt="ace"/><p>חזרה לאייס</p></a>
                            </span> 

                            <span>
                                <img src={Gps} alt="stores"/>
                                <a href="https://www.ace.co.il/stores">סניפים</a>
                            </span>

                        </div>


                        <div className="navbar__ace__desktop__section--menu">
                            {/* <div onClick={()=>{this.goto('/allchapters')}}>
                                <span>
                                    <img src={Nav4} alt="allchapters"/>
                                    <p>כל הפרקים</p>
                                </span>
                            </div> */}
                            {/* <div onClick={()=>{this.goto('/regulations')}}>
                                <span>
                                    <img src={Nav6} alt="regulations"/>
                                    <p>אודות המחירון</p>
                                </span>
                            </div>   */}

                            <div onClick={()=>{this.goto('/aces/cart')}} id="UC: Clicked on pros in menu">
                                <span id="UC: Clicked on pros in menu">
                                    <img id="UC: Clicked on pros in menu" src={Nav3} alt="cart"/>
                                    <p id="UC: Clicked on pros in menu">אנשי המקצוע שלנו</p>
                                </span>
                            </div>
                            <div onClick={()=>{this.goto('/choice')}}>
                                <span>
                                    <img src={Nav2} alt="rooms"/>
                                    <p>{lang.nav2}</p>
                                </span>
                            </div>
                            <div onClick={()=>{this.props.activateContactPopup()}} id="UC: Enter Contact Us Page">
                                <span id="UC: Enter Contact Us Page">
                                    <img id="UC: Enter Contact Us Page" src={Nav5} alt="contact-us"/>
                                    <p id="UC: Enter Contact Us Page">צרו קשר</p>
                                </span>
                            </div>
                            <div onClick={()=>{this.handleAuthentication()}}>
                                <span>
                                    <img src={Nav7} alt="contact-us"/>
                                    <p>{!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null ? 'התחבר':'התנתק'}</p>
                                </span>
                            </div>
                            <div id="UC: Went To Cart" className="navbar__ace__desktop__section--cart" onClick={()=>{this.handleAceCartClick()}}>
                                <div id="UC: Went To Cart">
                                    <img id="UC: Went To Cart" src={Cart} alt="cart"/>
                                    <aside id="UC: Went To Cart">
                                         <p id="UC: Went To Cart">{this.props.totalProductsInCart}</p> 
                                    </aside>
                                </div>
                            </div>
                            {/* <span>
                                <img src={Gps} alt="stores"/>
                                <a href="https://www.ace.co.il/stores">סניפים</a>
                            </span>
                            <span>
                                <a href="https://www.ace.co.il"><img src={BackToAceLogo} alt="ace"/>חזרה לאייס</a>
                            </span> */}
                        </div>
                    </div>
            
                </section>

                <section className="navbar__ace__desktop__section">
                    <div className="navbar__ace__desktop--grid">

                        {/* <div className="navbar__ace__desktop__section--cart" onClick={()=>{this.handleAceCartClick()}}>
                            <div>
                                <img src={Cart} alt="cart"/>
                                <aside>
                                <p>{this.props.totalProductsInCart}</p> 
                                </aside>
                            </div>
                            <h2>הרשימה שלי</h2>
                        </div> */}
                        <div className="navbar__ace__desktop__section--all-chapters-menu" onClick={()=>{this.toggleMenuAce()}}>
                            <div id="nav-icon3" className={hamburger ? "open":""}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <h2>המחירון</h2>

                            <div className={hamburger ? "navbar__ace__desktop__section--all-chapters-menu--dropdown navbar__ace__desktop__section--all-chapters-menu--dropdown--active":"navbar__ace__desktop__section--all-chapters-menu--dropdown"}>
                                <div onClick={()=>{this.goto('/allchapters')}}>
                                    <span>{lang.chapters}</span>
                                    <img src={NavArrowDesktop} alt="navigation-arrow"/>
                                </div>

                                {levelTwos.map(item=>{
                                    return <div key={item.level_two_id} onClick={()=>{this.goto(`/calculator/all/room/${item.level_two_id}`)}}>
                                            <span>{item.name}</span>
                                            <img src={NavArrowDesktop} alt="navigation-arrow"/>
                                        </div>
                                })}
                            </div>

                        </div>


                        <div className="navbar__ace__desktop__section__search">
                            <input 
                              value={value}
                              type="text"
                              name="nav-search"
                              placeholder="אני רוצה לשפץ…"
                              onChange={(e)=>{this.searchForValue(e.target.value)}}
                              onFocus={()=>{this.setState({displayLastSearches:true})}}
                            //   onBlur={()=>{this.setState({displayLastSearches:false})}}

                            />
                            <img src={Glass} alt="search-icon"/>

                            {searchResults.length > 0 ? 
                                <div className="navbar__ace__desktop__section__search__menu" onScroll={()=>{this.trackScrolling()}} ref={this.searchContainerRef}>
                                    <header>תוצאות חיפוש</header>
                                    {searchResults && searchResults.length > 0 && searchResults.map(item=>{
                                    return <div id={this.buildId(item)} onClick={()=>{this.goToProdPage(item,this.state.value)}}>
                                                <p id={this.buildId(item)}>{item.name}</p>
                                                <img id={this.buildId(item)} src={ArrowRes} alt="search-product"/>
                                            </div>
                                    })}
                                </div>
                                :
                                null
                            }

                            {displayLastSearches && !value && searchResults.length === 0 && lastSearchs.length > 0? 
                                <div className="navbar__ace__desktop__section__search__menu">
                                    <header>חיפושים אחרונים</header>
                                    {lastSearchs && lastSearchs.length > 0 && lastSearchs.map(item=>{
                                    return <div id={this.buildId(item)} onClick={()=>{this.goToProdPage(item,this.state.value)}}>
                                                <p id={this.buildId(item)}>{item.name}</p>
                                                <img id={this.buildId(item)} style={{transform:'rotate(0deg)'}} src={History} alt="searches-history"/>
                                            </div>
                                    })}
                                </div>
                                :
                                null
                            }



                        </div>

                    </div>
                </section>


           </nav>
        );
    }
}


function stateToProps({ userCart,levels }) {
    return { userCart,levels }
}
  
export default withRouter(connect(stateToProps, actions)(NavbarAce))
