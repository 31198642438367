import React, { Component } from 'react'
import './allchapters.css'
import InnerNavigation from '../../globals/InnerNavigation/InnerNavigation'
import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
//tools
import {ApiGet} from '../../../tools/tools'
//config
import {API_URL,IMAGE_URL_LEVELS} from '../../../configs/configs'
//comps 
import ImageComponent from '../../globals/ImageComponent/ImageComponent'



class AllChapters extends Component {

    constructor(){
        super()

        this.state = {
            levelTwos: [] 
        }
    }

    async componentWillMount() {
        let res = await ApiGet(API_URL + `/levelTwo/getAll`)
        if(res)
        this.setState({levelTwos:res})
    }
    
    handleNext = async(item) =>{
        this.props.saveSelectedLevelTwoItem(item)
        await this.props.saveLevelThrees(item.level_two_id)
        this.props.history.push(`/calculator/all/room/${item.level_two_id}`)
    }

    render() {
        const { lang,desktop } = this.props
        const {levelTwos} = this.state
        return (
            <div className="allchapters">
                <InnerNavigation links={['/allchapters']} pages={[lang.allchapters]}/>

                <h1>{lang.allchapters}</h1>

                <h2>{lang.desc}</h2>

                {/* {desktop ? 
                    <div className="allchapters__counter">
                        <p> מציג {levelTwos.length} פרקים  </p>
                    </div>
                    :
                    null
                } */}

                <div>
                    {levelTwos.map(item=>{
                        return <div className="allchapters__item">
                                    <section onClick={()=>{this.handleNext(item)}}>
                                        <ImageComponent src={IMAGE_URL_LEVELS + 'ace/' + item.level_two_id + '.png'}/>
                                        <img src={IMAGE_URL_LEVELS + 'ace/' + item.vendor_id + 'S.svg'} alt={item.name}/>
                                    </section>
                                    <h2>{item.name}</h2>
                                    <p>{item.description}</p>
                               </div>
                    })}
                </div>

            </div>
        );
    }
}

// export default withRouter(AllChapters);

function stateToProps({ levels }) {
    return { levels }
}
  
export default withRouter(connect(stateToProps, actions)(AllChapters))