export const ImagesIdsArray = [
    'L1000005',
    'L1000009',
    'L1000013',
    'L1000017',
    'L1000021',
    'L1000025',
    'L1000029',
    'L1000033',
    'L1000037',
    'L1000045',
    'L1000049',
    'L1000053',
    'L1000057',
    'L1000061',
    'L1000065',
    'L1000069',
    'L1000073',
    'L1000077',
    'L1000081',
    'L1000085',
    'L1000089',
    'L2000001',
    'L2000005',
    'L2000009',
    'L2000013',
    'L2000017',
    'L2000021',
    'L2000025',
    'L2000029',
    'L2000033',
    'L2000037',
    'L2000041',
    'L2000045',
    'L2000049',
    'L2000053',
    'L2000057',
    'L3000001',
    'L3000005',
    'L3000009',
    'L3000013',
    'L3000017',
    'L3000021',
    'L3000025',
    'L3000029',
    'L3000033',
    'L3000037',
    'L3000041',
    'L3000045',
    'L3000049',
    'L3000053',
    'L3000057',
    'L3000061',
    'L3000065',
    'L3000069',
    'L3000073',
    'L3000081',
    'L3000085',
    'L3000089',
    'L3000093',
    'L3000097',
    'L3000101',
    'L3000105',
    'L3000109',
    'L3000113',
    'L3000117',
    'L3000121',
    'L3000125',
    'L3000129',
    'L3000133',
    'L3000137',
    'L3000141',
    'L3000145',
    'L3000149',
    'L3000153',
    'L3000157',
    'L3000161',
    'L3000165',
    'L3000169',
    'L3000173',
    'L3000177',
    'L3000181',
    'L3000185',
    'L3000189',
    'L3000193',
    'L3000197',
    'L3000201',
    'L3000205',
    'L3000209',
    'L3000213',
    'L3000217',
    'L3000221',
    'L3000225',
    'L3000229',
    'L3000233',
    'L3000237',
    'L3000241',
    'L3000245',
    'L3000249',
    'L3000253',
    'L3000257',
    'L3000261',
    'L3000265',
    'L3000269',
    'L3000273',
    'L3000277',
    'L3000281',
    'L3000285',
    'L3000289',
    'L3000293',
    'L3000297',
    'L3000301',
    'L3000305',
    'L3000309',
    'L3000313',
    'L3000317',
    'L3000321',
    'L3000325',
    'L3000329',
    'L3000333',
    'L3000337',
    'L3000341',
    'L3000345',
    'L3000349',
    'L3000353',
    'L3000357',
    'L3000361',
    'L3000365',
    'L3000369',
    'L3000373',
    'L3000377',
    'L3000381',
    'L3000385',
    'L3000389',
    'L3000393',
    'L3000401',
    'L3000405',
    'L3000409',
    'L3000413',
    'L3000417',
    'L3000421',
    'L3000425',
    'L3000429',
    'L3000433',
    'L3000437',
    'L3000441',
    'L3000445',
    'L3000449',
    'L3000453',
    'L3000457',
    'L3000461',
    'L3000465',
    'L3000469',
    'L3000473',
    'L3000477',
    'L3000481',
    'L3000485',
    'L3000489',
]
