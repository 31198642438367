import React, { Component } from 'react'
import Cookies from 'js-cookie'
import {HOST_NAME} from '../../../configs/configs'
//img
import DefaultAce from '../../../images/default/noimgace.png'
import DefaultMech from '../../../images/default/noimgmech.png'
import WorkAce from '../../../images/default/workace.png'
import WorkMech from '../../../images/default/workmech.png'
import EqAce from '../../../images/default/eqace.png'
import EqMech from '../../../images/default/eqmech.png'
import DefaultMako from '../../../images/default/noimgmako.png'
import WorkMako from '../../../images/default/workmako.png'
import EqMako from '../../../images/default/eqmako.png'


class ImageComponent extends Component {

    constructor() {
        super()

        this.state = {
            loaded: false,
            typeOfMechiron: '',
        }
    }

    imgLoaded = () => {
        this.setState({ loaded: true })
        if(this.props.loadedPic) {
            this.props.loadedPic(this.props.statePic)
        }
    }

    
    componentWillMount() {
        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})
    }
    

    componentWillReceiveProps(nextProps) {
        if(nextProps.src != null && this.props.src !== nextProps.src)
        this.setState({default_image:false})
    }

    render() {
        const { default_image,typeOfMechiron } = this.state

        let newSrc = ''
        if(this.props.src.includes('worke') && typeOfMechiron === 'ace'){
            newSrc = WorkAce
        } else if(this.props.src.includes('worke') && typeOfMechiron === 'hamechiron'){
            newSrc = WorkMech
        } else if(this.props.src.includes('worke') && typeOfMechiron === 'mako'){
            newSrc = WorkMako
        } else if(this.props.src.includes('equipment') && typeOfMechiron === 'ace') {
            newSrc = EqAce
        } else if(this.props.src.includes('equipment') && typeOfMechiron === 'hamechiron') {
            newSrc = EqMech
        } else if(this.props.src.includes('equipment') && typeOfMechiron === 'mako') {
            newSrc = EqMako
        } else {
            newSrc = this.props.src
        }

        
        return (
            this.props.loadCallback ?
                <img
                    src={default_image ? default_image : newSrc}
                    alt={this.props.alt}
                    onError={(err) => {
                        if(!newSrc.includes('work') || !newSrc.includes('equipment') )
                        this.setState({ default_image: typeOfMechiron === 'ace' ? DefaultAce: typeOfMechiron === 'mako' ? DefaultMako:DefaultMech })
                    }}
                    className={this.props.className || ''}
                    style={this.state.loaded ? { opacity: '1', transition: 'all 0.5s' } : { opacity: '0', transition: 'all 0.5s' }}
                    onLoad={() => { this.imgLoaded();this.props.loadCallback() }}
                />
                :
                <img
                    src={default_image ? default_image : newSrc}
                    alt={this.props.alt}
                    onError={(err) => {
                        if(!newSrc.includes('work') || !newSrc.includes('equipment') )
                        this.setState({ default_image: typeOfMechiron === 'ace' ? DefaultAce: typeOfMechiron === 'mako' ? DefaultMako:DefaultMech })
                    }}
                    className={this.props.className || ''}
                    style={this.state.loaded ? { opacity: '1', transition: 'all 0.5s' } : { opacity: '0', transition: 'all 0.5s' }}
                    onLoad={() => { this.imgLoaded() }}
                />


        );
    }
}

export default ImageComponent;