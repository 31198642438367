import React, { Component } from 'react'
import { Switch, Route, withRouter, Link, Router } from 'react-router-dom'
import './prosinterface.css'
//img
import Logo from '../../../images/AceWhiteNoShadow.svg'
import Search from '../../../images/navbarace/3.svg'
import Menu from '../../../images/navbarace/2.svg'
//parts components
import Customers from './parts/Customers/Customers'
import Schedule from './parts/Schedule'

class ProsInterface extends Component {

    constructor(props) {
        super(props)

        this.state = {
            activeTab: props.match.params.pageState === 'customers' ? 0 : props.match.params.pageState === 'schedule' ? 1:0,
        }
    }

    moveToPage = (activeTab) => {
        let proId  = this.props.match.params.proId
        this.setState({activeTab},()=>{
            if(this.state.activeTab === 0){
                this.props.history.push(`/pros/interface/${proId}/customers/0`)
            } else if(this.state.activeTab === 1){
                this.props.history.push(`/pros/interface/${proId}/schedule/calender`)
            }
        })
    }



    render() {

        const {activeTab} = this.state
        const {activateCurtain, disActivateCurtain} = this.props

        return (
            <main className="pros-interface">
                {/* <nav className="pros-interface__navbar">
                    <div>
                        <img src={Logo} alt="acelogo"/>
                    </div>
                    <span>
                        <img src={Search} alt="search"/>
                        <img src={Menu} alt="menu"/>
                    </span>
                </nav> */}

                <div className="pros-interface__menu">
                    <span 
                        className={activeTab === 0 ? "pros-interface__menu__tab pros-interface__menu__tab--active":"pros-interface__menu__tab"}
                        onClick={()=>{this.moveToPage(0)}}
                    >
                        <p>לקוחות</p>
                    </span>
                    <span 
                        className={activeTab === 1 ? "pros-interface__menu__tab pros-interface__menu__tab--active":"pros-interface__menu__tab"}
                        onClick={()=>{this.moveToPage(1)}}
                    >
                        <p>יומן</p>
                    </span>
                </div>

                <Switch>
                    <Route path="/pros/interface/:proId/customers" render={() => <Customers activateCurtain = {activateCurtain} disActivateCurtain = {disActivateCurtain}/>} />
                    <Route path="/pros/interface/:proId/schedule" render={() => <Schedule />} />
                </Switch>
                

            </main>
        );
    }
}

export default withRouter(ProsInterface);