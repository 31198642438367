import React, { Component } from 'react'
import {Link, withRouter} from 'react-router-dom'
import Cookies from 'js-cookie'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
//config
import {USER_TOKEN} from '../../../configs/configs'
//img
import AceRedLogo from '../../../images/acered.png'
import WhiteX from '../../../images/navbarace/6.svg'
import WhiteLetter from '../../../images/icons/whiteletter.svg'
import branchesAce from '../../../images/newnavbar/branches.svg'
import backToAce from '../../../images/newnavbar/backtoace.svg'
import backToMako from '../../../images/newnavbar/backtomako.svg'
import Rooms from '../../../images/newnavbar/rooms.svg'
import Pros from '../../../images/newnavbar/pros.svg'
import MyListAce from '../../../images/newnavbar/mylistace.svg'
import MyListMako from '../../../images/newnavbar/mylistmako.svg'
import MyListMechiron from '../../../images/newnavbar/mylistmechiron.svg'
import ClientsAce from '../../../images/newnavbar/clientsace.svg'
import ClientsMako from '../../../images/newnavbar/clientsmako.svg'
import ClientsMechiron from '../../../images/newnavbar/clientsmechiron.svg'
import Contact from '../../../images/newnavbar/contactus.svg'
import AllChapters from '../../../images/newnavbar/allchapters.svg'

class NewNavbarMobileMenu extends Component {

    disActivateNav = () => {
        this.props.disActivateMobileMenuNav()
    }


    goTo = (link) => {
        this.props.disActivateMobileMenuNav()
        this.props.history.push(link)
    }

    handleAuthentication = (isLoggedIn) => {
        if(!isLoggedIn) {
            this.props.disActivateMobileMenuNav()
            this.props.history.push(`/login`)
        } else {
            Cookies.remove(USER_TOKEN)
            this.props.disActivateMobileMenuNav()
            this.props.history.push(`/`)
            this.props.resetCart()
        }
    }
    
    render() {

        const {active, typeOfMechiron, totalProductsInCart} = this.props
        let isLoggedIn = Cookies.get(USER_TOKEN) && Cookies.get(USER_TOKEN) != null 

        return (
            <nav className={active ? "new-navbar--menu new-navbar--menu--active":"new-navbar--menu"}>

                <div onClick={()=>{this.disActivateNav()}} className="new-navbar--menu__close">
                    <img src={WhiteX} alt="cancel-icon"/>
                    <h1>סגור תפריט</h1>
                </div>


                <section className="new-navbar--menu__section-buttons">
                    {this.props.userCart.userIsAcePro && isLoggedIn ? 
                        <button onClick={()=>{this.goTo(`/pros/interface/${this.props.userCart.userId}/customers/0`)}}>לקוחות</button>
                        :
                        <button onClick={()=>{this.goTo(`/contact/-1`)}}>
                            <img src={WhiteLetter} alt="content" style={{marginLeft:'7px',transform:'translateY(1px)'}}/>
                                צור קשר
                        </button>
                    }
                    {isLoggedIn ? 
                       <button onClick={()=>{this.handleAuthentication(isLoggedIn)}}>התנתק</button>
                       :
                       <button onClick={()=>{this.handleAuthentication(isLoggedIn)}}>התחבר</button>
                    }

                    
                </section>

                {
                    typeOfMechiron === 'ace' ? 
                    <section className="new-navbar--menu__section">
                        <div>
                            <img src={branchesAce} alt="stores"/>
                            <a href="https://www.ace.co.il/stores">סניפים</a>
                        </div>
    
                        <div>
                            <img src={backToAce} alt="ace-website"/>
                            <a href="https://www.ace.co.il">חזרה לאתר של <img src={AceRedLogo} alt="ace" style={{width:'32px',height:'15px',transform:'translateY(1px)'}}/></a>
                        </div>
    

                        <div onClick={()=>{this.goTo(`/cart/token`)}}>
                            {
                                typeOfMechiron === 'ace' ? 
                                <img src={MyListAce} alt="search"/>
                                : typeOfMechiron === 'mako' ? 
                                <img src={MyListMako} alt="search"/>
                                :
                                <img src={MyListMechiron} alt="search"/>
                            }
                            <a>הרשימה שלי</a>
                            {totalProductsInCart > 0 ? <figure>{totalProductsInCart}</figure>:null}
                        </div>
                        

                    </section> 
                    :
                    typeOfMechiron === 'mako' ? 
                    <section className="new-navbar--menu__section">
                        <div>
                            <img src={backToMako} alt="ace-website"/>
                            <a href="https://www.mako.co.il">חזרה לmako</a>
                        </div>

                        <div onClick={()=>{this.goTo(`/cart/token`)}}>
                            {
                                typeOfMechiron === 'ace' ? 
                                <img src={MyListAce} alt="search"/>
                                : typeOfMechiron === 'mako' ? 
                                <img src={MyListMako} alt="search"/>
                                :
                                <img src={MyListMechiron} alt="search"/>
                            }
                            <a>הרשימה שלי</a>
                            {totalProductsInCart > 0 ? <figure>{totalProductsInCart}</figure>:null}
                        </div>
                    </section> 
                    :
                    null
                }


                <section className="new-navbar--menu__section">
                    {typeOfMechiron !== 'ace' && typeOfMechiron !== 'mako' ? 
                        <div onClick={()=>{this.goTo(`/cart/token`)}}>
                            {
                                typeOfMechiron === 'ace' ? 
                                <img src={MyListAce} alt="search"/>
                                : typeOfMechiron === 'mako' ? 
                                <img src={MyListMako} alt="search"/>
                                :
                                <img src={MyListMechiron} alt="search"/>
                             }
                            <a >הרשימה שלי</a>
                            {totalProductsInCart > 0 ? <figure>{totalProductsInCart}</figure>:null}
                        </div>
                        :
                        null
                    }


                    <div onClick={()=>{this.goTo(`/aces/cart`)}}>
                        <img src={Pros} alt="ace-website"/>
                        <a>בעלי מקצוע</a>
                    </div>

                    <div onClick={()=>{this.goTo(`/choice`)}}>
                        <img src={Rooms} alt="ace-website"/>
                        <a>חדרים</a>
                    </div>

                    <div onClick={()=>{this.goTo(`/allchapters`)}}>
                        <img src={AllChapters} alt="ace-website"/>
                        <a>המחירון</a>
                    </div> 

                    {/* <div onClick={()=>{this.goTo(`/contact/-1`)}}>
                        <img src={Contact} alt="ace-website"/>
                        <a>צור קשר</a>
                    </div> */}

                </section> 


            </nav>
        )
    }
}


function stateToProps({ userCart,levels }) {
    return { userCart,levels }
}
  
export default withRouter(connect(stateToProps, actions)(NewNavbarMobileMenu))