import { API_URL } from '../configs/configs'
import { ApiGet } from '../tools/tools'

import {
    SAVE_LEVEL_ONES,
    SAVE_LEVEL_TWOS,
    SAVE_LEVEL_THREES,
    SAVE_SELECTED_LEVEL_ONE,
    SAVE_SELECTED_LEVEL_TWO,
    SAVE_SELECTED_LEVEL_THREE,
    SAVE_SELECTED_PRODUCT,
    SET_FALSE_SAVE_PRODUCTS,
    SAVE_FILTERS,
    UPDATE_RECOMMENDATION_QUANTITY,
    DELETE_RECOMMENDATION_ITEM,
    RESET_CATEGORY_STATE,
    SAVE_PRODUCTS,
    SET_FILTER,
    SAVE_VENDORS,
    SET_MEASUREMENTS,
    RESET_PRODUCTS,
    SAVE_RECOMMENDATION,
    SET_TRUE_SAVE_PRODUCTS,
    REPLACE_RECOMMENDATION_PRODUCT,
    CHANGE_SELECTED_MEASURMENTS,
    SAVE_MOST_VIEWED,
    SET_VENDORS_BOOLEAN,
    SET_REVERSE_VENDORS_BOOLEAN,
    SET_FILTERS_BOOLEAN,
    SET_REVERSE_FILTERS_BOOLEAN,
    SET_LVLONE_BOOLEAN,
    SET_REVERSE_LVLONE_BOOLEAN,
    SET_LVLTWO_BOOLEAN,
    SET_REVERSE_LVLTWO_BOOLEAN,
    SET_LVLTHREE_BOOLEAN,
    SET_REVERSE_LVLTHREE_BOOLEAN,
    STOP_PRODUCT_PAGINATION,
    REINIT_PAGINATION,
    SAVE_SEARCH_RESULTS,
    GET_MORE_SEARCH_RESULTS,
    STOP_MORE_SEARCH_RESULTS,
    SAVE_COMPLEMENTARY_PRODUCTS,
    ADD_TO_PATH
} from './types'



export const reInitPagination = () => dispatch => {
    dispatch({ type: REINIT_PAGINATION})
}


export const setReverseLvl2Boolean = () => dispatch => {
    dispatch({ type: SET_REVERSE_LVLTWO_BOOLEAN})
}

export const setReverseFiltersBoolean = () => dispatch => {
    dispatch({ type: SET_REVERSE_FILTERS_BOOLEAN})
}

export const setReverseVendorsBoolean = () => dispatch => {
    dispatch({ type: SET_REVERSE_VENDORS_BOOLEAN})
}

export const setLvltwosBoolean = () => dispatch => {
    dispatch({ type: SET_LVLTWO_BOOLEAN })
}

export const setLvlOnesBoolean = () => dispatch => {
    dispatch({ type: SET_LVLONE_BOOLEAN })
}

export const setReverseLvl1Boolean = () => dispatch => {
    dispatch({ type: SET_REVERSE_LVLONE_BOOLEAN})
}

export const setLvlThreesBoolean = () => dispatch => {
    dispatch({ type: SET_LVLTHREE_BOOLEAN })
}

export const setReverseLvl3Boolean = () => dispatch => {
    dispatch({ type: SET_REVERSE_LVLTHREE_BOOLEAN})
}


export const saveVendors = (inProductPage = false) => async dispatch => {
    if(inProductPage)
    dispatch({ type: SET_VENDORS_BOOLEAN})
    let vendors = await ApiGet(API_URL + '/vendors/getAll')
    dispatch({ type: SAVE_VENDORS, payload: vendors })
}
export const saveLevelOnes = () => async dispatch => {
    let levelOne = await ApiGet(API_URL + '/levelOne/getAll')
    dispatch({ type: SAVE_LEVEL_ONES, payload: levelOne })
}

export const saveLevelTwos = (levelOneId) => async dispatch => {
    if(levelOneId !== 'all'){
        let levelTwos = await ApiGet(API_URL + '/levelTwo/levelOne/' + levelOneId)
        dispatch({ type: SAVE_LEVEL_TWOS, payload: levelTwos })
    } else {
        let levelTwos = await ApiGet(API_URL + `/levelTwo/getAll`)
        dispatch({ type: SAVE_LEVEL_TWOS, payload: levelTwos })
    }

}

export const saveLevelThrees = (levelTwoID) => async dispatch => {
    let levelThrees = await ApiGet(API_URL + '/levelThree/levelTwo/' + levelTwoID)
    dispatch({ type: SAVE_LEVEL_THREES, payload: levelThrees })
}

export const addToPath = (path) => async dispatch => {
    dispatch({ type: ADD_TO_PATH, payload: path })
}

export const saveSelectedProduct = (product) => async dispatch => {
  //save complimantary producs
    if(product != null && product.complementary != null) {
        let finalComplementaryArray = []
        let splitedIdsArr = product.complementary.split(';')
        await Promise.all(splitedIdsArr.map(async(item)=>{
            let res = await ApiGet(API_URL + `/product/getById/${item}`)
            if(res.length !== 0)
            finalComplementaryArray.push(res)
        }))
        dispatch({ type: SAVE_COMPLEMENTARY_PRODUCTS, payload: finalComplementaryArray })
    }
    dispatch({ type: SAVE_SELECTED_PRODUCT, payload: product })
}

export const saveSelectedLevelOneItem = (levelOneItem) => async dispatch => {
    dispatch({ type: SAVE_SELECTED_LEVEL_ONE, payload: levelOneItem })
}

export const saveSelectedLevelTwoItem = (levelTwoItem) => async dispatch => {
    dispatch({ type: SAVE_SELECTED_LEVEL_TWO, payload: levelTwoItem })
}

export const saveSelectedLevelThreeItem = (levelThreeItem) => async dispatch => {
    dispatch({ type: SAVE_SELECTED_LEVEL_THREE, payload: levelThreeItem })
}

export const saveFilters = (levelThreeId,inProductPage = false) => async dispatch => {
    if(inProductPage)
    dispatch({ type: SET_FILTERS_BOOLEAN })
    let filters = await ApiGet(API_URL + '/filter/' + levelThreeId.level_three_id)
    dispatch({ type: SAVE_FILTERS, payload: filters })
}



export const getProducts = (filtersArray) => async dispatch => {
    dispatch({ type: SET_FALSE_SAVE_PRODUCTS })
    filtersArray.map(async(item)=>{
        let res = await ApiGet(API_URL + `/product/${item.filter_id}`)
        dispatch({ type: SAVE_PRODUCTS, payload: res })
    })
}

export const getProductsLimit = (filtersArray,limit,page,type) => async dispatch => {
    dispatch({ type: SET_FALSE_SAVE_PRODUCTS })
    let counter = 0
    filtersArray.map(async(item)=>{
        let res = await ApiGet(API_URL + `/product/get?limit=${limit}&page=${page}&filter_id=${item.filter_id}&type=${type}`)
        if(res.length === 0){
            counter++
        }
        if(filtersArray.length === counter){
            dispatch({ type: STOP_PRODUCT_PAGINATION })
        }

        dispatch({ type: SAVE_PRODUCTS, payload: res })
    })
}




export const resetProductsSaveBoolean = () => dispatch => {
    dispatch({ type: SET_TRUE_SAVE_PRODUCTS })
}

export const setFilter = (filterId) => dispatch => {
    dispatch({ type: SET_FILTER, payload: filterId })
}

export const setMeasurements = (x,y,z) => dispatch => {
    dispatch({ type: SET_MEASUREMENTS, payload: {x,y,z} })
}

export const resetProducts = () => dispatch => {
    dispatch({ type: RESET_PRODUCTS })
}

export const resetCategoryState = ()=> dispatch => {
    dispatch({ type: RESET_CATEGORY_STATE })
}

export const getRecommendation = (levelOneId, type = 'all') => async dispatch => {
    let recommendationProducts = []
    let payload = await ApiGet(API_URL + `/bbBom/getAllbyLevelOneId/${levelOneId}`)
    await Promise.all(payload.map(async(item)=>{
        let product = await ApiGet(API_URL + `/product/getById/${item.pid_id}?type=${type}`)
        if(product != null){
            product.qty_formula = item.qty_formula
            if(item.level_three_id != null)
            product.level_three_id = item.level_three_id
            recommendationProducts.push(product)
        }

    }))
    
    dispatch({ type: SAVE_RECOMMENDATION, payload:recommendationProducts })
}

export const updateRecommendation = (val,id) => async dispatch => {
    dispatch({ type: UPDATE_RECOMMENDATION_QUANTITY, payload:{val,id} })
}

export const deleteRecommendationItem = (pid_id) => async dispatch => {
    dispatch({ type: DELETE_RECOMMENDATION_ITEM, payload: pid_id })
}

export const replaceRecommedationItem = (pidToReplace, newProd) => async dispatch => {
    dispatch({ type: REPLACE_RECOMMENDATION_PRODUCT, payload: {pidToReplace, newProd} })
}


export const changeSelectedProductMeasurments = (name,value) => dispatch => {
    dispatch({ type: CHANGE_SELECTED_MEASURMENTS, payload: {name,value} })
}

export const saveSearchResults = (payload) => dispatch => {
    dispatch({ type: SAVE_SEARCH_RESULTS, payload })
}

export const getMoreSearchResults = (value,limit,page) => async dispatch => {
    let payload = await ApiGet(API_URL + '/product/search?keyword=' + value + `&page=${page}&limit=${limit}`)
    if(payload.length === 0){
        dispatch({ type: STOP_MORE_SEARCH_RESULTS })
    } else {
        dispatch({ type: GET_MORE_SEARCH_RESULTS, payload })
    }
}


// most viewd

export const saveMostViewed = (payload) => dispatch => {
    dispatch({ type: SAVE_MOST_VIEWED, payload })
}