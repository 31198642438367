import React from 'react'
import {withRouter} from 'react-router-dom'
//img
import Dots from '../../../../../../images/icons/3dots.svg'
import Icon from '../../../../../../images/icons/infoicon.svg'
import Phone from '../../../../../../images/icons/phone.svg'
import RoomNumber from '../../../../../../images/prosinterface/roomnumber.svg'
import DealPrice from '../../../../../../images/prosinterface/dealprice.svg'
import Gps from '../../../../../../images/prosinterface/gps.svg'
import DateIcon from '../../../../../../images/prosinterface/date.svg'
import Status from '../../../../../../images/prosinterface/status.svg'
import Meeting from '../../../../../../images/prosinterface/meetings.svg'
import Updated from '../../../../../../images/prosinterface/updatedbid.svg'
import Calender from '../../../../../../images/prosinterface/calender.svg'

const Bid = (props) => {

    const { kind,initMenu,bidCartId,dataObject,price } = props
    let showUpdatedDate = new Date(dataObject.updated_by_user_last_date)
    showUpdatedDate = showUpdatedDate.getTime() < new Date().getTime() + (1 * 24 * 60 * 60 * 1000)
   
    return (
        <div className="pros-interface__bid">
            <button onClick={()=>{initMenu(bidCartId)}}>
                <img src={Dots} alt="status-change"/>
            </button>

            { showUpdatedDate ? 
                <aside>
                    <img src={Updated} alt="updated"/>
                    <span>{dataObject.updated_by_user_counter}</span>
                </aside>
                :
                null
            }

            

            <div>
                <h1>{dataObject.full_name}</h1>

                {kind === 0 ?
                null:kind === 1 ?
                null:kind === 2 ?
                <h2>הוגשה הצעה ללקוח</h2>:kind === 3 ?
                <h2>עסקה נסגרה!</h2>:kind === 4 ?
                <h2>עסקה בוטלה</h2>
                :null}
                

                {kind === 0 || kind === 1 ?
                    <span>
                        <img src={RoomNumber} alt="room-number"/>
                        <p>מספר חדרים:</p>
                        <p>{dataObject.rooms.length}</p>
                    </span>
                    :
                    null
                } 
                {kind === 0 || kind === 1 || kind === 3 ?
                    <span>
                        <img src={DealPrice} alt="deal-price"/>
                        <p>גובה עיסקה:</p>
                        <p>₪{Number(price).toLocaleString(undefined, {maximumFractionDigits:2})}</p>
                    </span>
                    :
                    null
                } 

                <span>
                    <img src={Calender} alt="sent-in"/>
                    <p>תאריך פניה:</p>
                    <p>{new Date(dataObject.created_at).toLocaleDateString('he-IS').replace(/\./g, '/')}</p>
                </span>

                {kind === 1 || kind === 3 ?
                    <aside className="pros-interface__bid__meetings">
                        {dataObject.meetings.length > 0 ? 
                            <span>
                                <img src={Meeting} alt=""/>
                                <p>פגישות:</p>
                            </span>
                            :
                            null
                        }
                        {dataObject.meetings.length > 0 && dataObject.meetings.map(meeting =>{
                            let parsedDate = new Date(meeting.meet_at).toLocaleDateString('he-IS')
                            parsedDate = parsedDate.replace(/\./g,'/')
                            parsedDate = parsedDate + ' בשעה ' + new Date(meeting.meet_at).getHours() + ':' + new Date(meeting.meet_at).getMinutes()
                            let addZero = new Date(meeting.meet_at).getMinutes()
                            if(String(addZero).length === 1)
                            parsedDate = parsedDate + '0'
                            return  <div>
                                        <span>
                                            <img src={Gps} alt="address"/>
                                            <p>כתובת:</p>
                                            <p>{meeting.address ? meeting.address:'טרם נקבע'}</p>
                                        </span>
                                        <span>
                                            <img src={DateIcon} alt="meeting-date"/>
                                            <p>תאריך פגישה:</p>
                                            <p>{parsedDate}</p>
                                        </span>
                                    </div>
                        })}

                    </aside>
                    :
                    null
                } 
                {kind === 2 ?
                    <span>
                        <img src={DateIcon} alt="meeting-date"/>
                        {/* <p>נשלח בתאריך:</p>
                        <p>13/09/19 בשעה 15:30</p> */}
                        <p>-</p>
                        <p>-</p>
                    </span>
                    :
                    null
                }

            </div>

            <footer>
                <button onClick={()=>{props.history.push(`/pros/interface/${props.match.params.proId}/customers/customerCartId/${dataObject.cart_id}/customerUserId/${dataObject.user_id}`)}}> <p>כתב כמויות</p> </button>
                <button> <a href={"tel:" + dataObject.phone}>{dataObject.phone}</a><img src={Phone} alt="phone"/> </button>
            </footer>
        </div>
    );
};

export default withRouter(Bid);