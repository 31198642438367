import {
    SAVE_PROS_TYPE,
    SAVE_PROS_AREA,
    SET_SELECTED_PRO,
    RESET_PROS_LIST,
    SAVE_REVIEWS,
    ADD_PROS
} from '../actions/types'

const initialState = {
    prosArea: {},
    prosType: {},
    pros: [],
    selectedPro: {},
    prosLimit: 10,
    prosPage: 0,
    reviews: []
}

export default function(state = initialState, action) {

    switch(action.type) {
        case(SAVE_PROS_TYPE) : {
            return { ...state, prosType:action.payload }
        }
        case(SAVE_REVIEWS) : {
            return { ...state, reviews:action.payload }
        }
        case(RESET_PROS_LIST) : {
            return { ...state, pros:[] }
        }
        case(SAVE_PROS_AREA) : {
            return { ...state, prosArea:action.payload }
        }
        case(SET_SELECTED_PRO) : {
            return { ...state, selectedPro:action.payload }
        }
        case(ADD_PROS) : {
            let newProsState = state.pros.map(item=>item)

            if(newProsState.length > 0) {
                newProsState = newProsState.concat(action.payload.pros)
            } else {
                newProsState = action.payload.pros.map(item=>item)
            }
           
            return { ...state, pros: newProsState, prosLimit:action.payload.limit, prosPage: action.payload.page }
        }
        default: 
            return state
    }
}