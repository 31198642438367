import React, { Component } from 'react'
import Filter from '../../../../../globals/Filter/Filter'
import { withRouter } from 'react-router-dom'
import {ApiGetWithHeader,ApiPostWithHeaderToken,calculateTotalBidValue} from '../../../../../../tools/tools'
import {API_URL,USER_TOKEN} from '../../../../../../configs/configs'
import Cookies from 'js-cookie'
//comp
import Bid from './Bid'
//img
import Plus from '../../../../../../images/prosinterface/whiteplus.svg'


const lang = {
    filter:'סטטוס'
}

const filterParams = [
    {name:'פניה חדשה'},
    {name:'נקבעה פגישה'},
    {name:'הוגשה הצעה'},
    {name:'עסקה נסגרה'},
    {name:'בוטל'},
]


class Customers extends Component {

    constructor(props) {
        super(props)

        this.state = {
            activeFilter:
            props.match.params.activeFilter === '0' ? 0 :
            props.match.params.activeFilter === '1' ? 1 :
            props.match.params.activeFilter === '2' ? 2 :
            props.match.params.activeFilter === '3' ? 3 :
            props.match.params.activeFilter === '4' ? 4 :0,

            page:0,
            bids: [],
            stopFetchData:false,
            currentCartId:null,
            menuOpen:false,
            cartId: ''
        }
    }

    handleClickOutside = (e) => {
        if(e.target.className.includes('curtain')){
            this.props.disActivateCurtain()
            this.setState({menuOpen:false})
        }
    }


    async componentDidMount() {
       document.addEventListener('click', this.handleClickOutside);
       document.addEventListener('scroll', this.trackScrolling);

       let token =  Cookies.get(USER_TOKEN)
       const {page,activeFilter} = this.state
       let bids = await ApiGetWithHeader(API_URL + `/prointerface/getoffers/${page}/10/${activeFilter}`,token)
       this.setState({bids})
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
        document.removeEventListener('scroll', this.trackScrolling);
        if(document.querySelector(".curtain__ace"))
        document.querySelector(".curtain__ace").style.display = 'unset'
    }

    setFilter = (activeFilter) => {
        let proId  = this.props.match.params.proId
        let token =  Cookies.get(USER_TOKEN)
        const {page} = this.state

        this.setState({activeFilter,page:0,stopFetchData:false,bids:[]},async()=>{
            this.props.history.push(`/pros/interface/${proId}/customers/${activeFilter}`)
            let bids = await ApiGetWithHeader(API_URL + `/prointerface/getoffers/0/10/${activeFilter}`,token)
            this.setState({bids})
        })
    }

    trackScrolling = async() => {
        let docHeight = document.documentElement.getBoundingClientRect().height
        let windowHeight = window.innerHeight
        let scrollTop = window.scrollY
        let token =  Cookies.get(USER_TOKEN)
        const {page,activeFilter} = this.state

        let bidStateCopy = this.state.bids.map(item=>item)

        if(docHeight - windowHeight - 10 <= scrollTop) {
            
            document.removeEventListener('scroll', this.trackScrolling);

            if(!this.state.stopFetchData) {
                let newBids = await ApiGetWithHeader(API_URL + `/prointerface/getoffers/${page + 10}/10/${activeFilter}`,token)

                if(newBids.length > 0) {
                    newBids.map(item=>{
                        bidStateCopy.push(item)
                    })
                    
                    this.setState(prevState => ({
                        page: prevState.page + 10,
                        bids: bidStateCopy
                    }))
                } else {
                    this.setState({stopFetchData:true})
                }
            }
            document.addEventListener('scroll', this.trackScrolling);
        }
    }

    initMenu = (currentCartId) => {
        if(document.querySelector(".curtain__ace"))
        document.querySelector(".curtain__ace").style.display = 'none'
        this.props.activateCurtain()
        this.setState({cartId:currentCartId, menuOpen:true})
    }

    changeStatus = async(status) => {
        const {cartId} = this.state
        let body = {status,cart_id:cartId}
        let token  = Cookies.get(USER_TOKEN)
        let responseFromStatusChange = await ApiPostWithHeaderToken(API_URL + '/cart/changestatus',token,body)
        if(responseFromStatusChange.ok){
            const {page,activeFilter} = this.state
            let bids = await ApiGetWithHeader(API_URL + `/prointerface/getoffers/${page}/10/${activeFilter}`,token)
            this.setState({bids,menuOpen:false})
            this.props.disActivateCurtain()
        } else {
            console.log('error has been occured')
        }
    }

    addCustomer = () => {
        const {proId} = this.props.match.params
        this.props.history.push(`/pros/interface/${proId}/customers/newCustomer`)
    }

    render() {
        const {activeFilter, bids, menuOpen} = this.state
        
        return (
            <div className="pros-interface__customer-list">
                
                <div data-menu="1" ref={this.setWrapperRef} className={menuOpen ? "customdropdown__menu--active":"customdropdown__menu"}>
                    <h3 data-menu="1">{'בחר סטטוס ללקוח'}</h3>
                    {filterParams.map((item,index)=>{
                        return <div data-menu="1" className="customdropdown__menu--item" onClick={()=>{this.changeStatus(index)}}><p data-menu="1">{item.name}</p></div>
                    })}
                </div>
                
                <Filter lang={lang} filterParams={filterParams} activeFilterIndex={activeFilter} customCallBack={this.setFilter}/>

                {bids && bids.map( (bid,index) =>{
                    let price = calculateTotalBidValue(bid.rooms)
                    price = price.toFixed(2)
                    return <Bid dataObject={bid} price={price} kind={activeFilter} bidCartId={bid.cart_id} initMenu={this.initMenu}/>
                })}

                <div className="pros-interface__add-customer" onClick={()=>{this.addCustomer()}}><img src={Plus} alt="add-customer"></img></div>
            </div>
        );
    }
}

export default withRouter(Customers);