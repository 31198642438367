import React, { Component } from 'react'
import '../productpage.css'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {withRouter} from 'react-router-dom'
import Cookies from 'js-cookie'
//img
import ArrowLeft from '../../../../images/icons/whiteredleft.svg'
import ArrowLeftReg from '../../../../images/original/2.svg'
import ArrowLeftMako from '../../../../images/mako/sliderl.svg'
import DesktopRedArrow from '../../../../images/icons/desktopsliderace.svg'
import DesktopBlueArrow from '../../../../images/icons/mako/2.svg'
//config
import { IMAGE_URL,HOST_NAME } from '../../../../configs/configs'
//components
import ImageComponent from '../../../globals/ImageComponent/ImageComponent'
//tools
import {calculatePrices} from '../../../../tools/tools'

class ProductSlider extends Component {

    constructor(){
        super()
        this.state = {
            typeOfMechiron: '',
        }
    }

    
    componentWillMount() {
        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})
    }
    

    onSliderInit = () => {
        // fix bug that moves the slider to the end on init
        if(this.slider && this.slider !=null && ((window.innerWidth > 1249 && this.state.typeOfMechiron === 'ace') || (window.innerWidth > 979 && this.state.typeOfMechiron === 'mako')) ){
            setTimeout(()=>{ this.slider.slickGoTo(this.props.products.length - 4,false) }, 0);
        } else if(this.slider && this.slider !=null) {
            setTimeout(()=>{ this.slider.slickGoTo(this.props.products.length - 3.5,false) }, 0);
        }
     }

    componentWillReceiveProps() {
        if(this.slider && this.slider !=null){
            setTimeout(()=>{ 
                if(this.slider && this.slider !=null && ((window.innerWidth > 1249 && this.state.typeOfMechiron === 'ace') || (window.innerWidth > 979 && this.state.typeOfMechiron === 'mako')) ) {
                    this.slider.slickGoTo(this.props.products.length - 4,false)
                } else if(this.slider && this.slider !=null) {
                    this.slider.slickGoTo(this.props.products.length - 3.5,false)
                }
            }, 0); 
        }
    }
    

    componentWillUnmount(){
        this.slider = null
    }



    preventScroll = () => {
        console.log('modown')
        document.ontouchmove = function (e) {
            e.preventDefault();
        }
    }

    enableScroll = () => {
        console.log('moupp')
        document.ontouchmove = function (e) {
            return
        }
    }

    render() {
       

        const {levelOneId,levelTwoId,levelThreeId} = this.props.match.params
        const {selectedLevelOne,desktop,idType} = this.props
        const {typeOfMechiron} = this.state
        let lvl1Copy = {...selectedLevelOne}
        if(lvl1Copy == null) {
            lvl1Copy = {}
            lvl1Copy.x = 100
            lvl1Copy.y = 100
            lvl1Copy.z = 100
        }
        
        let settings = {
            infinite: false,
            rtl: true,
            arrows: false,
            speed: 500,
            slidesToShow: (window.innerWidth > 1249 && this.state.typeOfMechiron === 'ace') || (window.innerWidth > 979 && this.state.typeOfMechiron === 'mako') ? 4:3.5,
            slidesToScroll: (window.innerWidth > 1249 && this.state.typeOfMechiron === 'ace') || (window.innerWidth > 979 && this.state.typeOfMechiron === 'mako') ? 4:3.5,
            initialSlide:1,
            draggable:false
        };

        return (
            <div className={this.props.products.length < 4 ? "product-slider product-slider--float":"product-slider"} style={{display:this.props.display ? '':'none'}}>
                <Slider {...settings} ref={c => (this.slider = c)} onInit={()=>{this.onSliderInit()}} >
                    {this.props.products.map(item=>{
                        let fixedPrice = calculatePrices(item,'work',{x:lvl1Copy.x,y:lvl1Copy.y,z:lvl1Copy.z}) + calculatePrices(item,'product',{x:lvl1Copy.x,y:lvl1Copy.y,z:lvl1Copy.z})
                        fixedPrice = fixedPrice * 1.17
                        
                        return <div id={idType? idType:null}  key={item.pid_id} className="product-slider__item" onClick={()=>{this.props.history.push(`/products/${levelOneId}/${levelTwoId}/${levelThreeId}/${item.pid_id}`)}} onTouchStart={()=>{this.preventScroll()}} onTouchEnd={()=>{this.enableScroll()}} >
                                   <ImageComponent src={IMAGE_URL + '/ace/' + item.picture_id + '.png'} alt="product"/>
                                   <h1 id={idType? idType:null}>{item.name}</h1>
                                   <h5 id={idType? idType:null}>₪{parseFloat(fixedPrice.toFixed(2)).toLocaleString()}</h5>
                                   <h6 id={idType? idType:null}>₪{parseFloat((fixedPrice * 1.07).toFixed(2)).toLocaleString()}</h6>
                               </div>
                    })}
                </Slider>
                { (window.innerWidth > 1249 && typeOfMechiron === 'ace') || (window.innerWidth > 979 && this.state.typeOfMechiron === 'mako')  ? 
                    <img style={this.props.products.length <= 4 ? {display:'none'}:{left:'-14px'}} src={this.state.typeOfMechiron === 'mako' ? DesktopBlueArrow:DesktopRedArrow} alt="arrow" onClick={() => { this.slider.slickPrev() }}/>
                    :
                    <img style={this.props.products.length <= 4 ? {display:'none'}:{left:'-10px'}} src={typeOfMechiron === 'ace' ? ArrowLeft: typeOfMechiron === 'mako' ? ArrowLeftMako:ArrowLeftReg} alt="arrow" onClick={() => { this.slider.slickPrev() }}/>
                }
            </div>
        );
    }
}

export default withRouter(ProductSlider);