import React, { Component } from 'react'
import './category.css'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
//components
import InnerNavigation from '../../globals/InnerNavigation/InnerNavigation'
import Filter from '../../globals/Filter/Filter'
import Loader from '../../globals/Loader'
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
//images
import AddIcon from '../../../images/icons/addicon.svg'
//configs
import {IMAGE_URL,API_URL} from '../../../configs/configs'
//tools
import {ApiGet,calculatePrices} from '../../../tools/tools'

//work pic
import WorkPic from '../../../images/worke.png'
import Eqpic from '../../../images/equipment.png'

class SearchResults extends Component {
    constructor() {
        super()

        this.state = {
            testFilter: false,
            loader:false,
            limit:50,
            page:0
        }
    }


    async componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling);
        //get initial search from query on page load
        const {typeOfMechiron} = this.props
        let query = window.location.search.replace('?q=','')
        let result = await ApiGet(API_URL + '/product/search?keyword=' + query + `&page=${0}&limit=${50}&type=${typeOfMechiron}`)
        this.props.saveSearchResults(result)
        this.props.history.push(`/searchResults?q=${query}`)
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }
    

    selectProduct = async(product) => {
        console.log(product)
        this.props.saveSelectedProduct(product)
        let query = window.location.search.replace('?q=','')
        let res = await ApiGet(API_URL + '/filter/getById/' + product.filter_id)
        let lvl3 = res.level_three_id
        lvl3 = lvl3.split(';')
        lvl3 = lvl3[0]
        this.props.history.push(`/products/all/${product.level_two_id}/${lvl3}/${product.pid_id}/?q=${query}`)
    }

 
    trackScrolling = () => {
        if(this.props.levels.stopMoreSearchResults)return

        if(document.documentElement.scrollHeight !== window.innerHeight) {
            
            let docHeight = document.documentElement.getBoundingClientRect().height
            let windowHeight = window.innerHeight
            let scrollTop = window.scrollY
            if(docHeight - windowHeight - 0 <= scrollTop + 1) {
                this.setState(prevState => ({
                    page: prevState.page + 1,
                    loader:true
                }),async()=>{
                    let query = window.location.search.replace('?q=','')
                    if(!this.props.levels.stopMoreSearchResults)
                    await this.props.getMoreSearchResults(query,this.state.limit,this.state.page * this.state.limit)
                })
            }
        }

    }



    render() {
        const { lang } = this.props
        const { searchResults,vendors } = this.props.levels

        let productArray = searchResults != null ? searchResults.map((item)=>{
            return item
        }):[]


        let lvl1Copy = {}
       
        lvl1Copy.x = 100
        lvl1Copy.y = 100
        lvl1Copy.z = 100
        

        return (
            <div className="category">
                <section className="category__first-section">
                    <InnerNavigation 
                        pages={['תוצאות חיפוש']}
                        links={['/searchResults']}
                    />

                    <h1>תוצאות חיפוש</h1>

                </section>

               
                {searchResults == null || searchResults.length === 0? 
                    <div className="category__loaders">
                        <div className="category__loaders__item">
                            <figure></figure>
                            <aside></aside> <aside></aside> <aside></aside> <aside></aside> <aside></aside>
                        </div>
                        <div className="category__loaders__item">
                            <figure></figure>
                            <aside></aside> <aside></aside> <aside></aside> <aside></aside> <aside></aside>
                        </div>
                        <div className="category__loaders__item">
                            <figure></figure>
                            <aside></aside> <aside></aside> <aside></aside> <aside></aside> <aside></aside>
                        </div>
                        <div className="category__loaders__item">
                            <figure></figure>
                            <aside></aside> <aside></aside> <aside></aside> <aside></aside> <aside></aside>
                        </div>
                    </div>  
                    :
                    null
                }



                <div className="category__items__container">

                    {productArray.map(product=>{

                        let productCopy = {...product}

                        let productVendor = {name:''}
                        vendors && vendors.length > 0 && vendors.map(vendor=>{
                            if(vendor.vendor_id === productCopy.supplier_id){
                                productVendor = vendor
                            }
                        })

                        let total = calculatePrices(productCopy,'product',{x:lvl1Copy.x,y:lvl1Copy.y,z:lvl1Copy.z}) + calculatePrices(productCopy,'work',{x:lvl1Copy.x,y:lvl1Copy.y,z:lvl1Copy.z})
                        total = total * 1.17
                        total = parseFloat(Math.round(total * 100) / 100);

                        let stringName = ''
                
                        if(stringName.charAt(stringName.length -1) === 'X')
                        stringName = stringName.substr(0,stringName.length -1)

                        let showMeasurementsInName = true

                        if(productCopy.length === 'X' || productCopy.width === 'Y' || productCopy.height === 'Z'){
                            showMeasurementsInName = false
                        }

                        if(productCopy.height === 'Z' && lvl1Copy !=null && lvl1Copy.z!=null){
                            productCopy.height = lvl1Copy.z.toString()
                        }
                        if(productCopy.width === 'Y' && lvl1Copy !=null && lvl1Copy.y!=null){
                            productCopy.width = lvl1Copy.y.toString()
                        }
                        if(productCopy.length === 'X' && lvl1Copy !=null && lvl1Copy.x!=null){
                            productCopy.length = lvl1Copy.x.toString()
                        }


                        //build name
                        if(productCopy != null){
                            stringName = productCopy.name + ' '
                        }
                        //

                        //init measurements in name
                        if(productCopy != null && productCopy.width !== 'N' && productCopy.width != null && showMeasurementsInName){
                            stringName += productCopy.width + 'X'
                        }
                
                
                        if(productCopy != null && productCopy.height !== 'N' && productCopy.height != null && showMeasurementsInName){
                            stringName += productCopy.height + 'X'
                        }
                
                
                        if(productCopy != null && productCopy.length !== 'N' && productCopy.length != null && showMeasurementsInName){
                            stringName += productCopy.length
                        } 

                        
                        if(!this.props.desktop) {
                            if(stringName.length > 47){
                                stringName = stringName.substr(0,44) + '...'
                            }
                        } else {
                            if(stringName.length > 67){
                                stringName = stringName.substr(0,64) + '...'
                            }
                        }


                        return <div className="category__item" key={productCopy.pid_id} onClick={()=>{this.selectProduct(product)}}>
                                    <section>
                                        <ImageComponent
                                         src={
                                            productCopy.picture_id === 'work' ? 
                                            WorkPic : productCopy.picture_id === 'equipment' ?
                                            Eqpic : `${IMAGE_URL}/ace/${productCopy.picture_id}.png`
                                        }
                                         alt="product" 
                                         />
                                    </section>

                                    <section>
                                        <h3>{stringName}</h3>
                                        <h4>{productCopy.description.length < 85 ? productCopy.description:productCopy.description.substr(0,82) + '...'}</h4>
                                        <p>{lang.manifacturer + productVendor.name}</p>
                                        <span>
                                            <h5>מחיר ל-{productCopy.units}</h5>
                                            <h5>₪{parseFloat(total.toFixed(2)).toLocaleString()}</h5>
                                        </span>

                                        <h6></h6>
                                        <section>
                                            <button className="button__red">
                                                <img src={AddIcon} alt="add" />
                                                <span>
                                                    {lang.addButton}
                                                </span>
                                            </button>
                                        </section>

                                    </section>
                                </div>
                    })}

                </div>
                {!this.props.levels.stopMoreSearchResults && this.state.loader ? 
                    <div className="category__loader__bottom"><Loader/></div>
                    :
                    null
                }
                
            </div>
        );
    }
}

function stateToProps({ levels,userCart }) {
    return { levels,userCart }
}
  
export default withRouter(connect(stateToProps, actions)(SearchResults))
