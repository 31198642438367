import React, { Component } from 'react'
import './makomenudesktop.css'
import {withRouter} from 'react-router-dom'
import Cookies from 'js-cookie'
//img
import Maglass from '../../../images/mako/18.svg'
import Cart from '../../../images/icons/navpro.svg'
//config
import {API_URL, USER_TOKEN,HOST_NAME} from '../../../configs/configs'

class MakoMenuDesktop extends Component {

    constructor(){
        super()

        this.state = {
            activeTab:-1
        }
    }

    goTo = (link) =>{

        // if( (!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null) && (link !== '/') && (!link.includes('contact'))) {
        //     this.props.activateCurtain()
        //     this.props.activateLoginPopup(link)
        //     return
        // }

        if(link !== '/cart/token'){
            this.props.history.push(link)
        } else {
            if(this.props.userCart != null){

                let indexToGoTo = -1
                let level_one_id
                this.props.userCart.map( (room,index) => {
                    if(room.products.length > 0 && indexToGoTo === -1){
                        indexToGoTo = index
                        level_one_id = room.level_one_id
                    }
                })
                if(level_one_id == null){
                    this.props.history.push('/cart/token')
                } else {
                    this.props.history.push(`/cart/${indexToGoTo}/leveloneid/${level_one_id}/token`)
                }
            } else {
                this.props.history.push('/cart/token')
            }
        }
    }

    handleAuth = () => {
        if(!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null) {
            this.props.activateCurtain()
            this.props.activateLoginPopup('/choice')
        } else {
            Cookies.remove(USER_TOKEN)
            this.props.history.push(`/`)
            this.props.resetCart()
        }

    }


    componentDidMount() {
        this.handleActiveTab()
    }
    

    componentDidUpdate(prevProps) {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            this.handleActiveTab()
        }
    }

    handleActiveTab = () => {
        if(this.props.location.pathname === '/'){
            this.setState({activeTab:1})
        } else if(this.props.location.pathname.includes('aces')){
            this.setState({activeTab:2})
        } else if(this.props.location.pathname === '/choice'){
            this.setState({activeTab:3})
        } else if(this.props.location.pathname.includes('contact')){
            this.setState({activeTab:4})
        } else if(this.props.location.pathname.includes('login')){
            this.setState({activeTab:5})
        } else if(this.props.location.pathname.includes('allchapters')){
            this.setState({activeTab:6})
        } 
    }

    render() {
        const {totalProductsInCart} = this.props
        const {activeTab} = this.state

        return (
            <nav className="mako-menu-desktop">
                <section>
                    <h1>המחירון</h1>
                    <div onClick={()=>{this.goTo('/cart/token')}} id="UC: Went To Cart">
                        <img id="UC: Went To Cart" src={Cart} alt="cart"/>
                        {Cookies.get(USER_TOKEN) != null ? <span id="UC: Went To Cart">{totalProductsInCart}</span>:null}
                    </div>
                </section>



                <section>
                    <div>
                       {Cookies.get(USER_TOKEN) == null ? <span onClick={()=>{this.goTo('/')}}><p className={activeTab === 1 ? 'mako-menu-desktop__active-tab':''}>ראשי</p></span>:null} 
                        <span id="UC: Clicked on pros in menu" onClick={()=>{this.goTo('/aces/cart')}}><p id="UC: Clicked on pros in menu" className={activeTab === 2 ? 'mako-menu-desktop__active-tab':''}>אנשי המקצוע שלנו</p></span>
                        <span onClick={()=>{this.goTo('/choice')}}><p className={activeTab === 3 ? 'mako-menu-desktop__active-tab':''}>חדרים</p></span>
                        <span id="UC: Enter Contact Us Page" onClick={()=>{this.goTo('/contact/-1')}}><p id="UC: Enter Contact Us Page" className={activeTab === 4 ? 'mako-menu-desktop__active-tab':''}>צרו קשר</p></span>
                        <span onClick={()=>{this.handleAuth()}}><p className={activeTab === 5 ? 'mako-menu-desktop__active-tab':''}>{!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null ? 'התחבר':'התנתק'}</p></span>
                        <span onClick={()=>{this.goTo('/allchapters')}}><p className={activeTab === 6 ? 'mako-menu-desktop__active-tab':''}>פרקי המחירון</p></span>
                    </div>
                    <div onClick={()=>{this.goTo('/search')}}>
                        <img src={Maglass} alt=""/>
                        <p>חפש במחירון</p>
                    </div>
                </section>   

                <section>
                    
                    </section>
            </nav>
        );
    }
}

export default withRouter(MakoMenuDesktop);