import React, { Component } from 'react'
import Cookies from 'js-cookie'
import { withRouter } from 'react-router-dom'
import {BUILD_CART_FOR_USER_AS_PRO, API_URL,IMAGE_URL_LEVELS,IMAGE_URL,USER_TOKEN} from '../../../../../../configs/configs'
import {ApiGet,calculatePrices,ApiGetWithHeader,calculateTotalBidValue} from '../../../../../../tools/tools'
import { connect } from 'react-redux'
import * as actions from '../../../../../../actions/index'

//comps
import ImageComponent from '../../../../../globals/ImageComponent/ImageComponent'
import QuantityInput from '../../../../../globals/QuantityInput/QuantityInput'
//img
import img1 from '../../../../../../images/prosinterface/roomnumber.svg'
import img2 from '../../../../../../images/prosinterface/dealprice.svg'
import img3 from '../../../../../../images/prosinterface/gps.svg'
import img4 from '../../../../../../images/prosinterface/meetings.svg'
import WhitePhone from '../../../../../../images/icons/phone.svg'
import GeneralRoom from '../../../../../../images/rooms/general.png'
import Gps from '../../../../../../images/prosinterface/gps.svg'
import DateIcon from '../../../../../../images/prosinterface/date.svg'
//work pic
import WorkPic from '../../../../../../images/worke.png'
import Eqpic from '../../../../../../images/equipment.png'

class ExistCustomer extends Component {

    constructor(props){
        super(props)

        this.state = {
            currentUserCart: [],
            userMeetings: [],
            userInfo: [],
            level_one_id: props.match.params.levelOneId ? props.match.params.levelOneId:false
        }
    }

    async componentWillMount(){
        let token  = Cookies.get(USER_TOKEN)

        let currentUserCart = await ApiGetWithHeader(API_URL + `/cart/getbyid?cart_id=${this.props.match.params.customerCartId}`,token)
        let userMeetings = await ApiGetWithHeader(API_URL + `/prointerface/meeting/getAllByUserId/${this.props.match.params.customerUserId}`,token)
        let userInfo = await ApiGetWithHeader(API_URL + `/prointerface/getById/${this.props.match.params.customerUserId}`,token) 
        userInfo = userInfo[0]
        
        this.setState({currentUserCart,userMeetings,userInfo})
    
    }


    componentDidUpdate(prevProps, prevState) {
        if(this.props.location.pathname !== prevProps.location.pathname){
            if(!this.props.match.params.levelOneId || this.props.match.params.levelOneId == null) {
                this.setState({level_one_id:false})
            }
        }
    }
    
    

    addRoom = () => {
        // show back to interface button
        this.props.showBackToInterfaceButton(window.location.pathname)
        let cartId = this.props.match.params.customerCartId
        let cart = this.state.currentUserCart
        this.props.setUserCartToEditAsPro(cartId,cart)
        this.props.history.push('/choice')
    }

    addItem = () => {
        // show back to interface button
        this.props.showBackToInterfaceButton(window.location.pathname)
        let cartId = this.props.match.params.customerCartId
        let cart = this.state.currentUserCart
        let levelOneId = this.props.match.params.levelOneId
        this.props.setUserCartToEditAsPro(cartId,cart)
        this.props.history.push(`/calculator/${levelOneId}/room`)
    }

    setAppointment = () => {
        const {customerUserId, customerCartId, proId} = this.props.match.params
        this.props.history.push(`/pros/interface/${proId}/schedule/appointment/customerUserId/${customerUserId}/customerCartId/${customerCartId}/meetingId/new`)
    }

    goToRoomView = (level_one_id) => {
        this.setState({level_one_id},()=>{
            const {proId, customerCartId,customerUserId} = this.props.match.params
            const {level_one_id} = this.state
            this.props.history.push(`/pros/interface/${proId}/customers/customerCartId/${customerCartId}/customerUserId/${customerUserId}/levelOneId/${level_one_id}`)
        })

    }

    editProd = async(item) => {
        // show back to interface button
        this.props.showBackToInterfaceButton(window.location.pathname)
        //set current user to edit as pro cart configs 
        let cartId = this.props.match.params.customerCartId
        let cart = this.state.currentUserCart
        this.props.setUserCartToEditAsPro(cartId,cart)

        let lvl3 = ''
        if(item.level_three_id != null && item.level_three_id){
            lvl3 = item.level_three_id
        } else {
            let res = await ApiGet(API_URL + '/filter/getById/' + item.filter_id)
            lvl3 = res.level_three_id
            lvl3 = lvl3.substring(0,lvl3.indexOf(','))
        }

        await this.props.saveSelectedProduct(item)
        this.props.history.push(`/editproductinusercart/${this.props.match.params.levelOneId}/${item.level_two_id}/${lvl3}/${item.pid_id}`)
    }

    handleChangeItemRecommendation = async(item) => {
        // show back to interface button
        this.props.showBackToInterfaceButton(window.location.pathname)
        //set current user to edit as pro cart configs 
        let cartId = this.props.match.params.customerCartId
        let cart = this.state.currentUserCart
        this.props.setUserCartToEditAsPro(cartId,cart)


        let filter = await ApiGet(API_URL + '/filter/getById/' + item.filter_id)
        let finalLevel3ToSend
        if(item.level_three_id != null) {
           finalLevel3ToSend = item.level_three_id 
        } else {
            finalLevel3ToSend = filter.level_three_id
        }
        let lvl3 = await ApiGet(API_URL + '/levelThree/getById/' + finalLevel3ToSend)
        //save the url for redirect user back to pro interface after item replacement
        this.props.saveUrlToRedirectToProsInterface(window.location.pathname)
        this.props.history.push(`/cart/edit/roomindex/interface/edit/${item.pid_id}/${this.props.match.params.levelOneId}/${lvl3.level_two_id}/${finalLevel3ToSend}/filter/${item.filter_id}`)
    }

    removeItem = async(item) => {
        this.props.removeRoomsWithoutProducts()
         
        await new Promise(r => this.setState({timeout:setTimeout(r, 100)}))
        //set current user to edit as pro cart configs 
        let cartId = this.props.match.params.customerCartId
        let cart = this.state.currentUserCart
        this.props.setUserCartToEditAsPro(cartId,cart)

        let lv1 = this.props.match.params.levelOneId
        let roomIndexInCart = this.props.userCart.userCart.findIndex(room => room.level_one_id === lv1)

        this.props.removeCartItem(roomIndexInCart,item)
    }

    convertStatusNumberToName = (status) => {
        switch(status){
            case 0 :
                return 'פנייה חדשה'
            case 1 :
                return 'נקבעה פגישה'
            case 2 :
                return 'הוגשה הצעה'
            case 3 :
                return 'עסקה נסגרה'
            case 4 :
                return 'בוטל'
        }
    }

    changeQuantity = (value,item) => {

        let cartId = this.props.match.params.customerCartId
        let cart = this.state.currentUserCart
        this.props.setUserCartToEditAsPro(cartId,cart)

        item.qty_formula = parseFloat(value).toFixed(1)
        this.props.updateQuantityOfItem(this.props.match.params.indexInCart,item)

    }

    render() {
        const {currentUserCart, level_one_id, userMeetings, userInfo} = this.state
        let roomLength = currentUserCart.filter(room => {
            return room.products.length > 0  ? true:false
        }).length

        let productsLength = currentUserCart.map( room => {
            return room.products.length > 0 ? room.products.length:0
        }).reduce((total, item)=> {return total+item},0)

        let filteredRooms = currentUserCart.filter(room => {
            return room.products.length > 0
        })


        // let currentRoomIndex = currentUserCart.findIndex((item)=>{return item.level_one_id === this.props.match.params.levelOneId})
        let currentRoomIndex = 0
        currentUserCart.map((item,index)=>{
            if(item.level_one_id === this.props.match.params.levelOneId){
                currentRoomIndex = index
            }
        })
        
        return (
            <div className="pros-interface__existed-customer">
                <a href={"tel:" + userInfo.phone}>
                    <img src={WhitePhone} alt="phone"/>
                </a>

                <section>
                    <h1>{userInfo.full_name}</h1>
                    <p>סטטוס: {this.convertStatusNumberToName(userInfo.status)}</p>
                    <span>
                        <img src={img1} alt="room-number"/>
                        <p>מספר חדרים:</p>
                        <p>{roomLength}</p>
                    </span>
                    <span>
                        <img src={img2} alt="payment-amount"/>
                        <p>גובה עיסקה:</p>
                        <p>{'₪' + calculateTotalBidValue(filteredRooms)}</p>
                    </span>
                    <span>
                        <img src={img3} alt="total-items"/>
                        <p>סה”כ פריטים:</p>
                        <p>{productsLength}</p>
                    </span>

                    {userMeetings.length > 0 ? 
                        <span>
                            <img src={img4} alt="address"/>
                            <p>פגישות:</p>
                        </span>
                        :
                        null
                    }

                    <aside className="pros-interface__bid__meetings">
                        {userMeetings.length > 0 && userMeetings.map(meeting =>{
                                let parsedDate = new Date(meeting.meet_at).toLocaleDateString('he-IS')
                                parsedDate = parsedDate.replace(/\./g,'/')
                                parsedDate = parsedDate + ' בשעה ' + new Date(meeting.meet_at).getHours() + ':' + new Date(meeting.meet_at).getMinutes()
                                return  <div>
                                            <span>
                                                <img src={Gps} alt="address"/>
                                                <p>כתובת:</p>
                                                <p>{meeting.address ? meeting.address:'טרם נקבע'}</p>
                                            </span>
                                            <span>
                                                <img src={DateIcon} alt="meeting-date"/>
                                                <p>תאריך פגישה:</p>
                                                <p>{parsedDate}</p>
                                            </span>
                                        </div>
                        })}
                    </aside>

                </section>

                {!level_one_id ? 
                                <section>
                                {currentUserCart.map(room => {
                                    let productNames = ''
                                    let productCounter = 0
                                    let productPrice = 0
                
                                        room.products.map((product,index)=>{
                                            if(index !== room.products.length - 1 && productNames.length < 56){
                                                productNames+= product.name + ', '
                                            } else if(productNames.length < 56) {
                                                productNames+= product.name
                                            }
                                            
                                            productCounter++
                
                                            let formula = product.qty_formula
                                            formula = formula.toString()
                                            formula = formula.replace(/x/gi, room.x);
                                            formula = formula.replace(/y/gi, room.y);
                                            formula = formula.replace(/z/gi, room.z);
                                            formula = eval(formula)
                                            let total
                                            if(product.only_work){
                                                total = formula * (calculatePrices(product,'work',{x:room.x,y:room.y,z:room.z}))
                                            } else {
                                                total = formula * (calculatePrices(product,'work',{x:room.x,y:room.y,z:room.z}) + calculatePrices(product,'product',{x:room.x,y:room.y,z:room.z}))
                                            }
                                            total = parseFloat(Math.round(total * 100) / 100)
                                            productPrice += total
                
                                        })
                                        productPrice = productPrice * 1.17
                                        if(productNames.length >= 56)
                                        productNames = productNames.substr(0,56) + '...'
                                    
            
            
                                    if(room.products.length > 0)
                                    return <div className="newcart__room" onClick={()=>{this.goToRoomView(room.level_one_id)}}>
                                                <div>
                                                    {room.level_one_id !== 'all' ? 
                                                        <ImageComponent src={IMAGE_URL_LEVELS + 'ace/' + room.level_one_id + '.png'} alt={room.name}/>
                                                        :
                                                        <ImageComponent src={GeneralRoom} alt="חדר-כללי"/>
                                                    }
                                                    
                                                </div>
                                                <div>
                                                    <h3>{room.name}</h3>
                                                    <p>{productNames}</p>
                                                    <span>
                                                        <span>
                                                            <p>פריטים:</p>
                                                            <p>{room.products.length}</p>
                                                        </span>
                                                        <span>
                                                            ₪{parseFloat(productPrice.toFixed(2)).toLocaleString()}
                                                        </span>
                                                    </span>
                                                </div>
                                           </div>
                                })}
                            </section>
                            :
                            <section>
                               
                                {
                                    
                                    currentUserCart[currentRoomIndex] != null ? currentUserCart[currentRoomIndex].products.map((item ,indexInCart)=>{
                                    let totalRoomPrice = 0
                                    let formula = item.qty_formula
                                    formula = formula.toString()
                                    formula = formula.replace(/x/gi, currentUserCart[currentRoomIndex].x);
                                    formula = formula.replace(/y/gi, currentUserCart[currentRoomIndex].y);
                                    formula = formula.replace(/z/gi, currentUserCart[currentRoomIndex].z);
                                    formula = eval(formula)


                                    let total
                                    if(item.only_work){
                                        total = formula * (calculatePrices(item,'work',{x:currentUserCart[currentRoomIndex].x,y:currentUserCart[currentRoomIndex].y,z:currentUserCart[currentRoomIndex].z}))
                                    } else {
                                        total = formula * (calculatePrices(item,'work',{x:currentUserCart[currentRoomIndex].x,y:currentUserCart[currentRoomIndex].y,z:currentUserCart[currentRoomIndex].z}) + calculatePrices(item,'product',{x:currentUserCart[currentRoomIndex].x,y:currentUserCart[currentRoomIndex].y,z:currentUserCart[currentRoomIndex].z}))
                                    }
                                    
                                    total = total * 1.17
                                    total = parseFloat(Math.round(total * 100) / 100)
                                    
                                    totalRoomPrice+= total

                                    let stringName = item.name + ' '

                                    if(true) {
                                        if(item != null && item.width !== 'N' && item.width != null ){
                                            stringName += item.width + 'X'
                                        }
                                
                                
                                        if(item != null && item.height !== 'N' && item.height != null){
                                            stringName += item.height + 'X'
                                        }
                                
                                
                                        if(item != null && item.length !== 'N' && item.length != null){
                                            stringName += item.length
                                        }
                                
                                        if(stringName.charAt(stringName.length -1) === 'X')
                                        stringName = stringName.substr(0,stringName.length -1)
                                    }
                                    return <div className="newcart__item--product">
                                                <div className="newcart__item--product--first">
                                                    <div onClick={()=>{this.editProd(item)}}>
                                                        <ImageComponent
                                                        src={
                                                            item.picture_id === 'work' ? 
                                                            WorkPic : item.picture_id === 'equipment' ?
                                                            Eqpic : `${IMAGE_URL}/ace/${item.picture_id}.png`
                                                        }
                                                        alt="product" 
                                                        />
                                                    </div>
                                                    <div>

                                                        <span>
                                                            <h1>{stringName}</h1>
                                                            <p>{item.description ? item.description:''}</p>
                                                        </span>

                                                        <div>
                                                            <span>
                                                                <p>{item.product_price === "0" || item.only_work ? 'סה״כ עבודה':'סה״כ עבודה + מוצר'}</p>
                                                                <p>₪{parseFloat(total.toFixed(2)).toLocaleString()}</p>
                                                            </span>
                                                            <QuantityInput activatedOn={item} changeQuantity={this.changeQuantity} unit={item.units} quantity={formula}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="newcart__item--product--last">
                                                    <p onClick={()=>{this.editProd(item)}}>ערוך פריט</p>
                                                    <p onClick={()=>{this.removeItem(item)}}>הסר פריט</p>
                                                    <p onClick={()=>{this.handleChangeItemRecommendation(item)}}>החלף פריט</p>
                                                </div>
                                        </div>
                                }):null}
                               
                            </section>
                }


                <footer>
                    {userInfo.status === 0 ?
                        <button onClick={()=>{this.setAppointment()}}>קבע פגישה</button>
                        :
                        userInfo.status === 1 || userInfo.status === 2 ?
                        <button onClick={()=>{}}>הגש כתב כמויות</button>
                        :
                        null
                    }
                    {!level_one_id ? 
                        <button onClick={()=>{this.addRoom()}}>הוסף חדר</button>
                        :
                        <button onClick={()=>{this.addItem()}}>הוסף סעיף</button>
                    }
                </footer>

            </div>
        )
    }
}


function stateToProps({ userCart }) {
    return { userCart }
}
  
export default withRouter(connect(stateToProps, actions)(ExistCustomer))