import React, { Component } from 'react'
import './acepropage.css'
import Cookies from 'js-cookie'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
import { withRouter } from 'react-router-dom'
///images
import Header from '../../../images/tools.png'
import Phone from '../../../images/icons/phone.svg'
import MailW from '../../../images/icons/mailwhite.svg'
import MailR from '../../../images/icons/redenv.svg'
import PhoneR from '../../../images/icons/redphone.svg'
import PhoneB from '../../../images/icons/bluephone.svg'
import MailB from '../../../images/icons/email.svg'
//tools
import {calculatePrices,stringAreaSwap,ApiPostWithBody,ApiGet} from '../../../tools/tools'
//configs
import {API_URL,IMAGE_URL,USER_TOKEN} from '../../../configs/configs'
//test
import Pro from '../../../images/pro.png'
//components
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
import InnerNavigation from '../../globals/InnerNavigation/InnerNavigation'

class AceProPage extends Component {

    constructor(){
        super()

        this.state = {
            contactMenu:false,
            reliable:0,
            proffesion:0,
            service:0,
            smsWasSent:false,
            showProData:false,
            proInfo:{},
            reviews:[]
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.handleClick)
        window.addEventListener("beforeunload", this.onUnload)
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClick)
        window.removeEventListener("beforeunload", this.onUnload)
    }

    handleClick = (e) => {
        e.stopPropagation() 
        if(!e.target.className.includes('aces__pros__button'))
        this.setState({contactMenu:false})

        if(e.target.className.includes('curtain'))
        this.props.disActivateCurtain()
    }

    onUnload = () => {
        this.props.disActivateCurtain()
    }
    

    async componentWillMount() {
        let res = await ApiGet(API_URL + `/pros/getById/${this.props.match.params.proId}`)
        console.log(res)
        let reviewsArray = await ApiGet(API_URL + `/prosReviews/getAllByProId/${this.props.match.params.proId}`)
        let professionCounter = 0
        let reliableCounter = 0
        let serviceCounter = 0
        
        reviewsArray.map(review => {
            // scoreCounteer += (review.reliable + review.profession + review.service) / 3
            professionCounter +=  review.profession
            reliableCounter += review.reliable
            serviceCounter += review.service
        })

        this.setState({
            proInfo:res,
            reviews:reviewsArray,
            reliable: Number((reliableCounter / reviewsArray.length).toFixed(1)),
            proffesion: Number((professionCounter / reviewsArray.length).toFixed(1)),
            service: Number((serviceCounter / reviewsArray.length).toFixed(1))
        })

        let prosType = this.props.pros.prosType
        if(Object.keys(prosType).length === 0 && prosType.constructor === Object) {
            this.props.saveProsType()
        }
        let prosArea = this.props.pros.prosArea
        if(Object.keys(prosArea).length === 0 && prosArea.constructor === Object) {
            this.props.saveProsArea()
        }
    }

    handleLogin = () => {
        this.props.history.push(`/loginacepage/${this.props.match.params.proId}`)
    }

    sendMailToPro = async (item) => {
        
        let body = {
            pro_id: item.pro_id,
            cart_id: this.props.userCart.cartId
        }
        if(typeof body.cart_id === 'object')
        body.cart_id = body.cart_id.cart_id
    //    let res = await  ApiPostWithBody(API_URL + '/cart/sendByEmail', body)
       ApiGet(API_URL + `/cart/sendByEmail?pro_id=${body.pro_id}&cart_id=${body.cart_id}`)  
    }

    sendSmsToPro = async (item) => {
        this.setState({smsWasSent:true})
        

        this.props.disActivateCurtain()
        let body = {
            pro_id: item.pro_id,
            cart_id: this.props.userCart.cartId
        }

        if(typeof body.cart_id === 'object')
        body.cart_id = body.cart_id.cart_id

        if(!this.state.smsWasSent){
            // ApiPostWithBody(API_URL + '/cart/sendBySMS', body)  
            ApiGet(API_URL + `/cart/sendBySMS?pro_id=${body.pro_id}&cart_id=${body.cart_id}`)  
        }
        
    }

    activateContactMenu = (e) => {
     
        let token = Cookies.get(USER_TOKEN)
        if(!token) {
            this.props.activateCurtain()
            this.props.activateLoginPopup(`/aces/type/${this.props.match.params.proId}`)
            return
        } else {
            this.sendMailToPro({pro_id:this.props.match.params.proId})
            this.sendSmsToPro({pro_id:this.props.match.params.proId})
        }
        this.setState({contactMenu:true})
        this.props.activateCurtain()
    }

    handleClickOnTalkWithMe = (e) => {
        let token = Cookies.get(USER_TOKEN)
        if(token) {
            this.sendMailToPro({pro_id:this.props.match.params.proId})
            this.sendSmsToPro({pro_id:this.props.match.params.proId})
        }
        setTimeout(() => { this.setState({showProData:true}) }, 0);
        
    }
    
    render() {
        const { lang,desktop,desktopMako } = this.props
        const { contactMenu,reliable,proffesion,service,reviews,smsWasSent,showProData,proInfo } = this.state

        let token = Cookies.get(USER_TOKEN)

        return (
            <div className="ace-pro-page">

                {desktop || desktopMako ? 
                    <InnerNavigation pages={['הרשימה שלי','אנשי המקצוע שלנו',proInfo.name]} links={['/cart/token', '/aces/cart','/']}/>
                    :
                    null
                }

                {desktop && !desktopMako ? 
                    <h1>אנשי המקצוע שלנו</h1>
                    :
                null
                }

                <header>
                    <ImageComponent src={Header} alt="header"/>
                    <ImageComponent src={IMAGE_URL + '/ace/' + proInfo.pro_id + '.png'} alt="pro"/>
                    {desktop ? 
                        <main className="ace-pro-page__desktop-main">
                            <aside>
                                <h3>{proInfo.name}</h3>
                                <span>
                                    <p style={isNaN((reliable + proffesion + service)) ? {border:'0'}:{}}> <span>{reviews.length}</span>  חוות דעת</p>
                                    <p style={isNaN((reliable + proffesion + service)) ? {display:'none'}:{}}><span>{((reliable + proffesion + service) / 3).toFixed(1)}</span> ציון כללי</p>
                                </span>
                            </aside>

                            <div>
                                <aside style={isNaN((reliable + proffesion + service)) ? {display:'none'}:{}}>
                                    <CircularProgressbar
                                        value={reliable*10}
                                        text={`${reliable}`}
                                        strokeWidth={5}
                                        counterClockwise={false}
                                        styles={{
                                            text: {
                                                fill: 'white',
                                                fontSize: '40px',
                                                fontFamily: 'f400',
                                                letterSpacing: '0.3px',
                                                lineHeight: '1.13'
                                            },
                                        }}
                                    />
                                    <p>{lang.aminut}</p>
                                </aside>
                                <aside style={isNaN((reliable + proffesion + service)) ? {display:'none'}:{}}>
                                    <CircularProgressbar
                                        value={proffesion*10}
                                        text={`${proffesion}`}
                                        strokeWidth={5}
                                        counterClockwise={false}
                                        styles={{
                                            text: {
                                                fill: 'white',
                                                fontSize: '40px',
                                                fontFamily: 'f400',
                                                letterSpacing: '0.3px',
                                                lineHeight: '1.13'
                                            },
                                        }}
                                    />
                                    <p>{lang.mikzoyut}</p>
                                </aside>
                                <aside style={isNaN((reliable + proffesion + service)) ? {display:'none'}:{}}>
                                    <CircularProgressbar
                                        value={service*10}
                                        text={`${service}`}
                                        strokeWidth={5}
                                        counterClockwise={false}
                                        styles={{
                                            text: {
                                                fill: 'white',
                                                fontSize: '40px',
                                                fontFamily: 'f400',
                                                letterSpacing: '0.3px',
                                                lineHeight: '1.13'
                                            },
                                        }}
                                    />
                                    <p>{lang.shirutiyut}</p>
                                </aside>


                                <button style={contactMenu ? {zIndex:'10001'}:{}} onClick={(e)=>{this.activateContactMenu(e)}} className='aces__pros__button'>
                                    <p className='aces__pros__button'>דברו איתי</p>
                                    <aside className={ contactMenu ? "aces__pros__button__contact-menu aces__pros__button__contact-menu--active":"aces__pros__button__contact-menu"}>
                                        <div  >
                                            <a id="UC: Sent SMS To Professional" href={"tel:" + proInfo.phone} style={!proInfo.mail ? {width:'100%'}:{}}>
                                                <h1 id="UC: Sent SMS To Professional">{proInfo.phone}</h1>
                                                <img id="UC: Sent SMS To Professional" src={desktopMako ? PhoneB:PhoneR} alt="phone" />
                                            </a>
                                        </div>

                                        {proInfo.mail ? 
                                            <div >
                                                <a href={"mailto:" + proInfo.mail} >
                                                    <h1>{proInfo.mail}</h1>
                                                    <img src={desktopMako ? MailB:MailR} alt="email" />
                                                </a>
                                            </div>
                                        :
                                        null
                                        }
                                    </aside>
                                </button>
                            </div>

                        </main>
                        :
                        null
                    }
                </header>

            {!desktop ? 
                <section style={isNaN((reliable + proffesion + service)) ? {display:'none'}:{}}>
                    <aside>
                        <CircularProgressbar
                            value={reliable*10}
                            text={`${reliable}`}
                            strokeWidth={5}
                            counterClockwise={false}
                            styles={{
                                text: {
                                    fill: 'black',
                                    fontSize: '40px',
                                    fontFamily: 'f400',
                                    letterSpacing: '0.3px',
                                    lineHeight: '1.13'
                                },
                            }}
                        />
                        <p>{lang.aminut}</p>
                    </aside>
                    <aside>
                        <CircularProgressbar
                            value={proffesion*10}
                            text={`${proffesion}`}
                            strokeWidth={5}
                            counterClockwise={false}
                            styles={{
                                text: {
                                    fill: 'black',
                                    fontSize: '40px',
                                    fontFamily: 'f400',
                                    letterSpacing: '0.3px',
                                    lineHeight: '1.13'
                                },
                            }}
                        />
                        <p>{lang.mikzoyut}</p>
                    </aside>
                    <aside>
                        <CircularProgressbar
                            value={service*10}
                            text={`${service}`}
                            strokeWidth={5}
                            counterClockwise={false}
                            styles={{
                                text: {
                                    fill: 'black',
                                    fontSize: '40px',
                                    fontFamily: 'f400',
                                    letterSpacing: '0.3px',
                                    lineHeight: '1.13'
                                },
                            }}
                        />
                        <p>{lang.shirutiyut}</p>
                    </aside>
                </section>
                :
                null
                }

            {!desktop ? 
                <main style={isNaN((reliable + proffesion + service)) ? {paddingTop:'50px'}:{}}>
                    <h1>{proInfo.name}</h1>
                    <span style={isNaN((reliable + proffesion + service)) ? {display:'none'}:{}}>
                        <h3>{((reliable + proffesion + service) / 3).toFixed(1)}</h3>
                        <h4>{lang.score}</h4>
                    </span>
                    <p>
                        {proInfo.description}
                    </p>
                    <h2> {reviews.length} {lang.opinions}</h2>
                </main>
                :
                null
            }


            {!desktop ? 
                !token ? 
                <aside className="aces__pros--contact-info">
                    <a style={{width:'100%',textOverflow:'unset',cursor:'pointer'}} onClick={()=>{this.handleLogin()}}>
                        <h1>דברו איתי</h1>
                    </a>
                </aside> 
                :
                showProData ?
                <aside className="aces__pros--contact-info">
                    <a id="UC: Sent SMS To Professional" href={"tel:" + proInfo.phone} style={!proInfo.mail ? {width:'100%'}:{}}>
                        <h1 id="UC: Sent SMS To Professional">{proInfo.phone}</h1>
                        <img id="UC: Sent SMS To Professional" src={Phone} alt="phone" />
                    </a>
                    {proInfo.mail ? 
                        <a href={"mailto:" + proInfo.mail} >
                            <h1>{proInfo.mail}</h1>
                            <img src={MailW} alt="email" />
                        </a>
                        :
                        null
                    }

                </aside>
                :
                <aside className="aces__pros--contact-info">
                    <a style={{width:'100%',cursor:'pointer'}} onClick={(e)=>{this.handleClickOnTalkWithMe(e)}}>
                        <h1>דברו איתי</h1>
                    </a>
                </aside> 
                :
                null
            }

            {desktop ? 
                <div className="aces__pros__desktop-onme">
                   <h6>נעים להכיר</h6>
                   <p>{proInfo.description}</p>
                </div>
                :
                null
            }

            {desktop ? 
                <div className="aces__pros__desktop-onme">
                   <h6>מה אומרים עליי</h6>
                </div>
                :
                null
            }


            {reviews.map(item=>{
                return  <div className="ace-pro-page__opinion">
                            <h1>{item.name}</h1>
                            <h6>21/05/2019</h6>
                            <h2>חוות דעת</h2>
                            <p>{item.description}</p>
                            <span>
                                <p>ציון כללי </p>
                                <p>{ ((item.reliable + item.profession + item.service) / 3).toFixed(1) }</p>
                            </span>
                        </div>
            })}


            </div>
        );
    }
}


function stateToProps({ pros,userCart }) {
    return { pros,userCart }
}
  
export default withRouter(connect(stateToProps, actions)(AceProPage))