import React, { Component } from 'react'
import './navbar.css'
import { Link } from 'react-router-dom'
import {withRouter} from 'react-router-dom'
import Cookies from 'js-cookie'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
//images
import MechironWhiteLogo from '../../../images/mako/machw.svg'
import AceRed from '../../../images/acered.png'
import OriginalMechiron from '../../../images/original/11.svg'
import MakoMechiron from '../../../images/mako/16.svg'
import NavPro from '../../../images/icons/navpro.svg'
import NavProActive from '../../../images/icons/navproactive.svg'
import NavSearch from '../../../images/icons/navsearch.svg'
import NavSearchMako from '../../../images/mako/10.svg'
import Arrow from '../../../images/icons/nav1.svg'
import ArrowReg from '../../../images/original/16.svg'
import ArrowMako from '../../../images/mako/17.svg'
import BackArrow from '../../../images/icons/backArrow.svg'
import BackArrowMako from '../../../images/mako/11.svg'
import Nav2 from '../../../images/navbarace/11.svg'
import Nav3 from '../../../images/navbarace/22.svg'
import Nav33 from '../../../images/prosinterface/navbarCustomers.svg'
import Nav4 from '../../../images/navbarace/33.svg'
import Nav5 from '../../../images/navbarace/55.svg'
import Nav6 from '../../../images/navbarace/66.svg'
import Nav7 from '../../../images/navbarace/77.svg'
// import AceWhite from '../../../images/acewhite.png'
import AceWhite from '../../../images/newlogoace.png'
import BackToAceLogo from '../../../images/icons/aoace.svg'

import WhiteX from '../../../images/navbarace/6.svg'

import Gps from '../../../images/navbarace/4.svg'
import Cart from '../../../images/navbarace/5.svg'
//comps
import {ApiGet} from '../../../tools/tools'
//config
import {API_URL, USER_TOKEN,HOST_NAME} from '../../../configs/configs'
//img
import Glass from '../../../images/navbarace/3.svg'

class NavbarAce extends Component {

    constructor(props){
        super(props)

        this.state = {
            navScrolled:false,
            navMenuActive: false,
            levelTwos: [],
            prevPath: '',
        }
    }

    async componentWillMount() {
        let res = await ApiGet(API_URL + `/levelTwo/getAll`)
        if(res)
        this.setState({levelTwos:res})

        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})
    }
    

    componentDidMount() {
        window.addEventListener("scroll",this.handleScroll,{passive: true});
        window.addEventListener("click",this.handleClick,{passive: true});
    }

    componentWillUnmount() {
        window.addEventListener("scroll",this.handleScroll,{passive: true});
        window.addEventListener("click",this.handleClick,{passive: true});
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
          this.setState({ prevPath: this.props.location.pathname })
        }
    }

    handleScroll = (e) => {
        if(window.pageYOffset > 0) {
            this.setState({navScrolled:true})
        } else {
            this.setState({navScrolled:false})
        }
    }

    handleClick = (event) => {
        if(event.target.className.includes('curtain')){
            this.setState({navMenuActive:false})  
            this.props.disActivateCurtain()
        }
    }

    toggleNav = (e) => {
        if(e != null)
        e.stopPropagation()

        this.setState(prevState => ({
            navMenuActive: !prevState.navMenuActive
        }),()=>{
            this.props.toggleCurtain()
        });
    }

    activateNav = (e) => {
        document.querySelector(".navbar__ace").style.zIndex = '99999'
        if(document.querySelector(".curtain__ace")) 
        document.querySelector(".curtain__ace").style.display = ''
        if(e != null)
        e.stopPropagation()
        setTimeout(()=>{ this.setState({navMenuActive: true},()=>{this.props.activateCurtain()}) }, 0);

        
    }

    disActivateNav = (e) => {
        if(e != null)
        e.stopPropagation()
        this.setState({navMenuActive: false},()=>{this.props.disActivateCurtain()})
    }

    goto = (link) => {
        // if(Cookies.get(USER_TOKEN)) {
            this.setState({navMenuActive:false})
            this.props.disActivateCurtain()
            this.props.history.push(link)
        // } else {
        //     this.setState({navMenuActive:false})
        //     this.props.disActivateCurtain()
        //     if(link === '/regulations') {
        //         this.props.history.push('/regulations') 
        //     } else if(link.includes('contact')) {
        //         this.props.history.push('/contact/-1') 
        //     } else {
        //         this.props.history.push('/login')
        //     }
        // }
    }

    goBackFunc = () => {
        if(this.state.prevPath.includes('login') && window.location.pathname !=='/regulations'){
            this.props.history.push(`/`)
        } else {
            this.props.history.goBack()
        }
    }

    handleAceCartClick = () => {
        // if(!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null ){
        //     this.props.history.push('/login')
        // } else {
            this.props.history.push('/cart/token')
        // }
       
    }

    handleAuthentication = () => {
        if(!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null) {
            this.props.activateCurtain()
            this.props.history.push(`/login`)
        } else {
            Cookies.remove(USER_TOKEN)
            this.props.history.push(`/`)
            this.props.resetCart()
        }
    }

    render() {

        const {navScrolled, navMenuActive, levelTwos,typeOfMechiron} = this.state
        const {lang,showNav} =  this.props

        let isContact = window.location.href.includes('contact')
        let isLogin = window.location.href.includes('login')
        let isProInterface = window.location.href.includes('pros/interface')

        return (
            <nav style={isLogin || isProInterface? {height:'60px'}:!showNav ? {display:'none'}:{}} className="navbar__ace">
                <section className="navbar__ace__first">

                    <div className="navbar__ace__first__img-container">
                        <div style={window.location.pathname === '/' ? {display:'none'}:{}} className="navbar__back" onClick={()=>{this.goBackFunc()}}>
                            <img src={BackArrow} alt="back"/>
                        </div>

                        <div className="navbar__ace__first__img-container--red" onClick={()=>{this.props.history.push('/')}} >
                            <img src={AceWhite} alt="ace-logo"></img>
                        </div>
                    </div>

                    <div className="navbar__ace__first__div">
                        <Link to="/search">
                            <span className="navbar__icons">
                                <img src={Glass} alt="search" />
                            </span>
                        </Link>

                        <div id="nav-icon3" className={navMenuActive ? "":""} onClick={(e)=>{this.activateNav(e)}}>
                            <span onClick={(e)=>{this.activateNav(e)}}></span>
                            <span onClick={(e)=>{this.activateNav(e)}}></span>
                            <span onClick={(e)=>{this.activateNav(e)}}></span>
                        </div>
                    </div>
                </section>

                <section className="navbar__ace__sec" style={isLogin || isProInterface ? {display:"none"}:{}}>
                    <div>
                        <img src={Gps} alt="stores"/>
                        <a href="https://www.ace.co.il/stores">סניפים</a>
                    </div>

                    <div>
                        <img src={BackToAceLogo} alt="ace-website"/>
                        <a href="https://www.ace.co.il">חזרה לאתר של אייס</a>
                    </div>

                    <div onClick={()=>{this.handleAceCartClick()}} id="UC: Went to cart">
                        <img id="UC: Went to cart" src={Cart} alt="cart"/>
                        <aside id="UC: Went to cart">
                           <p id="UC: Went to cart">{this.props.totalProductsInCart}</p> 
                        </aside>
                    </div>
                </section>



                <aside className={navMenuActive ? "navbar__ace__menu navbar__ace__menu--active":"navbar__ace__menu"}>

                    <div onClick={()=>{this.setState({navMenuActive:false});this.props.disActivateCurtain()}}>
                        <img src={WhiteX} alt="cancel-icon"/>
                        <h1>סגור תפריט</h1>
                    </div>

                    <span>
                        <img src={Gps} alt="stores"/>
                        <a href="https://www.ace.co.il/stores">סניפים</a>
                    </span>

                    <span>
                        <img src={BackToAceLogo} alt="ace-website"/>
                        <a href="https://www.ace.co.il">חזרה לאתר של אייס</a>
                    </span>

                    <aside></aside>
                
                    <div onClick={()=>{this.goto('/choice')}}>
                        <span>
                            <img src={Nav2} alt="rooms"/>
                            <p>{lang.nav2}</p>
                        </span>
                    </div>
                    
                    {this.props.userCart.userIsAcePro ? 
                        <div onClick={()=>{this.goto(`/pros/interface/${this.props.userCart.userId}/customers/0`)}}>
                            <span>
                                <img src={Nav33} alt="cart"/>
                                <p>לקוחות</p>
                            </span>
                        </div>
                        :
                        <div onClick={()=>{this.goto('/aces/cart')}} id="UC: Clicked on pros in menu">
                            <span id="UC: Clicked on pros in menu">
                                <img id="UC: Clicked on pros in menu" src={Nav3} alt="cart"/>
                                <p id="UC: Clicked on pros in menu">אנשי המקצוע שלנו</p>
                            </span>
                        </div>
                    }




                    <div onClick={()=>{this.goto('/allchapters')}}>
                        <span>
                            <img src={Nav4} alt="all-chapters"/>
                            <p>המחירון</p>
                        </span>
                    </div>
                    <div onClick={()=>{this.handleAuthentication()}}>
                        <span>
                            <img src={Nav7} alt="contact-us"/>
                            <p>{!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null ? 'התחבר':'התנתק'}</p>
                        </span>
                    </div>
                    <div onClick={()=>{this.goto('/contact/-1')}} id="UC: Enter Contact Us Page">
                        <span id="UC: Enter Contact Us Page">
                            <img id="UC: Enter Contact Us Page" src={Nav5} alt="contact-us"/>
                            <p id="UC: Enter Contact Us Page">צרו קשר</p>
                        </span>
                    </div>
                    {/* <div>
                        <span>
                            <img src={Nav5} alt="liked"/>
                            <p>{lang.nav5}</p>
                        </span>
                    </div> */}
                    <div onClick={()=>{this.goto('/regulations')}}>
                        <span>
                            <img src={Nav6} alt="regulations"/>
                            <p>אודות המחירון</p>
                        </span>
                    </div>  
                
                </aside>
            </nav>
        );
    }
}



function stateToProps({ userCart }) {
    return { userCart }
}
  
export default withRouter(connect(stateToProps, actions)(NavbarAce))