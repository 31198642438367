//props (all required)
// header         - string (header text to input)
// typeOfMechiron - string (type of mechiron ace/mako/reg)
// value          - string/num (value of the input)
// typeOfInput    - string (determine if length/width/height)
//
import React, { Component } from 'react'
import '../../layouts/Calculator/calculator.css'
import './calcinput.css'
//images
import Width from '../../../images/icons/width.svg'
import Length from '../../../images/icons/length.svg'
import Height from '../../../images/icons/height.svg'

import WidthReg from '../../../images/original/9.svg'
import LengthReg from '../../../images/original/8.svg'
import HeightReg from '../../../images/original/10.svg'
import BlueArrow from '../../../images/original/16.svg'

import WidthMako from '../../../images/mako/7.svg'
import LengthMako from '../../../images/mako/8.svg'
import HeightMako from '../../../images/mako/9.svg'

class CalcInput extends Component {

    determinePic = (typeOfInput) => {
        
        const {typeOfMechiron} = this.props
        switch(true) {
            case (typeOfInput === 'length' && typeOfMechiron === 'ace'):
              return Length;
            case (typeOfInput === 'length' && typeOfMechiron === 'mako'):
              return LengthMako;
            case (typeOfInput === 'length' && typeOfMechiron === 'hamechiron'):
                return LengthReg;
            case (typeOfInput === 'width' && typeOfMechiron === 'ace'):
                return Width;
            case (typeOfInput === 'width' && typeOfMechiron === 'mako'):
                return WidthMako;
            case (typeOfInput === 'width' && typeOfMechiron === 'hamechiron'):
                return WidthReg;
            case (typeOfInput === 'height' && typeOfMechiron === 'ace'):
                return Height;
            case (typeOfInput === 'height' && typeOfMechiron === 'mako'):
                return HeightMako;
            case (typeOfInput === 'height' && typeOfMechiron === 'hamechiron'):
                return HeightReg;
            default: return Length
          }
    }
    
    render() {

        const {header, typeOfMechiron, value, typeOfInput} = this.props
        let typeOfInputPic = this.determinePic(typeOfInput)

        return (
            <div className="custom-calc-input">
                <figure onClick={(e) => { this.props.setValues(typeOfInput, value - 10) }}>-</figure>
                <section className="calculator__input">
                    <label htmlFor={typeOfInput}></label>
                    <aside>{header}</aside>
                    <div>
                        <img src={typeOfInputPic} alt={typeOfInput} />
                        <input type="number" id={typeOfInput} value = {value} name={typeOfInput} onChange={(e) => { this.props.setValues(e.target.name, e.target.value) }} onFocus={(e)=>{this.props.focusHandler(typeOfInput)}} onBlur={(e)=>{this.props.blurHandler(e)}}/>
                        <p>ס״מ</p>
                    </div>
                    <div style={{visibility:'hidden'}}></div>
                </section>
                <figure onClick={(e) => { this.props.setValues(typeOfInput, value + 10) }}>+</figure>
            </div>

        )
    }
}

export default  CalcInput
