import React, { Component } from 'react'
import {Link, withRouter} from 'react-router-dom'
import Cookies from 'js-cookie'
import './newnavbar.css' 
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
//config
import {USER_TOKEN} from '../../../configs/configs'
//img
import AceLogo from '../../../images/newnavbar/logo-ace.png'
import MakoLogo from '../../../images/newnavbar/logo-mako.svg'
import MechironLogo from '../../../images/newnavbar/logo-mechiron.svg'
import AceRedLogo from '../../../images/acered.png'

// img search
import SearchAce from '../../../images/newnavbar/search-ace.svg'
import SearchMako from '../../../images/newnavbar/search-mako.svg'
import SearchMechiron from '../../../images/newnavbar/search-mechiron.svg'
//img 2nd section
// import branchesAce from '../../../images/newnavbar/branches.svg'
import AllChapters from '../../../images/newnavbar/allchapters.svg'
import backToAce from '../../../images/newnavbar/backtoace.svg'
import backToMako from '../../../images/newnavbar/backtomako.svg'
import Rooms from '../../../images/newnavbar/rooms.svg'
import Pros from '../../../images/newnavbar/pros.svg'
import MyListAce from '../../../images/newnavbar/mylistace.svg'
import MyListMako from '../../../images/newnavbar/mylistmako.svg'
import MyListMechiron from '../../../images/newnavbar/mylistmechiron.svg'
import ClientsAce from '../../../images/newnavbar/clientsace.svg'
import ClientsMako from '../../../images/newnavbar/clientsmako.svg'
import ClientsMechiron from '../../../images/newnavbar/clientsmechiron.svg'
//go back arrows
import AceBack from '../../../images/newnavbar/arrowace.svg'
import MakoBack from '../../../images/newnavbar/arrowmako.svg'
import MechironBack from '../../../images/newnavbar/arrowmechiron.svg'

class NewNavbar extends Component {

    constructor(){
        super()

        this.state = {
            prevPath: ''
        }
    }

    activateNav = () => {
        this.props.activateMobileMenuNav()
    }

    goTo = (link) => {
        this.props.disActivateMobileMenuNav()
        this.props.history.push(link)
    }

    goBackFunc = () => {
        if(this.state.prevPath.includes('login') && window.location.pathname !=='/regulations'){
            this.props.history.push(`/`)
        } else {
            this.props.history.goBack()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
          this.setState({ prevPath: this.props.location.pathname })
        }
    }


    render() {
        const {desktop,typeOfMechiron,showNav,totalProductsInCart} = this.props
        const {navMenuActive} = this.state
        let isLoggedIn = Cookies.get(USER_TOKEN) && Cookies.get(USER_TOKEN) != null 
        let hideBackButton = 
        (window.location.pathname === '/' && !isLoggedIn) || (window.location.pathname === '/choice' && isLoggedIn)

        return (
            <nav className="new-navbar" style={showNav ? {}:{display:'none'}}>
                <section className="new-navbar__first-section">
                    <div>
                       {
                            typeOfMechiron === 'ace' ? 
                            <span onClick={()=>{this.goBackFunc()}} style={hideBackButton ? {display:'none'}:{}}>
                                <img src={AceBack} alt="go-back"/>
                            </span>
                            : typeOfMechiron === 'mako' ? 
                            <span onClick={()=>{this.goBackFunc()}} style={hideBackButton ? {display:'none'}:{}}>
                                <img src={MakoBack} alt="go-back"/>
                            </span>
                            :
                            <span onClick={()=>{this.goBackFunc()}} style={hideBackButton ? {display:'none'}:{}}>
                               <img src={MechironBack} alt="go-back"/>
                            </span>
                        }
                        {
                            typeOfMechiron === 'ace' ? 
                            <img src={AceLogo} alt="ace-logo" onClick={()=>{this.goTo('/')}}/>
                            : typeOfMechiron === 'mako' ? 
                            <img src={MakoLogo} alt="mako-logo" onClick={()=>{this.goTo('/')}}/>
                            :
                            <img src={MechironLogo} alt="hamechiron-logo" onClick={()=>{this.goTo('/')}}/>
                        }
                        
                    </div>
                    <div>
                        <Link to="/search">
                            <span className="new-navbar__search">
                                {
                                    typeOfMechiron === 'ace' ? 
                                    <img src={SearchAce} alt="search"/>
                                    : typeOfMechiron === 'mako' ? 
                                    <img src={SearchMako} alt="search"/>
                                    :
                                    <img src={SearchMechiron} alt="search"/>
                                }
                            </span>
                        </Link>

                        <div className="new-navbar__hamburger" onClick={(e)=>{this.activateNav(e)}}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </section>

                <section className="new-navbar__second-section">
                    {
                        typeOfMechiron === 'ace' ? 
                        // <a href="https://www.ace.co.il/stores">
                        //    <img src={AllChapters} alt="chapters"/>
                        //    <p>סניפים</p>
                        // </a>

                        <a onClick={()=>{this.goTo(`/allchapters`)}}>
                            <img src={AllChapters} alt="ace-website" style={{marginLeft:'5px'}}/>
                            <a>המחירון</a>
                        </a>
                        :
                        <a onClick={()=>{this.goTo(`/choice`)}}>
                            <img src={Rooms} alt="rooms"/>
                            <p>חדרים</p>
                        </a>
                    }

                    {
                        typeOfMechiron === 'ace' ? 
                        <a href="https://www.ace.co.il">
                           <img src={backToAce} alt="back-to-ace"/>
                           <p>חזרה ל</p>
                           <img src={AceRedLogo} alt="" style={{width:'32px',height:'15px',marginRight:'5px',transform:'translateY(-1px)'}}/>
                        </a>
                        : typeOfMechiron === 'mako' ? 
                        <a href="https://www.mako.co.il">
                            <img src={backToMako} alt="back-to-mako"/>
                            <p>חזרה ל-mako</p>
                        </a>
                        :
                        <div onClick={()=>{this.goTo(`/aces/cart`)}}>
                            <img src={Pros} alt="proffessionals"/>
                            <p>בעלי מקצוע</p>
                        </div>
                    }

                    {!this.props.userCart.userIsAcePro && isLoggedIn ?
                        <div onClick={()=>{this.goTo(`/pros/interface/${this.props.userCart.userId}/customers/0`)}}>
                            {
                                typeOfMechiron === 'ace' ? 
                                <img src={ClientsAce} alt="search"/>
                                : typeOfMechiron === 'mako' ? 
                                <img src={ClientsMako} alt="search"/>
                                :
                                <img src={ClientsMechiron} alt="search"/>
                            }
                            <p>לקוחות</p>
                        </div>
                        :
                        <div onClick={()=>{this.goTo(`/cart/token`)}}>
                            {
                                typeOfMechiron === 'ace' ? 
                                <img src={MyListAce} alt="search"/>
                                : typeOfMechiron === 'mako' ? 
                                <img src={MyListMako} alt="search"/>
                                :
                                <img src={MyListMechiron} alt="search"/>
                            }
                            <p>הרשימה שלי</p>

                            {totalProductsInCart > 0 ? <figure>{totalProductsInCart}</figure>:null}
                        </div>
                    }

                </section>
                
            </nav>
        )
    }
}


function stateToProps({ userCart,levels }) {
    return { userCart,levels }
}
  
export default withRouter(connect(stateToProps, actions)(NewNavbar))