import React, { Component } from 'react'
import CustomDropDown from '../../../../../globals/CustomDropDown/CustomDropDown'
import Cookies from 'js-cookie'
import {withRouter} from 'react-router-dom'
//configs and tools
import {ApiPostWithHeaderToken,israeliPhoneValidation} from '../../../../../../tools/tools'
import {API_URL,USER_TOKEN} from '../../../../../../configs/configs'

const options = [
    {name:'פניה חדשה'},
    {name:'נקבעה פגישה'},
    {name:'הוגשה הצעה'},
    {name:'עסקה נסגרה'},
    {name:'בוטל'},
]

class NewCustomer extends Component {

    constructor(){
        super()

        this.state = {
            full_name: '',
            phone: '',
            phoneErr: '',
            status: '',
            address: ''
        }
    }

    handleChange = (e) => {
        let eventName = e.target.name
        this.setState({
            [e.target.name]: e.target.value
        },()=>{
            if(this.state.phoneErr)
            this.setState({ phoneErr:israeliPhoneValidation(this.state.phone, true) })
        })

    }

    dropDownChange = (value) => {
        this.setState({status: value})
    }

    blurHandler = (e) =>{
        let eventName = e.target.name
        if(eventName === 'phone') {
            this.setState({ phoneErr:israeliPhoneValidation(this.state.phone, true) })
        }
    }

    submitForm = async(e) => {
        e.preventDefault()
        this.setState({ phoneErr:israeliPhoneValidation(this.state.phone, true) })

        const {full_name, phone, status, phoneErr, address} = this.state
        const proId = this.props.match.params
        let newStatus = status
        if(!full_name || !phone || !status || phoneErr)
        return 

        if(status){
            options.map( (item,index) => {
                if(item.name === status)
                newStatus = index
            } )
        }

        let body = {
            full_name,
            phone,
            status:newStatus,
            address
        }

        let token = Cookies.get(USER_TOKEN)

        let responseFromCreatingUser = await ApiPostWithHeaderToken(API_URL + '/prointerface/client/create',token,body)
        console.log(responseFromCreatingUser)
        this.props.history.push(`/pros/interface/${proId}/customers/0`)
    }

    render() {
        const {activateCurtain, disActivateCurtain} = this.props
        const {full_name, phone, status, phoneErr} = this.state
        const {proId} = this.props.match.params
        return (
            <form className="pros-interface__new-customer-form" onSubmit={(e)=>{this.submitForm(e)}}>
                <section>
                    <h1>הוספת לקוח</h1>
                    <span>
                        <p onClick={()=>{this.props.history.push(`/pros/interface/${proId}/customers/0`)}}>ביטול</p>
                        <p className={full_name && phone && status && !phoneErr ? "pros-interface__new-customer-form--add-customer":null}
                          onClick={(e)=>{this.submitForm(e)}}
                        >
                            הוסף
                        </p>
                    </span>
                </section>

                <section>
                    <input name="full_name" value={full_name} type="text" placeholder='שם לקוח*' onChange={(e)=>{this.handleChange(e)}}/>
                    <input name="phone" value={phone} type="text" placeholder='מספר נייד*' onChange={(e)=>{this.handleChange(e)}} onBlur={(e)=>{this.blurHandler(e)}}/>
                    {phoneErr ? <p className="pros-interface__new-customer-form__validation">{phoneErr}</p>:null}
                </section>

                <section>
                    <CustomDropDown
                        options = {options}
                        activateCurtain = {activateCurtain}
                        disActivateCurtain = {disActivateCurtain}
                        keyInObjectToDisplay = {'name'}
                        placeholder = 'סטטוס*'
                        changeHandler = {this.dropDownChange}
                    />
                    <input name="address" type="text" placeholder='כתובת' onChange={(e)=>{this.handleChange(e)}}/>
                </section>

            </form>
        )
    }
}

export default withRouter(NewCustomer);