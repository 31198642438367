import React, { Component } from 'react'
import './newcart.css'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
import { withRouter } from 'react-router-dom'
import InnerNavigation from '../../globals/InnerNavigation/InnerNavigation'
import QuantityInput from '../../globals/QuantityInput/QuantityInput'
import ImageComponent from '../../../Components/globals/ImageComponent/ImageComponent'
//configs
import {IMAGE_URL,API_URL} from '../../../configs/configs'
//tools
import {ApiGet,calculatePrices,calculateTotalPrice} from '../../../tools/tools'
//work pic
import WorkPic from '../../../images/worke.png'
import Eqpic from '../../../images/equipment.png'
//img
import PlusIcon from '../../../images/icons/plus.svg'
import MakoPlusIcon from '../../../images/icons/mako/3.svg'

class NewCartItem extends Component {


    constructor(){
        super()

        this.state = {
            sortedArray:[],
            scrollToItem: true
        }

        this.cartContainerRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClick);
    }
    
    componentWillReceiveProps(nextProps) {
        let oldRoom = this.props.userCart.userCart[this.props.match.params.indexInCart]
        let room = nextProps.userCart.userCart[this.props.match.params.indexInCart]
       
        if(oldRoom != null && room != null)
        if(this.state.sortedArray.length === 0 ) {
            this.productsFilterFunction(room)
        }

        if(this.props.location.pathname !== nextProps.location.pathname)
        this.setState({sortedArray:[]})

        if(this.cartContainerRef.current && this.state.scrollToItem && this.props.userCart.pidIdForOffsetChange && this.props.scrollToItemWhenEdit){
            this.setState({scrollToItem: false})
            
            setTimeout(()=>{ 
                let itemsLoaded = document.getElementsByClassName("newcart__item--product")
                for (var i = 0; i < itemsLoaded.length; i++) {
                    if(itemsLoaded[i].getAttribute('data-id') === this.props.userCart.pidIdForOffsetChange){
                        if(this.props.desktop) {
                            let topPos = itemsLoaded[i].offsetTop
                            this.cartContainerRef.current.scrollTop = topPos
                        } else {
                            let topPos = itemsLoaded[i].getBoundingClientRect().top + window.scrollY
                            topPos = topPos - itemsLoaded[i].scrollHeight - 40
                            window.scrollTo({top: topPos, behavior:"smooth"})
                        }

                    }
                }
            }, 0);

        }
        
       
    }


    productsFilterFunction = (room) => {
        if(room){
            let works = []
            let prods = []
            
            if (room != null) {
                works = room.products.filter((a)=> {
                    return (a.product_price === '0' || a.only_work === true)
                })
                prods = room.products.filter((a)=> {
                    return (a.product_price !== '0' && !a.only_work)
                })
            }
    
            if(works.length > 0)
            works = works.sort((a, b)=> {
                return calculateTotalPrice(b, room) - calculateTotalPrice(a, room)
            })
    
            if(prods.length > 0)
            prods = prods.sort((a, b)=> {
                return calculateTotalPrice(b, room) - calculateTotalPrice(a, room)
            })
    
            let sortedArray = prods.concat(works)
            this.setState({sortedArray})
        }
    }

    deleteFromCart = (item) => {
        let room = this.props.userCart.userCart[this.props.match.params.indexInCart]
        this.props.removeCartItem(this.props.match.params.indexInCart,item)
        this.productsFilterFunction(room)
    }

    handleClick = (e) => {

        if(!this.props.desktop && !this.props.desktopMako){
            if(e.target.className.includes('curtain')){
                this.props.disActivateCurtain()
            }
        }
    }


    changeQuantity = (value,item) => {
        item.qty_formula = parseFloat(value).toFixed(1)
        this.props.updateQuantityOfItem(this.props.match.params.indexInCart,item)
    }

    handleChangeItemRecommendation = async(item) => {
        let filter = await ApiGet(API_URL + '/filter/getById/' + item.filter_id)
        let finalLevel3ToSend
        if(item.level_three_id != null) {
           finalLevel3ToSend = item.level_three_id 
        } else {
            finalLevel3ToSend = filter.level_three_id
        }
        let lvl3 = await ApiGet(API_URL + '/levelThree/getById/' + finalLevel3ToSend)
        this.props.history.push(`/cart/edit/roomindex/${this.props.match.params.indexInCart}/edit/${item.pid_id}/${this.props.match.params.levelOneId}/${lvl3.level_two_id}/${finalLevel3ToSend}/filter/${item.filter_id}`)
    }

    editProd = async(item) => {

        let lvl3 = ''
        if(item.level_three_id != null && item.level_three_id){
            lvl3 = item.level_three_id
        } else {
            let res = await ApiGet(API_URL + '/filter/getById/' + item.filter_id)
            lvl3 = res.level_three_id
            lvl3 = lvl3.substring(0,lvl3.indexOf(','))
        }

        await this.props.saveSelectedProduct(item)
        this.props.history.push(`/editproductinusercart/${this.props.match.params.levelOneId}/${item.level_two_id}/${lvl3}/${item.pid_id}`)
    }

    render() {
        const {lang,desktop, typeOfMechiron} = this.props
        const {sortedArray} = this.state
        let room = this.props.userCart.userCart[this.props.match.params.indexInCart]

        let totalRoomPrice = 0
        
        if(room)
        return (
            <div className="newcart newcart__item__desktop" ref={this.cartContainerRef}>
                <header>
                    <InnerNavigation links={['/cart/token',`/cart/${this.props.match.params.indexInCart}/token`]} pages={['עגלת פריטים',room.name]}/>
                    <div>
                        <h1>{room.name}</h1>
                        <span>
                            <p>{lang.totalItems}</p>
                            <p>{sortedArray.length}</p>
                        </span>
                    </div>
                </header>

                {desktop ? 
                    <div className="newcart__item__desktop__top-header">
                        {/* <p>סה”כ אומדן לחדר: <span>₪{parseFloat(totalRoomPrice.toFixed(2)).toLocaleString()}</span></p> */}
                        <p>רשימת פריטים</p>
                    </div>
                :
                null
                }

                {desktop ? 
                    <div className="newcart__item__desktop__header">
                        <h2>{room.name}</h2>
                        <p>פריטים: {sortedArray.length}</p>
                    </div>
                :
                null
                }



                {sortedArray.map((item,index)=>{
                    let formula = item.qty_formula
                    formula = formula.toString()
                    formula = formula.replace(/x/gi, room.x);
                    formula = formula.replace(/y/gi, room.y);
                    formula = formula.replace(/z/gi, room.z);
                    formula = eval(formula)


                    let total
                    if(item.only_work){
                        total = formula * (calculatePrices(item,'work',{x:room.x,y:room.y,z:room.z}))
                    } else {
                        total = formula * (calculatePrices(item,'work',{x:room.x,y:room.y,z:room.z}) + calculatePrices(item,'product',{x:room.x,y:room.y,z:room.z}))
                    }
                    
                    total = total * 1.17
                    total = parseFloat(Math.round(total * 100) / 100)

                    totalRoomPrice+= total
                    // let total =  calculateTotalPrice(item, room)

                    let stringName = item.name + ' '

                    if(item.length !== 'X' && item.width !== 'Y' && item.height !== 'Z') {
                        if(item != null && item.width !== 'N' && item.width != null ){
                            stringName += item.width + 'X'
                        }

                        if(item != null && item.height !== 'N' && item.height != null){
                            stringName += item.height + 'X'
                        }
                
                
                        if(item != null && item.length !== 'N' && item.length != null){
                            stringName += item.length
                        }
                
                        if(stringName.charAt(stringName.length -1) === 'X')
                        stringName = stringName.substr(0,stringName.length -1)
                    }

                    
                    

                    return <div className="newcart__item--product" data-id={item.pid_id}>
                                <div className="newcart__item--product--first">
                                    <div onClick={()=>{this.editProd(item)}}>
                                        <ImageComponent
                                         src={
                                            item.picture_id === 'work' ? 
                                            WorkPic : item.picture_id === 'equipment' ?
                                            Eqpic : `${IMAGE_URL}/ace/${item.picture_id}.png`
                                        }
                                         alt="product" 
                                         />
                                    </div>
                                    <div>

                                        <span>
                                            <h1 onClick={()=>{this.editProd(item)}}>{stringName}</h1>
                                            <p>{item.description ? item.description:''}</p>
                                        </span>

                                        <div>
                                            <span>
                                                <p>{(item.product_price === "0") || ( item.only_work === true)? lang.totalwithoutprod:lang.totalwithprod}</p>
                                                <p>₪{parseFloat(total.toFixed(2)).toLocaleString()}</p>
                                            </span>
                                            <QuantityInput activatedOn={item} changeQuantity={this.changeQuantity} unit={item.units} quantity={formula}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="newcart__item--product--last">
                                    <p onClick={()=>{this.editProd(item)}}>עריכת פריט</p>
                                    <p onClick={()=>{this.handleChangeItemRecommendation(item)}}>פריטים נוספים</p>
                                    <p id={"UC: Removed Product - " + item.name} onClick={()=>{this.deleteFromCart(item)}}>הסרת פריט</p>
                                </div>
                           </div>
                })}

                {desktop ? 
                    <div className="newcart__item__prices__total__calc">
                        <button onClick={()=>{this.props.history.push(`/calculator/${this.props.match.params.levelOneId}/room`)}}>
                            הוסף פריט
                            <img src={typeOfMechiron === 'ace' ? PlusIcon:MakoPlusIcon} alt="add-item" style={{marginLeft:'5px',height:'14px',width:'14px'}}/>
                        </button>
                       <p>סה”כ אומדן לחדר: <span>₪{parseFloat(totalRoomPrice.toFixed(2)).toLocaleString()}</span></p>
                    </div>
                    :
                    null
                }

                <div className="newcart__item__prices">
                    <h1>{lang.totalPrice}</h1>
                    <h1>₪{parseFloat(totalRoomPrice.toFixed(2)).toLocaleString()}</h1>
                </div>  

                <div className="newcart__item__footer">
                    <button onClick={()=>{this.props.history.push(`/calculator/${this.props.match.params.levelOneId}/room`)}}>הוסף מוצר</button>
                    <button onClick={()=>{this.props.history.push('/aces/cart')}}>לקבלת הצעה</button>
                </div>

                
            </div>
        );
        return desktop ? <div></div>:null
    }
}


function stateToProps({ userCart }) {
    return { userCart }
}
  
export default withRouter(connect(stateToProps, actions)(NewCartItem))