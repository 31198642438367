import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import Arrow from '../../../../images/icons/redarrow.svg'
import moment from 'moment'
import Cookie from 'js-cookie'
//configs and tools
import {ApiPostWithHeaderToken, ApiGetWithHeader, israeliPhoneValidation} from '../../../../tools/tools'
import {API_URL,USER_TOKEN,MONTHS,DAYS} from '../../../../configs/configs'
//img
import WazeActive from '../../../../images/icons/wazeActive.svg'
import WazeInActive from '../../../../images/icons/wazeInActive.svg'

class Appointment extends Component {

    constructor() {
        super()

        this.state = {
            currentMeetingDate: moment().add(15, 'm'),
            full_name: '',
            phone: '',
            phoneErr: '',
            address: '',
            notes: '',
            datesTaken:[],
            popup:false
        }

        this.timeout = null
    }

    componentDidMount = async() => {
        
        const {customerUserId, meetingId} = this.props.match.params
        let token = Cookie.get(USER_TOKEN)

        let datesTaken = await this.fetchTakenDates()
        this.setState({datesTaken})
        
        
        if(meetingId === 'new') {
            //get user info
            let userInfo = await ApiGetWithHeader(API_URL + `/getUserNameByUserId/${customerUserId}`,token)
            this.setState({
                full_name: userInfo.full_name,
                phone: userInfo.phone
            })
        } else {
            // get meet by id
            let meetingInfo = await ApiGetWithHeader(API_URL + `/prointerface/meeting/getById/${meetingId}`,token)
            let userInfo = await ApiGetWithHeader(API_URL + `/getUserNameByUserId/${meetingInfo.client_id}`,token)

            this.setState({
                full_name: userInfo.full_name,
                phone: userInfo.phone,
                address: meetingInfo.address,
                notes: meetingInfo.notes,
                currentMeetingDate: moment(meetingInfo.meet_at),

                cart_id: meetingInfo.cart_id
            })
        }

    }

    componentDidUpdate(nextProps,nextState) {
        const {currentMeetingDate} = this.state
        let currentDate = new Date(currentMeetingDate).getTime()
        let nextDate = new Date(nextState.currentMeetingDate).getTime()
        if(currentDate !== nextDate) {
            this.fetchTakenDates(nextDate)
        }

    }

    fetchTakenDates = async(date) => {
        let token = Cookie.get(USER_TOKEN)
        let from = moment(date)
        from = from._d
        from = from.setUTCHours(0,0,0,0)
        from = new Date(from)
        let to = moment(date).add(2, 'days')
        to = to._d
        to = to.setUTCHours(0,0,0,0)
        to = new Date(to)
        let datesTaken = await ApiGetWithHeader(API_URL + `/prointerface/meeting/getBetweenDates?from=${from}&to=${to}`,token)
        datesTaken = datesTaken.map(item=>{
            let newMilisecDate = moment(item.meet_at).seconds(0).milliseconds(0).toISOString()
            newMilisecDate = new Date(newMilisecDate).getTime()
            return newMilisecDate
        })
        return datesTaken
    }
    

    submitForm = async(e) => {
        e.preventDefault()
        const {full_name, phone, phoneErr, address, notes, currentMeetingDate} = this.state
        const {customerUserId, customerCartId, proId} = this.props.match.params

        if(!full_name || phoneErr)
        return

        let timeParsedDate = new Date(currentMeetingDate)
        let parsedHour = this.parseHour(currentMeetingDate)
        let hourString = parsedHour.substring(0,parsedHour.lastIndexOf(":"))
        let minuteString = parsedHour.substring(parsedHour.indexOf(":") + 1)


        timeParsedDate.setHours(Number(hourString))
        timeParsedDate.setMinutes(Number(minuteString))

        let body = {
            phone,
            notes,
            address,
            user_id: customerUserId, 
            cart_id: customerCartId,
            meet_at: timeParsedDate
        }

        let token = Cookie.get(USER_TOKEN)
        let responseFromCreatingAppointment = await ApiPostWithHeaderToken(API_URL + '/prointerface/meeting/create',token,body)

        if(responseFromCreatingAppointment.ok){
            // this.props.history.push(`/pros/interface/${proId}/schedule/calender`)
            this.props.history.push(`/pros/interface/${proId}/customers/customerCartId/${customerCartId}/customerUserId/${customerUserId}`)
        } else {
            console.log(responseFromCreatingAppointment.result)
        }
    }

    parseHour = (date) => {
        let hours = date.hours()
        let minutes = date.minutes()
        if(minutes === 45 || minutes === 30 || minutes === 15){
            return hours + ':' + minutes
        } 
        if(minutes === 0) {
            return hours + ':' + '00'
        }

        if(minutes >= 45) {
            minutes = '00'
            hours ++
        } else if (minutes > 30) {
            minutes = 45
        } else if (minutes > 15) {
            minutes = 30
        } else if (minutes > 0) {
            minutes = 15
        }
        return hours + ':' + minutes
    }

    incrementDate = (kind) => {
        let oldDateObj = {...this.state.currentMeetingDate} 

        if(kind === 'hour')
        this.setState({currentMeetingDate: moment(oldDateObj).add(15, 'm')})

        if(kind === 'day')
        this.setState({currentMeetingDate: moment(oldDateObj).add(1, 'days')})

        if(kind === 'month'){
            //handle 30-31 day in month problem increment
            let fm = moment(oldDateObj).add(1, 'M');
            let fmEnd = moment(fm).endOf('month');
            let finalDate =  moment(oldDateObj) != fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD')) ? fm.add(1, 'd') : fm;
            this.setState({currentMeetingDate: finalDate})
        }
        
    }

    decrementDate = (kind) => {
        let oldDateObj = {...this.state.currentMeetingDate} 

        if(kind === 'hour')
        this.setState({currentMeetingDate: moment(oldDateObj).subtract(15, "minutes") })

        if(kind === 'day')
        this.setState({currentMeetingDate: moment(oldDateObj).subtract(1, "days") })

        if(kind === 'month')
        this.setState({currentMeetingDate: moment(oldDateObj).subtract(1, "months") })
    }

    determineDecrementation = (type) => {
        let now = new Date()
        let newDateComparison = new Date(this.state.currentMeetingDate)
        
        if(type === 'hour')
        newDateComparison =  moment(newDateComparison).subtract(15, "minutes") 

        if(type === 'day')
        newDateComparison = moment(newDateComparison).subtract(1, "days") 

        if(type === 'month')
        newDateComparison = moment(newDateComparison).subtract(1, "months")

        return (new Date(now).getTime() < new Date(newDateComparison).getTime())

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        },()=>{
            if(this.state.phoneErr)
            this.setState({ phoneErr:israeliPhoneValidation(this.state.phone) })
        })

    }

    blurHandler = (e) =>{
        let eventName = e.target.name
        if(eventName === 'phone') {
            this.setState({ phoneErr:israeliPhoneValidation(this.state.phone) })
        }
    }

    cancelMeeting = async() => {
        const {proId,meetingId} = this.props.match.params
        let token = Cookie.get(USER_TOKEN)
        let body = {
            meet_id: meetingId
        }
        let deleteResult = await ApiPostWithHeaderToken(API_URL + '/prointerface/meeting/remove',token,body)
        if(deleteResult.ok) {
            this.props.history.push(`/pros/interface/${proId}/schedule/calender`)
        } else {
            console.log(deleteResult,'error during delete meeting')
        }
    }

    updateMeeting = async() => {
        const {proId, meetingId} = this.props.match.params
        const {cart_id, phone, notes, address, full_name, currentMeetingDate} = this.state

        let token = Cookie.get(USER_TOKEN)
        let body = {
            meet_id: meetingId,
            phone, 
            address, 
            notes,
            meet_at: new Date(currentMeetingDate)
        }

        let editResult = await ApiPostWithHeaderToken(API_URL + '/prointerface/meeting/update',token,body)
        this.props.history.push(`/pros/interface/${proId}/schedule/calender`)
    }

    render() {

        const {currentMeetingDate, full_name, phoneErr, phone, address, notes, popup, datesTaken} = this.state
        const { meetingId } =  this.props.match.params

        let timeParsedDate = new Date(currentMeetingDate)
        let parsedHour = this.parseHour(currentMeetingDate)
        let hourString = parsedHour.substring(0,parsedHour.lastIndexOf(":"))
        let minuteString = parsedHour.substring(parsedHour.indexOf(":") + 1)

        timeParsedDate.setHours(Number(hourString))
        timeParsedDate.setMinutes(Number(minuteString))
        timeParsedDate = moment(timeParsedDate).seconds(0).milliseconds(0).toISOString()
        timeParsedDate = new Date(timeParsedDate).getTime()

        let isTaken = datesTaken.includes(timeParsedDate) ? true:false

        return (
            <form className="pros-interface__appointment" onSubmit={(e)=>{this.submitForm(e)}}>

                {popup ?
                    <aside className="pros-interface__appointment--curtain" onClick={()=>{this.setState({popup:false})}}></aside>
                    :
                    null
                }

                {popup ?
                    <section className="pros-interface__appointment--popup">
                        <h1>ביטול פגישה</h1>
                        <p>האם אתה בטוח שברצונך לבטל פגישה עם</p>
                        <aside>{full_name}</aside>
                        <button onClick={()=>{this.cancelMeeting()}}>כן בטל פגישה</button>
                        <button onClick={()=>{this.setState({popup:false})}}>לא לבטל את הפגישה</button>
                    </section>
                    :
                    null
                }


                <section className="pros-interface__appointment__section">
                    <input disabled value={full_name} name="full_name" type="text" placeholder='שם לקוח' onChange={(e)=>{this.handleChange(e)}}/>
                    <input disabled value={phone} name="phone" type="number" placeholder='טלפון לקוח' onChange={(e)=>{this.handleChange(e)}} onBlur={(e)=>{this.blurHandler(e)}}/>
                    {phoneErr ? <p className="pros-interface__appointment__section__error">{phoneErr}</p>:null}
                    <input value={address} name="address" type="text" placeholder='כתובת' onChange={(e)=>{this.handleChange(e)}}/>
                </section>

                <section className="pros-interface__appointment__section">
                    <div>
                        <p>שעת פגישה</p>
                        <aside>

                            <span
                              className={this.determineDecrementation('hour') ? '':'invalid-time-pick'}
                              onClick={()=>{this.decrementDate('hour')}}
                            >
                                  <img src={Arrow} alt="hour-decrement"/>
                            </span>
                            <span style={isTaken ? {opacity:'0.5'}:{}}>{this.parseHour(currentMeetingDate)}</span>
                            <span onClick={()=>{this.incrementDate('hour')}}><img src={Arrow} alt="hour-increment"/></span>
                        </aside>
                    </div>
                    <div>
                        <p>יום</p>
                        <aside>
                            <span
                             onClick={()=>{this.decrementDate('day')}}
                             className={this.determineDecrementation('day') ? '':'invalid-time-pick'}
                            >
                                <img src={Arrow} alt="days-decrement"/>
                            </span>
                            <span>{currentMeetingDate.date()} ({DAYS[currentMeetingDate.weekday()]}')</span>
                            <span onClick={()=>{this.incrementDate('day')}}><img src={Arrow} alt="days-increment"/></span>
                        </aside>
                    </div>
                    <div>
                        <p>חודש</p>
                        <aside>
                            <span
                             onClick={()=>{this.decrementDate('month')}}
                             className={this.determineDecrementation('month') ? '':'invalid-time-pick'}
                             >
                                 <img src={Arrow} alt="month-decrement"/>
                            </span>
                            <span>{MONTHS[currentMeetingDate.month()]} ({ String(currentMeetingDate.year()).substr(2) })</span>
                            <span onClick={()=>{this.incrementDate('month')}}><img src={Arrow} alt="month-increment"/></span>
                        </aside>
                    </div>


                    <textarea value={notes} name="notes" placeholder='תאור פגישה' onChange={(e)=>{this.handleChange(e)}}></textarea>
                </section>


                {meetingId === 'new' ?
                    <div>
                        <p className={full_name && !phoneErr && !isTaken ? "pros-interface__appointment--create":""} onClick={(e)=>{this.submitForm(e)}}>צור פגישה</p>
                        <p onClick={()=>{this.props.history.goBack()}}>ביטול</p>
                    </div>
                    :
                    <div>
                        <p className={full_name && !phoneErr ? "pros-interface__appointment--create":""} onClick={()=>{this.updateMeeting()}}>עדכן</p>
                        <p onClick={()=>{this.setState({popup:true})}}>ביטול פגישה</p>
                    </div>
                }
            </form>
        )
    }
}

export default withRouter(Appointment)