import React, { Component } from 'react'
import './cart.css'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import * as actions from '../../../actions/index'
//components
import QuantityInput from '../../globals/QuantityInput/QuantityInput'
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
import InnerNavigation from '../../globals/InnerNavigation/InnerNavigation'
import Loader from '../../globals/Loader'
//images
import HeaderPic from '../../../images/categories1/calculator.png'
//configs
import {IMAGE_URL,API_URL, IMAGE_URL_LEVELS,USER_TOKEN} from '../../../configs/configs'
//tools
import {ApiGet,calculatePrices,calculateTotalPrice} from '../../../tools/tools'


class Cart extends Component {

    constructor(){
        super()

        this.state = {
            addLoader:false,
            sortedArray: []
        }
    }

     componentWillMount() {
         console.log('dsdfhdfghdfghdfg')
        let obj = this.props.levels.levelOnes
        if(Object.keys(obj).length === 0 && obj.constructor === Object) {
            this.props.saveVendors()
            this.props.saveLevelOnes()
        }

        
        // let recommendation = this.props.levels.recommendation
        // if(!recommendation || recommendation.length === 0) {
            // await this.props.getRecommendation(this.props.match.params.levelOneId,this.props.typeOfMechiron)
            this.addBidToCart()
        // }
        
    }

    componentWillReceiveProps(){
        if(Object.keys(this.props.levels.levelOnes).length === 0 && this.props.levels.levelOnes.constructor === Object) {
            this.props.saveLevelOnes()
        }

        if(Object.keys(this.props.levels.selectedLevelOne).length === 0 && this.props.levels.selectedLevelOne.constructor === Object) {
            Object.keys(this.props.levels.levelOnes).forEach((key)=> {
                if(this.props.levels.levelOnes[key].level_one_id === this.props.match.params.levelOneId){
                    this.props.saveSelectedLevelOneItem(this.props.levels.levelOnes[key])
                }
            })
        }

        let recommendationCopy = this.props.levels.recommendation.map(item=>item)
        let selectedLevelOne = this.props.levels.selectedLevelOne
        
        if(this.state.sortedArray.length === 0 ) {
            let works = []
            let prods = []
            
            if (selectedLevelOne != null) {
                works = recommendationCopy.filter((a)=> {
                    return (a.product_price === '0' || a.only_work === true)
                })
                prods = recommendationCopy.filter((a)=> {
                    return (a.product_price !== '0' && !a.only_work)
                })
            }
    
            if(works.length > 0)
            works = works.sort((a, b)=> {
                return calculateTotalPrice(b, selectedLevelOne) - calculateTotalPrice(a, selectedLevelOne)
            })
    
            if(prods.length > 0)
            prods = prods.sort((a, b)=> {
                return calculateTotalPrice(b, selectedLevelOne) - calculateTotalPrice(a, selectedLevelOne)
            })
    
            let sortedArray = prods.concat(works)
            this.setState({sortedArray})
        }
        

    }

    addBidToCart = async() => {

        const {desktopMako, desktop} = this.props
        const {levelOneId} = this.props.match.params
        let token = Cookies.get(USER_TOKEN)
        if(!token) {
            if(desktop || desktopMako) {
                this.props.activateCurtain()
                this.props.activateLoginPopup(window.location.pathname)
                return
            } else {
                this.props.history.push(`/login/${levelOneId}`)
            }
        }

        this.setState({addLoader:true},async()=>{
            this.props.removeRoomsWithoutProducts()
            await this.props.initUserCartRoom(this.props.levels.selectedLevelOne,this.props.userCart)
            
            setTimeout(()=>{ 
                Promise.all (this.props.levels.recommendation.map(async(item,index)=>{
                    let makeApiCall = index === this.props.levels.recommendation.length-1
                    this.props.addProductToCart(item,this.props.match.params.levelOneId,makeApiCall)
                }))
    
                let lv1 = this.props.match.params.levelOneId
                let indexInCart = this.props.userCart.userCart.findIndex(item => {return item.level_one_id === lv1})
                this.props.history.push(`/cart/${indexInCart}/leveloneid/${lv1}/token`)
            }, 10);

        })

    }

    changeQuantity = (val,itemId) => {
        this.props.updateRecommendation(val,itemId)
    }

    handleDeleteItem = (item) => {
        this.props.deleteRecommendationItem(item.pid_id)
    }

    handleChangeItemRecommendation = async(item) => {
        let filter = await ApiGet(API_URL + '/filter/getById/' + item.filter_id)
        let finalLevel3ToSend
        if(item.level_three_id != null) {
           finalLevel3ToSend = item.level_three_id 
        } else {
            finalLevel3ToSend = filter.level_three_id
        }
        let lvl3 = await ApiGet(API_URL + '/levelThree/getById/' + finalLevel3ToSend)

        let lvl2 = await ApiGet(API_URL + '/levelTwo/getById/' + lvl3.level_two_id)
        this.props.saveSelectedLevelTwoItem(lvl2)

        this.props.history.push(`/recommendationedit/edit/${item.pid_id}/${this.props.match.params.levelOneId}/${lvl3.level_two_id}/${finalLevel3ToSend}/filterId/${item.filter_id}`)
    }


    render() {

        const { lang,desktop,desktopMako } = this.props
        const { selectedLevelOne,recommendation } = this.props.levels
        let TotalProductsPrice = 0
        let recommendationCopy = this.state.sortedArray.map(item=>item)
        recommendationCopy.map(item=>{
            
            let formula = item.qty_formula
            formula = formula.toString()
            formula = formula.replace(/x/gi, selectedLevelOne.x);
            formula = formula.replace(/y/gi, selectedLevelOne.y);
            formula = formula.replace(/z/gi, selectedLevelOne.z);
            formula = eval(formula)
         
            let total = 
            formula * 
            (
                calculatePrices(item,'work',{x:selectedLevelOne.x,y:selectedLevelOne.y,z:selectedLevelOne.z})
                + 
                calculatePrices(item,'product',{x:selectedLevelOne.x,y:selectedLevelOne.y,z:selectedLevelOne.z}) 
            )
           
            total = parseFloat(Math.round(total * 100) / 100)
            TotalProductsPrice += total
        })


        TotalProductsPrice = TotalProductsPrice * 1.17
        TotalProductsPrice = TotalProductsPrice.toFixed(2)

        return (
            <div className="cart">
                <span>
                    <span className="calculator__nav">
                        <InnerNavigation pages={['ראשי', selectedLevelOne.name,'המלצה שלנו לשיפוץ']} links={['/', '/calculator/' + selectedLevelOne.level_one_id + '/room' , '/recommendation/' + selectedLevelOne.level_one_id ,'/recommendation']} />
                    </span>

                    <div className="cart__heading">
                        <ImageComponent src={IMAGE_URL_LEVELS + 'ace/' + selectedLevelOne.picture_l1_id + '.png'} alt={selectedLevelOne.name} />
                        <div>
                            <h1>בנינו עבורכם</h1>
                        </div>
                    </div>

                    {desktop || desktopMako ? <h1 className="cart__title">ההמלצה שלנו - שיפוץ {selectedLevelOne.name}</h1>:null}

                    <header>
                        <p>{selectedLevelOne.name}</p>
                        <span>סה”כ פריטים: <b>{recommendationCopy.length}</b></span>
                    </header>
                </span>


                <div className="cart__container">
                   

                    {recommendationCopy.map((item,index)=>{

                        let selectedProduct = {...item}
                        let showMeasurementsInName = true 
                
                        if(selectedProduct.height === 'Z'){
                            showMeasurementsInName = false
                        }
                        if(selectedProduct.width === 'Y'){
                            showMeasurementsInName = false
                        }
                        if(selectedProduct.length === 'X'){
                            showMeasurementsInName = false
                        }


                        let stringName = ''

                        if(selectedProduct != null){
                            stringName = selectedProduct.name + ' '
                        }
                
                        if(showMeasurementsInName) {
                            if(selectedProduct != null && selectedProduct.width !== 'N' && selectedProduct.width != null ){
                                stringName += selectedProduct.width + 'X'
                            }
                    
                    
                            if(selectedProduct != null && selectedProduct.height !== 'N' && selectedProduct.height != null){
                                stringName += selectedProduct.height + 'X'
                            }
                    
                    
                            if(selectedProduct != null && selectedProduct.length !== 'N' && selectedProduct.length != null){
                                stringName += selectedProduct.length
                            }
                    
                            if(stringName.charAt(stringName.length -1) === 'X')
                            stringName = stringName.substr(0,stringName.length -1)
                        }

                        let formula = item.qty_formula
                        formula = formula.toString()
                        formula = formula.replace(/x/gi, selectedLevelOne.x);
                        formula = formula.replace(/y/gi, selectedLevelOne.y);
                        formula = formula.replace(/z/gi, selectedLevelOne.z);
                        formula = eval(formula)
                        let total = formula * (calculatePrices(item,'work',{x:selectedLevelOne.x,y:selectedLevelOne.y,z:selectedLevelOne.z}) + calculatePrices(item,'product',{x:selectedLevelOne.x,y:selectedLevelOne.y,z:selectedLevelOne.z}))
                        total = total * 1.17
                        total = parseFloat(Math.round(total * 100) / 100).toFixed(2);

                        let lvl3Id = false
                        if(recommendationCopy.level_three_id != null)
                        lvl3Id = recommendationCopy.level_three_id
                    return <div className="cart__product">
                            <ImageComponent src={IMAGE_URL + '/ace/' + item.picture_id + '.png'} alt="product" />
                            <div className="cart__product__desc">
                                <h1>{stringName}</h1>
                                <p className="cart__product__desc__first-text">{item.description}</p>
                                <div>
                                    <span>
                                        {item.product_price !== '0' ? <p>סה”כ עבודה + מוצר</p>:<p>סה”כ עבודה</p>}
                                        <h4>₪{parseFloat((total)).toLocaleString()}</h4>
                                    </span>
                                    <QuantityInput activatedOn={index} quantity={formula} changeQuantity={this.changeQuantity} itemId={item.pid_id} unit={item.units} header={lang.quantity} />
                                </div>
                                <span>
                                    <p onClick={()=>{this.handleChangeItemRecommendation(item)}} className="cart__product__desc__last-text">פריטים נוספים</p>
                                    <p onClick={()=>{this.handleDeleteItem(item)}} className="cart__product__desc__last-text">הסרת פריט</p>
                                </span>
                            </div>
                        </div>
                    })}

                
                    <div className="cart--popup__total">
                        <p>סה”כ מחיר: </p>
                        <p>₪{parseFloat((TotalProductsPrice)).toLocaleString()}</p>
                    </div>

                    <div className="cart__footer">
                        <button onClick={()=>{this.addBidToCart()}}> {this.state.addLoader ? <Loader></Loader>:<p>הוסף כחדר</p>}</button>
                        <button onClick={()=>{this.props.history.push(`/aces/typeofcart/recommend/cart/${selectedLevelOne.level_one_id}`)}}>לקבלת הצעה</button>
                    </div>

                </div>

                {/* <Link to={"/aces/typeofcart/recommend/cart/" + selectedLevelOne.level_one_id}><button className="button__red"><span>{lang.btnAcePros}</span></button></Link> */}
            </div>
        );
    }
}


function stateToProps({ levels,userCart }) {
    return { levels,userCart }
}
  
export default withRouter(connect(stateToProps, actions)(Cart))