import React, { Component } from 'react'
import './category.css'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
//components
import InnerNavigation from '../../globals/InnerNavigation/InnerNavigation'
import Filter from '../../globals/Filter/Filter'
import Loader from '../../globals/Loader'
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
//images
import AddIcon from '../../../images/icons/addicon.svg'
//configs
import {IMAGE_URL,API_URL} from '../../../configs/configs'
//tools
import {ApiGet,calculatePrices} from '../../../tools/tools'

//work pic
import WorkPic from '../../../images/worke.png'
import Eqpic from '../../../images/equipment.png'

class Category extends Component {
    constructor() {
        super()

        this.state = {
            testFilter: false,
            loader:false,
            limit:10,
            page:0
        }
    }

    //to be removed- for testing purpose
    testFilter = () => {
        this.setState({ testFilter: true })
    }

    selectProduct = (product) => {
        this.props.saveSelectedProduct(product)
    }

    componentWillUnmount() {
        this.props.resetCategoryState()
        this.props.resetProductsSaveBoolean()
        this.props.setReverseFiltersBoolean()
        this.props.setReverseLvl2Boolean()
        this.props.setReverseLvl1Boolean()
        this.props.setReverseLvl3Boolean()
        document.removeEventListener('scroll', this.trackScrolling);
       
    }

    async componentWillMount() {
      
        if(this.props.match.params.filterId)
        this.props.setFilter(this.props.match.params.filterId)
       
        // this.props.resetCategoryState()
        this.props.saveVendors()
        this.props.saveLevelOnes()
        this.props.reInitPagination()

        // let lvl2 = await ApiGet(API_URL + '/levelTwo/getById/' + this.props.match.params.levelTwoId)
        // this.props.saveSelectedLevelTwoItem(lvl2)
    }

    async componentDidUpdate(prevProps){
        if(Object.keys(this.props.levels.levelOnes).length === 0 && this.props.levels.levelOnes.constructor === Object) {
            this.props.saveLevelOnes()
        }

        //show all products if no filter id
        if(prevProps.match.params.filterId && !this.props.match.params.filterId){
            this.props.setFilter('')
        }

    }

    async componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling);
        if(this.props.match.params.filterId) {
            this.props.setFilter(this.props.match.params.filterId)
        }

        let lvl2 = await ApiGet(API_URL + '/levelTwo/getById/' + this.props.match.params.levelTwoId)
        this.props.saveSelectedLevelTwoItem(lvl2)
        let lvl3 = await ApiGet(API_URL + '/levelThree/getById/' + this.props.match.params.levelThreeId)
        this.props.saveSelectedLevelThreeItem(lvl3)
        await this.props.saveFilters(this.props.levels.selectedLevelThree,true)
        this.props.getProductsLimit(this.props.levels.filters,this.state.limit,this.state.page, this.props.typeOfMechiron)
    }
    

     async componentWillReceiveProps(nextProps) {
        let levelOne
        const { levelOneId,levelTwoId,levelThreeId } = this.props.match.params
        if(nextProps.levels.levelOnes !== this.props.levels.levelOnes && nextProps.levels.levelOnes.length > 0)
        levelOne = nextProps.levels.levelOnes.find(level=>{
            return level.level_one_id === this.props.match.params.levelOneId
        })
        if(levelOne)
        this.props.initUserCartRoom(levelOne,nextProps.userCart)
        // handle route change when current product selected
        if(this.props.levels.selectedProduct !== nextProps.levels.selectedProduct && !window.location.href.includes('edit'))
        this.props.history.push(`/products/${levelOneId}/${levelTwoId}/${levelThreeId}/${nextProps.levels.selectedProduct.pid_id}`)
        // handle route change when current product selected - edit mode recomendation
        if(this.props.levels.selectedProduct !== nextProps.levels.selectedProduct && window.location.href.includes('edit') && window.location.href.includes('recommendation'))
        this.props.history.push(`/recommendation/edit/${this.props.match.params.pidToEdit}/${levelOneId}/${levelTwoId}/${levelThreeId}/filter/${this.props.match.params.filterId}/${nextProps.levels.selectedProduct.pid_id}`)
         // handle route change when current product selected - edit mode cart
         if(this.props.levels.selectedProduct !== nextProps.levels.selectedProduct && window.location.href.includes('edit') && window.location.href.includes('cart'))
         this.props.history.push(`/cart/edit/roomindex/${this.props.match.params.indexInCart}/${this.props.match.params.pidToEdit}/${levelOneId}/${levelTwoId}/${levelThreeId}/filter/${this.props.match.params.filterId}/${nextProps.levels.selectedProduct.pid_id}`)
        //save current lvl one
        let selectedLevelOne = this.props.levels.selectedLevelOne
        if(selectedLevelOne != null)
        if(Object.keys(selectedLevelOne).length === 0 && selectedLevelOne.constructor === Object && !this.props.levels.lvl1Boolean) {
            this.props.setLvlOnesBoolean()
            let lvl1 = await ApiGet(API_URL + '/levelOne/getById/' + this.props.match.params.levelOneId)
            if(lvl1 != null)
            this.props.saveSelectedLevelOneItem(lvl1)
        }

        // save current level two
        let selectedLevelTwosInit = this.props.levels.selectedLevelTwo
        if(Object.keys(selectedLevelTwosInit).length === 0 && selectedLevelTwosInit.constructor === Object && !this.props.levels.lvl2Boolean) {
            this.props.setLvltwosBoolean()
            let lvl2 = await ApiGet(API_URL + '/levelTwo/getById/' + this.props.match.params.levelTwoId)
            this.props.saveSelectedLevelTwoItem(lvl2)
            
        }
        
        //save current level three
        let levelThreeInit = this.props.levels.selectedLevelThree
        if(Object.keys(levelThreeInit).length === 0 && levelThreeInit.constructor === Object && !this.props.levels.lvl3Boolean) {
            this.props.setLvlThreesBoolean()
            let lvl3 = await ApiGet(API_URL + '/levelThree/getById/' + this.props.match.params.levelThreeId)
            this.props.saveSelectedLevelThreeItem(lvl3)
        }
        //save vendors
        let vendors = this.props.levels.vendors
        if(Object.keys(vendors).length === 0 && vendors.constructor === Object) {
            this.props.saveVendors()
        }

        let filters = this.props.levels.filters
        let productsArr = this.props.levels.products

        //save filters
        if(Object.keys(levelThreeInit).length !== 0 && levelThreeInit.constructor === Object && filters.length === 0) {
            if(!this.props.levels.filtersBoolean){
                console.log(this.props.levels.selectedLevelThree)
                await this.props.saveFilters(this.props.levels.selectedLevelThree,true)
            }           
        }
        
        //save products 
        
        if(filters.length > 0 && productsArr.length === 0){
           
            if(!this.props.levels.stopFunctionSaveProducts){
                // this.props.getProducts(this.props.levels.filters)
                await this.props.getProductsLimit(this.props.levels.filters,this.state.limit,this.state.page, this.props.typeOfMechiron)
                // if(this.props.levels.product){
                //     this.setState({noMoreLoaders:true})
                // }
            }
        }

 
        // get recommendation

        if(this.props.levels.recommendation !== nextProps.levels.recommendation) {
            if(this.props.levels.recommendation.length === 0) {
                this.props.getRecommendation(this.props.match.params.levelOneId,this.props.typeOfMechiron)
            }
        }
       
    }
 
    trackScrolling = () => {
        // console.log('document Element scroll hieght: ', document.documentElement.scrollHeight);
        // console.log('window inner height: ', window.innerHeight);
        if(document.documentElement.scrollHeight !== window.innerHeight) {
            let docHeight = document.documentElement.getBoundingClientRect().height
            let windowHeight = window.innerHeight
            let scrollTop = window.scrollY
            if(docHeight - windowHeight - 50 <= scrollTop) {
                this.setState(prevState => ({
                    page: prevState.page + 1,
                    loader:true
                }),async()=>{
                    if(!this.props.levels.stopPagination)
                    await this.props.getProductsLimit(this.props.levels.filters,this.state.limit,this.state.page * this.state.limit, this.props.typeOfMechiron)
                })
            }
        }

    }

    setUrlFilter = (param) => {
        if(window.location.href.includes('edit'))
        return
        const {levelOneId, levelTwoId, levelThreeId} = this.props.match.params
        let filterId = this.props.levels.filters[param].filter_id
        
        this.props.history.push(`/productsCategory/${levelOneId}/${levelTwoId}/${levelThreeId}/filterIdInCategory/${filterId}`)
    }

    render() {
        const { lang } = this.props

        const { products,vendors,selectedLevelThree,selectedLevelTwo,selectedLevelOne,currentFilter } = this.props.levels
        let activeFilterIndex

        let productArray = products.filter((item)=>{
       
            if(this.props.match.params.pidToEdit){
                return item.pid_id !== this.props.match.params.pidToEdit
            } else {
                return true
            }
            
        })

       
        if(currentFilter) {
            productArray = productArray.filter((item)=>{
                return item.filter_id === currentFilter
            })
        } else {
            productArray = products.map((item)=>{
                return item
            })
        }


        if(this.props.levels.filters.length > 0) {
            activeFilterIndex = this.props.levels.filters.findIndex((item)=>{
                return item.filter_id === this.props.match.params.filterId
            })
        }

        let isEdit = window.location.pathname.includes('edit')

        let lvl1Copy = {...selectedLevelOne}
       
        if(lvl1Copy == null || Object.keys(lvl1Copy).length === 0) {
            lvl1Copy = {}
            lvl1Copy.x = 100
            lvl1Copy.y = 100
            lvl1Copy.z = 100
        }
    
        let bool = (this.props.match.params.filterId == null || this.props.match.params.filterId)
        let cartEdit = window.location.href.includes('cart') && window.location.href.includes('edit')


        return (
            <div className="category">
                <section className="category__first-section">
                {this.props.match.params.levelOneId !== 'all' && bool && !cartEdit?
                    <InnerNavigation
                      pages={['ראשי', selectedLevelOne.name, selectedLevelTwo.name,selectedLevelThree.name]}
                      links={[
                          '/',
                          '/calculator/' + selectedLevelOne.level_one_id + '/room',
                          '/calculator/' + selectedLevelOne.level_one_id + '/room/' + selectedLevelTwo.level_two_id,
                          '/products/' + selectedLevelOne.level_one_id + '/' + selectedLevelTwo.level_two_id + '/' + selectedLevelThree.level_three_id
                        ]} 
                    />
                    :
                    cartEdit ?
                    <InnerNavigation 
                        pages={['עגלת פריטים', selectedLevelOne.name, selectedLevelThree != null ? selectedLevelThree.name:null]}
                        links={['/cart/token',`/cart/${this.props.match.params.indexInCart}/leveloneid/${this.props.match.params.levelOneId}/token`,`/`]}
                    />
                    :
                    !bool ? 
                    <InnerNavigation 
                       pages={['ראשי', selectedLevelOne.name, 'בנינו עבורכם',selectedLevelThree.name]}
                       links={[
                           '/',
                           `/calculator/${selectedLevelOne.level_one_id}/room`,
                           `/recommendation/${selectedLevelOne.level_one_id}`,
                           `/recommendation/edit/${this.props.match.params.pidToEdit}/${this.props.match.params.levelOneId}/${this.props.match.params.levelTwoId}/${this.props.match.params.levelThreeId}/filter/${this.props.match.params.filterId}`
                        ]}
                    />
                    :
                    <InnerNavigation 
                        pages={['כל פרקי המחירון', selectedLevelTwo.name, selectedLevelThree.name]}
                        links={['/allchapters',`/calculator/all/room/${this.props.match.params.levelTwoId}`,`${this.props.match.params.levelThreeId}`]}
                    />
                
                }

                    {this.props.desktopMako ? <h1>{selectedLevelThree.name} - מציג {productArray.length} פריטים</h1>:<h1>{selectedLevelThree.name}</h1>}
                </section>


                <Filter customCallBack={this.setUrlFilter} desktopMako={this.props.desktopMako} numberOfItems={productArray.length} desktop={ (this.props.desktop || this.props.desktopMako) } trackScrolling={this.trackScrolling} activeFilterIndex={activeFilterIndex} lang={lang} testFilter={this.testFilter} filterParams={this.props.levels.filters} />

               
                {products.length === 0? 
                    <div className="category__loaders">
                        <div className="category__loaders__item">
                            <figure></figure>
                            <aside></aside> <aside></aside> <aside></aside> <aside></aside> <aside></aside>
                        </div>
                        <div className="category__loaders__item">
                            <figure></figure>
                            <aside></aside> <aside></aside> <aside></aside> <aside></aside> <aside></aside>
                        </div>
                        <div className="category__loaders__item">
                            <figure></figure>
                            <aside></aside> <aside></aside> <aside></aside> <aside></aside> <aside></aside>
                        </div>
                        <div className="category__loaders__item">
                            <figure></figure>
                            <aside></aside> <aside></aside> <aside></aside> <aside></aside> <aside></aside>
                        </div>
                    </div>  
                    :
                    null
                }



                <div className="category__items__container">

                    {productArray.map(product=>{
                        let productCopy = {...product}

                        let productVendor = {name:''}
                        vendors && vendors.length > 0 && vendors.map(vendor=>{
                            if(vendor.vendor_id === productCopy.supplier_id){
                                productVendor = vendor
                            }
                        })

                        let total = calculatePrices(productCopy,'product',{x:lvl1Copy.x,y:lvl1Copy.y,z:lvl1Copy.z}) + calculatePrices(productCopy,'work',{x:lvl1Copy.x,y:lvl1Copy.y,z:lvl1Copy.z})
                        total = total * 1.17
                        total = parseFloat(Math.round(total * 100) / 100);

                        let stringName = ''
                
                        if(stringName.charAt(stringName.length -1) === 'X')
                        stringName = stringName.substr(0,stringName.length -1)

                        let showMeasurementsInName = true

                        if(productCopy.length === 'X' || productCopy.width === 'Y' || productCopy.height === 'Z'){
                            showMeasurementsInName = false
                        }

                        if(productCopy.height === 'Z' && selectedLevelOne !=null && selectedLevelOne.z!=null){
                            productCopy.height = selectedLevelOne.z.toString()
                        }
                        if(productCopy.width === 'Y' && selectedLevelOne !=null && selectedLevelOne.y!=null){
                            productCopy.width = selectedLevelOne.y.toString()
                        }
                        if(productCopy.length === 'X' && selectedLevelOne !=null && selectedLevelOne.x!=null){
                            productCopy.length = selectedLevelOne.x.toString()
                        }

                        // if(product.pid_id === 'P1000001')
                        // console.log(showMeasurementsInName,product)

                        //build name
                        if(productCopy != null){
                            stringName = productCopy.name + ' '
                        }
                        //

                        //init measurements in name
                        if(productCopy != null && productCopy.width !== 'N' && productCopy.width != null && showMeasurementsInName){
                            let prodCopy = productCopy.width
                            stringName += prodCopy + 'X'
                        }
                
                
                        if(productCopy != null && productCopy.height !== 'N' && productCopy.height != null && showMeasurementsInName){
                            let prodCopy = productCopy.height
                            stringName += prodCopy + 'X'
                        }
                
                
                        if(productCopy != null && productCopy.length !== 'N' && productCopy.length != null && showMeasurementsInName){
                            let prodCopy = productCopy.length
                            stringName += prodCopy
                        } 

                        //

                        // if(product.height === 'Z') {
                        //     product.height = 100
                        // }

                        // if(product.width === 'Y') {
                        //     product.width = 100
                        // }

                        // if(product.length === 'Z') {
                        //     product.length = 100
                        // }

                        // && this.props.match.params.levelOneId === 'all'

                        
                        if(!this.props.desktop) {
                            if(stringName.length > 47){
                                stringName = stringName.substr(0,44) + '...'
                            }
                        } else {
                            if(stringName.length > 67){
                                stringName = stringName.substr(0,64) + '...'
                            }
                        }


                        return <div className="category__item" key={productCopy.pid_id} onClick={()=>{this.selectProduct(productCopy)}}>
                                    <section>
                                        <ImageComponent
                                            src={
                                                productCopy.picture_id === 'work' ? 
                                                WorkPic : productCopy.picture_id === 'equipment' ?
                                                Eqpic : `${IMAGE_URL}/ace/${productCopy.picture_id}.png`
                                            }
                                            alt="product" 
                                         />
                                    </section>

                                    <section>
                                        <h3>{stringName}</h3>
                                        <h4>{productCopy.description.length < 85 ? productCopy.description:productCopy.description.substr(0,82) + '...'}</h4>
                                        <p>{lang.manifacturer + productVendor.name}</p>
                                        <span>
                                            <h5>מחיר -{productCopy.units}</h5>
                                            <h5>₪{parseFloat(total.toFixed(2)).toLocaleString()}</h5>
                                        </span>
                                        <p style={{opacity:'0.6',transform:'translateY(15px)'}}>*כולל עבודה, חומר ופריט</p>
                                        <h6></h6>
                                        <section>
                                            <button className="button__red">
                                                <img src={AddIcon} alt="add" />
                                                <span>
                                                   {
                                                       !isEdit ?
                                                       lang.addButton
                                                       :
                                                       lang.editButton
                                                   }
                                                </span>
                                            </button>
                                        </section>

                                    </section>
                                </div>
                    })}

                </div>
                {this.props.levels.categoryLoader && this.state.loader ? 
                    <div className="category__loader__bottom"><Loader/></div>
                    :
                    null
                }
                
            </div>
        );
    }
}

function stateToProps({ levels,userCart }) {
    return { levels,userCart }
}
  
export default withRouter(connect(stateToProps, actions)(Category))
