import React, { Component } from 'react'
import './filter.css'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
//images
import FilterIcon from '../../../images/icons/filter.svg'

class Fliter extends Component {

    constructor(props){
        super(props)

        this.state = {
            activeSlide: this.props.activeFilterIndex
        }
    }

    
    componentWillReceiveProps(nextProps) {
        if(this.props.activeFilterIndex !== nextProps.activeFilterIndex)
        this.setState({activeSlide:nextProps.activeFilterIndex})
    }

    onSliderInit = () => {
        // fix bug that moves the slider to the end on init
        if(!this.props.desktop && this.slider != null)
        setTimeout(()=>{ this.slider.slickGoTo(this.props.filterParams.length - 2.5,false) }, 0);
    }

    preventScroll = () => {
        document.ontouchmove = function (e) {
            e.preventDefault();
        }
    }

    enableScroll = () => {
        document.ontouchmove = function (e) {
            return
        }
    }

    filter = (filterId, index) => {
       

        if(this.props.trackScrolling){
            setTimeout(()=>{    this.props.trackScrolling() }, 10);
        }
        
      if(index === this.state.activeSlide) {
        this.setState({
          activeSlide:-1
        },()=>{
            if(this.props.pros) {
                this.props.setFilterPros(null,index)
            } else {
                this.props.setFilter(null)
            }
        })

        return
      } else {
        this.setState({
          activeSlide:index
        })
      }
       
       if(this.props.pros) {
          this.props.setFilterPros(filterId,index)
       } else {
          this.props.setFilter(filterId)
       }

       if(this.props.customCallBack)
       this.props.customCallBack(index)
    }

    render() {
        let settings = {
            infinite: false,
            arrows: false,
            speed: 500,
            slidesToShow: 3.5,
            slidesToScroll: 3.5,
            rtl: true,
            initialSlide: 1.5
        };

        const { lang,filterParams,desktop,desktopMako,numberOfItems,aces } = this.props

        let arr = ['1','2']

        return (
            <div className="filter">
                {desktop ? 
                    <div className="filter__desktop">
                        {aces ? 
                            <p>מציג {numberOfItems ? numberOfItems:0} אנשי מקצוע</p>
                            :
                            desktopMako ? 
                            null
                            :
                            <p>מציג {numberOfItems ? numberOfItems:0} פריטים</p>
                        }
                        
                        <div>
                            {this.props.filterParams.length > 1 ? this.props.filterParams.map((item, index) => {
                                return <p className={this.state.activeSlide === index ? "filter__desktop__item filter__desktop__item--active":"filter__desktop__item"} onClick={()=>{this.filter(item.filter_id,index)}}>{item.name}</p>
                            }):null}
                        </div>

                    </div>
                    :
                    <Slider {...settings} ref={c => (this.slider = c)} onInit={()=>{this.onSliderInit()}}>
                        <div className="filter__item__container" style={{width:'100px'}} onTouchStart={()=>{this.preventScroll()}} onTouchEnd={()=>{this.enableScroll()}}>
                            <div className="filter__item" >
                                <img src={FilterIcon} alt="filter" />
                                <p>{lang.filter}</p>
                            </div>
                        </div>

                        {
                            this.props.filterParams.length === 0 ?
                            arr.map(item=>{
                                return <div key={item} style={{minWidth:'110%!important'}}></div>
                            })
                            :
                            null
                        }

                        {this.props.filterParams.map((item, index) => {
                            return <div key={item} className="filter__item__container" onTouchStart={()=>{this.preventScroll()}} onTouchEnd={()=>{this.enableScroll()}}>
                                {index === filterParams.length - 1 && filterParams.length >= 3 ? <aside style={{ width: '10px', height: '50px' }}></aside> : null}
                                <div className={this.state.activeSlide === index ? "filter__item filter__item--active":"filter__item"} onClick={()=>{this.filter(item.filter_id,index)}}>
                                    <p>{item.name}</p>
                                </div>
                                {index === filterParams.length - 1 && filterParams.length >= 3 ? <aside style={{ width: '10px', height: '50px' }}></aside> : null}
                            </div>
                        })}
                    </Slider>
                }

            </div>
        );
    }
}


function stateToProps({ levels }) {
  return { levels }
}

export default (connect(stateToProps, actions)(Fliter))
