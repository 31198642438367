import React, { Component } from 'react'
import {Link, withRouter} from 'react-router-dom'
import Cookies from 'js-cookie'
import './newnavbar.css' 
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
//config
import {API_URL, USER_TOKEN,HOST_NAME} from '../../../configs/configs'
//img
import AceLogo from '../../../images/newnavbar/logo-ace.png'
import MakoLogo from '../../../images/newnavbar/logo-mako.svg'
import MechironLogo from '../../../images/newnavbar/logo-mechiron.svg'
import WhiteLetter from '../../../images/icons/whiteletter.svg'
import AceRedLogo from '../../../images/acered.png'
// img search
import SearchAce from '../../../images/newnavbar/search-ace.svg'
import SearchMako from '../../../images/newnavbar/search-mako.svg'
import SearchMechiron from '../../../images/newnavbar/search-mechiron.svg'
//img 2nd section
import branchesAce from '../../../images/newnavbar/branches.svg'
import backToAce from '../../../images/newnavbar/backtoace.svg'
import backToMako from '../../../images/newnavbar/backtomako.svg'
import Rooms from '../../../images/newnavbar/rooms.svg'
import Pros from '../../../images/newnavbar/pros.svg'
import MyListAce from '../../../images/newnavbar/mylistace.svg'
import MyListMako from '../../../images/newnavbar/mylistmako.svg'
import MyListMechiron from '../../../images/newnavbar/mylistmechiron.svg'
import ClientsAce from '../../../images/newnavbar/clientsace.svg'
import ClientsMako from '../../../images/newnavbar/clientsmako.svg'
import ClientsMechiron from '../../../images/newnavbar/clientsmechiron.svg'
import Contact from '../../../images/newnavbar/contactus.svg'
import AllChapters from '../../../images/newnavbar/allchapters.svg'
//go back arrows
import AceBack from '../../../images/newnavbar/arrowace.svg'
import MakoBack from '../../../images/newnavbar/arrowmako.svg'
import MechironBack from '../../../images/newnavbar/arrowmechiron.svg'

import ArrowRes from '../../../images/icons/backArrow.svg'

import ArrowAllAce from '../../../images/icons/arrowallace.svg'
import ArrowAllMako from '../../../images/icons/arrowallmako.svg'
import ArrowAllMechiron from '../../../images/icons/arrowallmechiron.svg'

import History from '../../../images/icons/history.svg'


//comps
import {ApiGet,ApiGetWithHeader} from '../../../tools/tools'
import Login from '../../layouts/Login/Login'

class NewNavbarDesktop extends Component {

    constructor(){
        super()

        this.state = {
            navScrolled:false,
            navMenuActive: false,
            levelTwos: [],
            prevPath: '',
            //search
            displayLastSearches:false,
            noResults:false,
            searchResults:[],
            hamburger:false,
            value:'',
            page: 0,
            limit:20
        }

        this.searchContainerRef = React.createRef();
    }

    async componentWillMount() {
        let res = await ApiGet(API_URL + `/levelTwo/getAll`)
        if(res)
        this.setState({levelTwos:res})

        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})
    }
    

    componentDidMount() {
        window.addEventListener("scroll",this.handleScroll,{passive: true});
        window.addEventListener("click",this.handleClick,{passive: true});
    }

    componentWillUnmount() {
        window.addEventListener("scroll",this.handleScroll,{passive: true});
        window.addEventListener("click",this.handleClick,{passive: true});
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
          this.setState({ prevPath: this.props.location.pathname })
        }
      }

    handleScroll = () => {
        if(window.pageYOffset > 0) {
            this.setState({navScrolled:true})
        } else {
            this.setState({navScrolled:false})
        }
    }

    handleClick = (event) => {
        
        if(event.target.className !== 'navbar__hamburger')
        this.setState({navMenuActive:false})

        if(!event.target.className.includes('section__search') && event.target.name !== 'nav-search' && this.state.displayLastSearches){
            this.setState({
                displayLastSearches:false,
                value:'',
                searchResults:[],
                page:0
            })
        }
    }

    toggleNav = (e) => {
        if(e != null)
        e.stopPropagation()

        this.setState(prevState => ({
            navMenuActive: !prevState.navMenuActive
        }),()=>{
            this.props.toggleCurtain()
        });
    }

    activateNav = (e) => {
        if(e != null)
        e.stopPropagation()
        setTimeout(()=>{ this.setState({navMenuActive: true},()=>{this.props.activateCurtain()}) }, 0);
        
    }

    disActivateNav = (e) => {
        if(e != null)
        e.stopPropagation()
        this.setState({navMenuActive: false},()=>{this.props.disActivateCurtain()})
    }

    
    buildId = (product) => {
        let dataString = 
        "UC:"
        + "\"" + ', ' + "product_id:"
        + "\"" + product.pid_id + "\"" + ', ' + "product_name:"
        + "\"" + product.name + "\""
        if(this.props.userDetails)
        dataString = dataString + ', ' + "User_full_name:" 
        + "\"" + this.props.userDetails.full_name + "\"" +  ', ' + "User_phone:"
        + "\"" + this.props.userDetails.phone + "\"" 

        if(this.state.value)
        dataString = dataString + ', ' + "User_has_typed:" 
        + "\"" + this.state.value + "\"" 
        return dataString
    }

    goto = (link) => {
            this.setState({navMenuActive:false})
            this.props.disActivateCurtain()
            this.props.history.push(link)
    }

    goBackFunc = () => {
        if(this.state.prevPath.includes('login') && window.location.pathname !=='/regulations'){
            this.props.history.push(`/`)
        } else {
            this.props.history.goBack()
        }
    }

    handleAceCartClick = () => {
        if(window.location.href.includes('cart') && window.location.href.includes('token'))return
        // if(!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null ){
        //     this.props.history.push('/cart/token')
        // } else {
            this.props.removeRoomsWithoutProducts()
            if(this.props.userCart.userCart.length > 0){
                this.props.history.push(`/cart/0/leveloneid/${this.props.userCart.userCart[0].level_one_id}/token`)
            } else {
                this.props.history.push('/cart/token')
            }
        // }
       
    }

    // SEARCH
    sleep = m => new Promise(r => this.setState({timeout:setTimeout(r, m)}))

    searchForValue = async (value) => {
        this.setState({loader:true,noResults:false,value})
        
        clearTimeout(this.state.timeout)
        await this.sleep(500)
        
        if(value.length <= 2) {
            this.setState({searchResults:[],loader:false})
            return
        }

        let token = Cookies.get(USER_TOKEN)
        const {typeOfMechiron} = this.state
        let result = await ApiGetWithHeader(API_URL + '/product/search?keyword=' + value + `&page=${0}&limit=${5}&type=${typeOfMechiron}`,token)

        if(result.length === 0){
            
           this.setState({searchResults:result,loader:false,noResults:true})
           return
        }

        if(result && result.length > 0){
            this.setState({searchResults:result})
        }
    }

    goToProdPage = async(item,queryParam) => {
        
        let res = await ApiGet(API_URL + '/filter/getById/' + item.filter_id)
        let lvl3 = res.level_three_id
        lvl3 = lvl3.split(';')
        lvl3 = lvl3[0]
        
        
        let storedPids = JSON.parse(localStorage.getItem("ace-mechiron-searches"))
        
        if(storedPids == null){
            storedPids = [item]
            localStorage.setItem("ace-mechiron-searches", JSON.stringify(storedPids));
        } else {
            if(storedPids.length < 5){
                storedPids.push(item)
                localStorage.setItem("ace-mechiron-searches", JSON.stringify(storedPids));
            } else {
                storedPids.shift(item)
                storedPids.push(item)
                localStorage.setItem("ace-mechiron-searches", JSON.stringify(storedPids));
            }

        }
        
        let res2 = await ApiGet(API_URL + '/product/getById/' + item.pid_id)
        
        let lvl2Res = await ApiGet(API_URL + '/levelTwo/getById/' + item.level_two_id)
        this.props.saveSelectedLevelTwoItem(lvl2Res)
        let lvl3Res = await ApiGet(API_URL + '/levelThree/getById/' + lvl3)
        this.props.saveSelectedLevelThreeItem(lvl3Res)
        await this.props.saveFilters(lvl3Res,true)        
        await this.props.saveSelectedProduct(res2)   
        
        let selectedFilter = this.props.levels.filters.filter((subitem) => {
            return item.filter_id === subitem.filter_id
        })

        selectedFilter = selectedFilter[0]
        if (selectedFilter && selectedFilter.filter_id) {
            selectedFilter = selectedFilter.filter_id
        }

        this.props.setFilter(selectedFilter)
        this.props.resetProductsSaveBoolean() 
        this.props.resetCategoryState()
        
        this.props.history.push(`/`)
        this.props.history.push(`/products/all/${item.level_two_id}/${lvl3}/${item.pid_id}/?q=${queryParam}`)

    }


    trackScrolling = () => {
        if(this.searchContainerRef.current && this.searchContainerRef.current != null) {
           if(this.searchContainerRef.current.scrollTop >= (this.searchContainerRef.current.scrollHeight - this.searchContainerRef.current.offsetHeight) && this.state.searchResults.length > 0) {
               this.setState(prevState => ({
                   page: prevState.page + prevState.limit
               }),()=>{
                   this.searchMore()
               })
           }

        }
    }

    searchMore = async() => {
        const {typeOfMechiron} = this.state
        let result = await ApiGet(API_URL + '/product/search?keyword=' + this.state.value + `&page=${this.state.page}&limit=${5}&=${typeOfMechiron}`)
        if(result && result.length > 0) {
            let newState = this.state.searchResults.map(item=>item)
            newState = newState.concat(result)
            this.setState({searchResults:newState})
        }
    }

    toggleMenuAce = () => {
        this.setState(prevState => ({
            hamburger: !prevState.hamburger
        })); 
    }

    handleAuthentication = () => {
        if(!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null) {
            this.props.activateCurtain()
            this.props.activateLoginPopup('/')
        } else {
            Cookies.remove(USER_TOKEN)
            this.props.history.push(`/`)
            this.props.resetCart()
        }
    }

    goToSearchAll = async() => {
        let query = this.state.value
        let typeOfMechiron = this.state.typeOfMechiron
        let result = await ApiGet(API_URL + '/product/search?keyword=' + this.state.value + `&page=${this.state.page}&limit=${50}&type=${typeOfMechiron}`)
        this.props.saveSearchResults(result)
        console.log(result)
        this.props.history.push(`/searchResults?q=${query}`)
    }


    render() {
        const {typeOfMechiron} = this.props
        const {searchResults,value,displayLastSearches} = this.state
        let lastSearchs = JSON.parse(localStorage.getItem("ace-mechiron-searches"))
        let logoSrc = typeOfMechiron === 'ace' ? AceLogo:typeOfMechiron === 'mako' ? MakoLogo:MechironLogo


        return (
            <nav className="new-navbar-desktop">
                <section className="new-navbar-desktop__first-section">
                    <div className="new-navbar-desktop--grid">
                        <div className="new-navbar-desktop__first-section--part">
                            <img className="new-navbar-desktop__first-section--part--img" src={logoSrc} alt="logo" onClick={()=>{this.goto('/')}}/>

                            <div className="navbar__ace__desktop__section__search">
                                <input 
                                autoComplete="off"
                                value={value}
                                type="text"
                                name="nav-search"
                                placeholder="אני רוצה לשפץ…"
                                onChange={(e)=>{this.searchForValue(e.target.value)}}
                                onFocus={()=>{this.setState({displayLastSearches:true})}}
                                //   onBlur={()=>{this.setState({displayLastSearches:false})}}
                                />

                                {
                                    typeOfMechiron === 'ace' ? 
                                    <img src={SearchAce} alt="search"/>
                                    : typeOfMechiron === 'mako' ? 
                                    <img src={SearchMako} alt="search"/>
                                    :
                                    <img src={SearchMechiron} alt="search"/>
                                }

                                {searchResults != null && searchResults.length > 0 ? 
                                    <div className="navbar__ace__desktop__section__search__menu" onScroll={()=>{this.trackScrolling()}} ref={this.searchContainerRef}>
                                        <header>תוצאות חיפוש</header>
                                        {searchResults !=null && searchResults.length > 0 && searchResults.map(item=>{
                                        return <div id={this.buildId(item)} onClick={()=>{this.goToProdPage(item,this.state.value)}}>
                                                    <p id={this.buildId(item)}>{item.name}</p>
                                                    <img id={this.buildId(item)} src={ArrowRes} alt="search-product"/>
                                                </div>
                                        })}

                                        {this.state.value && this.state.value != null && this.state.value.length > 2 ?
                                            <div className="navbar__search-results--all" onClick={()=>{this.goToSearchAll()}}>
                                                
                                                {
                                                    typeOfMechiron === 'ace' ? 
                                                    <img src={ArrowAllAce} alt="all-results"/>
                                                    : typeOfMechiron === 'mako' ? 
                                                    <img src={ArrowAllMako} alt="all-results"/>
                                                    :
                                                    <img src={ArrowAllMechiron} alt="all-results"/>
                                                }
                                                <p>לכל תוצאות החיפוש</p>
                                            </div>
                                            :
                                            null
                                        }


                                    </div>
                                    :
                                    null
                                }

                                {displayLastSearches && !value && searchResults != null &&  searchResults.length === 0 && lastSearchs != null && lastSearchs.length > 0? 
                                    <div className="navbar__ace__desktop__section__search__menu">
                                        <header>חיפושים אחרונים</header>
                                        {lastSearchs && lastSearchs.length > 0 && lastSearchs.map(item=>{
                                        return <div id={this.buildId(item)} onClick={()=>{this.goToProdPage(item,this.state.value)}}>
                                                    <p id={this.buildId(item)}>{item.name}</p>
                                                    <img id={this.buildId(item)} style={{transform:'rotate(0deg)'}} src={History} alt="searches-history"/>
                                                </div>
                                        })}
                                    </div>
                                    :
                                    null
                                }
                            </div> 
                        </div>

                        <div className="new-navbar-desktop__first-section--part">
                        <button onClick={()=>{this.handleAuthentication()}}>{!Cookies.get(USER_TOKEN) || Cookies.get(USER_TOKEN) == null ? 'התחבר':'התנתק'}</button>

                        {this.props.userCart.userIsAcePro ? 
                            <button onClick={()=>{this.goto(`/pros/interface/${this.props.userCart.userId}/customers/0`)}}>לקוחות</button>
                            :
                            //old contact popup func this.props.activateContactPopup(2)
                            <button onClick={()=>{this.props.activateContactPopup()}}>
                                <img src={WhiteLetter} alt="contact"/>
                                צור קשר
                            </button>
                        }
                        </div>
                    </div>

                </section>

                <section className="new-navbar-desktop__second-section">
                    <div className="new-navbar-desktop--grid">
                        <div className="new-navbar-desktop__second-section--part">
                            <a onClick={()=>{this.goto('/aces/cart')}}>
                                <img src={Pros} alt=""/>
                                <p>בעלי מקצוע</p>
                            </a>
                            <a onClick={()=>{this.goto('/choice')}}>
                                <img src={Rooms} alt=""/>
                                <p>חדרים</p>
                            </a>
                            <a onClick={()=>{this.goto('/allchapters')}}>
                                <img src={AllChapters} alt=""/>
                                <p>המחירון</p>
                            </a>
                            {/* <a onClick={()=>{this.props.activateContactPopup()}}>
                                <img src={Contact} alt=""/>
                                <p>צור קשר</p>
                            </a> */}
                            {typeOfMechiron === 'ace' ? 
                                <a href="https://www.ace.co.il/stores">
                                    <img src={branchesAce} alt=""/>
                                    <p>סניפים</p>
                                </a>
                                :
                                null
                            }

                            {typeOfMechiron === 'ace' ? 
                                <a href="https://www.ace.co.il">
                                    <img src={backToAce} alt=""/>
                                    <p>חזרה ל</p>
                                    <img src={AceRedLogo} alt="" style={{width:'32px',height:'15px',marginRight:'5px',transform:'translateY(-2px)'}}/>
                                </a>
                                :
                                typeOfMechiron === 'mako' ? 
                                <a href="https://www.mako.co.il">
                                    <img src={backToMako} alt=""/>
                                    <p>חזרה ל-mako</p>
                                </a>
                                :
                                null
                            }


                        </div>

                        <div className="new-navbar-desktop__second-section--part" >

                                <a onClick={()=>{this.handleAceCartClick()}} style={{margin:'0',padding:'0'}}>
                                {
                                    typeOfMechiron === 'ace' ? 
                                    <img src={MyListAce} alt="search"/>
                                    : typeOfMechiron === 'mako' ? 
                                    <img src={MyListMako} alt="search"/>
                                    :
                                    <img src={MyListMechiron} alt="search"/>
                                }
                                <p>הרשימה שלי</p>
                                {this.props.totalProductsInCart > 0 ? 
                                    <figure>{this.props.totalProductsInCart}</figure>
                                    :
                                    null
                                }
                            </a>
                            
                        </div>
                    </div>
                </section>
            </nav>
        )
    }
}

function stateToProps({ userCart,levels }) {
    return { userCart,levels }
}
  
export default withRouter(connect(stateToProps, actions)(NewNavbarDesktop))
