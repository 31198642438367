import React from 'react'
import {Link} from 'react-router-dom'
import Check from '../../../../images/icons/redCheck.svg'
import CheckReg from '../../../../images/original/51.svg'
import CheckMako from '../../../../images/mako/cb.svg'
import RedCheckBoxCustom from '../../../../images/icons/checklist-checked-box.svg'
import BlueCheckBoxCustom from '../../../../images/icons/mako/1.svg'

const StageTwo = (props) => {
    const {lang,checkBox,toggleCheckBox,sendAgain,isReg,timing} = props
    return (
        <div className="login__stagetwo">
            <h6>{lang.stageTwoHeader1}</h6>
            <h6>{lang.stageTwoHeader2}</h6>

            <span>
                <div className="login__stageone__form-group">
                    <label htmlFor="" className={props.errValidation ? "login__stageone__form-group--error":""}>{lang.stageTwoCode} {props.errValidation ? " -" + props.errValidation:null}</label>
                    <input type="text" name={'codeInput'} value={props.codeInput} placeholder="xxxx" onChange={(e)=>{props.handleChange('codeInput',e.target.value)}} onFocus={()=>{props.handleChange('codeInput','');props.resetErr()}}/>
                    {!props.resend ? 
                    <span className="login__stagetwo__code" onClick={()=>{sendAgain()}}>{lang.stageTwoCodeVaildation} <span>{lang.stageTwoCodeVaildation2}</span></span>
                    :
                    <span className="login__stagetwo__code">{lang.stageTwoCodeVaildation3 + ' ' + lang.stageTwoCodeVaildation4 + ' ' + timing + ' ' + lang.stageTwoCodeVaildation5}</span>
                    }
                </div>

                <div className="login__stagetwo__check">
                    <span onClick={()=>{toggleCheckBox()}}>
                        {checkBox ? <img src={isReg === 'ace' ? Check: isReg === 'mako' ? CheckMako:CheckReg} alt="checkbox"/>:null}
                    </span>
                    <p>{lang.stageTwoAccept} <a href="/regulations" target="_blank" rel="_noreferrer" rel="_noopener" onClick={()=>{props.saveRegulation()}}>{lang.stageTwoAccept2}</a></p>
                </div>

                {/* <div className="login__stagetwo__pros-text">
                    <header>
                        <p>למה האייסים של אייס?</p>
                    </header>
                    <div>
                        <img src={isReg === 'mako' ? BlueCheckBoxCustom:RedCheckBoxCustom} alt="check"/>
                        <p>הקבלנים מחיובים למחירי העבודות לפי המחירון ואין הפתעות.</p>
                    </div>
                    <div>
                        <img src={isReg === 'mako' ? BlueCheckBoxCustom:RedCheckBoxCustom} alt="check"/>
                        <p>הנכס שלכם מבוטח לכל תקופת השיפוץ והבניה. </p>
                    </div>
                    <div>
                        <img src={isReg === 'mako' ? BlueCheckBoxCustom:RedCheckBoxCustom} alt="check"/>
                        <p>תנאי תשלום ייחודים שמגינים עליכם לפי שלבי השיפוץ והבניה.</p>
                    </div>
                    <div>
                        <img src={isReg === 'mako' ? BlueCheckBoxCustom:RedCheckBoxCustom} alt="check"/>
                        <p>אחריות לשנה על כל העבודות. שנתיים אחריות על אינסטלציה וחשמל.</p>
                    </div>
                </div> */}

            </span>
        </div>
    );
};

export default StageTwo;