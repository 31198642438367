import React, { Component } from 'react'
import './home.css'
import { Link,withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import * as actions from '../../../actions/index'
//components
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
import Loader from '../../globals/Loader'
//images
import HomeImg from '../../../images/homebg.png'
import HomeImgBig from '../../../images/homebgbig.png'
import AceWhite from '../../../images/acewhite.png'
import MechironWhite from '../../../images/original/19.svg'
import MakoWhite from '../../../images/mako/16.svg'
import checkHomeMako from '../../../images/makoDesktop/checkbox-home.svg'

import Choose from '../../../images/icons/choose.svg'
import GetPrice from '../../../images/icons/getprice.svg'
import FindPros from '../../../images/icons/findpros.svg'

import ChooseReg from '../../../images/original/15.svg'
import GetPriceReg from '../../../images/original/14.svg'
import FindProsReg from '../../../images/original/13.svg'

import ChooseMako from '../../../images/mako/Group1.svg'
import GetPriceMako from '../../../images/mako/Group2.svg'
import FindProsMako from '../../../images/mako/Group3.svg'

import ComTextPic1 from '../../../images/homepage/1.png'
import ComTextPic2 from '../../../images/homepage/2.png'
import ComTextPic3 from '../../../images/homepage/3.png'
import ComTextPic4 from '../../../images/homepage/4.png'

import AceArrowBig from '../../../images/arrowacebig.svg'
import MakoArrowBig from '../../../images/arrowmakobig.svg'
import RegArrowBig from '../../../images/arrowregbig.svg'
//config
import {HOST_NAME,USER_TOKEN} from '../../../configs/configs'

class Home extends Component {

    constructor() {
        super()

        this.state = {
            imgLoaded:false,
            typeOfMechiron: '',
            initialDataLoaded: false
        }
    }

    
    async componentWillMount() {

        // if(Cookies.get(USER_TOKEN)) {
            this.props.history.push('/choice')
        // }

        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})

        this.props.saveLevelOnes().then(()=>{
            this.setState({initialDataLoaded: true})
        })
        this.props.saveVendors()
        this.props.saveProsArea()
        this.props.saveProsType()
        this.props.getPros(10,0)

    }


    loadHeader = () => {
        this.setState({imgLoaded:true})
    }

    inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    initializeLogin = () => {
        // if(this.props.desktop || this.props.desktopMako){
        //     if(this.props.activateLoginPopup){
        //         this.props.activateCurtain()
        //         this.props.activateLoginPopup('/choice')
        //     }
        // } else {
        //     this.props.history.push('/login')
        // }
        this.props.history.push('/choice')
    }

    render() {
        const { lang,desktop,desktopMako } = this.props
        const { initialDataLoaded,typeOfMechiron } = this.state
        let runInIframe = this.inIframe()

        return (
            <div className="home">
                <section className="home__first">
                    <div className="home__first__wrapper">
                        <ImageComponent src={desktop && typeOfMechiron === 'ace' ? HomeImgBig:HomeImg} alt={'home'} loadCallback={this.loadHeader}/>
                        <img src={typeOfMechiron === "ace" ? AceWhite: typeOfMechiron === "mako" ? MakoWhite:MechironWhite} alt="logo"  style={this.state.imgLoaded ? {opacity:'1',transition:'all .5s'}:{opacity:'0',transition:'all .5s'}}/>
                        <h1 style={this.state.imgLoaded ? {opacity:'1',transition:'all .5s'}:{opacity:'0',transition:'all .5s'}}>{lang.header}</h1>
                        <h2 style={this.state.imgLoaded ? {opacity:'1',transition:'all .5s'}:{opacity:'0',transition:'all .5s'}}>{lang.subheader}</h2>
                    </div>
                </section>

                <section className="home__second">

                    <img src={typeOfMechiron === "ace" ? AceArrowBig:typeOfMechiron === "mako" ? MakoArrowBig:RegArrowBig} alt="goto" className="home__second__arrow home__second__arrow--1"/>
                    <img src={typeOfMechiron === "ace" ? AceArrowBig:typeOfMechiron === "mako" ? MakoArrowBig:RegArrowBig} alt="goto" className="home__second__arrow home__second__arrow--2"/>

                    <h3>{lang.firstH3}</h3>
                    <div className="home__second__desc">
                        <span>
                            <img src={typeOfMechiron === "ace" ? Choose: typeOfMechiron === "mako" ? ChooseMako:ChooseReg} alt="choose" />
                            <p>{lang.choose}</p>
                        </span>
                        <span>
                            <img src={typeOfMechiron === "ace" ? GetPrice: typeOfMechiron === "mako" ? GetPriceMako:GetPriceReg} alt="getprice" />
                            <p>{lang.getPrice}</p>
                        </span>
                        <span>
                            <img src={typeOfMechiron === "ace" ? FindPros: typeOfMechiron === "mako" ? FindProsMako:FindProsReg} alt="findpros" />
                            <p>{lang.findPros}</p>
                        </span>
                    </div>

                    
                    {typeOfMechiron !== "ace" && typeOfMechiron !== "mako" && runInIframe && !window.location.href.includes('mako')  ?
                        <span className="home__second__button-container" >
                            <a href="/choice" target="_blank" rel="noopener noreferrer">
                                <button disabled={!initialDataLoaded} className="button__red home__button__screen__animation">
                                <figure></figure>
                                {initialDataLoaded ? 
                                        <span>{this.props.totalProductsInCart ? lang.homeBtnWithItems:lang.homeBtn}</span>
                                        :
                                        <Loader />
                                }
                                </button>
                            </a>
                        </span>
                        :
                        <span className="home__second__button-container" >
                            <div onClick={()=>{this.props.history.push('/choice')}} >
                                <button disabled={!initialDataLoaded} className="button__red home__button__screen__animation">
                                <figure></figure>
                                {initialDataLoaded ? 
                                        <span>{this.props.totalProductsInCart ? lang.homeBtnWithItems:lang.homeBtn}</span>
                                        :
                                        <Loader />
                                }
                                </button>
                            </div>
                        </span>
                    }


                    {desktop && typeOfMechiron === 'ace' ? 
                        <span className="home__second__join" onClick={()=>{this.props.activateContactPopup(2)}}>
                            <p>{lang.joisAsPro}</p>
                        </span>
                        :
                        <Link to="/contact/2">
                            <span id="UC: Enter Contact Us Page"  className="home__second__join">
                                <p id="UC: Enter Contact Us Page" >{lang.joisAsPro}</p>
                            </span>
                        </Link>
                    }


                
                </section>

                {true ? 
                     <section className="home__mako-desktop-banners">
                        <header><p>שיפוץ ובניה - רק עם בעלי המקצוע שלנו!</p></header>
                        <div  className="home__mako-desktop-banners__container">
                            <div>
                                <ImageComponent src={ComTextPic1} alt="whypros-section"/>
                                <div>
                                    <p>הקבלנים מחויבים למחירי העבודות לפי המחירון ואין הפתעות.</p>
                                    <img src={checkHomeMako} alt=""/>
                                </div>
                            </div>
                            <div>
                                <ImageComponent src={ComTextPic2} alt="whypros-section"/>
                                <div>
                                    <p>הנכס שלכם מבוטח לכל תקופת השיפוץ והבניה.</p>
                                    <img src={checkHomeMako} alt=""/>
                                </div>
                            </div>
                            <div>
                                <ImageComponent src={ComTextPic3} alt="whypros-section"/>
                                <div>
                                    <p>תנאי תשלום ייחודים שמגינים עליכם לפי שלבי השיפוץ והבניה.</p>
                                    <img src={checkHomeMako} alt=""/>
                                </div>
                            </div>
                            <div>
                                <ImageComponent src={ComTextPic4} alt="whypros-section"/>
                                <div>
                                    <p>אחריות לשנה על כל העבודות. שנתיים אחריות על אינסטלציה וחשמל.</p>
                                    <img src={checkHomeMako} alt=""/>
                                </div>
                            </div>
                        </div>

                        <span className="home__second__button-container">
                            <div onClick={()=>{this.props.history.push('/choice')}}>
                                <button disabled={!initialDataLoaded} className="button__red">
                                
                                {initialDataLoaded ? 
                                        <span>{this.props.totalProductsInCart ? lang.homeBtnWithItems:lang.homeBtn}</span>
                                        :
                                        <Loader />
                                }
                                </button>
                            </div>
                        </span>
                     </section>
                     :
                     null
                }

            <p>{lang.activatedByRamdor}</p>

            </div>
        );
    }
}

function stateToProps({ levels, pros }) {
    return { levels, pros }
}
  
export default withRouter(connect(stateToProps, actions)(Home))
  