import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import './makodesktopnavbar.css'
//img
import LogoMako from '../../../images/mako/16.png'
import BackArrowMako from '../../../images/mako/11.svg'

class NavbarMakoDesktop extends Component {

    constructor() {
        super()
        this.state = {
            prevPath: '/'
        }
    }

    goBackFunc = () => {
        if(this.state.prevPath.includes('login') && window.location.pathname !=='/regulations'){
            this.props.history.push(`/`)
        } else {
            this.props.history.goBack()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
          this.setState({ prevPath: this.props.location.pathname })
        }
    }

    render() {
        return (
           <nav className="navbar__mako__desktop">
                <section className="navbar__mako__desktop__section">
                    <div className="navbar__mako__desktop__section--grid">
                        <div className="navbar__mako__desktop__section--inner">
                            <img src={LogoMako} alt="logomako" className="navbar__mako__desktop__section--inner__mako-logo" onClick={()=>{this.props.history.push('/')}}/>
                            <p><a href="https://www.mako.co.il">חזרה לאתר של mako</a></p>
                        </div>
                        <div className="navbar__mako__desktop__section--inner">
                            <aside onClick={()=>{this.goBackFunc()}}><img src={BackArrowMako} alt="arrow"/></aside>
                        </div>
                    </div>
                </section>

                <section></section>
          
                
           </nav>
        );
    }
}


  
export default withRouter(NavbarMakoDesktop)
