import { combineReducers } from 'redux'
import levelsReducers from './levels'
import prosReducers from './pros'
import userCartReducers from './userCart'
import imagesReducers from './images'

const reducers = combineReducers({
    levels: levelsReducers,
    pros: prosReducers,
    userCart: userCartReducers,
    images: imagesReducers
});

export default reducers;