//production api
// export const API_URL = 'https://ace-api.blmstn.com'
//staging api
// export const API_URL = 'http://localhost:3000'
export const API_URL = 'https://hamechiron.co.il:8443'

export const IMAGE_URL = 'https://hamechiron.co.il'
export const IMAGE_URL_LEVELS = 'https://hamechiron.co.il/'
//export const IMAGE_URL = 'https://obeliskai.s3.eu-central-1.amazonaws.com'
//export const IMAGE_URL_LEVELS = 'https://obeliskai.s3.eu-central-1.amazonaws.com/'

export const USER_TOKEN = 'ace-user'
export const HOST_NAME = 'hamechiron-host-name'
export const BUILD_CART_FOR_USER_AS_PRO = 'build-for-user-cart-id'

export const GOOGLE_ANALYTICS_MECHIRON_ID = 'UA-109093193-1'
export const GOOGLE_ANALYTICS_MAKO_ID = 'UA-150279729-1'
export const GOOGLE_ANALYTICS_ACE_ID = 'UA-148827534-1'

// export const GOOGLE_GTM_ACE_ID = 'GTM-WWHN922'
export const GOOGLE_GTM_ACE_ID = 'GTM-MG9G6KM'
export const GOOGLE_GTM_MAKO_ID = 'GTM-NBF7XRQ'


//
export const MONTHS = ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר']
export const DAYS = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש']
