import React from 'react'
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
import {IMAGE_URL,HOST_NAME} from '../../../configs/configs'
import { CircularProgressbar } from 'react-circular-progressbar'
import {calculatePrices,stringAreaSwap,ApiPostWithBody} from '../../../tools/tools'

//img
import GPS from '../../../images/icons/gps.svg'
import Phone from '../../../images/icons/phone.svg'
import MailW from '../../../images/icons/mailwhite.svg'
import Hands from '../../../images/icons/hands.svg'

export const AceProDetails = (props) => {
    const {item,scoreCounteer,newDesc} = props
    
    return (
            <div className="aces__pros__container__item">
                <div className="aces__pros" onClick={()=>{props.moveToProPage(item.pro_id)}}>
                    <div className="aces__pros__content">
                        <span>
                            <ImageComponent src={IMAGE_URL + '/ace/' + item.pro_id + '.png'} alt="ace-pro" />
                            <div className="">
                                <h1>{item.name}</h1>
                                <p>{newDesc}</p>
                                <span>
                                    <img src={GPS} alt="GPS" />
                                    <p>{stringAreaSwap(item.area_id)}</p>
                                </span>
                            </div>
                        </span>


                        {!isNaN(scoreCounteer) ? 
                            <aside>
                                <CircularProgressbar
                                    value={scoreCounteer*10}
                                    text={`${scoreCounteer}`}
                                    strokeWidth={5}
                                    counterClockwise={false}
                                    styles={{
                                        text: {
                                            fill: 'black',
                                            fontSize: '40px',
                                            fontFamily: 'f400',
                                            letterSpacing: '0.3px',
                                            lineHeight: '1.13'
                                        },
                                    }}
                                />
                            </aside>
                            :
                            null
                        }


                    </div>

                
                    <section className="aces__pros--contact-info">

                            <span style={{width:'100%'}}>
                                <img src={Hands} alt="hire" />
                                <h1>בואו נדבר</h1>
                            </span>

                    </section>
                </div>
            </div>
    )
}
