import React, { Component } from 'react'
import { Switch, Route, withRouter, Link, Router } from 'react-router-dom'
//components
import CustomersList from './parts/CustomersList'
import NewCustomer from './parts/NewCustomer'
import ExistCustomer from './parts/existCustomer'


export default class Customers extends Component {
    render() {
        const {activateCurtain, disActivateCurtain} = this.props
        return (
            <div>
                <Switch>
                    <Route exact path="/pros/interface/:proId/customers/newCustomer" render={() => <NewCustomer activateCurtain={activateCurtain} disActivateCurtain={disActivateCurtain}/>} />
                    <Route exact path="/pros/interface/:proId/customers/:activeFilter" render={() => <CustomersList activateCurtain={activateCurtain} disActivateCurtain={disActivateCurtain}/>} />
                    <Route exact path="/pros/interface/:proId/customers/customerCartId/:customerCartId/customerUserId/:customerUserId/levelOneId/:levelOneId" render={() => <ExistCustomer /> } />
                    <Route exact path="/pros/interface/:proId/customers/customerCartId/:customerCartId/customerUserId/:customerUserId" render={() => <ExistCustomer /> } />
                </Switch>
            </div>
        )
    }
}
