import React, { Component } from 'react'
import  {withRouter} from 'react-router-dom'
import ReactGA from 'react-ga';
//configs
import {GOOGLE_ANALYTICS_MECHIRON_ID,GOOGLE_ANALYTICS_ACE_ID,GOOGLE_ANALYTICS_MAKO_ID} from '../../configs/configs'

class ScrollToTop extends Component {

  constructor() {
    super()

    this.state = {
      prevPath: '' 
    }
  } 


  componentDidMount() {
        if(this.props.typeOfMechiron === 'ace') {
            ReactGA.initialize([
              {trackingId: GOOGLE_ANALYTICS_MECHIRON_ID, gaOptions: { name: 'trackerMechiron' }},
              {trackingId: GOOGLE_ANALYTICS_ACE_ID, gaOptions: { name: 'trackerAce' }} 
            ])
            ReactGA.pageview(window.location.pathname + window.location.search,['trackerMechiron','trackerAce'])
        } else if(this.props.typeOfMechiron === 'mako'){
            ReactGA.initialize([
              {trackingId: GOOGLE_ANALYTICS_MECHIRON_ID, gaOptions: { name: 'trackerMechiron' }},
              {trackingId: GOOGLE_ANALYTICS_MAKO_ID, gaOptions: { name: 'trackerMako' }} 
            ])
            ReactGA.pageview(window.location.pathname + window.location.search,['trackerMechiron','trackerMako'])
        } else {
            ReactGA.initialize(GOOGLE_ANALYTICS_MECHIRON_ID)
            ReactGA.pageview(window.location.pathname + window.location.search)
        }
    }
  
  
    componentDidUpdate(prevProps) {

      if(this.props.location !== prevProps.location){
          if(this.props.typeOfMechiron === 'ace') {
              ReactGA.pageview(window.location.pathname + window.location.search,['trackerMechiron','trackerAce'])
          } else if(this.props.typeOfMechiron === 'mako'){
              ReactGA.pageview(window.location.pathname + window.location.search,['trackerMechiron','trackerMako'])
          } else {
              ReactGA.pageview(window.location.pathname + window.location.search)
          }
         
      }

      if ( (this.props.location !== prevProps.location) && (this.state.prevPath !== this.props.location.pathname) && !this.props.location.hash) {
        this.props.disActivateCurtain()
        if(
          (prevProps.location.pathname.includes('edit') && prevProps.location.pathname.includes('roomindex') )
          ||
          (prevProps.location.pathname.includes('editproductinusercart'))
        ){
          
          this.props.setScrollToItemBoolean(true)
        } else {
          setTimeout(()=>{ window.scrollTo(0, 0) }, 0);
          this.props.setScrollToItemBoolean(false)
        }
        
      }

      if (prevProps.location.pathname !== this.props.location.pathname) {
        this.props.disActivateCurtain()
        this.setState({ prevPath: prevProps.location.pathname })
      }
    }
  
    render() {
      return this.props.children
    }
  }
  
  export default withRouter(ScrollToTop)