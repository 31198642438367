import React, { Component } from 'react'
import './choice.css'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../../actions/index'
import { IMAGE_URL,IMAGE_URL_LEVELS } from '../../../configs/configs'
import Cookies from 'js-cookie'
//components
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
import InnerNavigation from '../../globals/InnerNavigation/InnerNavigation'
import Calculator from '../Calculator/Calculator'
//images
import AceRed from '../../../images/acered.png'
import Icon from '../../../images/icons/roomchoiceicon.svg'
import IconReg from '../../../images/original/12.svg'
import IconMako from '../../../images/mako/21.svg'
import CancelCircle from '../../../images/icons/deskpopupcancel.svg'
import EditIcon from '../../../images/icons/penwhite.svg'
//config
import {HOST_NAME} from '../../../configs/configs'
//text 
import * as Hebrew from '../../../texts/hebrew'

class Choice extends Component {

    constructor() {
        super()

        this.state = {
            loader: false,
            pictureArray:[],
            typeOfMechiron: '',
            desktopPopupCalculator:false
        }
    }

    componentDidMount(){
        setTimeout(()=>{ window.scrollTo(0, 0) }, 0);
        window.addEventListener("click", this.handleClick);
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.handleClick);
    }

    
    componentWillMount() {
        let typeOfMechiron = Cookies.get(HOST_NAME)
        this.setState({typeOfMechiron})

        let obj = this.props.levels.levelOnes
        if(Object.keys(obj).length === 0 && obj.constructor === Object) {
            this.props.saveVendors()
            this.props.saveLevelOnes()
        }
    }


    handleClick = (e) => {
        if(e.target.className.includes('curtain') && this.state.desktopPopupCalculator)
        this.setState({desktopPopupCalculator:false},()=>{this.props.disActivateCurtain()})
    }


    getLevelTwo = (item) => {
        //init room to userCart
        this.props.initUserCartRoom(item,this.props.userCart)
        //id is the level one id and its used as path in URL as well
        this.props.addToPath(item.level_one_id)
        this.props.saveSelectedLevelOneItem(item).then(()=>{
             this.props.getRecommendation(item.level_one_id, this.state.typeOfMechiron)
        })
        this.setState({
            loader:true
        },()=>{
            this.props.saveLevelTwos(item.level_one_id).then(()=>{
                this.setState({
                    loader:true
                },async()=>{
                    if(this.props.desktop || this.props.desktopMako) {
                        let showEdit = false
                        Object.keys(this.props.userCart.userCart).map(room=>{
                           if(this.props.userCart.userCart[room].level_one_id === item.level_one_id && this.props.userCart.userCart[room].products.length > 0)
                           showEdit = true
                        })

                        if(!showEdit) {
                            // this.props.activateCurtain()
                            // await this.props.saveSelectedLevelOneItem(item)
                            // this.setState({desktopPopupCalculator:true})
                            this.props.history.push('/calculator/' + item.level_one_id + '/room')
                        } else {
                            this.props.history.push('/calculator/' + item.level_one_id + '/room')
                        }

                    } else {
                        let showEdit = false
                        Object.keys(this.props.userCart.userCart).map(room=>{
                           if(this.props.userCart.userCart[room].level_one_id === item.level_one_id && this.props.userCart.userCart[room].products.length > 0)
                           showEdit = true
                        })

                        if(!showEdit) {
                            // this.props.history.push('/calculator/' + item.level_one_id)
                            this.props.history.push('/calculator/' + item.level_one_id + '/room')
                        } else {
                            this.props.history.push('/calculator/' + item.level_one_id + '/room')
                        }

                        
                    }
                    
                })
                
            })
        })

    }

    openCalculator = (e,item) => {
        e.stopPropagation()
        this.props.saveSelectedLevelOneItem(item).then(()=>{
            this.props.getRecommendation(item.level_one_id, this.state.typeOfMechiron)
       })
       this.setState({
           loader:true
       },()=>{
           this.props.saveLevelTwos(item.level_one_id).then(()=>{
               this.setState({
                   loader:true
               },async()=>{
                   if(this.props.desktop || this.props.desktopMako) {
                       this.props.activateCurtain()
                       await this.props.saveSelectedLevelOneItem(item)
                       this.setState({desktopPopupCalculator:true})
                   } else {
                       this.props.history.push('/calculator/' + item.level_one_id)
                   }
                   
               })
               
           })
       })
    }

    render() {
        const { lang,desktop,desktopMako,disActivateCurtain } = this.props
        const { loader,typeOfMechiron,desktopPopupCalculator } = this.state
        const { levelOnes } = this.props.levels
        const { userCart } = this.props.userCart
     
       
        
        return (
            <div className="choice">

            {(desktopPopupCalculator && desktop) || (desktopPopupCalculator && desktopMako) ?
                <div className="choice__calculator__input">
                    <img src={CancelCircle} alt="cancel-popup" className="choice__calculator__input__x" onClick={()=>{this.setState({desktopPopupCalculator:false});this.props.disActivateCurtain()}}></img>
                    <Calculator disActivateCurtain={disActivateCurtain} desktop={(desktop|| desktopMako)} lang={Hebrew.Calculator}/>
                </div>
                :
                null
            }

                <section>

                    {desktop || desktopMako ?
                        <InnerNavigation pages={['ראשי', 'חדרים']} links={['/','/']} />
                        :
                        null
                    }

                    {desktop ?
                        <h1>חדרים</h1>
                        :
                        null
                    }

                    <aside>
                        <img src={typeOfMechiron === 'ace' ? Icon: typeOfMechiron === 'mako' ? IconMako:IconReg} alt="room-choice" />
                        <h4>
                           בחרו את החדר שברצונכם לשפץ
                        </h4>
                    </aside>

                    <div className="choice__images__container">
                        {Object.keys(levelOnes).length > 0 && levelOnes.map((item, index) => {
                            let showEdit = false
                            Object.keys(userCart).map(room=>{
                               if(userCart[room].level_one_id === item.level_one_id && userCart[room].products.length > 0)
                               showEdit = true
                            })

                            return <div key={item.level_one_id} onClick={()=>{this.getLevelTwo(item)}}>
                                <div id={"UC: Clicked On Room - " + item.name} className="choice__images__container__image">
                                    {showEdit ? <span onClick={(e)=>{this.openCalculator(e,item)}} className="choice__images__container__image__editor"><img src={EditIcon} alt="edit-measurements"/></span>:null}
                                    <div id={"UC: Clicked On Room - " + item.name}>
                                        <span id={"UC: Clicked On Room - " + item.name} className="choice__images__container__image__small-screens">
                                            <div id={"UC: Clicked On Room - " + item.name}>בחר בחדר</div>
                                        </span>
                                        <ImageComponent src={IMAGE_URL_LEVELS + 'ace/' + item.picture_l1_id + '.png'} alt={item.level_one_id} />
                                    </div>
                                    <p id={"UC: Clicked On Room - " + item.name}>{item.name}</p>
                                </div>
                            </div>
                        })}
                    </div>
                </section>

            </div>
        );
    }
}


function stateToProps({ levels,userCart,images }) {
    return { levels,userCart,images }
}
  
export default withRouter(connect(stateToProps, actions)(Choice))