
//levels
export const SAVE_VENDORS = 'SAVE_VENDORS'
export const SAVE_SELECTED_PRODUCT = 'SAVE_SELECTED_PRODUCT'
export const SAVE_LEVEL_ONES = 'SAVE_LEVEL_ONES'
export const SAVE_LEVEL_TWOS = 'SAVE_LEVEL_TWOS'
export const SAVE_LEVEL_THREES = 'SAVE_LEVEL_THREES'
export const ADD_TO_PATH = 'ADD_TO_PATH'
export const SET_FILTER = 'SET_FILTER'
export const SAVE_FILTERS = 'SAVE_FILTERS'
export const SAVE_PRODUCTS = 'SAVE_PRODUCTS'
export const SAVE_SELECTED_LEVEL_ONE = 'SAVE_SELECTED_LEVEL_ONE'
export const SAVE_SELECTED_LEVEL_TWO = 'SAVE_SELECTED_LEVEL_TWO'
export const SAVE_SELECTED_LEVEL_THREE = 'SAVE_SELECTED_LEVEL_THREE'
export const SET_MEASUREMENTS = 'SET_MEASUREMENTS'
export const RESET_PRODUCTS = 'RESET_PRODUCTS'
export const SAVE_RECOMMENDATION = 'SAVE_RECOMMENDATION'
export const UPDATE_RECOMMENDATION_QUANTITY = 'UPDATE_RECOMMENDATION_QUANTITY'
export const DELETE_RECOMMENDATION_ITEM = 'DELETE_RECOMMENDATION_ITEM'
export const SET_FALSE_SAVE_PRODUCTS = 'SET_FALSE_SAVE_PRODUCTS'
export const SET_TRUE_SAVE_PRODUCTS = 'SET_TRUE_SAVE_PRODUCTS'
export const RESET_CATEGORY_STATE = 'RESET_CATEGORY_STATE'
export const REPLACE_RECOMMENDATION_PRODUCT = 'REPLACE_RECOMMENDATION_PRODUCT'
export const CHANGE_SELECTED_MEASURMENTS = 'CHANGE_SELECTED_MEASURMENTS'
export const SAVE_MOST_VIEWED = 'SAVE_MOST_VIEWED'
export const SET_VENDORS_BOOLEAN = 'SET_VENDORS_BOOLEAN'
export const SET_REVERSE_VENDORS_BOOLEAN = 'SET_REVERSE_VENDORS_BOOLEAN'
export const SET_FILTERS_BOOLEAN = 'SET_FILTERS_BOOLEAN'
export const SET_REVERSE_FILTERS_BOOLEAN = 'SET_REVERSE_FILTERS_BOOLEAN'
export const SET_LVLTWO_BOOLEAN ='SET_LVLTWO_BOOLEAN'
export const SET_REVERSE_LVLTWO_BOOLEAN ='SET_REVERSE_LVLTWO_BOOLEAN'
export const SET_LVLONE_BOOLEAN = 'SET_LVLONE_BOOLEAN'
export const SET_REVERSE_LVLONE_BOOLEAN ='SET_REVERSE_LVLONE_BOOLEAN'
export const SET_LVLTHREE_BOOLEAN = 'SET_LVLTHREE_BOOLEAN'
export const SET_REVERSE_LVLTHREE_BOOLEAN ='SET_REVERSE_LVLTHREE_BOOLEAN'
export const STOP_PRODUCT_PAGINATION = 'STOP_PRODUCT_PAGINATION'
export const REINIT_PAGINATION = 'REINIT_PAGINATION'
export const SAVE_SEARCH_RESULTS = 'SAVE_SEARCH_RESULTS'
export const GET_MORE_SEARCH_RESULTS = 'GET_MORE_SEARCH_RESULTS'
export const STOP_MORE_SEARCH_RESULTS = 'STOP_MORE_SEARCH_RESULTS'
export const SAVE_COMPLEMENTARY_PRODUCTS = 'SAVE_COMPLEMENTARY_PRODUCTS'

//pros
export const SAVE_PROS_TYPE = 'SAVE_PROS_TYPE'
export const SAVE_PROS_AREA = 'SAVE_PROS_AREA'
export const ADD_PROS = 'ADD_PROS'
export const SET_SELECTED_PRO = 'SET_SELECTED_PRO'
export const RESET_PROS_LIST = 'RESET_PROS_LIST'
export const SAVE_REVIEWS = 'SAVE_REVIEWS'
//userCart
export const INIT_USER_CART_ROOM = 'INIT_USER_CART_ROOM'
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
export const UPDATE_QUANTITY_OF_CART_ITEM = 'UPDATE_QUANTITY_OF_CART_ITEM'
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART'
export const REPLACE_CART_ITEM = 'REPLACE_CART_ITEM'
export const EDIT_CART_ITEM = 'EDIT_CART_ITEM'
export const SAVE_USER_ID = 'SAVE_USER_ID'
export const SAVE_INITIAL_CART_DATA = 'SAVE_INITIAL_CART_DATA'
export const SAVE_REGULATION = 'SAVE_REGULATION'
export const SAVE_USE_DETAILS = 'SAVE_USE_DETAILS'
export const SAVE_MEASUREMENT_FROM_CART = 'SAVE_MEASUREMENT_FROM_CART'
export const DELETE_ROOM_FROM_CART = 'DELETE_ROOM_FROM_CART'
export const REMOVE_ROOMS_WITHOUT_PRODUCTS = 'REMOVE_ROOMS_WITHOUT_PRODUCTS'
export const SET_USER_IS_ACE_PRO = 'SET_USER_IS_ACE_PRO'
export const SET_USER_CART_ID_TO_EDIT_AS_PRO = 'SET_USER_CART_ID_TO_EDIT_AS_PRO'
export const RESET_CART = 'RESET_CART'
export const SHOW_BACK_TO_INTERFACE_BTN = 'SHOW_BACK_TO_INTERFACE_BTN'
export const HIDE_BACK_TO_INTERFACE_BTN = 'HIDE_BACK_TO_INTERFACE_BTN'
export const SAVE_URL_TO_REDIRECT_INTERFACE = 'SAVE_URL_TO_REDIRECT_INTERFACE'
export const REMOVE_URL_TO_REDIRECT_INTERFACE = 'REMOVE_URL_TO_REDIRECT_INTERFACE'
export const SAVE_OFFSET_FOR_ITEM_CHANGE = 'SAVE_OFFSET_FOR_ITEM_CHANGE'
export const SAVE_PID_FOR_OFFSET_CHANGE ='SAVE_PID_FOR_OFFSET_CHANGE'
//images
export const SAVE_LEVEL_ONES_IMAGES = 'SAVE_LEVEL_ONES_IMAGES' 
