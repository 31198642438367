import React, { Component } from 'react'
import  {withRouter} from 'react-router-dom'
import moment from "moment"
import he from 'date-fns/locale/he'
import 'moment/locale/he'
import Cookie from 'js-cookie'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {ApiGetWithHeader} from '../../../../tools/tools'
import { API_URL, USER_TOKEN } from '../../../../configs/configs'

const renderDayContents = (day, date) => {
    const tooltipText = `Tooltip for date: ${date}`;
    return <span title={tooltipText}>{day}<aside></aside></span>;
}

 class Calender extends Component {

    constructor(){
        super()

        this.state = {
            date: new Date(),
            meetingsForPro:[]
        }
    }

    async componentDidMount() {
        this.fetchMeetings(new Date())
    }

    fetchMeetings = async(date) => {
        date = moment(date)
        let token = Cookie.get(USER_TOKEN)

        let from = date.startOf('month')
        from = from.toDate()
        from = new Date(from.getTime() - (from.getTimezoneOffset() * 60000)).toISOString()
        
        let to = date.add(1, 'M')
        to = to.endOf('month')
        to = to.toDate()
        to = new Date(to.getTime() - (to.getTimezoneOffset() * 60000)).toISOString()

      
        let meetingsForPro = await ApiGetWithHeader(API_URL + `/prointerface/meeting/getBetweenDates?from=${from}&to=${to}`,token)
        // insert user name to array
        let newArrayWithName = []
        await Promise.all(
            meetingsForPro.map( async(meeting) => {
                let user_name = await ApiGetWithHeader(API_URL + `/getUserNameByUserId/${meeting.client_id}`,token)
                if(user_name){
                    // handle canceled bids meetingss
                    let dateByUserId = await ApiGetWithHeader(API_URL + `/prointerface/getById/${meeting.client_id}`,token)
                    if(dateByUserId[0].status !== 4){
                        newArrayWithName.push({...meeting, user_name:user_name.full_name,canceled:true})
                    } else {
                        newArrayWithName.push({...meeting, user_name:user_name.full_name,canceled:false})
                    }
                }
                
            })
        )
        
        this.setState({meetingsForPro:newArrayWithName})
    }
    

    handleDateChange = (date,reset = false) => {
        //reset returning current view to current month
        if(reset){
            this.setState({date:null},()=>{
                this.fetchMeetings(date)
                this.setState({date})
            })
        } else {
            this.setState({date})
        }

    }


    handleMonthChange = (date) => {
        //fetch meetings for current month
        this.fetchMeetings(date)
    }

    editMeeting = (meetingId) => {
        const {proId, customerUserId, customerCartId} = this.props.match.params
        this.props.history.push(
            `/pros/interface/${proId}/schedule/appointment/meetingId/${meetingId}`
        )
    }




    render() {
        const {date,meetingsForPro} = this.state
        let datesWithMeetings = meetingsForPro.map(meeting =>{
            return new Date(meeting.meet_at)
        })

        const highlightWithRanges = [
          {
            "react-datepicker__day--highlighted-custom-1": datesWithMeetings
          }
        ]

        let meetingsForCurrentDay = meetingsForPro.filter(meeting =>{
            let compareableDate = new Date(meeting.meet_at)
            let today = new Date(date)

            compareableDate.setHours(12, 0, 0, 0)
            today.setHours(12, 0, 0, 0)

            return today.getTime() === compareableDate.getTime()
        })

        meetingsForCurrentDay = meetingsForCurrentDay.sort( (a, b)=>{
            return new Date(a.meet_at).getTime() - new Date(b.meet_at).getTime()
        })

        let fillerArray = []
        if(meetingsForCurrentDay.length < 5)
        for(let i = 0; i < (5 - meetingsForCurrentDay.length); i++){
            fillerArray.push(true)
        }
        

        return (
            <div className="pros-interface__calender">
                
                <span style={{position:'relative'}}>
                    <DatePicker
                        selected={date}
                        onChange={this.handleDateChange}
                        locale={he}
                        inline
                        renderDayContents={renderDayContents}
                        highlightDates={highlightWithRanges}
                        calendarClassName = {'pros-interface__custom-calender'}
                        // todayButton="היום"
                        onMonthChange={this.handleMonthChange}
                    />
                    <button className="pros-interface__calender__today-btn" onClick={()=>{this.handleDateChange(new Date(),true)}}>היום</button>
                </span>


                <section className="pros-interface__calender__meetings">
                    {meetingsForCurrentDay.map(meeting=>{
                        let hours = new Date(meeting.meet_at).getHours()
                        let minutes = new Date(meeting.meet_at).getMinutes() === 0 ? '00':new Date(meeting.meet_at).getMinutes()
                        return <div className={!meeting.canceled ? "pros-interface__calender__meetings__meeting pros-interface__calender__meetings__meeting--canceled":"pros-interface__calender__meetings__meeting"} key={meeting._id} onClick={()=>{this.editMeeting(meeting._id)}}>
                                    <div>
                                        <p>שעת פגישה</p>
                                        <p>{hours + ':' + minutes}</p>
                                    </div>
                                    <div>
                                        <p>{ 'פגישה עם' + ' ' +  meeting.user_name + ' '  + meeting.address} {!meeting.canceled ? '(בוטל)':null}</p>
                                        <p>{meeting.notes}</p>
                                    </div>
                               </div>
                    })}

                    {fillerArray.map( (item, index) => 
                        <div className="pros-interface__calender__meetings__meeting pros-interface__calender__meetings__meeting--filler">
                        {index === 2 && meetingsForCurrentDay.length === 0 ? 'אין פגישות':null}
                        </div>
                    )}
                </section>

            </div>
        )
    }
}

export default withRouter(Calender)