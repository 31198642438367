import React from 'react'
import './regulations.css'
import Ancor from '../../../images/icons/ancor.svg'

const Regulations = (props) => {

    const {lang,aceApp} = props

    return (

        <main className="regulations">
            <h1>{lang.header}</h1>
            {aceApp ? 
                <div className="regulations__ancors">
                   {lang.ancorsAce.map((item,index)=>
                        <div><img src={Ancor} alt="ancor"/><a href={'#ancor-regulations' + index}>{item}</a></div>
                   )}
                </div>
                :
                <div className="regulations__ancors">
                    {lang.ancorsReg.map((item,index)=>
                        <div><img src={Ancor} alt="ancor"/><a href={'#ancor-regulations' + index}>{item}</a></div>
                    )}
                </div>
            }


            {aceApp ? 
                <div className="regulations__main">
                    {lang.mainContentAce.map((otherArr,index)=>{
                        return <div className="regulations__main__section">
                                    <h1 id={'ancor-regulations' + index} >{index+1 + '. ' +lang.ancorsAce[index]}</h1>
                                   { otherArr.map(innerArr=>{
                                       return   <div className="regulations__main__section__subject">
                                                    <p>{innerArr.index}</p>
                                                    <p>{innerArr.text}</p>
                                                </div>
                                   })}
                               </div>
                    })}
                </div>
                :
                <div className="regulations__main">
                    {lang.mainContentReg.map((otherArr,index)=>{
                        return <div className="regulations__main__section">
                                    <h1 id={'ancor-regulations' + index} >{index+1 + '. ' +lang.ancorsAce[index]}</h1>
                                    { otherArr.map(innerArr=>{
                                       return   <div className="regulations__main__section__subject">
                                                    <p>{innerArr.index}</p>
                                                    <p>{innerArr.text}</p>
                                                </div>
                                   })}
                               </div>
                    })}
                </div>
            }


        </main>
        
    );
};

export default Regulations;