import React, {Component} from 'react';
import SwipeableViews from 'react-swipeable-views'
//img
import ArrowAce from '../../../images/newhomepage/arrowace.svg'
import ArrowMako from '../../../images/newhomepage/arrowmako.svg'
import ArrowHamechiron from '../../../images/newhomepage/arrowmechiron.svg'
//configs
import {IMAGE_URL} from '../../../configs/configs'
//comps
import ImageComponent from '../../globals/ImageComponent/ImageComponent'
// images aces temporary
import ace1 from '../../../images/newhomepage/aces/1.png'
import ace2 from '../../../images/newhomepage/aces/2.png'
import ace3 from '../../../images/newhomepage/aces/3.png'
import ace4 from '../../../images/newhomepage/aces/4.png'
import ace5 from '../../../images/newhomepage/aces/5.png'
import ace6 from '../../../images/newhomepage/aces/6.png'
import ace7 from '../../../images/newhomepage/aces/7.png'
import ace8 from '../../../images/newhomepage/aces/8.png'
import ace9 from '../../../images/newhomepage/aces/9.png'
import ace10 from '../../../images/newhomepage/aces/10.png'
import ace11 from '../../../images/newhomepage/aces/11.png'
import ace12 from '../../../images/newhomepage/aces/12.png'
import ace13 from '../../../images/newhomepage/aces/13.png'
import ace14 from '../../../images/newhomepage/aces/14.png'
import ace15 from '../../../images/newhomepage/aces/15.png'
import ace16 from '../../../images/newhomepage/aces/16.png'
import ace17 from '../../../images/newhomepage/aces/17.png'
import ace18 from '../../../images/newhomepage/aces/18.png'

const picArray = [ace1,ace2,ace3,ace4,ace5,ace6,ace7,ace8,ace9,ace10,ace11,ace12,ace13,ace14,ace15,ace16,ace17,ace18]

const styles = {
  root: {
    direction: 'rtl',
    position: 'relative',
  },
  slide: {
    direction: 'rtl',
    padding: 15,
    minHeight: 100,
    color: '#fff',
    
  },
  containerStyle: {
    width:'70%',
    padding: ' 10px 0',
    height: '305px',
    transform: 'translate(-1%, 0px)'
  },
  containerStyleDesktop: {
    width:'25%',
    padding: ' 10px 0',
    height: '305px',
    transform: 'translate(-1%, 0px)'
  }
};

class HomeSlider extends Component {

    constructor() {
      super()

      this.state = {
          index: 0
      }
    }

    changeActive = (type) => {
      let newIndex = this.state.index
      let limit = this.props.sliderItems.length -1
        if(type === 'inc'){
          if(newIndex === limit)return
          newIndex = newIndex + 1
          this.setState({index:newIndex})
        } else if(type === 'dec'){
          if(newIndex === 0)return
          newIndex = newIndex - 1
          this.setState({index:newIndex})
        }
    }

    handleClick = (pro_id) => {
      this.props.navigate(`/aces/type/${pro_id}`)
    }

    render() {

        const {sliderItems,typeOfMechiron,desktopVersion} = this.props
        let arrowSrc = typeOfMechiron === 'ace' ? ArrowAce:typeOfMechiron === 'mako' ? ArrowMako:ArrowHamechiron

        return (
            <div style={styles.root}>
              <aside onClick={()=>{this.changeActive('inc')}} className="slider__arrow--next">
                <img src={arrowSrc}></img>
              </aside>
              
              <SwipeableViews 
                axis="x-reverse" 
                containerStyle={desktopVersion ? styles.containerStyleDesktop:styles.containerStyle} 
                slideCount={sliderItems.length} 
                index={this.state.index}
              >
                  {sliderItems.map((item,index)=>{
                      return <div className="home-slider-item" onClick={()=>{this.handleClick(item.pro_id)}}>
                                <div className="home-slider-item__section-img">
                                  <img src={picArray[index]} alt=""/>
                                  {(index === this.state.index) && !desktopVersion ? <aside><p>בואו נדבר</p></aside>:null}
                                  <aside className="home-slider-item__banner"><p>בואו נדבר</p></aside>
                                </div>
                                <div className="home-slider-item__section-pro">
                                  <figure>
                                     <ImageComponent className={'home-slider-item__section-pro__image'} src={IMAGE_URL + '/ace/' + item.pro_id + '.png'} alt="pro"/>
                                  </figure>
                                  <p>{item.name}</p>
                                </div>
                             </div>
                  })}
              </SwipeableViews>

              <aside onClick={()=>{this.changeActive('dec')}} className="slider__arrow--prev">
                <img src={arrowSrc}></img>
              </aside>
            </div>
        )
    }
}

export default HomeSlider;
