import { 
    SAVE_LEVEL_ONES_IMAGES
} from '../actions/types'

const initialState = {
    levelOnesImages: []
}

export default function(state = initialState, action) {

    switch(action.type) {
        case(SAVE_LEVEL_ONES_IMAGES) : {
            return { ...state,levelOnesImages: action.payload }
        }

        default: 
            return state
    }
}