import React from 'react'

const NavbarPlaceholder = (props) => {
    return (
        // <div  style=
        // {
        //    props.show? {height:'55px'}:
        //    (!props.showNav && !window.location.pathname.includes('search')) || (window.location.pathname.includes('search') && window.innerWidth < 940) ? {display:'none'} :
        //    props.big ? {height:'150px'}:{}
        // }  
        //  className="navbar__placeholder">
            
        // </div>
        <div className="navbar__placeholder" style={props.showNav ? {}:{display:'none'}}>
            
        </div>
    );
};

export default NavbarPlaceholder;