import React from 'react'
import CheckAce from '../../../images/banner/checkace.svg'
import CheckMako from '../../../images/banner/checkmako.svg'

export const Banner = (props) => {
    const {typeOfMechiron} = props
    return (
        <div className="aces__banner">
        <h1>מה מיוחד באנשי המקצוע שלנו?</h1>
        <section>
            <span>
                <img src={typeOfMechiron === 'ace' ? CheckAce:CheckMako} alt="check"/>
                <p>מחויבים למחירי העבודות לפי המחירון.</p>
            </span>
            <span>
                <img src={typeOfMechiron === 'ace' ? CheckAce:CheckMako} alt="check"/>
                <p>תנאי תשלום ייחודיים שמגנים עלייך בשלבי השיפוץ והבנייה.</p>
            </span>
        </section>
        <section>
            <span>
                <img src={typeOfMechiron === 'ace' ? CheckAce:CheckMako} alt="check"/>
                <p>אחריות לשנה על כל העבודות. שנתיים על אינסטלציה וחשמל!</p>
            </span>
            <span>
                <img src={typeOfMechiron === 'ace' ? CheckAce:CheckMako} alt="check"/>
                <p>הנכס שלך מבוטח לכל תקופת השיפוץ והבנייה.</p>
            </span>
        </section>
    </div>
    )
}
